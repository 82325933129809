import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'marker-tile',
  templateUrl: './marker-tile.component.html',
  styleUrls: ['./marker-tile.component.scss']
})
export class MarkerTileComponent {
  @Input() data: any;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

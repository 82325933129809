import { Component, HostBinding, OnInit } from '@angular/core';
import { AmplitudeService } from '@fg-services/amplitude.service';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { UiService } from '@fg-services/ui.service';
import { CategoryTileComponent } from '@fg-shared/tile/category-tile/category-tile.component';
import { Category } from '@fg-types/category';
import { ConfirmationService } from 'primeng/api';

export enum CategoryModelName {
  Global = 'Global',
  Organization = 'Organization'
}

@Component({
  selector: 'categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent implements OnInit {
  @HostBinding('class') classList = 'fg-view-container fg-with-header';
  selectedItem: Partial<Category>;
  items: Partial<Category>[];
  loading: boolean;
  isDirty: boolean;
  query: string;
  lastKey: string;
  listDetailComponent = CategoryTileComponent;
  constructor(
    private amplitudeService: AmplitudeService,
    private apiCallsInProgress: ApiCallsInProgress,
    private api: ApiService,
    private uiService: UiService,
    private confirmationService: ConfirmationService
  ) {
    this.amplitudeService.logEvent('VIEW', { TITLE: 'Oz > Library > Categories' });
  }

  ngOnInit() {
    this.loadItems();
  }

  onSelectItem(item?: Partial<Category>) {
    this.selectedItem = item;
    this.isDirty = false;
  }

  onScroll() {
    if (this.lastKey && !this.loading) {
      this.loadItems();
    }
  }

  loadItems() {
    this.loading = true;
    this.apiCallsInProgress.addCall();
    this.api.search
      .searchCategories(this.query, CategoryModelName.Global, null, this.lastKey)
      .subscribe(({ lastKey, items }) => {
        this.items = this.lastKey ? [...this.items, ...items] : items;
        this.lastKey = lastKey;
        this.apiCallsInProgress.reset();
      }, this.uiService.errorHandler);
  }

  searchCategory(query) {
    this.query = query;
    this.lastKey = '';
    this.loadItems();
  }

  deleteCategory(item: Partial<Category>) {
    this.confirmationService.confirm({
      message: `Are you sure you want to delete ${item.name}?`,
      header: 'Warning',
      acceptLabel: 'CONFIRM',
      rejectLabel: 'CANCEL',
      accept: () => {
        this.loading = true;
        this.apiCallsInProgress.addCall();
        this.api.categories.deleteCategory(item.id).subscribe(() => {
          this.loading = false;
          this.apiCallsInProgress.reset();
          const index = this.items.findIndex(convention => convention.id === item.id);
          this.items.splice(index, 1);
          if (this.items.length) {
            this.onSelectItem(this.items[index]);
          }
        }, this.uiService.errorHandler);
      }
    });
  }

  upsertCategory() {
    this.loading = true;
    this.apiCallsInProgress.addCall();
    if (this.selectedItem.id) {
      this.api.categories.patchCategory(this.selectedItem.id, this.selectedItem).subscribe(() => {
        this.loading = false;
        this.isDirty = false;
        this.apiCallsInProgress.reset();
      }, this.uiService.errorHandler);
    } else {
      this.api.categories.createCategory(this.selectedItem).subscribe(response => {
        this.items.unshift(response);
        this.onSelectItem(response);
        this.loading = false;
        this.apiCallsInProgress.reset();
      }, this.uiService.errorHandler);
    }
  }
}

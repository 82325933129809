import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Organization } from '@fg-types/organization.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'organization-card',
  templateUrl: './organization-card.component.html',
  styleUrls: ['./organization-card.component.scss']
})
export class OrganizationCardComponent implements OnInit, ListItem<Organization> {
  get image() {
    if (!this.data.headerMedia) return undefined;
    if (typeof this.data.headerMedia === 'string') return `url(${this.data.headerMedia})`;
    if (this.data.headerMedia.url) return `url(${this.data.headerMedia.url})`;
    if (this.data.headerMedia.sizes && this.data.headerMedia.sizes.length)
      return `url(${this.data.headerMedia.sizes[0].url})`;
  }

  @Input() data: Organization;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  constructor() {}
  ngOnInit() {}
}

<div class="input-container p-col-12">
  <div class="input-cell">
    <label *ngIf="title">{{ title }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="category"
        [suggestions]="filteredCategories"
        placeholder="Type to search a Category"
        field="calcName"
        (onSelect)="addCategory.emit($event); category = ''"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
        minLength="0"
        (completeMethod)="filter($event)"
        completeOnFocus="1"
      >
      </p-autoComplete>
    </span>
  </div>
</div>

import { PaginatedResponse } from '@fg-types/paginated-response';
import { ApiBase } from '@fg-services/api/models/api-base';
import { Invite } from '@fg-types/invites.type';

export class InvitesApi extends ApiBase {
  model = 'Invitations';

  getInvites({ modelId, modelName, email, lastKey }) {
    const params = this.setParams({ modelId, modelName, email, lastKey, limit: 1000 });
    return this.http.get<PaginatedResponse<Invite>>(this.modelBase, { params });
  }

  importInvites(invites: Invite[]) {
    return this.http.post<Invite[]>(`${this.modelBase}/import`, invites);
  }

  createInvite({ modelId, modelName, email, ticketTypeName }: Partial<Invite>) {
    return this.http.post<Invite>(this.modelBase, { modelId, modelName, email, ticketTypeName });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IPost } from '@fg-types/post';

@Component({
  selector: 'search-post',
  templateUrl: './search-post.component.html',
  styleUrls: ['./search-post.component.scss']
})
export class SearchPostComponent {
  @Input() posts: IPost[] = [];
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() placeholder = 'Search the user who created the post';
  @Input() localModels = true;
  @Output() selectPost = new EventEmitter<IPost>();
  filteredPosts: IPost[] = [];
  query = '';
  post: IPost;

  constructor(private apiService: ApiService, private apiCallsInProgress: ApiCallsInProgress) {}

  filter(params?: AutoCompleteEvent) {
    this.query = params ? params.query : '';
    if (!this.localModels) return this.search();
    const regexp = new RegExp(this.query, 'i');
    this.filteredPosts = this.query
      ? this.posts.filter(post => post.owner.username.match(regexp))
      : this.posts;
  }

  search() {
    this.apiCallsInProgress.addCall();
    this.apiService.search
      .searchPost({
        query: this.query,
        postTypes: 'album,snippet'
      })
      .subscribe(
        ({ items }) => {
          this.filteredPosts = items;
          this.apiCallsInProgress.reset();
        },
        () => this.apiCallsInProgress.reset()
      );
  }

  onSelect(post: IPost) {
    this.selectPost.emit(post);
    this.query = '';
    this.post = null;
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@fg-services/user.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private userServ: UserService, private router: Router) {}
  canActivate() {
    return this.userServ.isAuthenticated().pipe(
      tap(isAuth => {
        if (!isAuth) this.router.navigate(['/']);
      })
    );
  }
}

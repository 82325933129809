import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IReport } from '@fg-types/report';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'report-tile',
  templateUrl: './report-tile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportTileComponent implements ListItem<IReport<any>> {
  @Input() data: IReport<any>;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IUser } from '@fg-types/user.type';
import { ApiBase } from './api-base';

export class RoleApi extends ApiBase {
  model = 'roles';
  getUsersByRoleName(roleName: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IUser>>(`${this.modelBase}/${roleName}/users`, {
      params: NetworkHelpers.setParams({ lastKey })
    });
  }
}

// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
import { devChatTemplate } from '@fg-app/backstage/c/content/content-chats/chat-templates/chat-templates';
import { devInfoLabelMap } from './info-label-map';
import { version } from './version';
const FANGURU_URL = 'https://dev.fan.guru';

export const environment = {
  production: false,
  API_URL: 'https://dev-api.fan.guru/',
  CHAT_API_URL: 'https://api-us-2.sendbird.com',
  CHAT_API_ID: 'E4C968D6-94BC-43B3-B9B8-00FB54A20B19',
  SOCKET_API:'https://dev-backend-websocket.fan.guru/',
  POST_DETAIL_URL: `${FANGURU_URL}/p/{postId}`,
  CONTEST_ENTRY_URL: (convention: string, contestId: string, postId: string) =>
    `${FANGURU_URL}/c/${convention}/contests/${contestId}/entries/${postId}`,
  SCHEDULED_EVENT_DETAIL_URL: (conventionId: string, id: string) =>
    `${FANGURU_URL}/c/${conventionId}/e/${id}`,
  SCHEDULE_WIDGET_URL: (conventionId: string) =>
    `https://dev.fan.guru/widget/schedule/?conventionId=${conventionId}`,
  EXHIBITOR_WIDGET_URL: (conventionId: string, showItemsSegment: boolean = true) =>
    `https://dev.fan.guru/widget/exhibit-hall?conventionId=${conventionId}&showItemsSegment=${showItemsSegment}`,
  EXHIBITOR_WEB_WIDGET_URL: (id: string) => `https://dev.fan.guru/e/${id}/about`,
  EXHIBITOR_PROFILE_URL: (id: string) => `https://dev.fan.guru/e/${id || ''}`,
  API_VERSION: 'v2',
  ENABLE_NAVBAR_SECTION: false,
  GENERAL_CHANNEL_IMAGE_ID: '5b91abc0b53cd6001778b27e',
  version,
  FANGURU_URL,
  AMPLITUDE_API_KEY: 'b9934620c1646b97d888174fe46ae865',
  infoLabelMap: devInfoLabelMap,
  SQUARE_AUTH_DOMAIN: 'connect.squareupsandbox.com',
  STRIPE_PUBLISHABLE_KEY: 'pk_test_qGHfMx0f1KBmXp0rcJCC3J9H00sTH4RW96',
  CHAT_TEMPLATE: devChatTemplate
};

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'generic-tile',
  templateUrl: './generic-tile.component.html',
  styleUrls: ['./generic-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class GenericTileComponent implements ListItem<any> {
  @Input() data: any;
  @Input() tileType = 'tile';
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChatApiService } from '@fg-services/chat-api';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import {
  NavigationContextSegments,
  TopBarNavigationContext
} from '@fg-services/navigation-context/types';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IConvention } from '@fg-types/convention.type';
import { IUser } from '@fg-types/user.type';
import { AppComponent, logoEventologysvg } from '../app.component';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Location } from '@angular/common';

@Component({
  selector: 'fg-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss']
})
export class AppTopbarComponent extends BaseComponent implements OnInit {
  @ViewChild('op', { static: true }) op: OverlayPanel;
  toggleBackGroundColor :boolean = true;
  topBarData: string='';
  showButton:boolean = false;
  buttonLabel: string = '';
  topBarIsHidden = false;
  sideMenuIsHidden = true; // made this true in order to achieve functionality
  landbotPopUp: any;
  _user: IUser;
  srclogo = logoEventologysvg;
  isSubscriber: boolean;
  get user() {
    return this._user;
  }

  set user(user: IUser) {
    if (user?.needsUsername) return; // account is being created, do not set user yet
    /** initiate landbot only when user if first assigned */
    if (!this._user && user) {
      this.initLandbot(user);
    }
    this._user = user;
    if(this._user?.userType=== 'subscriber'){
      this.isSubscriber = true
    }else this.isSubscriber = false;
  }

  topBarNavigationContext: TopBarNavigationContext[];
  prevUser: boolean;
  constructor(
    public app: AppComponent,
    private router: Router,
    public userService: UserService,
    private route: ActivatedRoute,
    private titleService: Title,
    private localStorageService: LocalStorageService,
    private chatApiService: ChatApiService,
    navigationContextService: NavigationContextService,
    private location : Location,
  ) {
    super(navigationContextService);
    this.subscriptions.push(
      this.userService.getUserAsObservable().subscribe(user => {
        this.prevUser = !!this.localStorageService.getItem('prevToken');
        this.user = user;
      })
    );
    navigationContextService.getContextAsObservable().subscribe(({ segments }) => {
      const previousSegments: NavigationContextSegments = [];

      this.topBarNavigationContext = segments.map(segment => {
        previousSegments.push(segment);
        let link = this.navigationContext.getRoutePath(previousSegments);
        this.setTitle(link);
        if (!link.length) link = ['/'];
        return {
          label: segment.label,
          link,
          name: segment.subLabel
        };
      });
    });
  }
  ButtonOnClick(){
    
  }

  canDeactivate() {}

  ngOnInit() {
    this.router.events.subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        this.topBarIsHidden = !!this.route.snapshot.firstChild?.data?.hideTopBar;
        this.sideMenuIsHidden = !!this.route.snapshot.firstChild?.data?.hideMenu;
        if(this.location.path().includes('apps')){
          this.topBarData = 'Console' 
        }else{
          const childRoute = this.route.firstChild; // Get the first child route
          const Label = childRoute.firstChild.snapshot.data['Label'];
          this.topBarData = Label
        }
        // if(this.user?.userType==="subscriber"){
        //   this.isSubscriber = true;
        // }else{
        //   this.isSubscriber= false;
        // }
      }
    });
  }

  logout() {
    this.userService.logout().subscribe(
      () => this.disconnect(),
      () => this.disconnect()
    );
  }

  disconnect() {
    this.chatApiService.disconnect();
    this.landbotPopUp?.destroy();
    this.router.navigate(['/login']);
  }

  enableTutorialMode(enabled: boolean) {
    this.userService.updateUserPreferences({ backstageTutorialMode: { enabled } });
  }

  setTitle(link?: string[]) {
    const [path] = link;
    switch (path) {
      case 'eh':
        this.titleService.setTitle('EXHIBIT HALL');
        break;
      case 'bs':
        this.titleService.setTitle('BACKSTAGE');
        break;
      case 'oz':
        this.titleService.setTitle('OZ');
        break;
      case 'cp':
        this.titleService.setTitle('CREATOR') ;
        break;
      default:
        this.titleService.setTitle('CONSOLE');
        break;
    }
  }

  initLandbot({ username, protectedEmail }: IUser) {
    this.landbotPopUp = new window['LandbotLivechat']({
      index: `https://landbot.io/u/H-465043-INKQINBOD4NN4HC0/index.html?username=${username}&email=${protectedEmail}`,
      open: false,
      proactive: false,
      font: 'Montserrat',
      accent: '#22ccb6',
      launcherOptions: {
        type: 'image',
        color: '#de4561',
        shape: 'default',
        scale: 0.55,
        image: 'https://static.landbot.io/landbot/files/bubble.svg'
      },
      navOptions: {
        close: true,
        refresh: false
      }
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fg-chars-limit',
  templateUrl: './chars-limit.component.html',
  styleUrls: ['./chars-limit.component.scss']
})
export class CharsLimitComponent implements OnInit {
  @Input() maxLength: number;
  @Output() onError = new EventEmitter();
  @Output() onSuccess = new EventEmitter();
  _text: string;
  @Input() set text(text: string) {
    this._text = text;
    this.checkLength();
  }

  get text() {
    return this._text;
  }

  characterleft: number;
  lengthError: boolean;
  constructor() {}

  ngOnInit() {}

  checkLength() {
    const text = this.text || '';
    if (this.maxLength >= text.length) {
      this.lengthError = false;
      this.characterleft = this.maxLength - text.length;
      this.onSuccess.emit();
    } else {
      this.lengthError = true;
      this.characterleft = 0;
      this.onError.emit();
    }
  }
}

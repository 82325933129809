import { Route } from '@angular/router';
import { cRoute } from '@fg-app/backstage/c/c-route';
import { cmRoute } from '@fg-app/backstage/cm/cm-route';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { AuthGuard } from '@fg-shared/guards/auth.guard';
import { OzGuard } from '@fg-shared/guards/oz.guard';

export const oRoute: Route = {
  path: 'o/:id',
  data: { segmentName: NavigableModel.Organization },
  children: [
    {
      path: '',
      redirectTo: 'events',
      pathMatch: 'full'
    },
    {
      path: 'events',
      loadChildren: () => import('./o-events/o-events.module').then(m => m.OEventsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'communities',
      loadChildren: () => import('./communities/communities.module').then(m => m.CommunitiesModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'team',
      loadChildren: () => import('../../shared/team/team.module').then(m => m.TeamModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'artwork',
      loadChildren: () => import('./o-artwork/o-artwork.module').then(m => m.OArtworkModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'settings',
      loadChildren: () => import('./o-settings/o-settings.module').then(m => m.OSettingsModule),
      canActivate: [OzGuard]
    },
    {
      path: 'featured',
      loadChildren: () =>
        import('@fg-shared/featured-content/featured-content.module').then(
          m => m.FeaturedContentModule
        ),
      canActivate: [AuthGuard]
    },
    cmRoute,
    cRoute,
    {
      path: 'notifications',
      loadChildren: () =>
        import('@fg-shared/notifications/notifications.module').then(m => m.NotificationsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'billing',
      loadChildren: () => import('./o-billing/o-billing.module').then(m => m.OBillingModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'essentials',
      loadChildren: () =>
        import('./o-essentials/o-essentials.module').then(m => m.OEssentialsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'guests',
      loadChildren: () => import('./o-guests/o-guests.module').then(m => m.OGuestsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'subscriptions',
      loadChildren: () =>
        import('./o-subscriptions/o-subscriptions.module').then(m => m.OSubscriptionsModule),
      canActivate: [AuthGuard]
    }
  ]
};

import { BaseComponent } from '@fg-shared/helpers/base.component';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IToday } from '@fg-types/convention-today.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'featured-list-tile',
  templateUrl: './featured-list-tile.component.html'
})
export class FeaturedListTileComponent extends BaseComponent implements ListItem<IToday> {
  @Input() data: IToday;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  get startDate() {
    if (!this.convention) return '';
    const { timeZoneId } = this.convention;
    const [_, monthStart, monthDayStart, yearStart] = utcToZonedTime(
      this.data.startDate,
      timeZoneId
    )
      .toString()
      .split(' ');
    return `${monthStart} ${monthDayStart} ${yearStart}`;
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { SquareCategory } from '@fg-types/square';

@Component({
  selector: 'search-category',
  templateUrl: './search-category.component.html',
  styleUrls: ['./search-category.component.scss']
})
export class SearchCategoryComponent {
  @Input() categories: SquareCategory[];
  @Input() filteredCategories: SquareCategory[];
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() createNewText: string;
  @Output() addCategory = new EventEmitter<SquareCategory>();
  @Input() title = 'Add Category';
  @Output() queryChange = new EventEmitter<string>();
  filteredExhibitors: (SquareCategory | string)[] = [];
  category = '';

  filter(params?: AutoCompleteEvent) {
    const regex = new RegExp(`^${params.query}`, 'i');
    this.filteredCategories = this.categories
      .filter(category => regex.test(category.name))
      .map(category => ({
        ...category,
        calcName: `${category.name} [${category.itemsCount}]`
      }));
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { ExhibitorHelpers } from '@fg-shared/helpers/exhibitor-helpers';

@Component({
  selector: 'exhibitor-tile',
  templateUrl: './exhibitor-tile.component.html',
  styleUrls: ['./exhibitor-tile.component.scss']
})
export class ExhibitorTileComponent implements ListItem<ConventionExhibitor> {
  @Input() data: ConventionExhibitor;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  // Calculated values
  get imageUrl() {
    return ExhibitorHelpers.getImage(this.data);
  }
}

import { Injectable } from '@angular/core';
import { ModelToday, ModelTodaySuportedModels } from '@fg-types/today.type';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class FeaturedService {
  modelName: ModelTodaySuportedModels;
  modelId: string;

  private _items: BehaviorSubject<Map<string, ModelToday>> = new BehaviorSubject(
    new Map<string, ModelToday>()
  );

  get items() {
    return this._items.value;
  }

  set items(items: Map<string, ModelToday>) {
    this._items.next(items);
  }

  get itemsAdObservable() {
    return this._items.asObservable();
  }

  updateItem(id: string, today: ModelToday) {
    const items = this.items;
    items.set(id, today);
    this.items = items;
  }

  getItem(id: string) {
    return this._items.value.get(id);
  }
}

@Injectable()
export class disabledDropDown {
  public disableDrop = false;
  public dropDownOption = ""

  constructor() {}
}

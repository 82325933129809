<div [ngSwitch]="data.modelName">
  <exhibitor-tile *ngSwitchCase="'Exhibitor'" [data]="data.model"> </exhibitor-tile>
  <guest-tile *ngSwitchCase="'Celebrity'" [data]="data.model"> </guest-tile>
  <schedule-event-tile *ngSwitchCase="'ConventionEvent'" [data]="data.model"> </schedule-event-tile>
  <ng-container *ngSwitchCase="'ConventionPartner'">
    <ad-tile [data]="data.model"></ad-tile>
  </ng-container>
  <contest-entry-tile *ngSwitchCase="'Post'" [data]="data.model"></contest-entry-tile>
  <event-tile *ngSwitchCase="'Convention'" [data]="data.model"></event-tile>
  <community-tile *ngSwitchCase="'Fandom'" [data]="data.model" commonTileSize="1"></community-tile>
  <user-tile *ngSwitchCase="'user'" [data]="data.model"></user-tile>
  <grid-item-tile *ngSwitchCase="'SquareCatalog'" [item]="data.model"></grid-item-tile>
  <ng-template ngSwitchDefault>
    <label>Unknown item type ({{ data.modelName }})</label>
  </ng-template>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ContestWinner } from '@fg-types/convention-contest';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'contest-winner-tile',
  templateUrl: './contest-winner-tile.component.html',
  styleUrls: ['./contest-winner-tile.component.scss']
})
export class ContestWinnerTileComponent implements ListItem<ContestWinner> {
  @Input() data: ContestWinner;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  get title() {
    if (!this.data.post || !this.data.post.owner) return '';
    return this.data.post.owner.username;
  }

  get media() {
    if (!this.data.post || !this.data.post.media || !this.data.post.media.length) return '';
    if (this.data?.post?.media[0]?.mimetype === 'video/mp4') {
      return this.data.post.media[0].sizes[0]?.previewUrl;
    }
    return this.data.post.media[0].preview || this.data.post.media[0].url;
  }
}

<div class="min-md-hidden">
  <div class="topbar clearfix">
    <div class="topbar-right">
      <div class="top-bar-title">
        <div class="leftpanel-menu-button" (click)="onClose.next()">
          <i class="fas pi pi-angle-left"></i>
        </div>
        <div class="context-name" delay="1000">
          {{ title }}
        </div>
      </div>
    </div>
  </div>
  <div class="actions fg-col-header">
    <ng-content></ng-content>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ChallengeTypes } from '@fg-app/backstage/c/gamification/challenges-list/challenge-types-config';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { IChallenge } from '@fg-types/challenge';

@Component({
  selector: 'challenge-tile',
  templateUrl: './challenge-tile.component.html'
})
export class ChallengeTileComponent extends BaseComponent implements ListItem<IChallenge> {
  @Input() data: IChallenge;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  get challengeType(): string {
    if (this.data.type && ChallengeTypes[this.data.type])
      return ChallengeTypes[this.data.type].label;
    return this.data.type ? `Unknown type: ${this.data.type}` : 'Undefined type';
  }

  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }
}

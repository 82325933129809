<div class="post tile"  [class.active]="active">
    <div class="details">
      <div class="header">
        <div class="header1">
            <div class="input-container">
                <div class="input-cell">
                    <label>{{_data.date}}</label>
                </div>
            </div>
        </div>
        <div class="header2" *ngIf="!_data?.isCompleted">
            <i
          [matMenuTriggerFor]="menu"
          data-ignore-global-click="true"
          (click)="postMenuVisible = !postMenuVisible; (false)"
          class="menu pi pi-ellipsis-h"
        ></i>
        <mat-menu #menu="matMenu">
          <button mat-menu-item >   
            <div data-ignore-global-click="true" (click)="onMark.emit(_data?.id)">Mark Complete</div>
          </button>
        </mat-menu>
        </div>
      </div>
      <div class="p-grid p-m-2">
    <div class="input-container p-col-6">
        <div class="input-cell">
            <label>Name</label>
            <input type="text" name="name" readonly [value]="_data.name"/>
        </div>
    </div>
    <div class="input-container p-col-6">
        <div class="input-cell">
            <label>Email</label>
            <input type="text" readonly name="email" [value]="_data.email"/>
        </div>
    </div>

    <div class="input-container p-col-12">
        <div class="input-cell">
            <label>Request</label>
            <textarea type="text"  style="resize: none" readonly name="request" [value]="_data.description"></textarea>
        </div>
    </div>
    <div class="input-container p-col-12">
        <div class="input-cell">
            <label>Additional Message</label>
            <textarea type="text" style="resize: none" readonly name="message" [value]="_data.message"></textarea>
        </div>
    </div>
    <div class="input-container p-col-12">
        <div class="input-cell">
            <p *ngIf="_data?.private" class="pi pi-exclamation-triangle" > This person has asked to keep the request private.</p>
        </div>
    </div>
</div>
    
</div>
</div>
import { ApiBase } from '@fg-services/api/models/api-base';
import { AugmentedRealityAsset, AugmentedRealityMarker } from '@fg-types/challenge';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ARMarkersApi extends ApiBase {
  model = 'AugmentedRealityMarkers';

  /** Assets */

  getArAssetById(id: string) {
    return this.http.get<AugmentedRealityAsset>(`${this.modelUri(id)}`);
  }

  /** Markers */

  createArMarker(file: File, markerName: string, augmentedRealityAssetId: string) {
    const formData: FormData = new FormData();
    formData.append('vuforiaTarget', file, file.name);
    return this.http.post<AugmentedRealityMarker>(this.modelBase, formData, {
      params: {
        augmentedRealityAssetId,
        markerName
      }
    });
  }

  deleteArMarker(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelUri(id)}`);
  }

  updateArMarker(id: string, body: Partial<AugmentedRealityMarker>) {
    return this.http.patch<AugmentedRealityMarker>(`${this.modelUri(id)}`, body);
  }

  getArMarkers(query?: string, lastKey?: string) {
    const params = this.setParams({ lastKey, query });
    return this.http.get<PaginatedResponse<AugmentedRealityMarker>>(this.modelBase, {
      params
    });
  }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FgNewConventionFormComponent } from '@fg-app/backstage/fg-new-convention-form/fg-new-convention-form.component';
import { SearchModule } from '@fg-shared/search/search.module';
import { SharedModule } from '@fg-shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    SharedModule,
    ButtonModule,
    CardModule,
    PanelModule,
    InputSwitchModule,
    RadioButtonModule,
    DropdownModule,
    SearchModule
  ],
  declarations: [FgNewConventionFormComponent],
  exports: [FgNewConventionFormComponent]
})
export class NewConventionModule {}

<div class="p-grid p-nogutter router-tabs-flex">
  <ul class="router-tabs-container">
    <li
      class="router-tabs-item"
      *ngFor="let route of routes"
      routerLinkActive="active"
      [routerLinkActiveOptions]="{ exact: exact }"
    >
      <a [routerLink]="route.route" href (click)="route.action && route.action()">
        <pre>
        <span>{{ route.label }} </span>
        <i *ngIf="route.icon" [class]="'pi pi-' + route.icon" (click)="route.iconAction && route.iconAction()"></i>
        <div
          *ngIf="(route.icon !== 'lock') && route.badge"
          [matTooltip]="route.badge.tooltip"
          class="tooltip-touch badge"
          #tt="matTooltip"
          (click)="tt.show()"
        >
          <i>{{route.badge.value}}</i>
        </div>
      </pre>
      </a>
    </li>
    <ng-content></ng-content>
  </ul>
  <div class="info-trigger" *ngIf="!!infoBannerService.info">
    <i
      title="Select your profile picture above to turn off 'Tutorial Mode' in the drop-down."
      (click)="infoBannerService.setVisibility(!infoBannerService.visible)"
      class="more-info pointer"
      [ngStyle]="{
        'background-color':
          infoBannerService.info && infoBannerService.visible ? '#00e5da' : '#777777',
        color: infoBannerService.info && infoBannerService.visible ? '#000' : '#fff'
      }"
    >
      ?
    </i>
  </div>
  <info-banner *ngIf="infoBannerService.visible"></info-banner>
</div>

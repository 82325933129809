import { Component, Input } from '@angular/core';

@Component({
  selector: 'tooltip-button',
  templateUrl: './tooltip-button.component.html',
  styleUrls: ['./tooltip-button.component.scss']
})
export class TooltipButtonComponent {
  @Input() tooltip: string;
}

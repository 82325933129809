import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { ApiService } from '@fg-services/api/api.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { Community } from '@fg-types/community';
import { IConvention } from '@fg-types/convention.type';
import { IUser } from '@fg-types/user.type';
import amplitude from 'amplitude-js';
import { Organization } from './../types/organization.type';

interface LogEventData {
  TITLE: string;
  SEGMENT?: string;
}

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {
  convention: IConvention;
  community: Community;
  organization: Organization;
  user: IUser;
  constructor(
    private navigationContextService: NavigationContextService,
    private apiService: ApiService
  ) {
    this.navigationContextService.getContextAsObservable().subscribe(context => {
      if (!this.user) {
        this.apiService.users.authenticated().subscribe(val => {
          this.user = val;
          this.initAmplitude(val);
        });
      }
      const conventionSegment = context.getConventionData();
      const communitySegment = context.getCommunityData();
      const organizationSegment = context.getOrganizationData();
      if (conventionSegment) {
        this.convention = conventionSegment.convention;
        this.community = null;
        this.organization = null;
      }
      if (organizationSegment) {
        this.organization = organizationSegment.organization;
        this.community = null;
        this.convention = null;
      }
      if (communitySegment) {
        this.community = communitySegment.fandom;
        this.convention = null;
        this.organization = null;
      }
    });
  }

  initAmplitude(user: IUser): void {
    amplitude.getInstance().init(environment.AMPLITUDE_API_KEY, user.id);
    amplitude.getInstance().setUserId(user.id);
    amplitude.getInstance().setUserProperties({
      USER_ID: user.id,
      USERNAME: user.username,
      EMAIL_ADDRESS: user.protectedEmail
    });
  }

  logEvent(eventType: string, data?: LogEventData): void {
    if (!this.user) return;
    data = {
      ...data,
      ...{
        USERNAME: this.user.username,
        CONVENTION_NAME: this.convention?.name,
        CONVENTION_ID: this.convention?.id,
        COMMUNITY_NAME: this.community?.name,
        COMMUNITY_ID: this.community?.id,
        ORGANIZATION_NAME: this.organization?.name,
        ORGANIZATION_ID: this.organization?.id
      }
    };
    const _data = Object.keys(data).reduce(
      (acc, key) => (data[key] == null ? acc : { ...acc, [key]: data[key] }),
      {}
    );
    amplitude.getInstance().logEvent(eventType, _data);
  }
}

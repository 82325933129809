import { Route } from '@angular/router';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { OzGuard } from '@fg-shared/guards/oz.guard';

export const ozRoute: Route = {
  path: 'oz',
  data: { segmentName: NavigableModel.OZ, app: 'oz' },
  canActivate: [OzGuard],
  children: [
    {
      path: '',
      redirectTo: 'library/events',
      pathMatch: 'full'
    },
    {
      path: 'featured',
      loadChildren: () => import('./oz-featured/oz-featured.module').then(m => m.OzFeaturedModule)
    },
    {
      path: 'settings',
      loadChildren: () => import('./oz-settings/oz-settings.module').then(m => m.OzSettingsModule)
    },
    {
      path: 'library',
      loadChildren: () => import('./oz-library/oz-library.module').then(m => m.OzLibraryModule)
    },
    {
      path: 'advertising',
      loadChildren: () =>
        import('./oz-advertising/oz-advertising.module').then(m => m.OzAdvertisingModule)
    },
    {
      path: 'moderation',
      loadChildren: () =>
        import('./oz-moderation/oz-moderation.module').then(m => m.OzModerationModule)
    }
  ]
};

import { Route } from '@angular/router';
import { cRoute } from '@fg-app/backstage/c/c-route';
import { cmRoute } from '@fg-app/backstage/cm/cm-route';
import { homeRoutes } from '@fg-app/backstage/home/home.routes';
import { oRoute } from '@fg-app/backstage/o/o-route';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { BsGuard } from '@fg-shared/guards/bs.guard';

export const bsRoute: Route = {
  path: 'bs',
  data: { segmentName: NavigableModel.Backstage, app: 'bs' },
  canActivate: [BsGuard],
  children: [...homeRoutes, oRoute, cmRoute, cRoute]
};

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { UserService } from '@fg-services/user.service';
import { FeaturedService } from '@fg-shared/featured-content/featured.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConvention } from '@fg-types/convention.type';

@Component({
  selector: 'search-ozconvention',
  templateUrl: './search-ozconvention.component.html',
  styleUrls: ['./search-ozconvention.component.scss']
})
export class SearchOzconventionComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() allConventions = false;
  @Input() placeholder = 'Type to search an Event';
  @Output() addItem = new EventEmitter<IConvention>();
  filtered: IConvention[] = [];
  convention = '';

  constructor(private apiService: ApiService, private userService: UserService) {}

  ngOnInit() {
  }
  filter(params?: AutoCompleteEvent) {
    const allConventions = this.allConventions || this.userService?.roles?.isAdmin;
    if (params && this.userService?.roles?.isAdmin) {
      this.apiService.search
        .Conventions(params.query, null, allConventions)
        .subscribe(({ items }) => (this.filtered = items));
    } else {
      this.apiService.search
      .Conventions(params.query, null, this.allConventions)
      .subscribe(({ items }) => this.filtered = items.filter(item => item.goLive !== "Admin Only"));
    }
  }

}

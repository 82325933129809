import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { MediaService } from '@fg-services/media.service';
import { UiService } from '@fg-services/ui.service';
import { AdminHelpers } from '@fg-shared/helpers/admin-helpers';
import { CmMedia } from '@fg-types/cm-media';

@Component({
  selector: 'pdf-uploader',
  templateUrl: './pdf-uploader.component.html',
  styleUrls: ['./pdf-uploader.component.scss']
})
export class PdfUploaderComponent {
  @Input() pdf: CmMedia;
  @Input() disabled: boolean;
  @Input() footer: string;
  @Input() header: string;
  @Input() maxFileSize: number;
  @Output() setPdf = new EventEmitter<CmMedia>();
  @Output() onDelete = new EventEmitter<void>();
  @ViewChild('progressBar', { static: true }) public progressBar: ElementRef;
  resetUploader = true;
  loading = false;

  constructor(
    private mediaService: MediaService,
    private apiCallsInProgress: ApiCallsInProgress,
    private uiService: UiService
  ) {}

  selectFile($event) {
    const file = $event.target.files?.[0];
    if (!file) return;
    if (this.maxFileSize && file.size > this.maxFileSize) {
      this.uiService.toast({
        severity: 'error',
        summary: 'Invalid File',
        detail: `The file exceeds the ${this.maxFileSize / 1024 / 1024}MB limit`
      });
      return;
    } else {
      this.loading = true;
      const fileName = encodeURI(AdminHelpers.getGUID() + '.pdf');
      this.apiCallsInProgress.addCall();
      const subs = this.mediaService.getObservable().subscribe((res: any) => {
        if (res.status === 'finish') {
          this.apiCallsInProgress.reset();
          this.loading = false;
          this.setPdf.emit(res.success);
          subs.unsubscribe();
        }
      });
      this.mediaService.assetUpload(file, fileName);
    }
    this.resetUploader = false;
    setTimeout(() => (this.resetUploader = true));
  }
}

<div
  class="order-list p-datalist"
  cdkDropList
  (cdkDropListDropped)="drop($event)"
  fgInfiniteScroll
  (scrolled)="onScroll.emit()"
  [scrollFinished]="scrollFinished"
  [containerScroll]="containerScroll"
  [style.padding]="padding"
  [style.maxHeight]="maxContainerHeight"
  [class.use-max-tile-width]="useMaxTileWidth"
>
  <ng-template #loadingPlaceholder>
    <loading-list-item *ngIf="loading"></loading-list-item>
  </ng-template>
  <div *ngIf="items?.length; else loadingPlaceholder">
    <div
      *ngFor="let item of items; let i = index"
      cdkDrag
      [cdkDragDisabled]="disabled"
      class="order-list-item"
    >
      <i class="pi pi-bars" cdkDragHandle [class.disabled]="disabled"></i>
      <list-item
        [data]="item"
        [active]="selectedItem?.id === item.id"
        [itemComponent]="itemComponent"
        (onClick)="onClickItem.emit(item)"
      >
      </list-item>
      <i
        class="pi pi-times remove-icon"
        *ngIf="showDeleteIcon"
        (click)="onDeleteItem.next({ item: item, index: i })"
      ></i>
    </div>
  </div>
  <div *ngIf="!loading && !items.length">No Records Found</div>
</div>

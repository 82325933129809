import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';

@Component({
  selector: 'order-list',
  templateUrl: './order-list.component.html',
  styleUrls: ['./order-list.component.scss']
})
export class OrderListComponent<T, A> extends BaseComponent {
  @Input() useMaxTileWidth = true;
  @Output() onScroll = new EventEmitter();
  @Input() scrollFinished = false;
  @Input() padding = '8px 14px';
  @Input() maxContainerHeight: string;
  @Input() itemComponent: T;
  @Input() selectedItem: A;
  @Input() loading: boolean;
  @Input() items: A[];
  @Input() containerScroll: boolean;
  @Input() showDeleteIcon: boolean;
  @Input() disabled = false;
  @Output() onClickItem = new EventEmitter<A>();
  @Output() onDeleteItem = new EventEmitter<{ item: A; index: number }>();
  @Output() onOrderChange = new EventEmitter<A[]>();

  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.onOrderChange.emit(this.items);
  }
}

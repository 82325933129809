<div class="post tile" (click)="onClick.emit()" [class.active]="active">
  <div class="details">
    <div class="header">
      <div class="header1">
        <div
          *ngIf="(data.owner?.profilePhoto?.sizes)[0]?.url"
          class="avatar"
          [style.background-image]="'url(' + (data.owner?.profilePhoto?.sizes)[0]?.url + ')'"
        ></div>
      </div>
      <div class="header2">
        <strong>{{ data.owner?.username }}</strong>
        <label>{{ timeAgo }}</label>
      </div>
      <div class="header3">
        <i
          *ngIf="showMenu"
          [matMenuTriggerFor]="menu"
          data-ignore-global-click="true"
          (click)="postMenuVisible = !postMenuVisible; (false)"
          class="menu pi pi-ellipsis-h"
        ></i>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>   
            <div data-ignore-global-click="true" (click)="onEditClick.emit()">Edit</div>
          </button>
          <button mat-menu-item>
            <div data-ignore-global-click="true" (click)="onDeleteClick.emit(data.id)">Delete</div>
          </button>
          <button mat-menu-item>
            <div data-ignore-global-click="true" (click)="onCopyClick.emit(data.id)">Copy URL</div>
          </button>
        </mat-menu>

        <!-- <div class="post-id-container" *ngIf="postMenuVisible">
          <input
            [value]="data.id"
            readonly
            autofocus="autofocus"
            data-ignore-global-click="true"
            onfocus="this.select()"
          />
        </div> -->
        <div>
          <div class="fg-banner" *ngIf="data.fandom?.name">
            {{ data.fandom?.name }}
          </div>
        </div>
      </div>
    </div>
    <div class="status-title-containter">
      <strong *ngIf="data.title">{{ data.title }}</strong>
      <span class="status" *ngIf="status" [style.background]="status.color">{{
        status.status
      }}</span>
    </div>
    <div class="media" *ngIf="data.media && data.media[0]">
      <i
        data-ignore-global-click="true"
        (click)="updateCurrentMedia(-1)"
        *ngIf="data.media.length > 1 && currentMedia > 0"
        class="prev-image pi pi-chevron-left"
      ></i>
      <i
        data-ignore-global-click="true"
        (click)="updateCurrentMedia(+1)"
        *ngIf="data.media.length > 1 && currentMedia + 1 < data.media.length"
        class="next-image pi pi-chevron-right"
      ></i>
      <div class="media-container" *ngIf="data.media?.length">
        <div *ngIf="data.media[currentMedia]?.type === 'video'; else image">
          <fg-video-player
            *ngIf="!videoReset"
            [options]="{
              fluid: true,
              autoplay: false,
              source: (data.media[currentMedia]?.url),
              poster: (data.media[currentMedia]?.sizes)[0]?.previewUrl
            }"
          ></fg-video-player>
        </div>
        <ng-template #image>
          <div
            class="blurred"
            [style.backgroundImage]="'url(' + data.media[currentMedia].url + ')'"
          ></div>
          <div
            class="pc-media-item"
            [style.backgroundImage]="'url(' + data.media[currentMedia].url + ')'"
          ></div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="data.body" class="description limit-img-width">
      <pre>{{ data.body }}</pre>
    </div>
    
    <div *ngIf="data.body" class="post-access-type limit-img-width">
      <pre>{{ data | json }}</pre>
    </div>

    <div *ngIf="data.description" class="description limit-img-width">
      <pre>{{ data.description }}</pre>
    </div>
    <div class="p-grid p-col-fixed" [style.width]="'200px'">
      <div class="p-col p-d-flex p-ai-center">
        <img src="/assets/layout/images/fanguru/HEART_ICON_HOLLOW.png" />
        <span class="p-pl-2">{{ formattedCount(data.likeCount) }}</span>
      </div>
      <div class="p-col p-d-flex p-ai-center">
        <img src="/assets/layout/images/fanguru/CHAT_ICON.png" />
        <span class="p-pl-2">{{ formattedCount(data.commentCount) }}</span>
      </div>
      <div class="p-col p-d-flex p-ai-center">
        <img src="/assets/layout/images/fanguru/SHARE_ICON.png" />
      </div>
    </div>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Category } from '@fg-types/category';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'category-tile',
  templateUrl: './category-tile.component.html',
  styleUrls: ['./category-tile.component.scss']
})
export class CategoryTileComponent implements ListItem<Category> {
  get image() {
    const { iconMedia } = this.data;
    if (!iconMedia) return undefined;
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${iconMedia.sizes[0].url}) no-repeat center`
    );
  }

  @Input() data: Category;
  @Input() commonTileSize?: boolean;
  @Input() includeFanCount = true;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  constructor(protected sanitizer: DomSanitizer) {}
}

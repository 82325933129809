import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';

import { CategoryModelName } from '@fg-app/oz/oz-library/categories/categories.component';
import { ApiService } from '@fg-services/api/api.service';
import { SelectItem } from 'primeng/api/selectitem';
import { OverlayPanel } from 'primeng/overlaypanel';
import { FilterEvent, goLiveValues, SizeValue, sizeValues } from './filter-events.type';

@Component({
  selector: 'filter-events',
  templateUrl: './filter-events.component.html',
  styleUrls: ['./filter-events.component.scss']
})
export class FilterEventsComponent implements OnInit {
  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;
  @Output() onFilter = new EventEmitter<FilterEvent>();
  goLiveValues = goLiveValues;
  sizeValues = sizeValues;
  filter: FilterEvent = {};
  ticketProviders: { label: string; value: string }[] = [];
  expandedStatus = {
    dates: true,
    status: false,
    size: false,
    ticketProvider: false,
    category: false
  };

  allCategories: SelectItem[] = [];
  previousValues: {
    type?: string;
    goLive?: string;
    size?: string;
    ticketProvider?: string;
  } = {};

  constructor(private apiService: ApiService) {
    this.apiService.config.getTicketProviders().subscribe(({ ticketProviders }) => {
      this.ticketProviders = ticketProviders.map(ticketProvider => ({
        label: ticketProvider,
        value: ticketProvider
      }));
    });
  }

  ngOnInit() {
    this.loadAllCategories();
  }

  show(event: Event, initialFilter?: FilterEvent) {
    this.filter = { ...initialFilter };
    this.overlayPanel.show(event);
    this.expandedStatus = {
      dates: !!this.filter.type || !initialFilter,
      status: !!this.filter.goLive,
      size: !!this.filter.size,
      ticketProvider: !!this.filter.ticketProvider,
      category: !!this.filter.categoryIds
    };
  }

  emit() {
    this.onFilter.emit(this.filter);
    this.overlayPanel.hide();
  }

  reset() {
    this.filter = {};
    this.previousValues = {};
    this.onFilter.emit(null);
    this.overlayPanel.hide();
  }

  loadAllCategories() {
    this.apiService.search
      .searchCategories(null, CategoryModelName.Global)
      .subscribe(categories => {
        this.allCategories = categories.items.map(category => ({
          label: category.name,
          value: category.id
        }));
      });
  }

  handleRadioClick(prop: string, type: string | SizeValue) {
    if (this.previousValues[prop] === type) {
      delete this.filter[prop];
      delete this.previousValues[prop];
    } else this.previousValues[prop] = type;
  }
}

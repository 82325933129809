import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { bsRoute } from '@fg-app/backstage/bs-routes';
import { ConsoleComponent } from '@fg-app/console/console.component';
import { ehRoute } from '@fg-app/exhibit-hall/eh-route';
import { ozRoute } from '@fg-app/oz/oz-route';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { AuthGuard } from '@fg-shared/guards/auth.guard';
import { NotAuthGuard } from '@fg-shared/guards/not-auth.guard';
import { OzGuard } from '@fg-shared/guards/oz.guard';
import { EditProfileComponent } from './edit-profile/edit-profile.component';
import { ExhibitHallReferralLinkComponent } from './exhibit-hall-referral-link/exhibit-hall-referral-link.component';
import { FaqsViewComponent } from './faqs-view/faqs-view.component';
import { ImpersonateComponent } from './impersonate/impersonate.component';
import { cpRoute } from './creator-platform/cp-routes';

const routes: Routes = [
  { path: 'apps', component: ConsoleComponent, canActivate: [AuthGuard], data: { hideMenu: true } },
  {
    path: '',
    loadChildren: () => import('./landing/landing.module').then(m => m.LandingModule),
    canActivate: [NotAuthGuard],
    data: { hideMenu: true, hideTopBar: true }
  },
  {
    path: 'reset',
    loadChildren: () =>
      import('./password-reset/password-reset.module').then(m => m.PasswordResetModule),
    canActivate: [NotAuthGuard],
    data: { hideMenu: true, hideTopBar: true }
  },
  {
    path: 'signup',
    loadChildren: () => import('./signup/signup.module').then(m => m.SignupModule),
    data: { hideMenu: true, hideTopBar: true }
  },
  {
    path: 'editprofile',
    component: EditProfileComponent,
    canActivate: [AuthGuard],
    data: { hideMenu: true}
  },
  {
    path: 'setting',
    loadChildren: () => import('./account-setting/account-setting.module').then(m => m.AccountSetting),
    canActivate: [AuthGuard],
    data: { hideMenu: true}
  },
  bsRoute,
  ehRoute,
  ozRoute,
  cpRoute,
  { path: 'exhibit-hall', component: ExhibitHallReferralLinkComponent },
  {
    path: 'faqs',
    component: FaqsViewComponent,
    canActivate: [AuthGuard],
    data: { hideMenu: true, segmentName: NavigableModel.Faqs }
  },
  {
    path: 'impersonate',
    component: ImpersonateComponent,
    canActivate: [OzGuard],
    data: { hideMenu: true }
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}

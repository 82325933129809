import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { ConventionPartner } from '@fg-types/convention-partner';

@Component({
  selector: 'search-promo',
  templateUrl: './search-promo.component.html',
  styleUrls: ['./search-promo.component.scss']
})
export class SearchPromoComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Output() addPromo = new EventEmitter<ConventionPartner>();
  promos: ConventionPartner[] = [];
  filteredPromos: ConventionPartner[] = [];
  promo = '';

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.api.conventions
      .getConventionPartnersByType(this.conventionId, 'promo')
      .subscribe(res => (this.promos = res.items));
  }

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.filteredPromos = this.promos.filter(
        item => item.name.toLowerCase().search(new RegExp(params.query.toLowerCase() || '')) >= 0
      );
    } else {
      this.filteredPromos = [];
    }
  }
}

<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="'@' + data.owner?.username"
  [description]="data.comment"
  [media]="data.owner?.profilePhoto"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

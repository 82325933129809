import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { of } from 'rxjs';

export type HomeSegment = NavigationContextSegment<NavigableModel.Home, unknown, ''>;

export const routeToHomeSegment = () =>
  of<HomeSegment>({
    label: ' ',
    subLabel: 'Console',
    segmentName: NavigableModel.Home,
    model: {},
    role: ''
  });

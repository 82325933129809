import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { IMoment } from '@fg-types/moment';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class MomentApi extends ApiBase {
  model = 'Moments';

  create(body: Partial<IMoment>) {
    return this.http.post<IMoment>(this.modelBase, body);
  }

  update(id: string, body: Partial<IMoment>) {
    return this.http.patch<IMoment>(`${this.modelBase}/${id}`, body);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

@Component({
  selector: 'fg-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit, OnDestroy {
  @Input() public keyPhrase = 'FAN GURU';
  @Input() public visible: boolean;
  @Output() confirm = new EventEmitter();
  @Output() reject = new EventEmitter();
  userInput: string;
  constructor() {}
  ngOnInit() {
    document.addEventListener('keydown', this.onKeyPress.bind(this));
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeyPress.bind(this));
  }

  onKeyPress(e) {
    if (this.visible && e.keyCode === 27) {
      this.visible = false;
      this.reject.emit();
    }
  }
}

<div [ngStyle]="getSizeStyle()">
  <div class="label" *ngIf="width && height">
    <span class="right">{{ width }} x {{ height }}</span>
  </div>
    <!-- <div class="file p-grid" [ngStyle]="getBgStyle()"> -->
  <div class="file" [ngStyle]="getBgStyle()">
    <video *ngIf="previewImage && showVideo" class="video" width="100%" height="100%" controls>
      <source [src]="defaultImage" type="video/mp4" />
      Your browser does not support the video tag.
    </video>
    <div class="p-col-12">
      <div class="input-container" [ngStyle]="getButtonStyle(1)">
        <div class="input-cell">
          <label *ngIf="title" class="left">
            {{ title }}
            <tooltip-button *ngIf="tooltip" [tooltip]="tooltip"></tooltip-button>
          </label>
          <ng-content></ng-content>
          <p-fileUpload
            [disabled]="disabled"
            #fileUploader
            name="media"
            [accept]="accept || 'image/*'"
            styleClass="p-button-primary file-uploader"
            [showUploadButton]="false"
            showCancelButton="false"
            [chooseLabel]="chooseLabel"
            [showCancelButton]="false"
            [url]="mediaEndpoint"
            [auto]="false"
            (onSelect)="onSelect($event)"
            (uploadHandler)="customUploader($event)"
            [customUpload]="true"
          ></p-fileUpload>
        </div>
      </div>
    </div>
    <div class="p-col-12" *ngIf="defaultValue">
      <div class="input-container">
        <div class="input-cell">
          <input
            pInputText
            *ngIf="acceptPDF()"
            name="pdf-url"
            type="text"
            value="{{ defaultValue }}"
            disabled
          />
          <a href class="icon-right" (click)="openLink(defaultValue); (false)">
            <i class="pi pi-download" aria-hidden="true"></i>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { ICngConfig } from '@fg-types/convention.type';

@Injectable()
export class ConfigService {
  private readonly conventionURL = `${environment.API_URL + environment.API_VERSION}/Conventions`;
  constructor(private http: HttpClient) {}

  getConventionConfig(conventionId: string) {
    return this.http.get(`${this.conventionURL}/${conventionId}/Configs`);
  }

  patchConventionConfig(conventionId: string, config: ICngConfig) {
    return this.http.patch(`${this.conventionURL}/${conventionId}/Configs`, config);
  }

  refreshCngConfig(conventionId: string) {
    return this.http.put(`${this.conventionURL}/${conventionId}/CNG`, {});
  }
}

<p-overlayPanel #overlayPanel appendTo="body">
  <div class="filter-container">
    <i class="pi pi-times close" (click)="overlayPanel.hide()"></i>
    <div class="accent-header-style">CREATE NEW {{ modelName }}</div>
    <div class="filter-sections">
      <div class="input-container p-col-12">
        <div class="input-cell">
          <label>{{ modelName }} NAME</label>
          <input autofocus pInputText name="name" type="text" required [(ngModel)]="name" />
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        pButton
        class="p-button-secondary"
        type="button"
        label="CANCEL"
        (click)="overlayPanel.hide()"
      ></button>
      <span class="spacer"></span>
      <button
        pButton
        type="button"
        label="CREATE"
        class="p-button-primary"
        (click)="emit()"
        [disabled]="!name"
      ></button>
    </div>
  </div>
</p-overlayPanel>

import { ApiBase } from '@fg-services/api/models/api-base';

export class PaymentsApi extends ApiBase {
  model = 'Payments';

  summary(params: { ownerModelName: 'Exhibitor' | 'Convention'; ownerModelId: string }) {
    return this.http.get<{
      pending: number;
      paid: number;
      available: number;
      canCashout: boolean;
    }>(`${this.modelBase}/summary`, { params });
  }
}

export enum NavigableModel {
  Home = 'home',
  Convention = 'c',
  Fandom = 'cm',
  Organization = 'o',
  Backstage = 'bs',
  Exhibitor = 'eh',
  OZ = 'oz',
  Faqs = 'faqs',
  Creator= 'cp',
}

export interface NavigationContextSegment<N, M, R> {
  label: string;
  subLabel: string;
  readonly segmentName: N;
  modelId?: string;
  model: M;
  role: R;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConventionEvent } from '@fg-types/convention-event';

@Component({
  selector: 'search-schedule-event',
  templateUrl: './search-schedule-event.component.html',
  styleUrls: ['./search-schedule-event.component.scss']
})
export class SearchScheduleEventComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Output() addEvent = new EventEmitter<IConventionEvent>();
  filteredEvents: IConventionEvent[] = [];
  conventionEvent = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.apiService.search
        .searchConventionEvent(params.query, this.conventionId)
        .subscribe(res => {
          this.filteredEvents = res.items;
        });
    }
  }
}

<div class="tile" [class.active]="active" (click)="onClick.emit()">
  <div *ngIf="senderImage" class="tile-media">
    <div [style.backgroundImage]="senderImage"></div>
  </div>
  <div class="tile-body">
    <div class="tile-title">{{ title }}</div>
    <div *ngIf="message" class="tile-description"><span [innerHTML]="message"></span></div>
    <div *ngIf="image" class="tile-description">
      <a href (click)="onClickImage.emit(image); (false)">
        <img [src]="image" />
      </a>
      <div class="tile-description">{{data?.data}}</div>
    </div>
  </div>
</div>

export const devInfoLabelMap = {
  eventPromos: {
    info: `Promos will appear at the top of your event's home page in a banner that the user can scroll through. You can upload images or videos. In addition, data and analytics are available for every promo.`,
    mediaEmbed: `<div style="position: relative; padding-bottom: 56.25%; height: 0;"> <iframe src="https://www.loom.com/embed/802347dafcb34d8d828cfe8d55d4a089" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen style="position: absolute; top: 0; left: 0; width: 100%; height: 100%;"> </iframe> </div>`
  },
  essentialsWebWidgets: {
    info: `If you run a website for your event, use the embeddable widgets below to add your schedule, guest and exhibitor list info directly to your site. Your embed is always kept 100% in sync with Backstage, so you only need to update your data within Backstage and you’ll see your changes reflected on your site instantly.`
  },
  essentialsArtWork: {
    info: `Adjust the values below to change the visual appearance of your event app & web experience. There are some last details which are addressed within the App > In-App Menu, App > Custom Buttons, and Program > Featured pages, but this page covers the majority of your aesthetic options.`
  },
  essentialsLivePreview: {
    info: `This page shows you a preview of what your Event card will look like within the FAN GURU app and website before users enter it. This page also offers you a sharable marketing link that you can use on social media, your website, or within your emails to drive app downloads. Remember, the more users that download your app, the more information we’re able to provide you to help improve your event!`
  },
  programCommunities: {
    info: `Adding related communities to your event helps boost exposure for your event within those communities. For example, if you were to add “Game of Thrones” as one of your related communities, users who were exploring the “Game of Thrones” community on FAN GURU would find your event listed under the “Events” section.`
  },
  eventMenuModules: {
    info: `The Event Menu Modules are the default buttons that most events use when creating their app and web experiences. For more custom functionality and styling options for your event menu, check out the Custom Buttons tab.`
  },
  customButtons: {
    info: `Custom Buttons allow you to upload a custom background for your menu buttons, and also allow you to choose if you want the button to span the full-width of the menu, or if you want it to only span half of the width of the menu. Also, by using the mobile link type property, you can have your buttons open any URL, or you can deeplink it to any specific screen within the FAN GURU app.`
  },
  notificationCreateNew: {
    info: `Create a notification whenever you’ve got something important to tell your attendees! You can send it instantly, or schedule it for some time in the future. You can also adjust who it comes from via the “SENDER” field, or set a default sender on the Settings tab.`
  },
  notificationScheduled: {
    info: `This is where you’ll see any of your notification campaigns that are scheduled to send some time in the future. If you see it on this list, it hasn’t sent yet, which also means you can make changes to the notification campaign. In order to see the notification campaigns that have already gone out, please check the Sent tab.`
  },
  notificationSent: {
    info: `This is where you’ll see any of your notification campaigns that have already been delivered to your attendees. Since they have already been delivered, the notification content cannot be changed.`
  },
  gamificationChallenges: {
    info: `Add challenges to give your attendees even more fun things to do related to your event. You can offer challenges where attendees must create a Fan Moment, create their personal schedule, rate the guests and exhibitors they interact with, and even vote in contests just to name a few. Check the prizes tab to really make it worth their while!`
  },
  gamificationPrizes: {
    info: `Add prizes to your event to incentivize your attendees to complete challenges. Every challenge awards a certain number of raffle tickets, and every prize winner is selected by drawing a winner randomly from the pool of unpicked raffle tickets.`
  },
  gamificationGroups: {
    info: `Groups allow you to “group” batches of challenges together. For example, if a sponsor has paid to create some challenges within your event, you might want to group those all together so they can display by themselves. As another example, if you were to create challenges around a certain theme, such as a particular fandom, putting them all in group would make sense.`
  },
  gamificationGroupsPrizes: {
    info: `Adding a Prize to a Group will ensure that when a winner is selected, the raffle tickets that are drawn for the prize will only be selected from the pool that is generated from challenges that exist within the group.`
  },
  gamificationEntriesList: {
    info: `Contests are a great way to build community within your event. One option is to create a contest where any of your attendees can submit their own entries, such as for an on-the-fly costume contest. Another option is upload all of the contest entries yourself and disallow users from uploading their own content. This latter approach is good if quality control is a high priority, and also if you want a simple way for attendees to “cast their vote” within a set of entries which might represent “Best Movie of the Year”, or some other theme where you as the event manager can prep the contest entries in advance.`
  },
  fans: {
    info: `Your Fans list gives you a glimpse into your event attendees profiles, as well their ratings and any of the actions they took within your event. In the unlikely event that a user is creating problems within your event, you can search their username, and then click “BAN”, which will prevent them from creating any more content within your event, but in such a way that they do not realize their content is not being displayed to other attendees.`
  },
  team: {
    info: `The team page is where you can decide who has access to make changes within your event, and more specifically, what kind of changes they are allowed to make.`
  }
};
export const infoLabelMap = {
  eventPromos: {
    info: `Promos will appear at the top of your event's home page in a banner that the user can scroll through. You can upload images or videos. In addition, data and analytics are available for every promo.`
  },
  essentialsWebWidgets: {
    info: `If you run a website for your event, use the embeddable widgets below to add your schedule, guest and exhibitor list info directly to your site. Your embed is always kept 100% in sync with Backstage, so you only need to update your data within Backstage and you’ll see your changes reflected on your site instantly.`
  },
  essentialsArtWork: {
    info: `Adjust the values below to change the visual appearance of your event app & web experience. There are some last details which are addressed within the App > In-App Menu, App > Custom Buttons, and Program > Featured pages, but this page covers the majority of your aesthetic options.`
  },
  essentialsLivePreview: {
    info: `This page shows you a preview of what your Event card will look like within the FAN GURU app and website before users enter it. This page also offers you a sharable marketing link that you can use on social media, your website, or within your emails to drive app downloads. Remember, the more users that download your app, the more information we’re able to provide you to help improve your event!`
  },
  programCommunities: {
    info: `Adding related communities to your event helps boost exposure for your event within those communities. For example, if you were to add “Game of Thrones” as one of your related communities, users who were exploring the “Game of Thrones” community on FAN GURU would find your event listed under the “Events” section.`
  },
  eventMenuModules: {
    info: `The Event Menu Modules are the default buttons that most events use when creating their app and web experiences. For more custom functionality and styling options for your event menu, check out the Custom Buttons tab.`
  },
  customButtons: {
    info: `Custom Buttons allow you to upload a custom background for your menu buttons, and also allow you to choose if you want the button to span the full-width of the menu, or if you want it to only span half of the width of the menu. Also, by using the mobile link type property, you can have your buttons open any URL, or you can deeplink it to any specific screen within the FAN GURU app.`
  },
  notificationCreateNew: {
    info: `Create a notification whenever you’ve got something important to tell your attendees! You can send it instantly, or schedule it for some time in the future. You can also adjust who it comes from via the “SENDER” field, or set a default sender on the Settings tab.`
  },
  notificationScheduled: {
    info: `This is where you’ll see any of your notification campaigns that are scheduled to send some time in the future. If you see it on this list, it hasn’t sent yet, which also means you can make changes to the notification campaign. In order to see the notification campaigns that have already gone out, please check the Sent tab.`
  },
  notificationSent: {
    info: `This is where you’ll see any of your notification campaigns that have already been delivered to your attendees. Since they have already been delivered, the notification content cannot be changed.`
  },
  gamificationChallenges: {
    info: `Add challenges to give your attendees even more fun things to do related to your event. You can offer challenges where attendees must create a Fan Moment, create their personal schedule, rate the guests and exhibitors they interact with, and even vote in contests just to name a few. Check the prizes tab to really make it worth their while!`
  },
  gamificationPrizes: {
    info: `Add prizes to your event to incentivize your attendees to complete challenges. Every challenge awards a certain number of raffle tickets, and every prize winner is selected by drawing a winner randomly from the pool of unpicked raffle tickets.`
  },
  gamificationGroups: {
    info: `Groups allow you to “group” batches of challenges together. For example, if a sponsor has paid to create some challenges within your event, you might want to group those all together so they can display by themselves. As another example, if you were to create challenges around a certain theme, such as a particular fandom, putting them all in group would make sense.`
  },
  gamificationGroupsPrizes: {
    info: `Adding a Prize to a Group will ensure that when a winner is selected, the raffle tickets that are drawn for the prize will only be selected from the pool that is generated from challenges that exist within the group.`
  },
  gamificationEntriesList: {
    info: `Contests are a great way to build community within your event. One option is to create a contest where any of your attendees can submit their own entries, such as for an on-the-fly costume contest. Another option is upload all of the contest entries yourself and disallow users from uploading their own content. This latter approach is good if quality control is a high priority, and also if you want a simple way for attendees to “cast their vote” within a set of entries which might represent “Best Movie of the Year”, or some other theme where you as the event manager can prep the contest entries in advance.`
  },
  fans: {
    info: `Your Fans list gives you a glimpse into your event attendees profiles, as well their ratings and any of the actions they took within your event. In the unlikely event that a user is creating problems within your event, you can search their username, and then click “BAN”, which will prevent them from creating any more content within your event, but in such a way that they do not realize their content is not being displayed to other attendees.`
  },
  team: {
    info: `The team page is where you can decide who has access to make changes within your event, and more specifically, what kind of changes they are allowed to make.`
  }
};

import { Observable, of } from 'rxjs';
import { NavigableModel, NavigationContextSegment } from './navigation-context-segment';

export enum CreatorRole {
  CreatorAdmin = 'creator-admin',
  CreatorMod = 'creator-mod',
  Admin = 'admin'
}

export type CreatorSegment = NavigationContextSegment<NavigableModel.Creator, unknown,''>;
export const routetoCreatorSegment= ():Observable<CreatorSegment> =>
of<CreatorSegment>({
    label: 'CreatorPlatform',
    subLabel:'Creator Mode',
    segmentName: NavigableModel.Creator,
    model: {},
    role:''
});

import { DEFAULT_IMAGE } from '@fg-environments/assets';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { SquareItem } from '@fg-types/square';

export class ExhibitorHelpers {
  static getImage(exhibitor: ConventionExhibitor) {
    const defaultImage = DEFAULT_IMAGE;
    if (!exhibitor) return defaultImage;
    if (exhibitor.alternateLogoMedia && exhibitor.alternateLogoMedia.url)
      return exhibitor.alternateLogoMedia;
    if (exhibitor.logoMedia) return exhibitor.logoMedia;
    if (exhibitor.growtixImage) return exhibitor.growtixImage;
    return defaultImage;
  }

  static getPriceRangeString(item: SquareItem) {
    const prices = item.squareData?.item_data?.variations?.map(
      it => it?.item_variation_data?.price_money?.amount
    );

    if (!prices || !prices[0]) return;
    const highest = Math.max(...prices);
    const lowest = Math.min(...prices);
    if (highest === lowest) return formatDollars(highest);
    return `${formatDollars(lowest)} - ${formatDollars(highest)}`;
  }
}

const formatDollars = (amount: number) => `$${(amount / 100).toFixed(2)}`;

<div class="input-container p-col-12">
    <div class="input-cell">
      <span class="input-flex flex-right">
        <p-autoComplete
          [disabled]="disabled"
          [ngModelOptions]="{ standalone: true }"
          [(ngModel)]="convention"
          [suggestions]="filtered"
          [placeholder]="placeholder"
          field="name"
          (completeMethod)="filter($event)"
          (onSelect)="addItem.emit($event); convention = ''"
          styleClass="autocomplete-tile complete-input"
          inputStyleClass="complete-input"
          [panelStyle]="{
            height: '360px',
            maxHeight: 'unset'
          }"
        >
          <ng-template let-item pTemplate="item">
            <fg-event-feed-tile [hoverActive]="true" [convention]="item"></fg-event-feed-tile>
          </ng-template>
        </p-autoComplete>
      </span>
    </div>
  </div>
  
<div
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  (click)="onClick.emit()"
>
  <div class="tile-media" [style.backgroundImage]="image"></div>
  <div class="tile-body">
    <div class="tile-title p-py-3">{{ data.name }}</div>
    <div class="tile-description">
      Organization
    </div>
    <div class="tile-description">
      &nbsp;
    </div>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

<div class="input-cell">
  <span class="input-flex flex-left inline-input white">
    <i class="pi pi-search"></i>
    <input
      [disabled]="disabled"
      name="search"
      [(ngModel)]="query"
      (ngModelChange)="delaySearch$.next()"
      [placeholder]="placeholder"
      type="text"
      pInputText
    />
  </span>
</div>

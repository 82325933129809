import { MenuItem, SubMenuItemsToRemove } from "@fg-app/app-menu/app-submenu/types";
import { BaseMenuItem } from "@fg-app/app-menu/base-menu-items";
import {  CreatorSegment } from "@fg-services/navigation-context/segments/creator-segment";

export class CreatorMenuItems extends BaseMenuItem<CreatorSegment>{
    menuItems() {
        const items : MenuItem []=[
            {
                label: 'OverView',
                icon: 'grid_on',
                order: 10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive: false,
                childActive: false,
                routerLink: [...this.routePath, 'overview']
            },
            {
                label:'Creator Profile',
                icon: 'fiber_manual_record',
                order: 10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive: false,
                childActive: false,
                routerLink: [...this.routePath, 'profile']
            },
            {
                label:'Payments',
                icon:'credit_card',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                items: [
                    {
                      label: 'Subscriptions',
                      routerLink: [...this.routePath, 'payments', 'subscriptions'],
                      fatherActive: false,
                      childActive: false
                    },
                    {
                        label: 'Subscribers',
                        routerLink:[...this.routePath, 'payments', 'subscribers'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Payout',
                        routerLink:[...this.routePath,'payments', 'payout'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Billing',
                        routerLink:[...this.routePath,'payments','billing'],
                        fatherActive:false,
                        childActive:false,
                    }
                ]
            },
            {
                label:'Paid Content',
                icon:'fiber_manual_record',
                order: 10,
                roles:[],
                fatherActive:false,
                childActive: false,
                items:[
                    {
                        label: 'Custom Requests',
                        routerLink:[...this.routePath,'paid','customReq'],
                        fatherActive: false,
                        childActive: false,
                    },
                ]
            },
            {
                label:'Polls',
                icon:'fiber_manual_record',
                order: 10,
                roles:[],
                fatherActive:false,
                childActive: false,
                items:[
                    {
                        label: 'New',
                        routerLink:[...this.routePath,'polls','create-poll'],
                        fatherActive: false,
                        childActive: false,
                    },
                    {
                        label: 'Published',
                        routerLink:[...this.routePath,'polls','published'],
                        fatherActive: false,
                        childActive: false,
                    },
                    {
                        label: 'Scheduled',
                        routerLink:[...this.routePath,'polls','scheduled'],
                        fatherActive: false,
                        childActive: false,
                    },
                    {
                        label: 'Drafts',
                        routerLink:[...this.routePath,'polls','drafts'],
                        fatherActive: false,
                        childActive: false,
                    },
                ]
            },
            {
                label:'Promo',
                icon:'fiber_manual_record',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'promos']
            },
            {
                label:'Posts',
                icon:'fiber_manual_record',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                items: [
                    {
                        label:'New',
                        routerLink:[...this.routePath,'posts','newPost'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Published',
                        routerLink:[...this.routePath,'posts','published'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Scheduled',
                        routerLink:[...this.routePath,'posts','scheduled'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Drafts',
                        routerLink:[...this.routePath,'posts','drafts'],
                        fatherActive:false,
                        childActive:false,
                    },

                ]
            },
            {
                label:'Calendar',
                icon:'event_available',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'calendar']
            },
            {
                label:'Notifications',
                icon:'notifications_active',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                items:[
                    {
                        label:'New',
                        routerLink:[...this.routePath,'notifications','new'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Published',
                        routerLink:[...this.routePath,'notifications','published'],
                        fatherActive:false,
                        childActive:false,
                    },
                    {
                        label:'Scheduled',
                        routerLink:[...this.routePath,'notifications','scheduled'],
                        fatherActive:false,
                        childActive:false,
                    },
                ]
            },
            {
                label:'Contest',
                icon:'bookmark',
                order:10,
                roles:[],
                fatherActive: false,
                childActive: false,
                routerLink:[...this.routePath,'contests'],
            },
            {
                label: 'Moderation',
                icon: 'fiber_manual_record',
                order: 10,
                roles:[],
                fatherActive: false,
                childActive: false,
                items:[
                    {
                        label: 'User Content',
                        routerLink: [...this.routePath,'mode','userContent'],
                        fatherActive: false,
                        childActive: false,
                    },
                    {
                        label: 'Reported Content',
                        routerLink:[...this.routePath,'mode','reported'],
                        fatherActive: false,
                        childActive : false,
                    },
                    {
                        label: 'User Moderations',
                        routerLink:[...this.routePath,'mode','user-moderations'],
                        fatherActive: false,
                        childActive : false,
                    }
                ]
            },
            {
                label:'Communities',
                icon:'fiber_manual_record',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'communities']
            },
            {
                label:'Team Members',
                icon:'supervisor_account',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'team-members']
            },
            {
                label:'Analytics',
                icon:'fiber_manual_record',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'analytics']
            },
            {
                label:'Settings',
                icon:'fiber_manual_record',
                order:10,
                roles:[],
                // roles: [CPRole.Admin],
                fatherActive:false,
                childActive:false,
                routerLink: [...this.routePath, 'settings']
            }
        ];
        return this.filterOptions(items) 
    }
    filterOptions(menuItems: MenuItem[]) {
        const labelsToRemove: string[] = [];
        const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
        const filteredOptions = this.removeSideNavItems(menuItems, null, labelsToRemove);
        // console.log(filteredOptions)
        return this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
      }
}
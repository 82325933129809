<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.title"
  [description]="challengeType"
  [media]="data?.squareImage || convention?.eventImagePlaceholder"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

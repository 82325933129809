import {
  Component,
  ComponentFactoryResolver,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Type,
  ViewChild
} from '@angular/core';
import { ViewHostDirective } from '@fg-shared/directives/view-host.directive';

export interface ListItem<T> {
  data: T;
  active?: boolean;
  onClick?: EventEmitter<void>;
  hoverActive?: boolean;
}

@Component({
  selector: 'list-item',
  template: '<ng-template view-host></ng-template>'
})
export class ListItemComponent<T> implements OnChanges {
  @Input() itemComponent: Type<ListItem<T>>;
  @Input() data: T;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  @ViewChild(ViewHostDirective, { static: true }) itemHost: ViewHostDirective;

  constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnChanges() {
    this.loadComponent();
  }

  loadComponent() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
      this.itemComponent
    );

    const viewContainerRef = this.itemHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    const instance = componentRef.instance as ListItem<T>;
    instance.data = this.data;
    instance.active = this.active;
    instance.onClick = this.onClick;
    instance.hoverActive = this.hoverActive;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { ApiBase } from '@fg-services/api/models/api-base';
import { ConventionExhibitoSpecial } from '@fg-types/convention-exhibitor';
import { PaginatedResponse } from '@fg-types/paginated-response';

@Injectable()
export class ConventionExhibitorSpecialApi extends ApiBase {
  model = 'ConventionExhibitorSpecials';

  get(lastKey?: string, conventionIds?: string, exhibitorIds?: string) {
    return this.http.get<PaginatedResponse<ConventionExhibitoSpecial>>(this.modelBase, {
      params: this.setParams({
        lastKey,
        conventionIds,
        exhibitorIds
      })
    });
  }

  create(special: Partial<ConventionExhibitoSpecial>) {
    return this.http.post<ConventionExhibitoSpecial>(this.modelBase, special);
  }

  patch(id: string, special: Partial<ConventionExhibitoSpecial>) {
    return this.http.patch<ConventionExhibitoSpecial>(`${this.modelBase}/${id}`, special);
  }

  delete(id: string) {
    return this.http.delete(`${this.modelBase}/${id}`);
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { SquareCategory, SquareItem } from '@fg-types/square';

export interface SquareCatalogPatchRequest {
  isVisible?: boolean;
  isFeatured?: boolean;
  isTaxExempt?: boolean;
  isAvailableForShipping?: boolean;
  isAvailableForPickup?: boolean;
  order?: number;
  mediaIds?: string[];
}

export class SquareCatalogApi extends ApiBase {
  model = 'SquareCatalogs';

  getById<T extends SquareItem | SquareCategory>(id: string) {
    return this.http.get<T>(`${this.modelBase}/${id}`);
  }

  patch(id: string, body: SquareCatalogPatchRequest) {
    return this.http.patch<SquareItem>(`${this.modelBase}/${id}`, body);
  }
}

import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import {
  OrganizationRole,
  OrganizationSegment
} from '@fg-services/navigation-context/segments/organization-segment';

export class OrganizationMenuItems extends BaseMenuItem<OrganizationSegment> {
  menuItems() {
    const { organization } = this.context.model;
    if (!organization) return [];

    const items: MenuItem[] = [
      {
        label: 'Essentials',
        customIcon: 'essentials',
        order: 0,
        roles: [OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'essentials']
      },
      {
        label: 'Events',
        serviceLabel: 'Organization.conventions',
        icon: 'apps',
        order: 10,
        roles: [OrganizationRole.OrgMod, OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'events']
      },
      {
        label: 'Guests and Talent List',
        serviceLabel: 'Organization.celebrity',
        icon: 'people',
        order: 11,
        roles: [OrganizationRole.OrgMod, OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'guests']
      },
      {
        label: 'Subscriptions',
        serviceLabel: 'Organization.celebrity',
        icon: 'people',
        order: 12,
        roles: [OrganizationRole.OrgMod, OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'subscriptions']
      },
      {
        label: 'Team',
        serviceLabel: 'Organization.team',
        icon: 'group_work',
        order: 20,
        roles: [OrganizationRole.Admin, OrganizationRole.OrgAdmin],
        routerLink: [...this.routePath, 'team'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Billing',
        serviceLabel: 'Organization.billing',
        customIcon: 'o_billing',
        order: 50,
        roles: [OrganizationRole.OrgMod, OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        routerLink: [...this.routePath, 'billing'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Communities',
        serviceLabel: 'Organization.communities',
        icon: 'groups',
        order: 10,
        roles: [OrganizationRole.OrgMod, OrganizationRole.OrgAdmin, OrganizationRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'communities']
      },
      {
        label: 'Notifications',
        serviceLabel: 'Organization.notifications',
        icon: 'notifications_active',
        order: 40,
        roles: [OrganizationRole.Admin, OrganizationRole.OrgAdmin],
        routerLink: [...this.routePath, 'notifications'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Artwork',
        serviceLabel: 'Organization.artwork',
        icon: 'format_paint',
        order: 30,
        roles: [OrganizationRole.Admin, OrganizationRole.OrgAdmin],
        routerLink: [...this.routePath, 'artwork'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Featured',
        serviceLabel: 'Organization.featured',
        icon: 'playlist_add_check',
        order: 30,
        roles: [OrganizationRole.Admin, OrganizationRole.OrgAdmin],
        routerLink: [...this.routePath, 'featured'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Settings',
        icon: 'settings',
        order: 70,
        roles: [OrganizationRole.Admin],
        routerLink: [...this.routePath, 'settings'],
        fatherActive: false,
        childActive: false
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const { model, role } = this.context;
    const { services } = model;
    const labelsToRemove: string[] = [];
    const labelsToLock: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    if (role !== OrganizationRole.Admin) {
      menuItems
        .filter(({ serviceLabel }) => serviceLabel)
        .forEach(({ serviceLabel, label }) => {
          if (!services[serviceLabel]) labelsToLock.push(label);
        });
    }
    const filteredOptions = this.removeSideNavItems(menuItems, role, labelsToRemove);
    const subNavFilteredNavItems = this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
    return this.lockSideNavItems(labelsToLock, subNavFilteredNavItems);
  }

  lockSideNavItems(lockSideNavItems: string[], menuItems: MenuItem[]) {
    return menuItems.map(_menuItem =>
      lockSideNavItems.includes(_menuItem.label)
        ? {
            ..._menuItem,
            icon: 'lock',
            customIcon: '',
            badgeStyleClass: 'locked'
          }
        : _menuItem
    );
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { Organization } from '@fg-types/organization.type';
import { UserRoles } from '@fg-types/roles.type';

@Component({
  selector: 'organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss']
})
export class OrganizationsComponent implements OnInit {
  items: Organization[];
  userRoles: UserRoles;
  query: string;
  lastKey: string;
  loading = false;
  scrollFinished = false;
  showNewOrgForm = false;
  noOrgs = false;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private router: Router,
    private apiCallsInProgress: ApiCallsInProgress,
    private uiService: UiService,
    private localStorageService: LocalStorageService
  ) {}

  ngOnInit() {
    if (!this.userRoles) {
      this.userService.loadRoles().subscribe(roles => {
        this.userRoles = roles;
        this.loadItems();
      });
    } else {
      this.userRoles = this.userService.roles;
      this.loadItems();
    }
    this.localStorageService.setItem('last-home-option', 'organizations');
  }

  loadAdminItems() {
    if (this.loading) return;
    this.loading = true;
    this.apiCallsInProgress.addCall();
    this.api.search.organizations(this.query, this.lastKey).subscribe(
      ({ items, lastKey }) => {
        this.items = this.lastKey ? [...this.items, ...items] : items;
        this.loading = false;
        this.scrollFinished = !items.length || !lastKey;
        this.lastKey = lastKey;
        this.apiCallsInProgress.reset();
      },
      () => {
        this.loading = false;
        this.apiCallsInProgress.reset();
      }
    );
  }

  loadItems() {
    if (this.userRoles.isAdmin) {
      this.loadAdminItems();
    } else {
      const regex = this.query ? new RegExp(`^${this.query}`, 'i') : undefined;
      this.items = [
        ...this.userRoles.organizationsAdmining,
        ...this.userRoles.organizationsModerating
      ].filter(item => {
        if (!regex) return true;
        if (!item) return false;
        return regex.test(item.name);
      });
      if (
        [...this.userRoles.organizationsAdmining, ...this.userRoles.organizationsModerating]
          .length === 0
      )
        this.noOrgs = true;
    }
  }

  search(query: string) {
    this.query = query;
    this.lastKey = undefined;
    this.loadItems();
  }

  selectItem(organization: Organization) {
    this.router.navigate(['bs', 'o', organization.id]);
  }

  createNew(name: string) {
    let defaultColor = {
      primaryColor: '#00e5da',
      secondaryColor: '#192a2e',
      searchTextColor: '#323232',
      labelColor: '#ffffff',
      accentColor: '#541c9c',
      headerColor: '#192a2e',
      headerLabelColor: '#ffffff',
      iconColor: '#00e5da'
    };

    const changes: Partial<Organization> = {
      theme: defaultColor,
      name: name
    };

    this.apiCallsInProgress.addCall();
    this.api.organizations.create(changes).subscribe(
      org => {
        this.apiCallsInProgress.reset();
        this.router.navigate(['bs', 'o', org.id]);
      },
      () => {
        this.uiService.toast({
          severity: 'error',
          detail: `There was an error creating the Organization.`
        });
        this.apiCallsInProgress.reset();
      }
    );
  }
}

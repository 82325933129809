import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ButtonModule } from 'primeng/button';
import { ProgressBarModule } from 'primeng/progressbar';
import { CropImageComponent } from './crop-image/crop-image.component';
import { CroppperComponent } from './cropper/cropper.component';
import { PdfUploaderComponent } from './pdf-uploader/pdf-uploader.component';
import { ImageuploaderComponent } from './imageuploader/imageuploader.component';

@NgModule({
  imports: [CommonModule, ProgressBarModule, ButtonModule, PdfViewerModule],
  declarations: [CroppperComponent, CropImageComponent, PdfUploaderComponent, ImageuploaderComponent],
  exports: [CroppperComponent, PdfUploaderComponent]
})
export class UploaderModule {}

<div class="input-container">
  <div class="input-cell">
    <label *ngIf="title">{{ title }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="exhibitor"
        [suggestions]="filteredExhibitors"
        placeholder="Type to search an Exhibitor"
        field="name"
        (completeMethod)="filter($event)"
        (onSelect)="addExhibitor.emit($event); exhibitor = ''"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <exhibitor-tile
            *ngIf="item.id; else createNew"
            [data]="item"
            hoverActive="1"
          ></exhibitor-tile>
          <ng-template #createNew>{{ item }}</ng-template>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

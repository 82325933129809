import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { MediaService } from '@fg-services/media.service';
import { AdminHelpers } from '@fg-shared/helpers/admin-helpers';
import { Category } from '@fg-types/category';

@Component({
  selector: 'fg-category-form',
  templateUrl: './fg-category-form.component.html',
  styleUrls: ['./fg-category-form.component.scss']
})
export class FgCategoryFormComponent implements OnInit {
  @ViewChild('progressBar') public progressBar: ElementRef;
  @Input() category: Category;
  @Output() onChange = new EventEmitter();
  svg_loading: boolean;

  get svgPreview() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `url(${this.category?.iconMedia?.sizes[0]?.url}) no-repeat center`
    );
  }

  constructor(
    private sanitizer: DomSanitizer,
    private apiCallsInProgress: ApiCallsInProgress,
    private mediaService: MediaService
  ) {}

  ngOnInit(): void {}
  updateImage($event, prop) {
    this.category[prop] = $event;
    this.category[prop].url = $event.url;
    this.category[prop + 'Id'] = $event.id;
    this.onChange.emit();
  }

  deleteImage() {
    delete this.category.media;
    delete this.category.mediaId;
    this.onChange.emit();
  }

  selectImage({ files }) {
    this.svg_loading = true;
    const fileName = encodeURI(AdminHelpers.getGUID() + '.svg');
    this.apiCallsInProgress.addCall();
    const watch = this.mediaService.getObservable().subscribe((res: any) => {
      if (res.status === 'pending') {
        Object(this.progressBar).value = res.progress;
      }
      if (res.status === 'finish') {
        this.apiCallsInProgress.reset();
        this.svg_loading = false;
        this.onChange.emit();
        this.updateImage(res.success, 'iconMedia');
        watch.unsubscribe();
      }
    });
    if (files[0]) {
      this.mediaService.assetUpload(files[0], fileName);
    }
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { ConventionPartner } from '@fg-types/convention-partner';

@Component({
  selector: 'ad-tile',
  templateUrl: './ad-tile.component.html',
  styleUrls: ['./ad-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdTileComponent implements ListItem<ConventionPartner> {
  @Input() data: ConventionPartner;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'mobile-detail-header',
  templateUrl: './mobile-detail-header.component.html',
  styleUrls: ['./mobile-detail-header.component.scss']
})
export class MobileDetailHeaderComponent {
  @Input() title: string;
  @Output() onClose = new EventEmitter<void>();
}

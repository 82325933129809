import { DEFAULT_IMAGE } from '@fg-environments/assets';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';

export class GuestHelpers {
  static getImage(guest: IConventionCelebrity, imageField: 'profileMedia' | 'landscapeMedia') {
    const defaultImage = DEFAULT_IMAGE;
    if (!guest) return defaultImage;
    const landscapeImage = guest.alternateLandscapeMedia || guest.landscapeMedia;
    if (imageField === 'landscapeMedia' && landscapeImage) {
      return landscapeImage;
    }
    if (guest.alternateProfileMedia && guest.alternateProfileMedia.url)
      return guest.alternateProfileMedia;
    if (guest.profileMedia) return guest.profileMedia;
    return defaultImage;
  }

  static getName(guest: IConventionCelebrity) {
    if (!guest) return '';
    return `${guest.firstName || ''} ${guest.lastName || ''}`;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as SendBird from 'sendbird';
import { environment } from '@fg-environments/environment';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { ApiService } from '@fg-services/api/api.service';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class ChatApiService {
  public appId = environment.CHAT_API_ID;
  public isAuthenticated = false;
  public sbConnection = new SendBird({ appId: this.appId });
  public channel;
  private API_URL = environment.API_URL + environment.API_VERSION;
  public get userId() {
    return this.localStorageService.getItem('userId');
  }

  constructor(private http: HttpClient, private localStorageService: LocalStorageService, 
    private apiService : ApiService,
    private route : ActivatedRoute,
    ) {}

  connectListener(): Observable<any> {
    return Observable.create(observer => {
      this.sbConnection.connect(this.userId, (user, err) => {
        if (err) {
          observer.error(err);
        }
        return observer.next(user);
      });
    });
  }

  public disconnect() {
    this.sbConnection.disconnect();
  }

  public getOpenChannels(conventionIds?: string, lastkey?: string): Observable<any> {
    const paramsTemplate = { lastkey, conventionIds };
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (paramsTemplate[key]) {
        params = params.set(key, paramsTemplate[key]);
      }
    }
    const options = { ...this.getHeaders(), params };
    return this.http.get(`${this.API_URL}/ConventionOpenChannels`, options);
  }

  public handleChannelState(CHANNEL_URL, channelState): Observable<any> {
    return Observable.create(observer => {
      this.sbConnection.OpenChannel.getChannel(CHANNEL_URL, (channel, error) => {
        this.channel = channel;
        const { channelType, operators } = this.channel;
        if (error) {
          observer.error(error);
        }
        switch (channelState) {
          case 'enter':
            channel.enter(res => {
              observer.next(res);
            });
            break;
          case 'exit':
            channel.exit((success, err) => {
              if (err) {
                observer.error(err);
              }
            });
            break;
        }
      });
    });
  }

  public getChannelChats(): Observable<any> {
    return Observable.create(observer => {
      const messageListQuery = this.channel.createPreviousMessageListQuery();
      messageListQuery.limit = 200;
      messageListQuery.reverse = true;
      messageListQuery.load((messageList, listQueryError) => {
        if (listQueryError) {
          observer.error(listQueryError);
        }
        return observer.next(messageList);
      });
    });
  }

  deleteMessage(message: any, channel?: any): Observable<any> {
    const eventId = channel?.id
    return Observable.create(observer => {
      this.apiService.users.deleteChatMessage(eventId,message?.messageId).subscribe(
        response => {observer.next(response)
                     observer.complete()},
          err => observer.error(err)
      );
    // this.channel.deleteMessage(message, (response, error) => {
        // if (error) {
        //   return observer.error(error);
        // }
        // observer.next(response);
      // });
    });
  }

  removeUser(user: any): Observable<any> {
    const one_year = 1000 * 60 * 60 * 24 * 365;
    return Observable.create(observer => {
      this.channel.banUser(user, one_year, (response, error) =>
        error ? observer.error(error) : observer.next(response)
      );
    });
  }

  deleteChannel(id: string): Observable<any> {
    return Observable.create(observer => {
      this.channel.delete((response, error) => {
        if (error) {
          return observer.error(error);
        }
        this.http.delete(`${this.API_URL}/ConventionOpenChannels/${id}`).subscribe(
          res => observer.next(response),
          err => observer.error(err)
        );
      });
    });
  }

  getHeaders(): any {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
  }

  createOrJoinGroupChannel(
    userId: string,
    distinct?: boolean,
    name?: string,
    coverUrl?: string,
    data?: string
  ): Observable<any> {
    return Observable.create(subscriber => {
      this.sbConnection.GroupChannel.createChannelWithUserIds(
        [this.userId, userId],
        distinct,
        name,
        coverUrl,
        data,
        (channel, error) => {
          if (error) {
            return subscriber.error(error);
          }
          this.channel = channel;
          return subscriber.next(channel);
        }
      );
    });
  }

  sendMessage(message: string): Observable<any> {
    return Observable.create(subscriber => {
      this.channel.sendUserMessage(message, null, null, (resMessage, error) => {
        if (error) {
          return subscriber.error(error);
        }
        return subscriber.next(resMessage);
      });
    });
  }
}

import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { ApiBase } from '@fg-services/api/models/api-base';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ConventionCelebrityApi extends ApiBase {
  model = 'ConventionCelebrities';

  mapConventionCelebrity(conventionCelebrity: IConventionCelebrity) {
    const growtixData = conventionCelebrity.growtixData || {};
    const imageUrl =
      growtixData.images && growtixData.images[0] && growtixData.images[0].big
        ? growtixData.images[0].big
        : null;
    return {
      ...conventionCelebrity,
      profileMedia: imageUrl ? { url: imageUrl } : null
    };
  }

  get(
    isVisible: boolean,
    lastKey?: string,
    conventionIds?: string,
    exhibitorIds?: string,
    types?: string[]
  ) {
    const options = {
      params: this.setParams({
        isVisible,
        lastKey,
        conventionIds,
        exhibitorIds,
        types
      })
    };
    return this.http.get<PaginatedResponse<IConventionCelebrity>>(this.modelBase, options).pipe(
      map(response => ({
        ...response,
        items: response.items.map(this.mapConventionCelebrity)
      }))
    );
  }

  attach(conventionCelebrityId: string, celebrityId: string): Observable<any> {
    return this.http.put<IConventionCelebrity>(`${this.modelBase}/${conventionCelebrityId}`, {
      celebrityId
    });
  }

  delete(conventionCelebrityId: string) {
    return this.http.delete(`${this.modelBase}/${conventionCelebrityId}`);
  }
}

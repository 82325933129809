import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

export type ICategory =
  | {
      color?: string;
      id?: string;
      index?: number;
      name: string;
      count?: number;
    }
  | string;

@Component({
  selector: 'fg-editable-list',
  templateUrl: './fg-editable-list.component.html',
  styleUrls: ['./fg-editable-list.component.scss']
})
export class FgEditableListComponent extends BaseComponent {
  @Input() title: string;
  @Input() disabled: boolean;
  @Input() hasColor: boolean;
  @Input() isSortable = true;
  @Input() strings: boolean;
  @Input() placeholder = 'Type to add a Category';
  @Output() onChange = new EventEmitter<ICategory[]>();
  _categories: ICategory[];
  @Input() set categories(categories: ICategory[]) {
    this._categories = categories;
  }

  get categories() {
    return this._categories || [];
  }

  get stringsArray() {
    return this.categories.map(c => ({ value: c }));
  }

  newCategory = '';
  dragulaType = 'categories';
  delayUpdateType$ = new Subject<{ index: number; value: string }>();

  constructor(public uiService: UiService, navigationContextService: NavigationContextService) {
    super(navigationContextService);
    this.delayUpdateType$.pipe(debounceTime(300)).subscribe(({ index, value }) => {
      this.categories[index] = value;
      this.onChange.next(this.categories);
    });
  }

  drop(event: CdkDragDrop<ICategory[]>) {
    moveItemInArray(this.categories, event.previousIndex, event.currentIndex);
    this.onChange.emit(this.categories);
  }

  addEvent() {
    if (!this.newCategory) {
      return this.uiService.toast({
        severity: 'error',
        detail: 'Please enter a value.'
      });
    }
    const existing = this.categories.find(item => {
      const name = (typeof item === 'string' ? item : item.name) || '';
      return name.toLowerCase() === this.newCategory.toLowerCase();
    });
    if (existing) {
      this.uiService.toast({
        severity: 'error',
        summary: 'Error:',
        detail: 'Item already exists'
      });
      return;
    }
    const newCategory = this.strings ? this.newCategory : { name: this.newCategory };
    this.onChange.emit([newCategory, ...this.categories]);
    this.newCategory = '';
  }

  deleteInput(index: number) {
    this.categories.splice(index, 1);
    this.onChange.emit(this.categories);
  }

  trackByFn(index) {
    return index;
  }
}

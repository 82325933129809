<div *ngIf="visible" class="background">
  <div class="confirmation-container">
    <div class="description">
      <h2>Enter the words "{{ keyPhrase }}" in the box below to complete this action.</h2>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Type here...</label>
        <input pInputText class="input" name="event-name" type="text" [(ngModel)]="userInput" />
      </div>
    </div>
    <div class="buttons">
      <button
        pButton
        class="p-button-primary center"
        type="button"
        label="ENTER"
        [disabled]="userInput !== keyPhrase"
        (click)="confirm.emit(); userInput = ''"
      ></button>
      <button
        pButton
        class="p-button-secondary center"
        type="button"
        label="CANCEL"
        (click)="userInput = ''; reject.emit()"
      ></button>
    </div>
  </div>
</div>

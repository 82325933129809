<div
  class="tile"
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  (click)="onClick.emit()"
>
  <div class="square-indicator">{{ data.count }}<br />FANS</div>
  <div class="tile-body">
    {{ data.title }}<span><br />{{ data.percentage / 100 | percent: '1.0-2' }}</span>
  </div>
  <div class="square-indicator">
    <percentage-circle
      [percentage]="data.percentage"
      [showTitle]="false"
      [showUnits]="false"
    ></percentage-circle>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

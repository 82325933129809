import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IPartner } from '@fg-types/partner';

@Component({
  selector: 'search-ad-partner',
  templateUrl: './search-ad-partner.component.html',
  styleUrls: ['./search-ad-partner.component.scss']
})
export class SearchAdPartnerComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() label: string;
  @Input() createNewText: string;
  @Input() clearOnSelect = true;
  @Output() addAdPartner = new EventEmitter<IPartner>();
  @Output() queryChange = new EventEmitter<string>();
  filteredAdPartners: (IPartner | string)[] = [];
  adPartner = '';

  constructor(private api: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.api.search.searchAdPartners(params.query).subscribe(res => {
        this.filteredAdPartners = [
          ...res.items,
          ...(this.createNewText ? [this.createNewText] : [])
        ];
      });
    }
  }

  onSelect(adPartner: IPartner) {
    this.addAdPartner.emit(adPartner);
    if (this.clearOnSelect) this.adPartner = '';
  }
}

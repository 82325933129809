import { ApiBase } from '@fg-services/api/models/api-base';
import { IPartner } from '@fg-types/partner';

export class AdPartnerApi extends ApiBase {
  model = 'AdPartners';

  create(body: Partial<IPartner>) {
    return this.http.post<IPartner>(this.modelBase, body);
  }

  update(id: string, body: Partial<IPartner>) {
    return this.http.patch<IPartner>(`${this.modelBase}/${id}`, body);
  }

  delete(id: string) {
    return this.http.delete<any>(`${this.modelBase}/${id}`);
  }
}

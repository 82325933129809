import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FgNewConventionFormOutput } from '@fg-app/backstage/fg-new-convention-form/fg-new-convention-form.component';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { ConventionHelpers } from '@fg-shared/helpers/convention-helpers';
import isNonNil from '@fg-shared/helpers/isNonNil';
import { FilterEvent } from '@fg-shared/layout/filter-events/filter-events.type';
import { IConvention } from '@fg-types/convention.type';
import { UserRoles } from '@fg-types/roles.type';
import * as moment from 'moment-mini';
import { forkJoin, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})
export class EventsComponent extends BaseComponent {
  items: IConvention[];
  userRoles: UserRoles;
  query: string;
  lastKey: string;
  loading: boolean;
  loadingPastEvents: boolean;
  loadingUpcomingEvents: boolean;
  scrollFinished = false;
  _filter: FilterEvent;
  pastEvents: IConvention[];
  pastLastKey: string;
  pastScrollFinished = false;
  upcomingEvents: IConvention[];
  upcomingLastKey: string;
  upcomingScrollFinished = false;
  displayContainer: '' | 'past' | 'upcoming' = '';
  addNew = false;

  set filter(filter: FilterEvent) {
    if (!filter) {
      this.resetItems();
    } else {
      this.localStorageService.setItem('eventFilter', filter);
    }
    this._filter = filter;
    this.lastKey = undefined;
    this.loading = false;
    this.loadItems();
  }

  get filter() {
    return this._filter;
  }

  get filterText() {
    const filters = Object.keys(this.filter || {}).length;
    if (!filters) return '';
    return `FILTER BY: ${filters} Filter${filters > 1 ? 's' : ''} Applied`;
  }

  constructor(
    private api: ApiService,
    private userService: UserService,
    private router: Router,
    private apiCallsInProgress: ApiCallsInProgress,
    private uiService: UiService,
    private localStorageService: LocalStorageService,
    navigationContextService: NavigationContextService
  ) {
    super(navigationContextService);
    const filter = this.localStorageService.getItem('eventFilter');
    if (filter) {
      this._filter = filter;
    }
  }

  onContextChange = () => {
    if (!this.userRoles) {
      this.userService.loadRoles().subscribe(roles => {
        this.userRoles = roles;
        this.loadItems();
      });
    } else {
      this.userRoles = this.userService.roles;
      this.loadItems();
    }
    this.localStorageService.setItem('last-home-option', 'events');
  };

  getLoader() {
    if (this.filter && Object.keys(this.filter).length) {
      const { size, fromDate, toDate, type = 'all', ...otherFilters } = this.filter;
      return this.api.feed.conventions('all', {
        ...(this.userRoles.isAdmin ? {} : { userId: this.userService.userId }),
        lastKey: this.lastKey,
        query: this.query,
        fromPublishedAttendance: size && isNonNil(size.min) ? size.min : undefined,
        toPublishedAttendance: size && isNonNil(size.max) ? size.max : undefined,
        fromDate:
          fromDate && type === 'all'
            ? moment
                .utc(fromDate)
                .set('hour', 0)
                .toISOString()
            : undefined,
        toDate:
          toDate && type === 'all'
            ? moment
                .utc(toDate)
                .set('hour', 0)
                .add(1, 'day')
                .subtract(1, 'second')
                .toISOString()
            : undefined,
        type,
        allConventions: this.userRoles?.isAdmin,
        ...otherFilters
      });
    }
    if (this.query)
      return this.api.search.Conventions(this.query, this.lastKey, this.userRoles?.isAdmin);
    return this.api.feed.conventions('featured', {
      lastKey: this.lastKey,
      allConventions: this.userRoles?.isAdmin
    });
  }

  loadExternalItems() {
    const loadComplete = (items, lastKey) => {
      this.items = this.lastKey ? [...this.items, ...items] : items;
      this.loading = false;
      this.scrollFinished = items?.length < 20 || !items.length || !lastKey;
      this.lastKey = lastKey;
      this.apiCallsInProgress.reset();
    };
    if (this.loading) return;
    this.loading = true;
    this.apiCallsInProgress.addCall();
    this.getLoader().subscribe(
      ({ items, lastKey }) => {
        if (items.length) {
          forkJoin(
            items.map(item => this.preloadImage(ConventionHelpers.getImageUrl(item)))
          ).subscribe(() => loadComplete(items, lastKey));
        } else loadComplete(items, lastKey);
      },
      err => {
        this.uiService.errorHandler(err);
        this.loading = false;
      }
    );
  }

  loadItems(type?: string) {
    if (this.loading) return;
    if (this.userRoles.isAdmin) {
      this.loadExternalItems();
    } else {
      if (!this.userRoles?.consAdmining?.length && !this.userRoles?.consModerating?.length) {
        return this.router.navigate(['bs', 'organizations']);
      }
      this.apiCallsInProgress.addCall();
      this.loadModAdminEvents('past');
      this.loadModAdminEvents('upcoming');
    }
  }

  loadModAdminEvents(type: 'past' | 'upcoming') {
    const capVariant = { past: 'Past', upcoming: 'Upcoming' };
    const upperType = capVariant[type];
    this[`loading${upperType}Events`] = true;
    const onLoadComplete = (items, lastKey) => {
      this[`${type}ScrollFinished`] = items.length < 20 || !items.length || !lastKey;
      this[`${type}Events`] = this[`${type}LastKey`] ? [...this[`${type}Events`], ...items] : items;
      this[`${type}LastKey`] = lastKey;
      this[`loading${upperType}Events`] = false;
      this.query = '';
      this.apiCallsInProgress.reset();
    };
    if (this[`${type}ScrollFinished`] && !this.query) return;
    const allConventions = Boolean(
      this.userRoles?.organizationsAdmining?.length ||
        this.userRoles?.organizationsModerating?.length
    );
    this.api.feed
      .conventions('all', {
        userId: this.userService.userId,
        lastKey: this[`${type}LastKey`],
        allConventions,
        type
      })
      .subscribe(({ items, lastKey }) => {
        if (items.length) {
          forkJoin(
            items.map(item => this.preloadImage(ConventionHelpers.getImageUrl(item)))
          ).subscribe(() => onLoadComplete(items, lastKey));
        } else onLoadComplete(items, lastKey);
      });
  }

  search(query: string) {
    if (!query) {
      this.resetItems();
    }
    this.query = query;
    this.lastKey = undefined;
    this.loadItems();
  }

  selectItem(item: IConvention) {
    const path = this.navigationContext.getRoutePath();
    this.router.navigate([...path, 'c', item.id]);
  }

  createNew({ convention, orgId }: FgNewConventionFormOutput) {
    this.apiCallsInProgress.addCall();
    this.api.conventions.create(convention).subscribe(
      c => {
        this.attachNewConvention(c, orgId);
      },
      e => {
        this.uiService.errorHandler(e);
        this.addNew = false;
      }
    );
  }

  attachNewConvention(convention: IConvention, selectedOrgId: string) {
    const path = this.navigationContext.getRoutePath();
    if (!selectedOrgId && this.userRoles.isAdmin) {
      return this.router.navigate([...path, 'c', convention.id]);
    }
    const orgId = selectedOrgId || this.userRoles.organizationsAdmining[0].id;
    this.api.organizations
      .putOrgConvention(orgId, convention.id, { isOwner: true })
      .pipe(mergeMap(() => this.userService.loadRoles()))
      .subscribe(
        () => {
          this.apiCallsInProgress.reset();
          this.router.navigate([...path, 'c', convention.id]);
        },
        () => {
          this.apiCallsInProgress.reset();
          this.router.navigate([...path, 'c', convention.id]);
        }
      );
  }

  resetItems() {
    this.items = [];
    this.pastEvents = [];
    this.upcomingEvents = [];
    this.query = '';
    this.lastKey = '';
    this.pastLastKey = '';
    this.upcomingLastKey = '';
    this.displayContainer = '';
    this.scrollFinished = false;
    this.upcomingScrollFinished = false;
    this.pastScrollFinished = false;
    this.localStorageService.removeItem('eventFilter');
  }

  preloadImage(url) {
    return new Observable(observer => {
      const img = new Image();
      img.src = url;
      img.onload = () => {
        observer.next();
        observer.complete();
      };
    });
  }

  onCreateNew() {
    if (this.userRoles.isAdmin || this.userRoles.isOrganizationAdmin) {
      this.addNew = true;
    } else {
      this.router.navigate(['bs', 'organizations']);
    }
  }
}

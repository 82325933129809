<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data.name"
  [media]="data.logoHorizontal"
  [description]="conventionData"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
  <div *ngIf="data.logoHorizontal" class="go-live-status p-grid p-align-center">
    <div
      class="indicator"
      [style.background-color]="indicatorColorMap[data?.goLive] || '#5cb85c'"
    ></div>
    <div>{{ data?.goLive }}</div>
  </div>
</tile>

import { ApiBase } from '@fg-services/api/models/api-base';
import { Advertisement } from '@fg-types/advertisement';
import { DeleteResponse } from '@fg-types/delete-response';

export class AdvertisementsApi extends ApiBase {
  model = 'Advertisements';

  createAdvertisement(ad: Partial<Advertisement>) {
    return this.http.post<Advertisement>(this.modelBase, ad);
  }

  updateAdvertisement(ad: Partial<Advertisement>) {
    return this.http.patch<Advertisement>(`${this.modelBase}/${ad.id}`, ad);
  }

  deleteAdvertisement(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'flat-list',
  templateUrl: './flat-list.component.html',
  styleUrls: ['./flat-list.component.scss']
})
export class FlatListComponent<T, A> implements OnInit {
  @Output() onScroll = new EventEmitter();
  @Input() scrollFinished = false;
  @Input() itemComponent: T;
  @Input() selectedItem: A;
  @Input() items: A[];
  @Input() loading: boolean;
  @Output() onClickItem = new EventEmitter<A>();
  @Input() containerScroll: boolean;

  constructor() {}

  ngOnInit() {}
}

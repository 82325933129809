import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { GuestHelpers } from '@fg-shared/helpers/guest-helpers';

@Component({
  selector: 'guest-tile',
  templateUrl: './guest-tile.component.html',
  styleUrls: ['./guest-tile.component.scss']
})
export class GuestTileComponent extends BaseComponent implements ListItem<IConventionCelebrity> {
  @Input() data: IConventionCelebrity;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  get landscapeMediaAllowed() {
    return this.navigationContext.isConventionServiceAllowed('Celebrity.landscapeMedia');
  }

  // Calculated values
  get imageUrl() {
    return GuestHelpers.getImage(
      this.data,
      this.landscapeMediaAllowed ? 'landscapeMedia' : 'profileMedia'
    );
  }

  get name() {
    return GuestHelpers.getName(this.data);
  }

  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }
}

<div class="comment">
  <div class="p-grid">
    <div class="p-col-11">
      <schedule-event-tile
        *ngIf="data.conventionEvent"
        [data]="data.conventionEvent"
      ></schedule-event-tile>
      <guest-tile
        *ngIf="data.model && data.modelName == 'Celebrity'"
        [data]="data.model"
      ></guest-tile>
      <exhibitor-tile
        *ngIf="data.model && data.modelName == 'Exhibitor'"
        [data]="data.model"
      ></exhibitor-tile>
      <!-- Default empty tile -->
      <schedule-event-tile *ngIf="!data.conventionEvent && !data.model"></schedule-event-tile>
    </div>
    <div class="p-col-1 icon">
      <img *ngIf="image" [src]="image" />
    </div>
    <div class="p-col-12" *ngIf="data.comment">"{{ data.comment }}"</div>
    <div class="clearfix"></div>
  </div>
</div>

import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UserService } from '@fg-services/user.service';

@Injectable({providedIn: 'root'})
export class OzGuard implements CanActivate {
  constructor(private userServ: UserService) {}
  canActivate() {
    return this.userServ.isOzAllowed();
  }
}

import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@fg-services/user.service';
import { map } from 'rxjs/operators';

@Injectable()
export class NotAuthGuard implements CanActivate {
  constructor( private userServ: UserService, private router: Router ) { }
  canActivate() {
    return this.userServ.isAuthenticated().pipe(
      map( isAuth => {
        if ( isAuth ) {
          if ( location.hash === '#faq' ) this.router.navigate( [ '/faqs' ] );
          else this.router.navigate( [ '/apps' ] );
        }
        return !isAuth;
      } )
    );
  }
}

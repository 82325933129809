import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import { FandomRole, FandomSegment } from '@fg-services/navigation-context/segments/fandom-segment';

export class CommunityMenuItems extends BaseMenuItem<FandomSegment> {
  menuItems() {
    const { fandom } = this.context.model;
    if (!fandom) return [];

    const items: MenuItem[] = [
      {
        label: 'Essentials',
        customIcon: 'essentials',
        order: 10,
        roles: [FandomRole.FandomAdmin, FandomRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'COMMUNITY DETAILS',
            routerLink: [...this.routePath, 'essentials', 'community-details'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'GUESTS',
            routerLink: [...this.routePath, 'essentials', 'guests'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'EXHIBITORS',
            routerLink: [...this.routePath, 'essentials', 'exhibitors'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Events',
        icon: 'apps',
        order: 20,
        roles: [FandomRole.FandomAdmin, FandomRole.FandomMod, FandomRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'events']
      },
      {
        label: 'Team',
        icon: 'group_work',
        order: 30,
        roles: [FandomRole.Admin, FandomRole.FandomAdmin],
        routerLink: [...this.routePath, 'team'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Featured',
        icon: 'playlist_add_check',
        order: 40,
        roles: [FandomRole.Admin, FandomRole.FandomAdmin],
        routerLink: [...this.routePath, 'featured'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Notifications',
        icon: 'notifications_active',
        order: 50,
        roles: [FandomRole.Admin, FandomRole.FandomAdmin],
        routerLink: [...this.routePath, 'notifications'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Moderation',
        icon: 'fiber_manual_record',
        order: 60,
        roles: [FandomRole.FandomAdmin, FandomRole.FandomMod, FandomRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Posts',
            routerLink: [...this.routePath, 'moderation', 'posts'],
            fatherActive: false,
            childActive: false
          }
        ]
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const { model, role } = this.context;
    const labelsToRemove: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    const filteredOptions = this.removeSideNavItems(menuItems, role, labelsToRemove);
    return this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
  }
}

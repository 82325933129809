import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@fg-environments/environment';
import { TConventionOpenChannel } from '@fg-types/convention-open-channel';
import { PaginatedResponse } from '@fg-types/paginated-response';

@Injectable()
export class ConventionOpenChannelService {
  private baseUrl = `${environment.API_URL + environment.API_VERSION}/ConventionOpenChannels`;

  constructor(private http: HttpClient) {}

  get(conventionIds?: string, lastKey?: string) {
    let params = new HttpParams();
    if (lastKey) {
      params = params.set('lastKey', lastKey);
    }
    if (conventionIds) {
      params = params.set('conventionIds', conventionIds);
    }
    return this.http.get<PaginatedResponse<TConventionOpenChannel>>(this.baseUrl, {
      params
    });
  }

  delete(id: string) {
    const options: any = {};
    return this.http.delete(`${this.baseUrl}/${id}`, options);
  }

  patch(id: string, data: Partial<TConventionOpenChannel>) {
    return this.http.patch<TConventionOpenChannel>(`${this.baseUrl}/${id}`, data);
  }

  create(data: any) {
    return this.http.post<TConventionOpenChannel>(`${this.baseUrl}`, data);
  }
}

<div
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  [class.common-tile-size]="commonTileSize"
  (click)="onClick.emit()"
>
  <div [style.flex-basis]="mediaFlexBasis" class="tile-media" [style.backgroundImage]="image"></div>
  <div class="square-indicator" *ngIf="showFans">
    {{ data.subscriberCount }}<br />
    {{ data.title ? data.title : data.subscriberCount != 1 ? 'FANS' : 'FAN' }}
  </div>
  <div class="tile-body">
    <div class="tile-title">{{ data.name }}</div>
    <div *ngIf="data.description" class="tile-description">
      <div class="truncate-overflow truncate-3" multilineDotdotdot>
        <div [innerHTML]="data.description"></div>
      </div>
    </div>
    <ng-content></ng-content>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

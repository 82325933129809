import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { IConventionUser } from '@fg-types/convention-user';
import { PaginatedResponse } from '@fg-types/paginated-response';

@Injectable()
export class ManagerService {
  private getUserConventionURL = `${environment.API_URL + environment.API_VERSION}/Conventions`;
  constructor(private http: HttpClient) {}

  getUserConvention(conventionId: string) {
    return this.http.get<PaginatedResponse<IConventionUser>>(
      `${this.getUserConventionURL}/${conventionId}/users`
    );
  }

  postUserConvention(conventionId: string, conventionUser: Partial<IConventionUser>) {
    return this.http.post<IConventionUser>(
      `${this.getUserConventionURL}/${conventionId}/users`,
      conventionUser
    );
  }

  deleteUserConvention(conventionId: string, userId: string) {
    return this.http.delete(`${this.getUserConventionURL}/${conventionId}/users/${userId}`);
  }
}

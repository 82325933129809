import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IConventionMap } from '@fg-types/convention.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'map-tile',
  templateUrl: './map-tile.component.html',
  styleUrls: ['./map-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MapTileComponent implements ListItem<IConventionMap> {
  @Input() data: IConventionMap;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { TConventionOpenChannel } from '@fg-types/convention-open-channel';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'channel-tile',
  templateUrl: './channel-tile.component.html',
  styleUrls: ['./channel-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ChannelTileComponent implements ListItem<TConventionOpenChannel> {
  @Input() data: TConventionOpenChannel;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

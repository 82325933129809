import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { MediaService } from '@fg-services/media.service';
import { UiService } from '@fg-services/ui.service';
import { AdminHelpers } from '@fg-shared/helpers/admin-helpers';
import { CmMedia } from '@fg-types/cm-media';

@Component({
  selector: 'cropper',
  templateUrl: './cropper.component.html',
  styleUrls: ['./cropper.component.scss']
})
export class CroppperComponent implements OnInit, OnChanges {
  @Input() finalWidth: number;
  @Input() finalHeight: number;
  @Input() width: string;
  @Input() height: string;
  @Input() borderRadius:string;
  @Input() img: string;
  @Input() marginAuto = true;
  @Input() ratio = '';
  @Input() disabled: boolean;
  @Input() showDeleteIcon: boolean;
  @Input() footer: string;
  @Input() header: string;
  @Input() classContainer: string;
  @Input() backgroundSizeOverride = 'contain';
  @Input() multiple: boolean;
  @Input() maxImages: number;
  @Input() currentImageCount: number;
  @Input() buttonColor:boolean;
  @Output() setImage = new EventEmitter<CmMedia>();
  @Output() onDelete = new EventEmitter<void>();
  @ViewChild('progressBar', { static: true }) public progressBar: ElementRef;
  selectedImage: string;
  resetUploader = true;
  sanitizedImageUrl: SafeStyle;
  loading = false;
  paddingBottom: string;
  allowedFilesRegex = /\.(jpe?g|png|gif|bmp)$/i;
  isGif: boolean;
  containBackgroundImage: boolean;
  imageFiles: File[];
  constructor(
    private mediaService: MediaService,
    private sanitizer: DomSanitizer,
    private apiCallsInProgress: ApiCallsInProgress,
    private uiService: UiService
  ) {}

  ngOnInit() {}

  ngOnChanges() {
    this.sanitizedImageUrl = this.img
      ? this.sanitizer.bypassSecurityTrustStyle(`url('${this.img}')`)
      : null;
    this.containBackgroundImage =
      !this.sanitizedImageUrl && Number(this.finalWidth) / 2 > Number(this.finalHeight);
    const [width = 0, height = 0] = this.ratio.split(':');
    this.paddingBottom = `${(+width / +height) * 100}%`;
  }

  selectImage(image: string) {
    this.loading = true;
    this.selectedImage = undefined;
    const fileName = encodeURI(AdminHelpers.getGUID() + '.jpg');
    this.apiCallsInProgress.addCall();
    const subs = this.mediaService.getObservable().subscribe((res: any) => {
      if (res.status === 'pending') {
        Object(this.progressBar).value = res.progress;
      }
      if (res.status === 'finish') {
        this.apiCallsInProgress.subtractCall();
        this.loading = false;
        this.setImage.emit(res.success);
        subs.unsubscribe();
      }
    });
    this.mediaService.assetUpload(AdminHelpers.dataURItoBlob(image), fileName);
    if (this.imageFiles.length) this.loadNextImage();
  }

  selectFile($event: any) {
    if ($event.target.files.length === 0) {
      return;
    }
    this.imageFiles = [...$event.target.files];
    if (this.maxImages && this.currentImageCount + this.imageFiles.length > this.maxImages) {
      return this.uiService.toast({
        severity: 'error',
        summary: 'Oops!',
        detail: `Only ${this.maxImages} photos allowed`
      });
    }
    this.loadNextImage();
  }

  loadNextImage() {
    const [file] = this.imageFiles.splice(0, 1);
    this.isGif = file.type === 'image/gif';
    if (this.allowedFilesRegex.test(file.name)) {
      const fileReader = new FileReader();
      fileReader.addEventListener('loadend', (loadEvent: any) => {
        this.selectedImage = loadEvent.target.result;
      });
      fileReader.readAsDataURL(file);
    }
    // Rebuild the input type=file to allow selecting the same file
    this.resetUploader = false;
    setTimeout(() => (this.resetUploader = true));
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { Community } from '@fg-types/community';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { IConvention } from '@fg-types/convention.type';
import { DeleteResponse } from '@fg-types/delete-response';
import { ExhibitorManager } from '@fg-types/manager.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ShippingZone } from '@fg-types/shipping';
import { SquareCategory, SquareItem } from '@fg-types/square';

export class ExhibitorApi extends ApiBase {
  model = 'Exhibitors';

  getById(id: string, conventionId?: string) {
    return this.http.get<ConventionExhibitor>(`${this.modelBase}/${id}`, {
      params: this.setParams({ conventionId })
    });
  }

  create(
    exhibitor: { termsOfServiceAccepted?: boolean; makeManager?: true} & Partial<
      ConventionExhibitor
    >
  ) {
    return this.http.post<ConventionExhibitor>(this.modelBase, exhibitor);
  }

  createCreatorExhibitor(
    exhibitor: { termsOfServiceAccepted?: boolean; makeManager?: true , creatorId ?: string} & Partial<
      ConventionExhibitor
    >
  ) {
    return this.http.post<ConventionExhibitor>(this.modelBase, exhibitor);
  }

  patch(id: string, exhibitor: Partial<ConventionExhibitor>) {
    return this.http.patch<ConventionExhibitor>(`${this.modelBase}/${id}`, exhibitor);
  }

  getManagers(id: string) {
    return this.http.get<PaginatedResponse<ExhibitorManager>>(`${this.modelBase}/${id}/Managers`);
  }

  getConventions(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/${id}/Conventions`, {
      params: this.setParams({ lastKey })
    });
  }

  isSquareConnected(id: string) {
    return this.http.get<{ isLinked: boolean }>(`${this.modelBase}/${id}/SquareIntegrations`);
  }

  getSquareCategories(
    id: string,
    params?: { lastKey?: string; isVisible?: boolean; limit?: number }
  ) {
    return this.http.get<PaginatedResponse<SquareCategory>>(
      `${this.modelBase}/${id}/SquareCategories`,
      {
        params: this.setParams(params)
      }
    );
  }

  getSquareItems(
    id: string,
    params?: {
      lastKey?: string;
      isVisible?: boolean;
      categoryId?: string;
      uncategorized?: boolean;
      isFeatured?: boolean;
      limit?: number;
    }
  ) {
    return this.http.get<PaginatedResponse<SquareItem>>(`${this.modelBase}/${id}/SquareItems`, {
      params: this.setParams(params)
    });
  }

  shippingZones(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<ShippingZone>>(`${this.modelBase}/${id}/ShippingZones`, {
      params: this.setParams({ lastKey })
    });
  }

  getCommunitiesByExhibitor(id: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/${id}/Fandoms`);
  }

  deleteExhibitor(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConventionContest } from '@fg-types/convention-contest';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'contest-tile',
  templateUrl: './contest-tile.component.html',
  styleUrls: ['./contest-tile.component.scss']
})
export class ContestTileComponent implements ListItem<IConventionContest> {
  @Input() data: IConventionContest;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { Organization } from '@fg-types/organization.type';

@Component({
  selector: 'search-organization',
  templateUrl: './search-organization.component.html',
  styleUrls: ['./search-organization.component.scss']
})
export class SearchOrganizationComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() clearOnSelect = true;
  @Input() item = '';
  @Input() label: string;
  @Output() addItem = new EventEmitter<Organization>();
  @Output() queryChange = new EventEmitter<string>();
  filteredItems: Organization[] = [];

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.apiService.search.organizations(params.query).subscribe(res => {
        this.filteredItems = res.items;
      });
    }
  }

  onSelect(org: Organization) {
    this.addItem.emit(org);
    if (this.clearOnSelect) this.item = '';
  }
}

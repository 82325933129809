<div class="sub-base">
  <div class="image" [style.backgroundImage]="image"></div>
  <div class="body">
    <div class="title">
      {{ subscriptionBase.stripeProduct?.name }}
    </div>
    <div class="description">
      {{ subscriptionBase.stripeProduct?.description }}
    </div>
    <div class="price">
      {{ price }}
    </div>
  </div>
</div>

<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.name || data?.title || data?.markerName"
  [media]="data?.headerMedia?.url || data.media?.url || data.filterMedia?.url"
  [tileType]="tileType"
  [hoverActive]="hoverActive"
  [description]="data?.description"
>
  <ng-content></ng-content>
</tile>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IPost } from '@fg-types/post';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'contest-entry-tile',
  templateUrl: './contest-entry-tile.component.html',
  styleUrls: ['./contest-entry-tile.component.scss']
})
export class ContestEntryTileComponent implements ListItem<IPost> {
  @Input() data: IPost;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  get title() {
    return this.data.owner.username;
  }

  get media() {
    if (!this.data || !this.data.media || !this.data.media.length) return '';
    return this.data.media[0].preview || this.data.media[0].url;
  }
}

<ng-container [ngSwitch]="data.type">
  <ng-container *ngSwitchCase="'album'">
    <ng-container *ngTemplateOutlet="post"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'snippet'">
    <ng-container *ngTemplateOutlet="post"></ng-container>
  </ng-container>
  <schedule-event-tile
    [data]="data.item"
    (onClick)="onClick.emit()"
    [active]="active"
    [hoverActive]="hoverActive"
    *ngSwitchCase="'convention_event'"
  >
  </schedule-event-tile>
  <comment-tile
    [data]="data"
    (onClick)="onClick.emit()"
    [active]="active"
    [hoverActive]="hoverActive"
    *ngSwitchCase="'comment'"
  >
  </comment-tile>
  <tile
    (onClick)="onClick.emit()"
    [active]="active"
    [title]="data.type"
    [description]="data.status"
    [hoverActive]="hoverActive"
    *ngSwitchDefault
  >
    <ng-content></ng-content>
  </tile>
</ng-container>

<ng-template #post>
  <fg-post-card
    [class.selected]="active"
    [data]="data.item"
    (click)="onClick.emit()"
  ></fg-post-card>
</ng-template>

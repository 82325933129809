<div class="sub-base">
        <div class="body">
            <div class="image" [style.backgroundImage]="image"></div>
            <div class="distribution">
                <div class="title">
                    {{ subscriptionBase?.name }}
                </div>
                <div class="price">
                    {{ price }}
                </div>
            </div>
        </div>
</div>
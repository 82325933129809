<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.owner?.username"
  [description]="data?.title || data?.description"
  [media]="media"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

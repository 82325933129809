<div
  [ngClass]="getMaskClass()"
  class="p-dialog-wrapper p-ai-center p-d-flex p-jc-center"
  *ngIf="maskVisible"
>
  <div
    [ngClass]="{
      'p-dialog p-confirmdialog p-component p-component-content': true,
      'p-dialog-rtl': rtl
    }"
    [ngStyle]="style"
    [style.width]="'500px'"
    [class]="styleClass"
    (mousedown)="moveOnTop()"
    [@animation]="{
      value: 'visible',
      params: { transform: transformOptions, transition: transitionOptions }
    }"
    (@animation.start)="onAnimationStart($event)"
    (@animation.done)="onAnimationEnd($event)"
    *ngIf="visible"
  >
    <div class="p-dialog-titlebar p-component-header p-helper-clearfix p-d-flex p-jc-between">
      <span class="p-dialog-title" *ngIf="option('header')">{{ option('header') }}</span>
      <div class="p-dialog-titlebar-icons">
        <a
          *ngIf="closable"
          [ngClass]="{ 'p-dialog-titlebar-icon p-dialog-titlebar-close': true }"
          tabindex="0"
          role="button"
          (click)="close($event)"
          (keydown.enter)="close($event)"
        >
          <span class="pi pi-times"></span>
        </a>
      </div>
    </div>
    <div #content class="p-dialog-content p-component-content">
      <i [ngClass]="'p-confirmdialog-icon'" [class]="option('icon')" *ngIf="option('icon')"></i>
      <span class="p-confirmdialog-message" [innerHTML]="option('message')"></span>
    </div>
    <div class="p-dialog-footer p-component-content" *ngIf="footer">
      <ng-content select="p-footer"></ng-content>
    </div>
    <div class="p-dialog-footer p-component-content" *ngIf="!footer">
      <button
        type="button"
        pButton
        [icon]="confirmationOptions.rejectIcon"
        [label]="confirmationOptions.rejectLabel"
        (click)="reject()"
        [class]="rejectButtonStyleClass"
        *ngIf="confirmationOptions.rejectVisible"
      ></button>
      <button
        type="button"
        pButton
        [icon]="confirmationOptions.acceptIcon"
        [label]="confirmationOptions.acceptLabel"
        (click)="accept()"
        [class]="acceptButtonStyleClass"
        *ngIf="confirmationOptions.acceptVisible"
      ></button>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AnalyticsItem } from '@fg-types/analytics';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'analytic-tile',
  templateUrl: './analytic-tile.component.html',
  styleUrls: ['./analytic-tile.component.scss']
})
export class AnalyticTileComponent implements OnInit, ListItem<AnalyticsItem> {
  @Input() data: AnalyticsItem;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  constructor() {}
  ngOnInit() {}
}

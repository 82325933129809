<div class="info-banner-container p-grid">
  <div class="media-embed p-sm-12 p-md-12 p-col" *ngIf="infoBannerService.mediaEmbed">
    <div [innerHtml]="infoBannerService.mediaEmbed"></div>
  </div>
  <div class="p-col">
    {{ infoBannerService.info }}
  </div>
  <div class="p-col-fixed" style="width: 25px;">
    <i (click)="infoBannerService.setVisibility(false)" class="pi pi-times pointer"></i>
  </div>
</div>

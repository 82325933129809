import { Route } from '@angular/router';
import { CommunitiesComponent } from '@fg-app/backstage/home/communities/communities.component';
import { EventsComponent } from '@fg-app/backstage/home/events/events.component';
import { OrganizationsComponent } from '@fg-app/backstage/home/organizations/organizations.component';
import { HomeComponent } from './home.component';

export const homeRoutes: Route[] = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'organizations',
    component: OrganizationsComponent
  },
  {
    path: 'communities',
    component: CommunitiesComponent
  },
  {
    path: 'events',
    component: EventsComponent
  }
];

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { Community } from '@fg-types/community';
import { UserRoles } from '@fg-types/roles.type';

@Component({
  selector: 'communities',
  templateUrl: './communities.component.html',
  styleUrls: ['./communities.component.scss']
})
export class CommunitiesComponent extends BaseComponent implements OnInit {
  items: Community[];
  userRoles: UserRoles;
  query: string;
  lastKey: string;
  loading = false;
  scrollFinished = false;

  constructor(
    private api: ApiService,
    private userService: UserService,
    private router: Router,
    private apiCallsInProgress: ApiCallsInProgress,
    private localStorageService: LocalStorageService,
    private uiService: UiService,
    navigationContextService: NavigationContextService
  ) {
    super(navigationContextService);
  }

  ngOnInit() {
    if (!this.userRoles) {
      this.userService.loadRoles().subscribe(roles => {
        this.userRoles = roles;
        this.loadItems();
      });
    } else {
      this.userRoles = this.userService.roles;
      this.loadItems();
    }
    this.localStorageService.setItem('last-home-option', 'communities');
  }

  getLoader() {
    if (this.query) return this.api.search.searchFandoms(this.query, this.lastKey);
    return this.api.feed.communities('curated', this.lastKey);
  }

  loadAdminItems() {
    if (this.loading) return;
    this.loading = true;
    this.apiCallsInProgress.addCall();
    this.getLoader().subscribe(
      ({ items, lastKey }) => {
        this.items = this.lastKey ? [...this.items, ...items] : items;
        this.loading = false;
        this.scrollFinished = !items.length || !lastKey;
        this.lastKey = lastKey;
        this.apiCallsInProgress.reset();
      },
      () => {
        this.loading = false;
        this.apiCallsInProgress.reset();
      }
    );
  }

  loadItems() {
    if (!this.userRoles) return;
    if (this.userRoles.isAdmin) {
      this.loadAdminItems();
    } else {
      const regex = this.query ? new RegExp(`^${this.query}`, 'i') : undefined;
      this.items = [...this.userRoles.fandomsAdmining, ...this.userRoles.fandomsModerating].filter(
        item => {
          if (!regex) return true;
          if (!item) return false;
          return regex.test(item.name);
        }
      );
    }
  }

  search(query: string) {
    this.query = query;
    this.lastKey = undefined;
    this.loadItems();
  }

  selectItem(item: Community) {
    const path = this.navigationContext.getRoutePath();
    this.router.navigate([...path, 'cm', item.id]);
  }

  createNew(name: string) {
    this.apiCallsInProgress.addCall();
    this.api.fandoms.create({ name }).subscribe(
      community => {
        const path = this.navigationContext.getRoutePath();
        this.apiCallsInProgress.reset();
        this.router.navigate([...path, 'cm', community.id]);
      },
      () => {
        this.uiService.toast({
          severity: 'error',
          detail: `There was an error creating the Community.`
        });
        this.apiCallsInProgress.reset();
      }
    );
  }
}

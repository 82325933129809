import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Router } from '@angular/router';
import { ApiService } from '@fg-services/api/api.service';
import { routeToBackstageSegment } from '@fg-services/navigation-context/segments/backstage-segment';
import { routeToHomeSegment } from '@fg-services/navigation-context/segments/console-segment';
import { routeToConventionSegment } from '@fg-services/navigation-context/segments/convention-segment';
import { routeToExhibitorSegment } from '@fg-services/navigation-context/segments/exhibitor-segment';
import { routeToFandomSegment } from '@fg-services/navigation-context/segments/fandom-segment';
import { routeToFaqSegment } from '@fg-services/navigation-context/segments/faq-segment';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { routeToOrganizationSegment } from '@fg-services/navigation-context/segments/organization-segment';
import { routeToOzSegment } from '@fg-services/navigation-context/segments/oz-segment';
import { UserService } from '@fg-services/user.service';
import isNonNil from '@fg-shared/helpers/isNonNil';
import { NavigationContext } from '@fg-shared/services/navigation-context/navigation-context';
import { Community } from '@fg-types/community';
import { GroupedAllowedService } from '@fg-types/convention-service';
import { IConvention } from '@fg-types/convention.type';
import { Organization } from '@fg-types/organization.type';
import { BehaviorSubject, forkJoin, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { routetoCreatorSegment } from './segments/creator-segment';
import { CreatorService } from '@fg-services/dummy.service';

@Injectable({
  providedIn: 'root'
})
export class NavigationContextService {
  // Caches
  conventions = new Map<string, IConvention>();
  squareConnected = new Map<string, boolean>();
  organizations = new Map<string, Organization>();
  communities = new Map<string, Community>();
  services = new Map<string, GroupedAllowedService>();
  // Context + setters and getters
  private _context: BehaviorSubject<NavigationContext> = new BehaviorSubject<NavigationContext>(
    new NavigationContext()
  );

  get context() {
    return this._context.value;
  }

  set context(context: NavigationContext) {
    this._context.next(context);
  }

  get url() {
    return this.router.url;
  }

  get userRoles() {
    return this.userService.roles;
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public apiService: ApiService,
    public userService: UserService,
    public creatorService : CreatorService,
  ) {
    this.router.events.subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        this.updateNavigationContext();
      }
    });
  }

  getContextAsObservable() {
    return this._context.asObservable();
  }

  updateNavigationContext(useCache = true) {
    this.routeToContext(this.route.snapshot.children[0], useCache).subscribe(
      context => {
        this.context = context;
      },
      err => {
        console.warn('Err');
      }
    );
  }

  routeToContext(route: ActivatedRouteSnapshot, useCache = true) {
    // List of nested models in the route
    const nestedModelIds = new Map<NavigableModel, string>();
    const segmentNames: NavigableModel[] = [NavigableModel.Home];
    let currentRoute = route;
    while (currentRoute) {
      const { data, params } = currentRoute;
      const modelId: string = params?.id;
      if (isNonNil(data?.segmentName)) {
        if (modelId) nestedModelIds.set(data.segmentName, modelId);
        if (!segmentNames.includes(data.segmentName)) segmentNames.push(data.segmentName);
      }
      currentRoute = currentRoute.children?.[0];
    }
    return forkJoin(
      segmentNames
        .map(segmentName => {
          switch (segmentName) {
            case NavigableModel.Home:
              return routeToHomeSegment();
            case NavigableModel.Faqs:
              return routeToFaqSegment();
            case NavigableModel.Exhibitor:
              return routeToExhibitorSegment(this, useCache);
            case NavigableModel.Backstage:
              return routeToBackstageSegment();
            case NavigableModel.Organization:
              return routeToOrganizationSegment(this, nestedModelIds.get(segmentName));
            case NavigableModel.Fandom:
              return routeToFandomSegment(this, nestedModelIds.get(segmentName), nestedModelIds);
            case NavigableModel.Convention:
              return routeToConventionSegment(
                this,
                nestedModelIds.get(segmentName),
                nestedModelIds
              );
            case NavigableModel.OZ:
              return routeToOzSegment();
            case NavigableModel.Creator:
              return routetoCreatorSegment();
          }
        })
        .filter(Boolean)
    ).pipe(map(segments => new NavigationContext(segments)));
  }

  updateConvention(id: string, changes: Partial<IConvention>) {
    const convention = this.conventions.get(id);
    if (!convention) return;
    this.conventions.set(id, {
      ...convention,
      ...changes
    });
  }

  updateOrganization(id: string, changes: Partial<Organization>) {
    const organization = this.organizations.get(id);
    if (!organization) return;
    this.organizations.set(id, {
      ...organization,
      ...changes
    });
  }

  reloadConvention(id: string) {
    return this.apiService.conventions.getConventionById(id).pipe(
      tap(convention => {
        this.conventions.set(id, convention);
        this.updateConvention(convention.id, convention);
        this.updateNavigationContext();
      })
    );
  }

  reloadOrganization(id: string) {
    return this.apiService.organizations.getOrganizationById(id).pipe(
      map(org => {
        this.organizations.set(id, org);
        return org;
      })
    );
  }

  reloadConventionServices(id: string) {
    return new Promise((res, rej) => {
      this.apiService.conventions.getConventionAllowedServices(id).subscribe(
        services => {
          this.services.set(id, services);
          return res(null);
        },
        err => rej(err)
      );
    });
  }

  updateStats(field: keyof IConvention['stats'], change: number) {
    // if (!this.convention || !this.convention.stats) return;
    // this.convention = {
    //   ...this.convention,
    //   stats: {
    //     ...this.convention.stats,
    //     [field]: this.convention.stats[field] + change
    //   }
    // };
    // this.subject.next(this.convention);
  }
}

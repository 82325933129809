<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data.name"
  [media]="data.headerMedia"
  [description]="data.description"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

<div class="input-container p-col-12">
  <div class="input-cell">
    <label *ngIf="title">{{ title }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="item"
        [suggestions]="filteredItems"
        placeholder="Type to search an Item"
        field="name"
        (completeMethod)="filter($event)"
        (onSelect)="addItem.emit($event); item = ''"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <item-tile *ngIf="item.id; else createNew" [item]="item" hoverActive="1"></item-tile>
          <ng-template #createNew>{{ item }}</ng-template>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

<div class="fg-view-body single-pane">
  <div class="fg-single-col wide">
    <div class="fg-col-body">
      <div class="card">
        <div class="p-grid">
          <div class="p-col-12 p-md-6">
            <p-card [header]="parsedModelName + ' HTML'">
              <div class="p-grid p-mt-3">
                <div *ngIf="modelName === 'exhibitors'" class="input-container p-col-12">
                  <div class="input-cell">
                    <label>Show Items Segment</label>
                    <p-inputSwitch [(ngModel)]="showItemsSegment"></p-inputSwitch>
                  </div>
                </div>
                <div class="p-col-12">
                  Apply this iframe HTML where you want your {{ parsedModelName }} widget to appear
                  on your site.
                  <div class="input-container p-pl-0 p-col-12">
                    <div class="input-cell">
                      <textarea #textareaWidget name="events-widget" rows="3" pInputText readonly>{{
                        embedHTMLCode
                      }}</textarea>
                    </div>
                  </div>
                </div>
                <button
                  label="COPY"
                  pButton
                  type="button"
                  class="p-button-secondary p-ml-2"
                  (click)="copyConventionLink(textareaWidget)"
                ></button>
                <div class="clearfix"></div>
              </div>
            </p-card>
          </div>
          <div class="p-col-12 p-md-6">
            <iframe
              *ngIf="iframeSrc"
              id="my-iframe"
              [src]="iframeSrc"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { ExhibitorManager } from '@fg-types/manager.type';

export class ManagerApi extends ApiBase {
  model = 'Managers';

  createExhibitorManager(exhibitorId: string, userId: string) {
    return this.http.post<ExhibitorManager>(this.modelBase, { exhibitorId, userId });
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

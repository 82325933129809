import { NavigationContextHelpers } from '@fg-services/navigation-context/navigation-context.helpers';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { ExhibitorManager } from '@fg-types/manager.type';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ExhibitorRole {
  Authenticated = 'eh-auth',
  HasProfile = 'eh-profile',
  SquareConnected = 'eh-connected'
}

export interface ExhibitorSegmentModel {
  managing?: ExhibitorManager;
}

export type ExhibitorSegment = NavigationContextSegment<
  NavigableModel.Exhibitor,
  ExhibitorSegmentModel,
  ExhibitorRole
>;

export const exhibitorRoleResolver = (
  managing?: ExhibitorManager,
  isSquareConnected?: boolean
): ExhibitorRole => {
  if (!managing) return ExhibitorRole.Authenticated;
  if (!isSquareConnected) return ExhibitorRole.HasProfile;
  return ExhibitorRole.SquareConnected;
};

export const routeToExhibitorSegment = (
  navigationContextService: NavigationContextService,
  useCache = true
): Observable<ExhibitorSegment> => {
  let creatorId ='';
  const { apiService, userService, userRoles, squareConnected,creatorService } = navigationContextService;
  const ex = creatorService.currentCreator$.subscribe(c=>{
    creatorId = c?.id
  })
  const managing = userRoles.managing.find(item => item.modelName === 'Exhibitor' && item.creatorId === creatorId);
  return NavigationContextHelpers.loader<boolean>(
    userService.userId,
    managing
      ? apiService.exhibitors.isSquareConnected(managing?.modelId).pipe(map(res => res.isLinked))
      : of(false),
    squareConnected,
    useCache
  ).pipe(
    map(isConnected => ({
      label: ' ',
      subLabel: 'Exhibit Hall',
      segmentName: NavigableModel.Exhibitor,
      model: {
        managing
      },
      role: exhibitorRoleResolver(managing, isConnected)
    }))
  );
};

import { ChallengeType } from '@fg-types/challenge';

export interface ChallengeTypeConfig {
  label: string;
  showIsRepeatable: boolean;
  showLeaderboard: boolean;
  showRewardBasedOnProbabilty: boolean;
  showARconfig: boolean;
  showMaxTickets: boolean;
  showIsDependent: boolean;
  showTypeCount: boolean;
  showMomentConfig: boolean;
  showLink: boolean;
  showTypeformWebhook: boolean;
  showVideoConfig: boolean;
}

export const defaultChallengeConfig: ChallengeTypeConfig = {
  label: '',
  showIsRepeatable: false,
  showLeaderboard: false,
  showRewardBasedOnProbabilty: false,
  showARconfig: false,
  showMaxTickets: false,
  showIsDependent: false,
  showTypeCount: false,
  showMomentConfig: false,
  showLink: false,
  showTypeformWebhook: false,
  showVideoConfig: false
};

const actionTypeConfig: ChallengeTypeConfig = {
  ...defaultChallengeConfig,
  showIsRepeatable: true,
  showLeaderboard: true,
  showMaxTickets: true,
  showTypeCount: true
};

const typeformTypeConfig: ChallengeTypeConfig = {
  ...defaultChallengeConfig,
  showLeaderboard: true,
  showMaxTickets: true,
  showIsDependent: true,
  showLink: true,
  showTypeformWebhook: true,
  showVideoConfig: true
};

export const ChallengeTypes: { [key in ChallengeType]: ChallengeTypeConfig } = {
  ARExperience: {
    ...defaultChallengeConfig,
    label: 'AR Experience',
    showIsRepeatable: true,
    showLeaderboard: true,
    showRewardBasedOnProbabilty: true,
    showARconfig: true,
    showMaxTickets: true,
    showIsDependent: true
  },
  attend: {
    ...defaultChallengeConfig,
    label: 'Attend',
    showMaxTickets: true,
    showIsRepeatable: true
  },
  bonus: {
    ...defaultChallengeConfig,
    label: 'Bonus',
    showMaxTickets: true
  },
  contestVotes: {
    ...actionTypeConfig,
    label: 'Contest Votes'
  },
  createSchedule: {
    ...actionTypeConfig,
    label: 'Create Schedule'
  },
  favoriteCelebrity: {
    ...actionTypeConfig,
    label: 'Favorite Celebrity'
  },
  favoriteExhibitor: {
    ...actionTypeConfig,
    label: 'Favorite Exhibitor'
  },
  post: {
    ...defaultChallengeConfig,
    label: 'Post',
    showIsRepeatable: true,
    showLeaderboard: true,
    showMaxTickets: true,
    showIsDependent: true,
    showMomentConfig: true
  },
  quiz: {
    ...typeformTypeConfig,
    label: 'Quiz'
  },
  rate: {
    ...actionTypeConfig,
    label: 'Rate'
  },
  survey: {
    ...typeformTypeConfig,
    label: 'Survey'
  }
};

export const ChallengeTypeDropdownList = [
  { label: '', value: null },
  ...Object.keys(ChallengeTypes).map(value => ({
    label: ChallengeTypes[value].label,
    value
  }))
];

import { Injectable } from '@angular/core';
import { ApiBase } from '@fg-services/api/models/api-base';
import {
  ConventionExhibitorRequest,
  ConventionExhibitorRequestStatus
} from '@fg-types/convention-exhibitor';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';

@Injectable()
export class ConventionExhibitorRequestApi extends ApiBase {
  model = 'ConventionExhibitorRequests';

  get(params: {
    lastKey?: string;
    conventionIds?: string;
    exhibitorIds?: string;
    status?: ConventionExhibitorRequestStatus;
  }) {
    return this.http.get<PaginatedResponse<ConventionExhibitorRequest>>(this.modelBase, {
      params: this.setParams(params)
    });
  }

  deleteById(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  updateStatus(id: string, status: ConventionExhibitorRequestStatus) {
    return this.http.put(`${this.modelBase}/${id}/status/${status}`, {});
  }

  apply(data: { exhibitorId: string; conventionId: string }) {
    return this.http.post<ConventionExhibitorRequest>(this.modelBase, data);
  }
}

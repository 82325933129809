import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { App, AppsService } from '@fg-app/console/apps.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { UiService } from '@fg-services/ui.service';
import * as URI from 'urijs';
import { matIcons } from '../assets/sass/fonts/custom-mat-icons';
import { IUser } from '@fg-types/user.type';
import { UserService } from '@fg-services/user.service';
import { Creator, CreatorData } from '@fg-types/creator';
import { ApiService } from '@fg-services/api/api.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { environment } from '@fg-environments/environment';
import { CreatorService } from '@fg-services/dummy.service';

export enum MenuOrientation {
  STATIC,
  OVERLAY,
  SLIM,
  HORIZONTAL
}
export const logoEventologysvg = 'assets/layout/images/LOGO-EventologyBlackAndColor@2x.png'

@Component({
  selector: 'fg-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends BaseComponent implements OnInit, AfterViewInit {
  @ViewChild('sideNav', { static: true }) sideNav;
  @ViewChild('layoutContainer', { static: true }) layoutContainerViewChild: ElementRef;
  @ViewChild('layoutMenuScroller', { static: true }) layoutMenuScrollerViewChild: ElementRef;
  logoEventologysvg= logoEventologysvg;
  creator: CreatorData;
  creators: CreatorData[] = [];
  defaultItem: CreatorData = {
    id: '',
    firstName: '',
    posts: []
  };
  allApps: App[];
  currentApp: App;
  selectedCon: string;
  selectedCity: any;
  layoutMode: MenuOrientation = MenuOrientation.STATIC;
  profileMode = 'inline';
  overlayMenuActive: boolean;
  staticMenuDesktopInactive = true;
  staticMenuMobileActive: boolean;
  rightPanelActive: boolean;
  rightPanelClick: boolean;
  layoutContainer: HTMLDivElement;
  layoutMenuScroller: HTMLDivElement;
  menuClick: boolean;
  topbarItemClick: boolean;
  activeTopbarItem: any;
  resetMenu: boolean;
  menuHoverActive: boolean;
  mobileExperienceModal: boolean;
  topBarVisible: boolean = true;
  _user: IUser;
  lastKey: any;
  isSubscriber:boolean;
  isAdmin: boolean;

  constructor(
    private router: Router,
    public uiService: UiService,
    public renderer: Renderer2,
    private route: ActivatedRoute,
    private appsService: AppsService,
    private storage: LocalStorageService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private cp: CreatorService,
    private api: ApiService,
    private nav: NavigationContextService
  ) {
    super(nav);
    matIcons.forEach(icon => {
      this.matIconRegistry.addSvgIcon(
        icon.label,
        this.domSanitizer.bypassSecurityTrustResourceUrl(icon.url)
      );
    });
    if (location.search) {
      const params: { referralCode?: string } = URI.parseQuery(location.search);
      if (params.referralCode)
        this.storage.setItem('referrer-model', { code: params.referralCode });
    }
  }
  get user() {
    return this.userService.user;
  }
  newWindow(){
    const url = environment.FANGURU_URL;
    url ? window.open(`${url}/creator-list/featured`).focus() : this.router.navigate(['/'])
  }
  
  ngAfterViewInit() {
    if(this.user?.userType === 'subscriber'){
      this.isSubscriber = true;
    }else{
      this.isSubscriber = false;
    }
    if(this.layoutContainerViewChild)
    this.layoutContainer = this.layoutContainerViewChild.nativeElement as HTMLDivElement;
    if(this.layoutMenuScrollerViewChild)
    this.layoutMenuScroller = this.layoutMenuScrollerViewChild.nativeElement as HTMLDivElement;
  }

  ngOnInit() {
    this.appsService.apps.subscribe(apps => {
      this.allApps = apps;
      this.loadCreator();
    });
    this.router.events.subscribe(navigation => {
      if (navigation instanceof NavigationEnd) {
        this.staticMenuDesktopInactive = !!this.route.snapshot.firstChild?.data?.hideMenu;
        this.currentApp = this.allApps.find(
          app => app.id === this.route.snapshot.firstChild?.data?.app
        );
        this.hideOverlayMenu();
      }
      const currentRoute = this.route.snapshot.firstChild?.data?.app;
      if (currentRoute === 'cp') {
        this.topBarVisible = false;
      } else {
        this.topBarVisible = true;
      }
      if(this.user?.userType === 'subscriber'){
        this.isSubscriber = true;
      }else{
        this.isSubscriber = false;
      }      
    });
    if (navigator.userAgent.includes('Mobile') && window.innerHeight > window.innerWidth) {
      this.mobileExperienceModal = true;
    }
  }
  loadCreator() {
    this.cp.currentCreator$.subscribe(c=>{this.creator = c})
    this.cp.userCreatorsList.subscribe(items => {
      this.creators = items;
    });
  }
  addCreator(item) {
    this.cp.updateCurrentCreator(item);
    this.storage.setItem('creator',item?.id)
    this.router.navigate(['cp','profile']);
  }

  createNewCreator() {
    this.router.navigate(['cp','new']);
  }
  onLayoutClick() {
    if (!this.topbarItemClick) {
      this.activeTopbarItem = null;
    }

    if (!this.menuClick) {
      if (this.isHorizontal() || this.isSlim()) {
        this.resetMenu = true;
      }

      if (this.overlayMenuActive || this.staticMenuMobileActive) {
        this.hideOverlayMenu();
      }

      this.menuHoverActive = false;
    }

    if (!this.rightPanelClick) {
      this.rightPanelActive = false;
    }

    this.topbarItemClick = false;
    this.menuClick = false;
    this.rightPanelClick = false;
  }

  onMenuButtonClick() {
    this.menuClick = true;
    this.rightPanelActive = false;
    if (this.layoutMode === MenuOrientation.OVERLAY) {
      this.overlayMenuActive = !this.overlayMenuActive;
    } else {
      if (this.isDesktop()) {
        this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
      } else {
        this.staticMenuMobileActive = !this.staticMenuMobileActive;
      }
    }
  }

  onMenuClick() {
    this.menuClick = true;
    this.resetMenu = false;
  }

  onTopbarMenuButtonClick(event) {
    this.topbarItemClick = true;

    this.hideOverlayMenu();

    event.preventDefault();
  }

  onTopbarItemClick(event, item) {
    this.topbarItemClick = true;
    this.activeTopbarItem = this.activeTopbarItem === item ? null : item;
    event.preventDefault();
  }

  onRightPanelButtonClick(event) {
    this.rightPanelClick = true;
    this.rightPanelActive = !this.rightPanelActive;
    event.preventDefault();
  }

  onRightPanelClick() {
    this.rightPanelClick = true;
  }

  hideOverlayMenu() {
    this.overlayMenuActive = false;
    this.staticMenuMobileActive = false;
  }

  isTablet() {
    const width = window.innerWidth;
    return width <= 1040 && width > 640;
  }

  isDesktop() {
    return window.innerWidth > 1040;
  }

  isOverlay() {
    return this.layoutMode === MenuOrientation.OVERLAY;
  }

  isHorizontal() {
    return this.layoutMode === MenuOrientation.HORIZONTAL;
  }

  isSlim() {
    return this.layoutMode === MenuOrientation.SLIM;
  }

  changeToStaticMenu() {
    this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
    this.layoutMode = MenuOrientation.OVERLAY;
  }

  changeToHorizontalMenu() {
    this.layoutMode = MenuOrientation.HORIZONTAL;
  }

  changeToSlimMenu() {
    this.layoutMode = MenuOrientation.SLIM;
  }
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';

@Injectable()
export class ConventionUserService {
  private readonly conventionUserUrl: string = `${environment.API_URL +
    environment.API_VERSION}/ConventionUsers`;

  constructor(private http: HttpClient) {}

  patch(id: string, body: any): Observable<any> {
    return this.http.patch<any>(`${this.conventionUserUrl}/${id}`, body);
  }
}

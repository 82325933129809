import { Injectable } from "@angular/core";
import { ApiBase } from "./api-base";
import { StripeProduct } from "@fg-types/stripe";

@Injectable({
    providedIn: 'root'
  })
export class CreatorStripeApi extends ApiBase {
    model = 'StripeProducts'


    createStripeProduct(name: string, unit_amount : string, creatorId : string){
        return this.http.post<any>(`${this.modelBase}`,{
          productName: name,
          unitAmount: unit_amount,
          creatorId : creatorId
        })
      }
 }
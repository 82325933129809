import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { ApiService } from '@fg-services/api/api.service';
import { CreatorService } from '@fg-services/dummy.service';
import { UserService } from '@fg-services/user.service';

@Injectable()
export class EhOnBoardGuard implements CanActivate {
  constructor( private userServ: UserService, private router: Router, private api : ApiService ,private creatorService : CreatorService) {}
  canActivate() {
    if(this.userServ.user?.userType === 'subscriber'){
      if (this.userServ.roles?.managing.length < 1) {
        this.router.navigate(['/eh/on-boarding']);
      }
    }else{
      this.creatorService.currentCreator$.subscribe(creator=>{
        const exhibitor = this.userServ.roles?.managing?.find(item => item.modelName === 'Exhibitor' && item.creatorId === creator?.id)
        if(!exhibitor){
          this.router.navigate(['/eh/on-boarding']);
        }
      })
    }
    return true;
  }
}

import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import { BackstageSegment } from '@fg-services/navigation-context/segments/backstage-segment';

export class BackstageMenuItems extends BaseMenuItem<BackstageSegment> {
  menuItems() {
    const items: MenuItem[] = [
      {
        label: 'Organizations',
        icon: 'domain',
        order: 10,
        roles: [],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'organizations']
      },
      {
        label: 'Communities',
        icon: 'groups',
        order: 10,
        roles: [],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'communities']
      },
      {
        label: 'Events',
        icon: 'apps',
        order: 10,
        roles: [],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'events']
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const labelsToRemove: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    if (this.userRoles.isAdmin) return menuItems;
    // Rules to remove side menu items
    if (!this.userRoles.isConAdmin && !this.userRoles.isConMod) {
      labelsToRemove.push('Events');
    }
    if (!this.userRoles.isFandomAdmin && !this.userRoles.isFandomMod) {
      labelsToRemove.push('Communities');
    }
    const filteredOptions = this.removeSideNavItems(menuItems, null, labelsToRemove);
    return this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserService } from '@fg-services/user.service';
import { eventEnvironments } from '@fg-shared/layout/filter-events/filter-events.type';
import { CmMedia } from '@fg-types/cm-media';
import { IConvention } from '@fg-types/convention.type';
import { TIME_ZONES } from '@fg-environments/assets';

export interface FgNewConventionFormOutput {
  convention: Partial<IConvention>;
  orgId: string;
}
@Component({
  selector: 'fg-new-convention-form',
  templateUrl: './fg-new-convention-form.component.html',
  styleUrls: ['./fg-new-convention-form.component.scss']
})
export class FgNewConventionFormComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  @Output() save = new EventEmitter<FgNewConventionFormOutput>();
  @Input() orgId: string;
  organizations =
    this.userService.roles?.organizationsAdmining?.map(o => ({
      label: o.name,
      value: o.id
    })) ?? [];

  convention: Partial<IConvention> = {};
  eventType: string;
  roles = this.userService.roles;
  eventEnvironments = eventEnvironments;
  timezones = TIME_ZONES.map(tz => ({ value: tz, label: tz }));
  constructor(private userService: UserService) {
    this.orgId = this.organizations?.[0]?.value ?? '';
  }

  ngOnInit(): void {}
  setImage(image: CmMedia) {
    this.convention.logoHorizontal = image;
    this.convention.logoHorizontalId = image.id;
  }

  deleteImage() {
    this.convention.logoHorizontal = null;
    this.convention.logoHorizontalId = null;
  }

  saveConvention() {
    this.save.emit({ convention: this.convention, orgId: this.orgId });
  }
}

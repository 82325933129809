import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { Community } from '@fg-types/community';

@Component({
  selector: 'search-community',
  templateUrl: './search-community.component.html',
  styleUrls: ['./search-community.component.scss']
})
export class SearchCommunityComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() clearOnSelect = true;
  @Input() label: string;
  @Output() addItem = new EventEmitter<Community>();
  @Output() queryChange = new EventEmitter<string>();
  filteredItems: Community[] = [];
  item = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.apiService.search.searchFandoms(params.query).subscribe(res => {
        this.filteredItems = res.items;
      });
    }
  }

  onSelect(community: Community) {
    this.addItem.emit(community);
    this.item = '';
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConventionRatingComment } from '@fg-types/convention-rating';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'rating-tile',
  templateUrl: './rating-tile.component.html',
  styleUrls: ['./rating-tile.component.scss']
})
export class RatingTileComponent implements ListItem<IConventionRatingComment> {
  @Input() data: IConventionRatingComment;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  get image() {
    const value = this.data.value || this.data.answer;
    if (value === 1) return 'assets/layout/images/thumb_up.png';
    if (value === -1) return 'assets/layout/images/thumb_down.png';
  }
}

import {
  Directive,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  Renderer2
} from '@angular/core';

@Directive({
  selector: '[fgInfiniteScroll]'
})
export class InfiniteScrollDirective {
  @Input() public scrollFinished: boolean;
  @Input() public isLoading: boolean;
  @Input() public offset = 650;
  @Input() public containerScroll: boolean;
  @Input() public reverseScroll = false;

  @Output() public scrolled: EventEmitter<any> = new EventEmitter();

  public paused = true;

  private previousScrollTop: number;

  constructor(private element: ElementRef, private renderer: Renderer2) {
    if (!!document.body.hasAttribute('style')) {
      this.renderer.listen(this.element.nativeElement, 'scroll', () => {
        this.handleScrollContainer();
      });
    }
  }

  @HostListener('scroll')
  public scroll() {
    if (this.containerScroll && this.isEnabled()) {
      this.handleScrollContainer();
    }
  }

  @HostListener('window:scroll')
  public scrollWindow() {
    if (!this.containerScroll && this.isEnabled()) {
      this.handleScroll();
    }
  }

  private handleScroll() {
    const body = document.documentElement;
    const previousScrollTop = this.previousScrollTop;
    const scrolledFromTop = window.scrollY || window.pageYOffset;
    this.handle(body, previousScrollTop, scrolledFromTop);
  }

  private handleScrollContainer() {
    const element = this.element.nativeElement;
    const previousScrollTop = this.previousScrollTop;
    const scrolledFromTop = element.scrollTop;
    this.handle(element, previousScrollTop, scrolledFromTop);
  }

  private handle(element, previousScrollTop, scrolledFromTop) {
    if (this.reverseScroll) {
      this.handleReverseScroll(previousScrollTop, scrolledFromTop);
    } else {
      this.handleRegularScroll(element, previousScrollTop, scrolledFromTop);
    }
  }

  private handleRegularScroll(element, previousScrollTop, scrolledFromTop) {
    this.previousScrollTop = scrolledFromTop;
    // Ignore scroll to top
    if (previousScrollTop >= scrolledFromTop) {
      return;
    }
    const criticalScrollHeight = element.scrollHeight - element.offsetHeight - this.offset;
    if (scrolledFromTop > criticalScrollHeight) {
      this.scrolled.emit();
    }
  }

  private handleReverseScroll(previousScrollTop, scrolledFromTop) {
    this.previousScrollTop = scrolledFromTop;
    // Ignore scroll to bottom
    if (scrolledFromTop >= previousScrollTop) {
      return;
    }
    if (scrolledFromTop < this.offset) {
      this.scrolled.emit();
    }
  }

  private isEnabled() {
    return !this.isLoading && !this.scrollFinished;
  }
}

<div class="fg-view-container fg-view-container-bg ">
  <div class="layout-padding fg-col-body">
    <div class="p-grid p-mt-3">
      <div class="p-col-12 input-container">
        <div class="input-cell title-font">
          <div class="color unsetFontStyles">Team Details</div>
        </div>
      </div>
    </div>
    <div *ngIf="config">
    <div *ngFor="let role of config.roles">
      <div class="p-grid p-mt-3">
        <search-user
          [disabled]="false"
          (addUser)="addUserConvention($event, role)"
          [label]="roleLabel[role]"
          placeholder="Type to search usernames, or enter email address"
        ></search-user>
        <div class="p-col-12 grid-container">
          <div *ngFor="let user of modelUsers.get(role)">
            <div class="user_tile">
              <user-tile [data]="user.user">
              </user-tile>
              <i (click)="deleteUserConvention(user, role)" class="pi pi-times pointer icon_style"></i>
            </div>
          </div>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
  </div> 

  <!-- <div class="fg-footer ">
    <div class="btn-container ">
      <button pButton label="Save and Continue" class="fg-button"></button>
    </div>
  </div> -->
</div>

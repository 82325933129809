<div class="fg-view-header">
  <div class="actions fg-col-header">
    <div class="input-container fg-header-left-col">
      <search-input
        placeholder="Search Categories"
        (onSearch)="searchCategory($event)"
      ></search-input>
    </div>
    <ng-template *ngIf="selectedItem" [ngTemplateOutlet]="actions"></ng-template>
  </div>
</div>
<div class="fg-view-body">
  <div class="fg-left-col fg-col-with-header">
    <div class="fg-col-header fg-col-list-header">
      <button
        pButton
        class="p-button-primary large right-content raised full-width"
        type="button"
        label="ADD Category"
        (click)="onSelectItem({})"
      >
        <i class="pi pi-plus-circle right p-d-flex p-ai-center"></i>
      </button>
    </div>
    <div class="fg-col-body">
      <flat-list
        [loading]="loading"
        [items]="items"
        [selectedItem]="selectedItem"
        (onClickItem)="onSelectItem($event)"
        [itemComponent]="listDetailComponent"
        (onScroll)="onScroll()"
        [containerScroll]="true"
      ></flat-list>
    </div>
  </div>
  <div #previewWindow class="fg-right-col fg-col-with-header mobile-full-view" *ngIf="selectedItem">
    <mobile-detail-header (onClose)="onSelectItem()" [title]="selectedItem.name">
      <ng-template [ngTemplateOutlet]="actions"></ng-template>
    </mobile-detail-header>
    <fg-category-form [category]="selectedItem" (onChange)="isDirty = true"></fg-category-form>
  </div>
</div>

<ng-template #actions>
  <span></span>
  <div class="p-grid p-nogutter">
    <button
      pButton
      class="p-button-secondary"
      type="button"
      label="REMOVE"
      (click)="deleteCategory(selectedItem)"
      *ngIf="selectedItem.id"
    ></button>
    <span class="spacer"></span>
    <button
      pButton
      class="p-button-primary"
      type="button"
      [label]="isDirty ? 'SAVE' : 'SAVED'"
      *ngIf="selectedItem"
      (click)="upsertCategory()"
      [disabled]="!isDirty"
    ></button>
  </div>
</ng-template>

<div class="card head">
  <div class="heading" [routerLink]="'/'">
    <div class="pi pi-chevron-left edit-profile-pointer">
      Edit Profile
    </div>
  </div>
  <p-panel>
    <form>
      <div class="p-grid p-mt-3">
        <div class="input-container">
          <cropper
            [disabled]="false"
            [img]="(fan.profilePhoto?.sizes)[0]?.url"
            (setImage)="updateImage($event)"
            height="135px"
            width="135px"
            class="input-cell"
            borderRadius="50%"
            [marginAuto]="false"
            classContainer="md-l-lg-r"
          ></cropper>
        </div>
        <!-- <div class="name-container p-col-12 "> -->
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>USERNAME</label>
            <input type="text" pInputText name="username" [(ngModel)]="fan.username" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>DISPLAY NAME</label>
            <input type="text" pInputText name="name" [(ngModel)]="fan.name" />
          </div>
        </div>

        <!-- </div> -->

        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>Title</label>
            <input type="text" pInputText name="title" [(ngModel)]="fan.title" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>Organization</label>
            <input type="text" pInputText name="organization" [(ngModel)]="fan.organization" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>Location</label>
            <input type="text" pInputText name="location" [(ngModel)]="fan.location" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>Description</label>
            <textarea pInputTextarea name="bio" [(ngModel)]="fan.bio"></textarea>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>Instagram Handle</label>
            <span class="input-flex flex-left flex-right">
              <span class="input-mask">@</span>
            <input type="text" pInputText name="instagram" [(ngModel)]="fan.instagram" />
            </span>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>&nbsp;X HANDLE</label>
            <span class="input-flex flex-left flex-right">
              <span class="input-mask">@</span>
            <input type="text" pInputText name="twitter" [(ngModel)]="fan.twitter" />
            </span>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>TWITCH HANDLE</label>
            <span class="input-flex flex-left flex-right">
              <span class="input-mask">@</span>
            <input type="text" pInputText name="twitch" [(ngModel)]="fan.twitch"/>
            </span>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>YOUTUBE URL</label>
            <input type="text" pInputText name="youtube" placeholder="https://fan.guru"
            [pattern]="'.*youtube\.com.*'"
            [(ngModel)]="fan.youtube"/>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>WEBSITE</label>
            <input type="text" pInputText name="website" [(ngModel)]="fan.website" placeholder="https://fan.guru" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>FACEBOOK URL</label>
            <input type="text" pInputText name="facebook" [(ngModel)]="fan.facebook" placeholder="https://fan.guru" />
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>TIKTOK HANDLE</label>
            <span class="input-flex flex-left flex-right">
              <span class="input-mask">@</span>
              <input type="text" pInputText name="facebook" [(ngModel)]="fan.tiktok" />
            </span>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>DISCORD URL</label>
              <input type="text" pInputText name="discord" [(ngModel)]="fan.discord" placeholder="https://fan.guru"/>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>THREADS URL</label>
            <input type="text" pInputText name="threads" [(ngModel)]="fan.threads" placeholder="https://fan.guru"/>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>IMDB HANDLE</label>
            <span class="input-flex flex-left flex-right">
              <span class="input-mask">@</span>
              <input type="text" pInputText name="imdb" [(ngModel)]="fan.imdb" />
            </span>
          </div>
        </div>
        <div class="input-container p-col-12">
          <div class="input-cell">
            <label>SPOTIFY URL</label>
            <input type="text" pInputText name="spotify" [(ngModel)]="fan.spotify" placeholder="https://fan.guru" />
          </div>
        </div>
        <div class="clearfix"></div>
        <div class="btn-container ">
          <button
            pButton
            name="password"
            type="submit"
            label="SAVE"
            (click)="submitForm()"
            class="fg-button"
          ></button>
        </div>
      </div>
    </form>
  </p-panel>
</div>

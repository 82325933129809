import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IConventionEvent } from '@fg-types/convention-event';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { ConventionEventHelpers } from '@fg-shared/helpers/convention-event-helpers';

@Component({
  selector: 'schedule-event-tile',
  templateUrl: './schedule-event-tile.component.html'
})
export class ScheduleEventTileComponent extends BaseComponent
  implements ListItem<IConventionEvent> {
  @Input() data: IConventionEvent;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }

  // Calculated values
  get imageUrl() {
    return ConventionEventHelpers.getImage(this.data, this.convention);
  }

  get description() {
    if (this.convention) {
      const { date, location, time } = ConventionEventHelpers.getDetails(
        this.data,
        this.convention
      );
      return [date, time, location].join('<br />');
    } else return '';
  }
}

import { NavigationContextHelpers } from '@fg-services/navigation-context/navigation-context.helpers';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { GroupedAllowedService } from '@fg-types/convention-service';
import { Organization } from '@fg-types/organization.type';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum OrganizationRole {
  OrgAdmin = 'organization-admin',
  OrgMod = 'organization-mod',
  Admin = 'admin'
}

export interface OrganizationSegmentModel {
  organization: Organization;
  services: GroupedAllowedService;
}

export type OrganizationSegment = NavigationContextSegment<
  NavigableModel.Organization,
  OrganizationSegmentModel,
  OrganizationRole
>;

export const organizationRoleResolver = (
  modelId: string,
  navigationContextService: NavigationContextService
): OrganizationRole => {
  if (!navigationContextService.userRoles) return null;
  if (navigationContextService.userRoles.isAdmin) return OrganizationRole.Admin;
  if (navigationContextService.userRoles.organizationsAdminingIds.includes(modelId))
    return OrganizationRole.OrgAdmin;
  if (navigationContextService.userRoles.organizationsModeratingIds.includes(modelId))
    return OrganizationRole.OrgMod;
};

export const routeToOrganizationSegment = (
  navigationContextService: NavigationContextService,
  modelId: string
): Observable<OrganizationSegment> =>
  forkJoin([
    NavigationContextHelpers.loader<Organization>(
      modelId,
      navigationContextService.apiService.organizations.getOrganizationById(modelId),
      navigationContextService.organizations
    ),
    NavigationContextHelpers.loader<GroupedAllowedService>(
      modelId,
      navigationContextService.apiService.organizations.getOrganizationAllowedServices(modelId),
      navigationContextService.services
    )
  ]).pipe(
    map(([organization, services]) => ({
      label: 'Organization',
      subLabel: organization?.name ?? '',
      segmentName: NavigableModel.Organization,
      modelId,
      model: { organization, services },
      role: organizationRoleResolver(modelId, navigationContextService)
    }))
  );

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { IGroupedChallenge } from '@fg-types/grouped-challenge';

@Injectable()
export class ConventionGroupedChallengeService {
  private buttonUrl = `${environment.API_URL +
    environment.API_VERSION}/ConventionGroupedChallenges`;

  constructor(private http: HttpClient) {}

  create(body: Partial<IGroupedChallenge>) {
    return this.http.post<IGroupedChallenge>(this.buttonUrl, body);
  }

  patchById(id: string, body: Partial<IGroupedChallenge>) {
    return this.http.patch<IGroupedChallenge>(this.buttonUrl + '/' + id, body);
  }

  getById(id: string, filter: any) {
    const options: any = {};
    options.params = new HttpParams();
    if (filter) {
      options.params = options.params.set('filter', JSON.stringify(filter));
    }
    return this.http.get<any>(this.buttonUrl + '/' + id, options);
  }

  deleteById(id: string) {
    return this.http.delete<any>(this.buttonUrl + '/' + id);
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';
import { Community } from '@fg-types/community';
import { IConvention } from '@fg-types/convention.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IPost } from '@fg-types/post.d';
import { IUser } from '@fg-types/user.type';

type CommunityFeed = 'popular' | 'new' | 'suggested' | 'curated';
type ConventionFeed = 'attending' | 'local' | 'all' | 'featured';
type PostFeed =
  | 'all'
  | 'following'
  | 'shop'
  | 'home'
  | 'market'
  | 'stream'
  | 'curated'
  | 'approval';
type PostType = 'listing' | 'album' | 'snippet' | 'event' | 'stream';
interface PostFeedConfig {
  feed: PostFeed;
  lastKey?: string;
  postTypes?: PostType[];
  order?: string;
  categoryIds?: string;
  maxPrice?: number;
  minPrice?: number;
  following?: boolean;
  fandoms?: boolean;
}

export interface ConventionFeedParams {
  radius?: number;
  categoryIds?: string[];
  type?: string;
  fromPublishedAttendance?: number;
  toPublishedAttendance?: number;
  fromDate?: string;
  toDate?: string;
  ticketProvider?: string;
  goLive?: string;
  userId?: string;
}

export class FeedApi extends ApiBase {
  model = 'Feeds';

  posts({
    feed,
    lastKey,
    postTypes,
    order,
    categoryIds,
    maxPrice,
    minPrice,
    following,
    fandoms
  }: PostFeedConfig) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/Posts/${feed}`, {
      params: this.setParams({
        lastKey,
        postTypes: postTypes.join(','),
        order,
        categoryIds,
        maxPrice,
        minPrice,
        following,
        fandoms
      })
    });
  }

  communities(feed: CommunityFeed, lastKey?: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/Fandoms/${feed}`, {
      params: this.setParams({ lastKey })
    });
  }

  conventions(
    feed: ConventionFeed,
    params: {
      lastKey?: string;
      limit?: number;
      query?: string;
      allConventions?: boolean;
    } & ConventionFeedParams
  ) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/Conventions/${feed}`, {
      params: this.setParams(params)
    });
  }

  users(lastKey: string) {
    return this.http.get<PaginatedResponse<IUser>>(`${this.modelBase}/users/all`, {
      params: NetworkHelpers.setParams({ lastKey })
    });
  }
}

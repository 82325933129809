<div class="fg-view-container fg-with-header">
  <div class="fg-view-header">
    <router-tabs></router-tabs>
  </div>
  <div class="fg-view-body single-pane">
    <div class="fg-single-col wide fg-col-with-header">
      <div class="fg-col-header fg-col-list-header" *ngIf="!noOrgs">
        <div class="input-container">
          <search-input
            placeholder="Search Organizations"
            (onSearch)="search($event)"
          ></search-input>
          <ng-template [ngIf]="userRoles?.isAdmin">
            <div class="spacer"></div>
            <button
              pButton
              class="p-button-primary"
              type="button"
              label="CREATE NEW"
              (click)="createOverlay.show($event, query)"
            ></button>
            <div class="spacer"></div>
          </ng-template>
          <create-modal
            #createOverlay
            (onCreate)="createNew($event)"
            modelName="ORGANIZATION"
          ></create-modal>
        </div>
      </div>

      <div
        class="fg-col-body"
        fgInfiniteScroll
        (scrolled)="loadItems()"
        [scrollFinished]="scrollFinished"
        [containerScroll]="true"
      >
        <div class="card">
          <div class="items p-grid">
            <ng-container *ngFor="let item of items; let i = index">
              <div class="p-col-12 p-md-6 p-lg-4">
                <organization-card
                  (onClick)="selectItem(item)"
                  [hoverActive]="true"
                  [data]="item"
                ></organization-card>
              </div>
              <div *ngIf="i % 2" class="clearfix"></div>
            </ng-container>
            <div class="clearfix"></div>
          </div>
        </div>
        <div *ngIf="loading" class="fg-col-body">
          <div class="card">
            <div class="items p-grid">
              <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let x of [1, 2, 3, 4, 5, 6]">
                <loading-event-card></loading-event-card>
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="noOrgs" class="fg-col-body">
          <ng-template [ngTemplateOutlet]="emptyOrgsView"></ng-template>
        </div>
      </div>
    </div>
  </div>
</div>

<!--
  *********************************************************
  ********           New Org Creation              ********
  *********************************************************
 -->
<ng-template #emptyOrgsView>
  <div class="empty-orgs-container" *ngIf="!showNewOrgForm; else newOrgForm">
    <div class="p-grid p-dir-col p-align-center">
      <div class="empty-orgs-icon">
        <i class="pi pi-calendar-times"></i>
      </div>
      <br /><br />
      <h2>Create an Organization to get started</h2>
      <br />
      <p></p>
      <p>
        Once you belong to an Organization you can create events.
      </p>
      <br />
      <button
        pButton
        class="p-button-primary large"
        type="button"
        label="CREATE ORGANIZATION"
        (click)="showNewOrgForm = true"
      ></button>
    </div>
  </div>
</ng-template>

<!--
  ********************************************************
  ********            CREATE ORG VIEW             ********
  ********************************************************
 -->
<ng-template #newOrgForm>
  <fg-new-org-form (cancel)="showNewOrgForm = false"></fg-new-org-form>
</ng-template>

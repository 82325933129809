export const matIcons = [
  {
    label: 'app',
    url: '../assets/layout/images/app_icon.svg'
  },
  {
    label: 'essentials',
    url: '../assets/layout/images/essentials_icon.svg'
  },
  {
    label: 'event_dashboard',
    url: '../assets/layout/images/event_dashboard_icon.svg'
  },
  {
    label: 'exhibitor_details',
    url: '../assets/layout/images/exhibitor_details_icon.svg'
  },
  {
    label: 'inventory',
    url: '../assets/layout/images/inventory_icon.svg'
  },
  {
    label: 'refer_a_friend',
    url: '../assets/layout/images/refer_a_friend_icon.svg'
  },
  {
    label: 'shipping',
    url: '../assets/layout/images/shipping_icon.svg'
  },
  {
    label: 'square',
    url: '../assets/layout/images/square_icon.svg'
  },
  {
    label: 'taxes',
    url: '../assets/layout/images/taxes_icon.svg'
  },
  {
    label: 'team_settings',
    url: '../assets/layout/images/team_settings_icon.svg'
  },
  {
    label: 'tips',
    url: '../assets/layout/images/tips_icon.svg'
  },
  {
    label: 'community',
    url: '../assets/layout/images/community.svg'
  },
  {
    label: 'exhibit_hall',
    url: '../assets/layout/images/shopper.svg'
  },
  {
    label: 'o_billing',
    url: '../assets/layout/images/o_billing.svg'
  },
  {
    label: 'ticketing_registration',
    url: '../assets/layout/images/ticketing_registration_icon.svg'
  }
];

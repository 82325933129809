<div
  *ngFor="let item of items; let i = index"
  class="radio-container"
  [ngClass]="containerClass || 'p-col-12 p-sm-6 p-xl-4'"
  [title]="item.value"
>
  <p-checkbox
    [name]="item.value + i"
    [inputId]="item.value + i"
    class="checkbox-radio"
    [value]="item.value"
    [(ngModel)]="selectedItems"
    [disabled]="disabled"
    [binary]="binary"
    [tabindex]="item.tabindex"
    [inputId]="item.inputId"
    [style]="style"
    [styleClass]="styleClass"
    [checkboxIcon]="checkboxIcon || 'pi pi-check'"
    (ngModelChange)="onSelect.emit($event)"
  >
  </p-checkbox>
  <span class="spacer"></span>
  <label [for]="item.value + i">
    <striped-label [label]="item.label" [color]="item.color"></striped-label
  ></label>
</div>

<div class="input-container p-col-12">
  <div class="input-cell">
    <label *ngIf="label">{{ label }}</label>
    <span class="input-description" *ngIf="inputDescription">{{ inputDescription }}</span>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="userInput"
        [suggestions]="filteredUsers"
        [placeholder]="placeholder"
        field="username"
        (completeMethod)="filter($event)"
        (onSelect)="onSelect($event)"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
        (onKeyUp)="onKeyUp($event)"
      >
        <ng-template let-item pTemplate="item">
          <user-tile *ngIf="item.id; else createNew" [data]="item" hoverActive="1"></user-tile>
          <ng-template #createNew>{{ item }}</ng-template>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

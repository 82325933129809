import { Injectable } from '@angular/core';
import { CreatorData, Polls, Subscription } from '@fg-types/creator';
import { IPost } from '@fg-types/post';
import { ICreatorSubscriptionBase } from '@fg-types/subscriptionBase';
import { Observable, Subscriber, of } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Subject } from 'rxjs/internal/Subject';
import { FanWall } from '@fg-types/fanwall';

@Injectable({
  providedIn: 'root'
})

// Creaator Subscription class 
export class CreatorSubsription{
  private currentSubscription: Subject<ICreatorSubscriptionBase> = new BehaviorSubject<ICreatorSubscriptionBase>({} as Subscription);

  private SubscriptionList: Subject<ICreatorSubscriptionBase[]> = new BehaviorSubject<ICreatorSubscriptionBase[]>([])
  private InActivatedSubscriptionsList: Subject<ICreatorSubscriptionBase[]> = new BehaviorSubject<ICreatorSubscriptionBase[]>([])

  constructor(){}
  get CurrentCreatorSubscription():Observable<ICreatorSubscriptionBase | null>{
    return this.currentSubscription.asObservable()
  }
  UpdateCurrentCreatorSubscription(newSubscription:ICreatorSubscriptionBase){
    return this.currentSubscription.next(newSubscription)
  }
  get SubscriptionsList():Observable<ICreatorSubscriptionBase[] | null>{
    return this.SubscriptionList.asObservable()
  }
  UpdateSubscriptionsList(newSubscription: ICreatorSubscriptionBase[]){
    return this.SubscriptionList.next(newSubscription);
  }

  get DeActivatedSubscriptionsList():Observable<ICreatorSubscriptionBase[] | null>{
    return this.InActivatedSubscriptionsList.asObservable()
  }
  UpdateDeActivatedSubscriptionsList(newSubscription: ICreatorSubscriptionBase[]){
    return this.InActivatedSubscriptionsList.next(newSubscription);
  }
}
//creatorService
export class CreatorService{
  public creatorPosts: IPost[];
  // public userCreators: CreatorData[];
  public userCreators: Subject<CreatorData[]> = new BehaviorSubject<CreatorData[]>([]);
  private currentCreator: BehaviorSubject<CreatorData> = new BehaviorSubject<CreatorData>({});

  constructor() {}
  get userCreatorsList():Observable<CreatorData[] | null>{
    return this.userCreators.asObservable()
  }
  updateUserCreatorsList(newCreator: CreatorData[]){
    return this.userCreators.next(newCreator);
  }
  get currentCreator$() {
    return this.currentCreator.asObservable();
  }

  updateCurrentCreator(newCreator: CreatorData) {
    this.currentCreator.next(newCreator);
  }
}

//FanWall Service
export class PollService{
  public list: Subject<Polls[]> = new BehaviorSubject<Polls[]>([]);
  private poll: BehaviorSubject<Polls> = new BehaviorSubject<Polls>({});

  constructor() {}
  get userPollList():Observable<Polls[] | null>{
    return this.list.asObservable()
  }
  updatePollList(newPoll: Polls[]){
    return this.list.next(newPoll);
  }
  get currentPoll$() {
    return this.poll.asObservable();
  }

  updateCurrentPoll(newPoll: Polls) {
    this.poll.next(newPoll);
  }
}

export class FanWallService{
  private blockedList: BehaviorSubject<FanWall[]> = new BehaviorSubject<FanWall[]>([]);

  constructor() {}
  get blockList():Observable<FanWall[] | null>{
    return this.blockedList.asObservable()
  }
  updateBlockList(newlist: FanWall[]){
    return this.blockedList.next(newlist);
  }
}
export class DummyDataService {
  DummyData =
    // Generate and return dummy data of subscribers
    [
      {
        id: 1,
        name: 'John',
        username: 'Peter',
        date: '24 June 2023',
        profile: 'assets/layout/images/Tier_1_1.png',
      },
      {
        id: 2,
        name: 'Jimin',
        username: 'Park',
        date: '6 aug 2023',
        profile: 'assets/layout/images/Nabi.jpg',
      },
      {
        id: 3,
        name: 'Taylor',
        username: 'Swift',
        date: '7 sep 2023 ',
        profile: 'assets/layout/images/Tier_1_1.png',
      }
    ];

  generateDummyData() {
    return this.DummyData;
  }

  generatePayoutData(): any[] {
    return [
      {
        date: 'Oct 15 2023',
        transfered: '$100',
        charges: '$10'
      },
      {
        date: 'Sep 30 2023',
        transfered: '$230',
        charges: '$23'
      },
      {
        date: 'Sep 1 2023',
        transfered: '$550',
        charges: '$55'
      },
      {
        date: 'Aug 23 2023',
        transfered: '$1,120',
        charges: '$112'
      }
    ];
  }
}

export const arrayData=[
  {
      id:'1',
      date:'FEb 2 2024',
      name:'TXT',
      role:'K.Dawn Nation',
      description:'Much Love from Hollywood.',
      title:'West Coast Rep!',
      image:'assets/layout/images/Nabi.jpg',
      status:'pending',
    },
    {
      id:'2',
      date:'Dec 30 2023',
      name:'EXO',
      role:'Supporter',
      title:'Music Lovers',
      media:'assets/layout/images/guitar-back.jpg',
      description:'Musch Love from K-pop Industry. <3 We will always vote for your page  ',
      image:'assets/layout/images/Tier_1_1.png',
      status:'pending',
    },
    {
      id:'3',
      date:'Sep 12 2023',
      name:'Stary Kids',
      role:'VIP Member',
      description:'More Offers More Subscribers Keep Going, Fighting, Spread love everywhere you go.',
      status:'pending',
    }
]
export class CommunityData {

  generateData(): any[] {
    return [
      {
        joinedAt: 'Oct 15 2023',
        name: 'John',
        username:'Kim'
      },
      {
        joinedAt: 'Sep 30 2023',
        name: 'Kim',
        username:'Tae'
      },
      {
        joinedAt: 'Sep 1 2023',
        name: 'Lisa',
        username:'BP'
      },
      {
        joinedAt: 'Aug 23 2023',
        name: 'Jimin',
        username:'Park'
      }
    ];
  }
}

export class RequestDummyData{
    D_data = [
      {
        id: "123",
        name: 'John',
        email: 'John.23@gmail.com',
        description: 'I would like to request for the one on one call',
        message: 'From Blink :) ',
        private : false,
        date: 'Sent on Jan 16, 2023 at 10:30am',
        isCompleted : false,
      },
      {
        id: "112",
        name: 'Jimin',
        email: 'Jimin.143@gmail.com',
        description: 'Best friend for the rest of my life.',
        message: 'From Army :) ',
        private : true,
        date: 'Sent on Jan 5, 2023 at 3:30am',
        isCompleted : false,
      },
      {
        id: "113",
        name: 'KingDemon',
        email: 'Demon.23@gmail.com',
        description: 'Every end has a new start.',
        message: 'From Bells :) ',
        private : true,
        date: 'Sent on Jan 1, 2023 at 9:30pm',
        isCompleted : false,
      }
    ]

    generateData(){
      return this.D_data;
    }
  }

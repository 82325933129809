import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConventionRatingSummaryCommon } from '@fg-types/convention-rating';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
@Component({
  selector: 'convention-analytic-tile',
  templateUrl: './convention-analytics-tile.component.html',
  styleUrls: ['./convention-analytics-tile.component.scss']
})
export class ConventionAnalyticTileComponent
  implements OnInit, ListItem<IConventionRatingSummaryCommon> {
  @Input() data: IConventionRatingSummaryCommon;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  constructor() {}
  ngOnInit() {}
}

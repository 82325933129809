import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AdTileComponent } from '@fg-shared/tile/ad-tile/ad-tile.component';
import { AnalyticTileComponent } from '@fg-shared/tile/analytic-tile/analytic-tile.component';
import { CategoryTileComponent } from '@fg-shared/tile/category-tile/category-tile.component';
import { ChallengeTileComponent } from '@fg-shared/tile/challenge-tile/challenge-tile.component';
import { ChannelMessageTileComponent } from '@fg-shared/tile/channel-message-tile/channel-message-tile.component';
import { ChannelTileComponent } from '@fg-shared/tile/channel-tile/channel-tile.component';
import { CommentTileComponent } from '@fg-shared/tile/comment-tile/comment-tile.component';
import { CommunityTileComponent } from '@fg-shared/tile/community-tile/community-tile.component';
import { ContestEntryTileComponent } from '@fg-shared/tile/contest-entry-tile/contest-entry-tile.component';
import { ContestTileComponent } from '@fg-shared/tile/contest-tile/contest-tile.component';
import { ContestWinnerTileComponent } from '@fg-shared/tile/contest-winner-tile/contest-winner-tile.component';
import { ConventionAnalyticTileComponent } from '@fg-shared/tile/convention-analytics-tile/convention-analytics-tile.component';
import { ExhibitorRequestTileComponent } from '@fg-shared/tile/exhibitor-request-tile/exhibitor-request-tile.component';
import { ExhibitorTileComponent } from '@fg-shared/tile/exhibitor-tile/exhibitor-tile.component';
import { FeaturedItemTileComponent } from '@fg-shared/tile/featured-item-tile/featured-item-tile.component';
import { FeaturedListTileComponent } from '@fg-shared/tile/featured-list-tile/featured-list-tile.component';
import { GroupChallengeTileComponent } from '@fg-shared/tile/group-challenge-tile/group-challenge-tile.component';
import { GuestTileComponent } from '@fg-shared/tile/guest-tile/guest-tile.component';
import { MapTileComponent } from '@fg-shared/tile/map-tile/map-tile.component';
import { NotificationAnalyticsTileComponent } from '@fg-shared/tile/notification-analytics-tile/notification-analytics-tile.component';
import { NotificationTileComponent } from '@fg-shared/tile/notification-tile/notification-tile.component';
import { PercentageBarComponent } from '@fg-shared/tile/percentage-bar/percentage-bar.component';
import { PercentageCircleComponent } from '@fg-shared/tile/percentage-circle/percentage-circle.component';
import { PostCardComponent } from '@fg-shared/tile/post-card/post-card.component';
import { PrizeTileComponent } from '@fg-shared/tile/prize-tile/prize-tile.component';
import { RatingTileComponent } from '@fg-shared/tile/rating-tile/rating-tile.component';
import { ReportTileComponent } from '@fg-shared/tile/report-tile/report-tile.component';
import { ReportedScheduleEventTileComponent } from '@fg-shared/tile/schedule-event-tile/reported-schedule-event-tile.component';
import { ScheduleEventTileComponent } from '@fg-shared/tile/schedule-event-tile/schedule-event-tile.component';
import { SpecialTileComponent } from '@fg-shared/tile/special-tile/special-tile.component';
import { SurveyResponseTileComponent } from '@fg-shared/tile/survey-response-tile/survey-response-tile.component';
import { TileComponent } from '@fg-shared/tile/tile.component';
import { UserTileComponent } from '@fg-shared/tile/user-tile/user-tile.component';
import { VideoPlayerComponent } from '@fg-shared/tile/video-player/video-player.component';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MultilineDotdotdotDirective } from '@fg-shared/directives/multiline-dotdotdot.directive';
import { CommunityCardComponent } from '@fg-shared/tile/community-card/community-card.component';
import { EventCardComponent } from '@fg-shared/tile/event-card/event-card.component';
import { EventIndicatorComponent } from '@fg-shared/tile/event-card/event-indicator/event-indicator.component';
import { EventTileComponent } from '@fg-shared/tile/event-tile/event-tile.component';
import { GenericTileComponent } from '@fg-shared/tile/generic-tile/generic-tile.component';
import { GridItemTileComponent } from '@fg-shared/tile/grid-item-tile/grid-item-tile.component';
import { ItemTileComponent } from '@fg-shared/tile/item-tile/item-tile.component';
import { ModuleTileComponent } from '@fg-shared/tile/module-tile/module-tile.component';
import { OrganizationCardComponent } from '@fg-shared/tile/organization-card/organization-card.component';
import { ScheduleCategoryTileComponent } from '@fg-shared/tile/schedule-category-tile/schedule-category-tile.component';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { EventFeedTileComponent } from '@fg-shared/layout/event-feed-tile/event-feed-tile.component';
import { SubscriptionBaseComponent } from '@fg-shared/layout/subscription-base-card/subscription-base-card.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { MarkerTileComponent } from './marker-tile/marker-tile.component';
import { CreatorSubscriptionBaseComponent } from '@fg-shared/layout/creator-subscription-card/creator-subscription-card';
import { CreatorCommunityComponent } from '@fg-shared/layout/community-card/community-card.component';
import { CustomRequestCardComponent } from './custom-request-tile/custom-req-card.component';
import { CreatorNotificationTileComponent } from './creator-notification-tile/creator-notification-tile.component';

const tiles = [
  GuestTileComponent,
  CommentTileComponent,
  AdTileComponent,
  CommunityTileComponent,
  ExhibitorTileComponent,
  ExhibitorRequestTileComponent,
  UserTileComponent,
  ContestTileComponent,
  ScheduleEventTileComponent,
  ReportedScheduleEventTileComponent,
  ChallengeTileComponent,
  ReportTileComponent,
  PrizeTileComponent,
  GroupChallengeTileComponent,
  ChannelTileComponent,
  MapTileComponent,
  NotificationTileComponent,
  NotificationAnalyticsTileComponent,
  PostCardComponent,
  FeaturedItemTileComponent,
  FeaturedListTileComponent,
  SpecialTileComponent,
  ChannelMessageTileComponent,
  SurveyResponseTileComponent,
  ContestEntryTileComponent,
  VideoPlayerComponent,
  ContestWinnerTileComponent,
  AnalyticTileComponent,
  ConventionAnalyticTileComponent,
  PercentageBarComponent,
  PercentageCircleComponent,
  CategoryTileComponent,
  RatingTileComponent,
  ModuleTileComponent,
  OrganizationCardComponent,
  CommunityCardComponent,
  EventCardComponent,
  EventTileComponent,
  ItemTileComponent,
  GridItemTileComponent,
  ScheduleCategoryTileComponent,
  TileComponent,
  GenericTileComponent,
  EventFeedTileComponent,
  SubscriptionBaseComponent,
  CreatorSubscriptionBaseComponent,
  CreatorCommunityComponent,
  CustomRequestCardComponent,
  CreatorNotificationTileComponent,
];
@NgModule({
  imports: [OverlayPanelModule, CommonModule, MatTooltipModule, MatMenuModule,
    MatIconModule, NgCircleProgressModule.forRoot({})],
  declarations: [
    MultilineDotdotdotDirective,
    EventIndicatorComponent,
    ...tiles,
    MarkerTileComponent
  ],
  exports: tiles
})
export class TileModule {}

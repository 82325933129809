<div
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  [class.common-tile-size]="commonTileSize"
  (click)="onClick.emit()"
  *ngIf="data"
>
  <div class="square-indicator" *ngIf="data.subscriberCount">
    {{ data.subscriberCount }}<br />
    {{ data.title ? data.title : data.subscriberCount != 1 ? 'FANS' : 'FAN' }}
  </div>
  <div class="tile-body">
    {{ data.name }}<span *ngIf="data.description"><br />{{ data.description }}</span>
  </div>
  <div class="tile-media">
    <div class="tile-media">
      <div *ngIf="image" [style.mask]="image" [style.-webkit-mask]="image" class="logo"></div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
<div *ngIf="active" class="active-indicator"></div>

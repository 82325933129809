<div class="card card-with-title">
  <p-card>
    <div class="p-card-title">
      {{ title }}
      <tooltip-button
        tooltip="
          Add your categories here to help attendees sort through all that your event has to offer.

          Guest category examples include: Celebrity, Voice Actor, Artist, Cosplayer, etc.

          Schedule Event category examples include: Programming, Photo Ops, Autographs, Q&A, etc...

          Exhibitor category examples include: Sponsor, Vendor, Artist, etc...

          Change their colors by entering your desired color code or selecting via the color picker.
        "
      ></tooltip-button>
    </div>
    <div class="p-grid p-my-3">
      <div *ngIf="!disabled" class="input-container p-col-12">
        <div class="input-cell">
          <label>ADD {{ title }}</label>
          <span class="input-flex inline-input">
            <input
              type="text"
              pInputText
              class="complete-input"
              [placeholder]="placeholder"
              [(ngModel)]="newCategory"
              (keyup.enter)="addEvent(); (false)"
            />
            <button
              *ngIf="newCategory"
              pButton
              class="p-button-primary"
              type="button"
              label="CREATE"
              (click)="addEvent(); (false)"
            ></button>
          </span>
        </div>
      </div>

      <div class="p-grid p-ml-0 p-mt-3 p-col-12 p-pl-0">
        <div class="input-container" [ngClass]="hasColor ? 'p-md-4 p-col-12' : 'p-md-7 p-col-12'">
          <div class="input-cell">
            <label>NAME</label>
          </div>
        </div>
        <div class="input-container p-md-4 p-col-12" *ngIf="hasColor">
          <div class="input-cell">
            <label>COLOR</label>
          </div>
        </div>
        <div class="input-container p-md-4 p-col-12" *ngIf="hasColor">
          <div class="input-cell">
            <label
              >PREVIEW
              <tooltip-button
                tooltip="This preview shows how this category label will appear within the mobile app and website."
              ></tooltip-button>
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>

    <div class="order-list" cdkDropList (cdkDropListDropped)="drop($event)">
      <div
        cdkDrag
        class="list-item p-grid fg-seperator-list"
        *ngFor="let item of categories; let i = index; trackBy: trackByFn"
      >
        <div *ngIf="isSortable" class="p-mx-0 p-col-1 p-ai-center p-grid p-jc-center">
          <i cdkDragHandle class="pi pi-bars"></i>
        </div>
        <div class="p-mx-0 p-grid" [ngClass]="isSortable ? 'p-col-10' : 'p-col-11'">
          <div class="input-container p-col-12 p-pl-0" [ngClass]="hasColor ? 'p-md-5' : 'p-col-12'">
            <div class="input-cell">
              <input
                *ngIf="!strings"
                pInputText
                [name]="'category-name' + i"
                type="text"
                placeholder="Category"
                [(ngModel)]="item.name"
                [disabled]="disabled"
                (ngModelChange)="onChange.emit(categories)"
              />
              <input
                *ngIf="strings"
                pInputText
                [name]="'category-name' + i"
                type="text"
                placeholder="Category"
                [(ngModel)]="stringsArray[i].value"
                [disabled]="disabled"
                (ngModelChange)="delayUpdateType$.next({ index: i, value: $event })"
              />
            </div>
          </div>
          <div class="input-container p-md-5 p-col-12" *ngIf="hasColor">
            <div class="input-cell">
              <span class="input-flex">
                <input
                  [name]="'category-color' + i"
                  type="text"
                  class="p-col-10"
                  pInputText
                  defaultValue="#CCCCCC"
                  [(ngModel)]="item.color"
                  (change)="onChange.emit(categories)"
                />
                <p-colorPicker
                  #eventTypePicker
                  [name]="'category-color-picker' + i"
                  [(ngModel)]="item.color"
                  (ngModelChange)="onChange.emit(categories)"
                ></p-colorPicker>
              </span>
            </div>
          </div>
          <div class="input-container p-md-2 p-col-12 color-preview" *ngIf="hasColor">
            <striped-label [label]="item.name" [color]="item.color"></striped-label>
          </div>
        </div>
        <div class="p-mx-0 p-col-1 p-ai-center p-grid p-jc-center">
          <i class="pi pi-times" *ngIf="!disabled" (click)="deleteInput(i)"></i>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div *ngIf="!categories?.length">No Records Found</div>
    <div class="clearfix"></div>
  </p-card>
</div>

import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import {
  ExhibitorRole,
  ExhibitorSegment
} from '@fg-services/navigation-context/segments/exhibitor-segment';

export class ExhibitorHallMenuItems extends BaseMenuItem<ExhibitorSegment> {
  menuItems() {
    const items: MenuItem[] = [
      {
        label: 'Events Dashboard',
        customIcon: 'event_dashboard',
        order: 10,
        roles: [ExhibitorRole.HasProfile, ExhibitorRole.SquareConnected],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'events']
      },
      {
        label: 'Exhibitor Details',
        customIcon: 'exhibitor_details',
        order: 20,
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'details']
      },
      {
        label: 'Categories & Items',
        customIcon: 'inventory',
        order: 30,
        routerLink: [...this.routePath, 'inventory', 'categories'],
        fatherActive: false,
        roles: [ExhibitorRole.SquareConnected],
        childActive: false
      },
      {
        label: 'Shipping',
        customIcon: 'shipping',
        routerLink: [...this.routePath, 'inventory', 'shipping'],
        fatherActive: false,
        childActive: false,
        order: 31,
        roles: [ExhibitorRole.SquareConnected]
      },
      {
        label: 'Taxes',
        customIcon: 'taxes',
        order: 40,
        roles: [ExhibitorRole.SquareConnected],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'taxes']
      },
      {
        label: 'Square Account',
        customIcon: 'square',
        routerLink: [...this.routePath, 'settings', 'square'],
        fatherActive: false,
        childActive: false,
        order: 50,
        roles: [ExhibitorRole.HasProfile, ExhibitorRole.SquareConnected]
      },
      {
        label: 'Team Settings',
        customIcon: 'team_settings',
        routerLink: [...this.routePath, 'settings', 'team'],
        fatherActive: false,
        childActive: false,
        order: 51,
        roles: [ExhibitorRole.HasProfile, ExhibitorRole.SquareConnected]
      },
      {
        label: 'Refer a Friend',
        customIcon: 'refer_a_friend',
        routerLink: [...this.routePath, 'settings', 'referral'],
        fatherActive: false,
        childActive: false,
        order: 52,
        roles: [ExhibitorRole.HasProfile, ExhibitorRole.SquareConnected]
      },
      {
        label: 'Tips',
        customIcon: 'tips',
        routerLink: [...this.routePath, 'tips'],
        fatherActive: false,
        childActive: false,
        order: 60,
        roles: [ExhibitorRole.HasProfile, ExhibitorRole.SquareConnected]
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const labelsToRemove: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    const filteredOptions = this.removeSideNavItems(menuItems, this.context.role, labelsToRemove);
    return this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
  }
}

import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'striped-label',
  templateUrl: './striped-label.component.html',
  styleUrls: ['./striped-label.component.scss']
})
export class StripedLabelComponent implements OnInit {
  @Input() label: string;
  @Input() color: string;
  constructor() {}

  ngOnInit() {}
}

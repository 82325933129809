import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';
import { ITag } from '@fg-types/tag';
import { Observable } from 'rxjs';
import { PaginatedResponse } from './../types/paginated-response.d';

@Injectable()
export class TagService {
  private getTagURL = `${environment.API_URL + environment.API_VERSION}/Tags`;

  constructor(private http: HttpClient) {}

  getTagByAutocomplete(term: string) {
    return this.http.get<PaginatedResponse<ITag>>(this.getTagURL + '/autocomplete', {
      params: NetworkHelpers.setParams({ term })
    });
  }

  postTag(text: any): Observable<any> {
    return this.http.post<any>(this.getTagURL + '/', { text });
  }

  updateTag(id: string, data: any): Observable<any> {
    return this.http.patch<any>(`${this.getTagURL}/${id}`, data);
  }
}

import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import { OzRole, OzSegment } from '@fg-services/navigation-context/segments/oz-segment';

export class OzMenuItems extends BaseMenuItem<OzSegment> {
  menuItems() {
    const items: MenuItem[] = [
      {
        label: 'Featured',
        icon: 'fiber_manual_record',
        order: 10,
        roles: [OzRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Home Page',
            routerLink: [...this.routePath, 'featured', 'web_home'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Exhibit Hall',
            routerLink: [...this.routePath, 'featured', 'exhibit_hall'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Exhibitors',
            routerLink: [...this.routePath, 'featured', 'exhibit_hall_exhibitors'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Events',
            routerLink: [...this.routePath, 'featured', 'events'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Communities',
            routerLink: [...this.routePath, 'featured', 'communities'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Settings',
        icon: 'fiber_manual_record',
        order: 50,
        roles: [OzRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Access',
            routerLink: [...this.routePath, 'settings', 'access'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'ExhibitHall',
            routerLink: [...this.routePath, 'settings', 'exhibit-hall'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Version',
            routerLink: [...this.routePath, 'settings', 'version'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'User Management',
            routerLink: [...this.routePath, 'settings', 'user-management'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Ticket Providers',
            routerLink: [...this.routePath, 'settings', 'ticket-providers'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Library',
        icon: 'fiber_manual_record',
        order: 50,
        roles: [OzRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'events',
            routerLink: [...this.routePath, 'library', 'events'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'guests',
            routerLink: [...this.routePath, 'library', 'guests'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'exhibitors',
            routerLink: [...this.routePath, 'library', 'exhibitors'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'communities',
            routerLink: [...this.routePath, 'library', 'communities'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'categories',
            routerLink: [...this.routePath, 'library', 'categories'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'organizations',
            routerLink: [...this.routePath, 'library', 'organizations'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'color palette',
            routerLink: [...this.routePath, 'library', 'color-palettes'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Advertising',
        icon: 'fiber_manual_record',
        order: 60,
        roles: [OzRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'inventory',
            routerLink: [...this.routePath, 'advertising', 'inventory'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'reports',
            routerLink: [...this.routePath, 'advertising', 'reports'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'partners',
            routerLink: [...this.routePath, 'advertising', 'partners'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'moments',
            routerLink: [...this.routePath, 'advertising', 'moments'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'AR Reveal Assets',
            routerLink: [...this.routePath, 'advertising', 'ar-assets'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'AR Marker',
            routerLink: [...this.routePath, 'advertising', 'ar-markers'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Moderation',
        icon: 'fiber_manual_record',
        order: 70,
        roles: [OzRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'UGC Feeds',
            routerLink: [...this.routePath, 'moderation', 'ugc-all'],
            fatherActive: false,
            childActive: false
          }
        ]
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const labelsToRemove: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    const filteredOptions = this.removeSideNavItems(menuItems, this.context.role, labelsToRemove);
    return this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
  }
}

import { Component, Input } from '@angular/core';
import { ExhibitorHelpers } from '@fg-shared/helpers/exhibitor-helpers';
import { SquareItem } from '@fg-types/square';

@Component({
  selector: 'grid-item-tile',
  templateUrl: './grid-item-tile.component.html',
  styleUrls: ['./grid-item-tile.component.scss']
})
export class GridItemTileComponent {
  @Input() item: SquareItem;
  @Input() width: string;

  get priceString() {
    return ExhibitorHelpers.getPriceRangeString(this.item);
  }

  get image() {
    const image = this.item.media?.[0]?.url ?? this.item?.squareData?.item_data?.image_url;
    return image ? `url(${image})` : undefined;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';

@Component({
  selector: 'search-guest',
  templateUrl: './search-guest.component.html',
  styleUrls: ['./search-guest.component.scss']
})
export class SearchGuestComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() createNewText: string;
  @Input() title = 'Add Guests';
  @Output() addGuest = new EventEmitter<IConventionCelebrity>();
  @Output() queryChange = new EventEmitter<string>();
  filteredGuests: (IConventionCelebrity | string)[] = [];
  guest = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.apiService.search
        .searchCelebrities(params.query, undefined, this.conventionId)
        .subscribe(res => {
          this.filteredGuests = [...res.items, ...(this.createNewText ? [this.createNewText] : [])];
        });
    }
  }
}

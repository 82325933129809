import {
  HttpClient,
  HttpEvent,
  HttpEventType,
  HttpHeaders,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { CmMedia } from '@fg-types/cm-media';
import { Observable, Subject } from 'rxjs/';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class MediaService {
  private mediaURL = `${environment.API_URL + environment.API_VERSION}/Media`;
  private eventSubject: Subject<any>;
  private eventProgress = {
    status: 'pending',
    progress: 0,
    success: {}
  };

  constructor(private http: HttpClient, private localStorageService: LocalStorageService) {
    this.eventSubject = new Subject<number>();
  }

  upLoadMedia(item: any) {
    const options = this.getHeaders();
    return this.http.post(this.mediaURL, item, options);
  }

  uploadUrl(url: string) {
    return this.http.post(this.mediaURL, {
      url
    });
  }

  getHeaders(): any {
    return new HttpHeaders({
      'Content-Type': 'multipart/form-data'
    });
  }

  assetUpload(file: any, fileName: string) {
    const options = this.getHeaders();
    const item: FormData = new FormData();
    item.append('media', file, fileName);
    const req = new HttpRequest('POST', this.mediaURL, item, {
      headers: options.headers,
      reportProgress: true
    });
    this.http.request(req).subscribe((event: HttpEvent<any>) => {
      switch (event.type) {
        case HttpEventType.Sent:
          this.eventProgress = {
            status: 'pending',
            progress: 0,
            success: {}
          };
          this.eventSubject.next(this.eventProgress);
          break;
        case HttpEventType.UploadProgress:
          const percentDone = Math.round((100 * event.loaded) / event.total);
          this.eventProgress = {
            status: 'pending',
            progress: percentDone,
            success: {}
          };
          this.eventSubject.next(this.eventProgress);
          break;
        case HttpEventType.Response:
          this.eventProgress = {
            status: 'finish',
            progress: 100,
            success: event.body
          };
          this.eventSubject.next(this.eventProgress);
      }
    });
  }

  getObservable(): Observable<number> {
    return this.eventSubject.asObservable();
  }

  getMediaById(id: string) {
    return this.http.get<CmMedia>(`${this.mediaURL}/${id}`);
  }

  loadExternalSVG(url: any): Observable<string> {
    return this.http.get<string>(url, {
      headers: new HttpHeaders().set('Accept', 'image/svg+xml'),
      responseType: 'text' as 'json'
    });
  }
}

import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { of } from 'rxjs';

export type FaqSegment = NavigationContextSegment<NavigableModel.Faqs, unknown, ''>;

export const routeToFaqSegment = () =>
  of<FaqSegment>({
    label: ' ',
    subLabel: 'FAQs',
    segmentName: NavigableModel.Faqs,
    model: {},
    role: ''
  });

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IGroupedChallenge } from '@fg-types/grouped-challenge';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'group-challenge-tile',
  template: `
    <tile
      (onClick)="onClick.emit()"
      [active]="active"
      [title]="data?.name"
      [description]="data?.description"
      [media]="data?.conventionPartner?.image || convention?.eventImagePlaceholder"
      [hoverActive]="hoverActive"
    >
      <ng-content></ng-content>
    </tile>
  `
})
export class GroupChallengeTileComponent extends BaseComponent
  implements ListItem<IGroupedChallenge> {
  @Input() data: IGroupedChallenge;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }
}

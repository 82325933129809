import { Component, EventEmitter, Input, Output } from '@angular/core';
import { joinNonEmptyStrings } from '@fg-shared/helpers/join-non-empty-strings';
import { conventionData, formatEventDate } from '@fg-shared/tile/event-card/helpers';
import { IConvention } from '@fg-types/convention.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'event-tile',
  templateUrl: './event-tile.component.html',
  styleUrls: ['./event-tile.component.scss']
})
export class EventTileComponent implements ListItem<IConvention> {
  get conventionData() {
    const date = formatEventDate(this.data);
    const [fullFacility, cityAndState] = conventionData(this.data);
    return [date, fullFacility, cityAndState].filter(c => c).join('<br />');
  }

  @Input() data: IConvention;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  indicatorColorMap = {
    'Admin Only': '#f26b6b',
    Cancelled: '#f26b6b',
    Postponed: '#f26b6b',
    'Coming Soon': '#f0ad4e',
    Rescheduled: '#f0ad4e'
  };
}

import { Component, HostBinding, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@fg-environments/environment';
import { InfoBannerService } from '@fg-services/info-banner.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';

@Component({
  selector: 'web-widget',
  templateUrl: './web-widget.component.html',
  styleUrls: ['./web-widget.component.scss']
})
export class WebWidgetComponent extends BaseComponent {
  @HostBinding('class') classList = 'fg-view-container';
  @ViewChild('textareaWidget', { static: true }) textareaWidget: any;
  showItemsSegment = false;
  iframeSrc: SafeResourceUrl;

  get modelName(): 'schedule' | 'guests' | 'exhibitors' {
    return this.route.snapshot.data.modelName;
  }

  get conventionId() {
    if (!this.convention) return '';
    return this.convention.id;
  }

  get parsedModelName() {
    return this.modelName.replace('-', ' ');
  }

  get embedHTMLCode() {
    const isSchedule = this.modelName === 'schedule';
    return isSchedule
      ? `<iframe
  src="${environment.SCHEDULE_WIDGET_URL(this.conventionId)}"
  width="100%"
  height="600"
  frameborder="0"
></iframe>`
      : `<iframe
  src="${environment.EXHIBITOR_WIDGET_URL(this.conventionId, this.showItemsSegment)}"
  width="100%"
  height="600"
  frameborder="0"
  allow="camera;microphone"
></iframe>`;
  }

  constructor(
    private route: ActivatedRoute,
    private uiService: UiService,
    private domSanitizer: DomSanitizer,
    public infoBannerService: InfoBannerService,
    navigationContextService: NavigationContextService
  ) {
    super(navigationContextService);
    infoBannerService.configureBannerContent('essentialsWebWidgets');
  }

  onContextChange = () => {
    if (this.convention) {
      const isSchedule = this.modelName === 'schedule';
      this.iframeSrc = isSchedule
        ? this.domSanitizer.bypassSecurityTrustResourceUrl(
            environment.SCHEDULE_WIDGET_URL(this.conventionId)
          )
        : this.domSanitizer.bypassSecurityTrustResourceUrl(
            environment.EXHIBITOR_WIDGET_URL(this.conventionId, this.showItemsSegment)
          );
    }
  };

  copyConventionLink(item) {
    item.select();
    document.execCommand('copy');
    this.uiService.toast({
      severity: 'success',
      summary: 'Copied',
      detail: 'The code was copied to the clipboard!'
    });
  }
}

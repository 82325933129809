<p-card header="Associated Schedule Events">
  <label class="p-my-3 p-d-inline-block">
    Search to add schedule events
    <tooltip-button
      tooltip="It's important to add your guest's associated schedule events for your attendee's user experience. Doing so will render the associated schedule events on your guest's profile within the mobile app and website so attendees can customize their schedule around particular guests."
    ></tooltip-button>
  </label>
  <div class="p-grid">
    <div class="p-col-12">
      <search-schedule-event
        [disabled]="disabled"
        [conventionId]="conventionId"
        (addEvent)="addEvent.emit($event)"
      ></search-schedule-event>
    </div>
    <div class="p-col-12">
      <schedule-event-tile *ngFor="let event of events; let i = index" [data]="event">
        <i
          class="pi pi-times pointer"
          (click)="deleteEvent.emit({ event: event, index: i })"
          *ngIf="!disabled"
        ></i>
      </schedule-event-tile>
    </div>
    <div class="clearfix"></div>
  </div>
</p-card>

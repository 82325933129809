import { Component, Input } from '@angular/core';
import { faqs } from './frequently-asked-questions';

@Component({
  selector: 'fg-faqs',
  templateUrl: './faqs.component.html',
  styleUrls: ['./faqs.component.scss']
})
export class FaqsComponent {
  faqs = faqs;
}

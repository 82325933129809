import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'loading-list-item',
  templateUrl: './loading-list-item.component.html',
  styleUrls: ['./loading-list-item.component.scss']
})
export class LoadingListItemComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';

@Component({
  selector: 'fg-google-autocomplete',
  templateUrl: './google-autocomplete.component.html',
  styleUrls: ['./google-autocomplete.component.scss']
})
export class GoogleAutocompleteComponent {
  @ViewChild('placesRef') placesRef: GooglePlaceDirective;
  @Input() adressType = '';
  @Input() inputClass = '';
  @Input() disabled: boolean;
  @Input() placeholder = '';
  @Input() name = '';
  @Input() autofocus: boolean;
  @Input() required: boolean;
  @Input() label = '';
  @Input() tooltip: string;
  @Input() autocompleteInput: string;
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Output() delete: EventEmitter<void> = new EventEmitter();

  handleChange(autocompleteInput) {
    if (!autocompleteInput) this.delete.emit();
  }
}

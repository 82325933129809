import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { CmMedia } from '@fg-types/cm-media';
import { Organization } from '@fg-types/organization.type';
import { mergeMap } from 'rxjs/operators';

@Component({
  selector: 'fg-new-org-form',
  templateUrl: './fg-new-org-form.component.html',
  styleUrls: ['./fg-new-org-form.component.scss']
})
export class FgNewOrgFormComponent implements OnInit {
  @Output() cancel = new EventEmitter();
  org: Partial<Organization> = {};
  eventType: string;
  constructor(
    private apiCallsInProgress: ApiCallsInProgress,
    private api: ApiService,
    private uiService: UiService,
    private router: Router,
    public userService: UserService
  ) {}

  ngOnInit(): void {}
  setImage(image: CmMedia) {
    this.org.headerMedia = image;
    this.org.headerMediaId = image.id;
  }

  deleteImage() {
    this.org.headerMedia = null;
    this.org.headerMediaId = null;
  }

  saveConvention() {
    this.apiCallsInProgress.addCall();
    let org: Organization;
    this.api.organizations
      .create(this.org)
      .pipe(
        mergeMap(o => {
          org = o;
          return this.userService.loadRoles();
        })
      )
      .subscribe(() => {
        this.apiCallsInProgress.reset();
        this.router.navigate(['bs', 'o', org.id]);
      }, this.uiService.errorHandler);
  }
}

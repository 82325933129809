import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@fg-services/api/api.service';
import { UserRoles } from '@fg-types/roles.type';
import { NotificationPreferernces, UserPreferences } from '@fg-types/user-preferences';
import { IUser, IUserLoginCredentials } from '@fg-types/user.type';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import * as URI from 'urijs';
import { LocalStorageService } from './localstorage.service';
import { CreatorService, CreatorSubsription } from './dummy.service';

@Injectable()
export class UserService {
  public userId: string;
  public token: string;
  private _user: BehaviorSubject<IUser> = new BehaviorSubject<IUser>(null);
  private _roles: BehaviorSubject<UserRoles> = new BehaviorSubject<UserRoles>(null);
  private _userPreferences: BehaviorSubject<UserPreferences> = new BehaviorSubject<UserPreferences>(
    null
  );
  private _notificationPreferences: BehaviorSubject<NotificationPreferernces> = new BehaviorSubject<NotificationPreferernces>(
    null
  );
  lastKey: any;

  get userPreferences() {
    return this._userPreferences.value;
  }

  set userPreferences(userPreferences: UserPreferences) {
    this._userPreferences.next(userPreferences);
  }

  get notificationPreferences() {
    return this._notificationPreferences.value;
  }

  set notificationPreferences(notificationPreferences: NotificationPreferernces) {
    this._notificationPreferences.next(notificationPreferences);
  }

  get user() {
    return this._user.value;
  }

  set user(user: IUser) {
    this._user.next(user);
  }

  get roles() {
    return this._roles.value;
  }

  set roles(roles: UserRoles) {
    this._roles.next(roles);
  }

  constructor(
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private router: Router,
    private Creatorservice: CreatorService,
  ) {
    /**
     *  It is possible to be transferred from another site (ex. https://fan.guru). If already
     * logged in there, a userId/token will be provided as a url query. If the user is not already
     * logged on here, we will set those values before authenticating.
     */
    const query: { token?: string; userId?: string } = URI.parseQuery(location.search);
    if (!this.localStorageService.getItem('userId') && query.userId) {
      this.localStorageService.setItem('userId', query.userId);
    }
    if (!this.localStorageService.getItem('token') && query.token) {
      this.localStorageService.setItem('token', query.token);
    }
    this.token = this.localStorageService.getItem('token');
    this.userId = this.localStorageService.getItem('userId');
    if (this.token) {
      this.loadAuthenticatedUser().subscribe(
        () => {this.loadCreators()},
        ({ error }) => {
          if (error?.error?.statusCode === 401) {
            this.router.navigate(['landing']);
            this.logout().subscribe();
          }
        }
      );
    }
  }

  getUserPreferences() {
    this.apiService.users.getUserPreferences().subscribe((userPreferences: UserPreferences) => {
      this.userPreferences = userPreferences;
    });
  }

  getNotificationPreferences() {
    this.apiService.users.getNotificationPreferences().subscribe((notificationPreferences: NotificationPreferernces) => {
      this.notificationPreferences = notificationPreferences;
    });
  }

  updateUserPreferences(changes) {
    this.apiService.users
      .updateUserPreferences(changes)
      .subscribe((userPreferences: UserPreferences) => {
        this.userPreferences = userPreferences;
      });
  }

  updateNotificationPreferences(changes) {
    this.apiService.users
      .updateNotificationPreferences(changes)
      .subscribe((notificationPreferences: NotificationPreferernces) => {
        this.notificationPreferences = notificationPreferences;
      });
  }

  getUserPreferencesAsObservable() {
    return this._userPreferences.asObservable();
  }

  getUserAsObservable() {
    return this._user.asObservable();
  }

  getRolesAsObservable() {
    return this._roles.asObservable();
  }

  login(credentials: IUserLoginCredentials) {
    return this.apiService.users
      .login(credentials)
      .pipe(switchMap((data: IUser) => this.onLoginSuccess(data)));
  }

  fbLogin(token: string) {
    return this.apiService.users.fbLogin(token);
  }

  appleLogin(token: string) {
    return this.apiService.users.appleLogin(token);
  }

  onLoginSuccess(data: IUser) {
    this.user = data;
    const token = data.accessToken.id;
    this.localStorageService.setItem('token', token);
    this.localStorageService.setItem('userId', data.id);
    this.token = token;
    this.userId = data.id;
    this.roles = null;
    this.loadCreators()
    return this.loadRoles();
  }

  loadCreators(){
    this.apiService.users.userCreators(this.user.id,this.lastKey).subscribe(({items,lastKey})=>{
      const creators= this.lastKey ?[...items] : items;
      this.lastKey = lastKey;
      if (creators.length > 0) {
            this.Creatorservice.updateCurrentCreator(creators.slice(-1)[0]);
            this.localStorageService.setItem('creator',creators.slice(-1)[0].id)
      }
      this.Creatorservice.updateUserCreatorsList(creators.reverse());
    })
  }
  impersonate(data: IUser) {
    this.localStorageService.setItem('prevToken', this.token);
    this.localStorageService.setItem('prevUserId', this.userId);
    this.localStorageService.setItem('prevUser', this.user);
    return this.onLoginSuccess(data);
  }

  endImpersonation() {
    const prevUser = {
      ...this.localStorageService.getItem('prevUser'),
      id: this.localStorageService.getItem('prevUserId'),
      accessToken: { id: this.localStorageService.getItem('prevToken') }
    };
    this.localStorageService.removeItem('prevToken');
    this.localStorageService.removeItem('prevUserId');
    this.localStorageService.removeItem('prevUser');
    this.onLoginSuccess(prevUser);
  }

  logout() {
    return this.apiService.users.logout().pipe(
      catchError(() => {
        this.clear();
        return of(null);
      }),
      tap(() => {
        this.clear();
      })
    );
  }

  clear() {
    this.localStorageService.removeItem('token');
    this.localStorageService.removeItem('userId');
    this.localStorageService.removeItem('username');
    this.localStorageService.removeItem('lastTodayId');
    this.localStorageService.removeItem('role');
    this.localStorageService.removeItem('isAdmin');
    this.localStorageService.removeItem('creator');
    this.Creatorservice.updateCurrentCreator({});
    this.Creatorservice.updateUserCreatorsList([]);
    this.user = null;
    this.roles = null;
    this.userId = null;
    this.token = null;
  }

  isAuthenticated() {
    return new Observable<boolean>(subscriber => {
      if (!this.token || !this.userId) {
        subscriber.next(false);
        subscriber.complete();
      }
      if (this.roles) {
        subscriber.next(true);
        subscriber.complete();
        return;
      }
      this.loadRoles().subscribe(
        () => {
          subscriber.next(true);
          subscriber.complete();
        },
        err => subscriber.error(err)
      );
    });
  }

  isBsAllowed() {
    return new Observable<boolean>(subscriber => {
      if (!this.token || !this.userId) {
        subscriber.next(false);
        subscriber.complete();
      }
      if (this.roles) {
        subscriber.next(this.isRoleAllowed());
        subscriber.complete();
        return;
      }
      this.loadRoles().subscribe(
        () => {
          subscriber.next(this.isRoleAllowed());
          subscriber.complete();
        },
        err => subscriber.error(err)
      );
    });
  }

  isOzAllowed() {
    return new Observable<boolean>(subscriber => {
      if (!this.token || !this.userId) {
        subscriber.next(false);
        subscriber.complete();
      }
      if (this.roles) {
        subscriber.next(this.roles.isAdmin);
        subscriber.complete();
        return;
      }
      this.loadRoles().subscribe(
        () => {
          subscriber.next(this.roles.isAdmin);
          subscriber.complete();
        },
        err => subscriber.error(err)
      );
    });
  }

  loadRoles() {
    if (!this.userId) return of<UserRoles>(null);
    return this.apiService.users.getRoles(this.userId).pipe(
      tap(roles => {
        this.roles = roles;
      })
    );
  }

  isRoleAllowed() {
    if (!this.roles) {
      this.clear();
      return false;
    }
    // Roles that can access backstage
    const {
      isAdmin,
      isConAdmin,
      isConMod,
      isFandomAdmin,
      isFandomMod,
      isOrganizationAdmin,
      isOrganizationMod
    } = this.roles;
    return (
      isAdmin ||
      isConAdmin ||
      isConMod ||
      isFandomAdmin ||
      isFandomMod ||
      isOrganizationAdmin ||
      isOrganizationMod
    );
  }

  loadAuthenticatedUser() {
    return this.apiService.users.authenticated().pipe(
      tap(val => {
        this.user = val;
      })
    );
  }
}

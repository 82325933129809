import { Injectable } from "@angular/core";
import { ContestPost, CreatorContest } from "@fg-types/creator-contest";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
  
export class ContestService{
    public CreatorContestlist: Subject<CreatorContest[]> = new BehaviorSubject<CreatorContest[]>([]);
    private currentContest: BehaviorSubject<CreatorContest> = new BehaviorSubject<CreatorContest>({});
  
    constructor() {}
    get userContestList():Observable<CreatorContest[] | null>{
      return this.CreatorContestlist.asObservable()
    }
    updateContestList(newContest: CreatorContest[]){
      return this.CreatorContestlist.next(newContest);
    }
    get currentContest$() {
      return this.currentContest.asObservable();
    }
  
    updateCurrentContest(newContest: CreatorContest) {
      this.currentContest.next(newContest);
    }
  }

  export class ContestDataService { 
  ContestData =[
    {
      id:'1',
      title: 'Fan Fest throwBack Thursday',
      description: 'Remember when...',
      createdAt: '2024-02-23T09:22:03.041Z',
      username:'Nezuko',
      role:'VIP Member',
      isStatus: '',
      media:'assets/layout/images/guitar-back.jpg',
      profilePhoto:'assets/layout/images/guitar-back.jpg',
    },
    {
      id:'2',
      title: 'Entry contest',
      description: 'Remember when...',
      createdAt: '2024-02-16T05:27:57.662Z',
      username:'Tomakayo',
      role:'Membership',
      isStatus: '',
      profilePhoto:'assets/layout/images/Nabi.jpg'
    },
    {
      id:'3',
      title: 'JIMIN : CLOSER THAN THIS',
      description: 'All fans can use their vote for "closer than this" song ...',
      createdAt: '2024-01-22T11:32:19.307Z',
      username:'Jimin',
      role:'Membership',
      isStatus: '',
      profilePhoto:'assets/layout/images/Tier_1_1.png'
    }
  ]
  
  generateDummyData() {
    return this.ContestData;
  }
}
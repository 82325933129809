import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FileUploaderComponent } from '@fg-shared/layout/file-uploader/file-uploader.component';
import { LoadingComponent } from '@fg-shared/layout/loading/loading.component';
import { SortTableComponent } from '@fg-shared/layout/sort-table/sort-table.component';

// ImageCropper components
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatTooltipModule } from '@angular/material/tooltip';
import { CategoryListComponent } from '@fg-shared/layout/category-list/category-list.component';
import { CharsLimitComponent } from '@fg-shared/layout/chars-limit/chars-limit.component';
import { ConnectedEventsComponent } from '@fg-shared/layout/connected-events/connected-events.component';
import { ConnectedGuestsComponent } from '@fg-shared/layout/connected-guests/connected-guests.component';
import { ContentFilterComponent } from '@fg-shared/layout/content-filter/content-filter.component';
import { CreateModalComponent } from '@fg-shared/layout/create-event/create-modal.component';
import { DeeplinkComponent } from '@fg-shared/layout/deeplink/deeplink.component';
import { FgEditableListComponent } from '@fg-shared/layout/fg-editable-list/fg-editable-list.component';
import { FilterIconComponent } from '@fg-shared/layout/filter-icon/filter-icon.component';
import { FlatListComponent } from '@fg-shared/layout/flat-list/flat-list.component';
import { ListItemComponent } from '@fg-shared/layout/flat-list/list-item.component';
import { InfoBannerComponent } from '@fg-shared/layout/info-banner/info-banner.component';
import { LoadingEventCardComponent } from '@fg-shared/layout/loading-event-card/loading-event-card.component';
import { LoadingListItemComponent } from '@fg-shared/layout/loading-list-item/loading-list-item.component';
import { MobileDetailHeaderComponent } from '@fg-shared/layout/mobile-detail-header/mobile-detail-header.component';
import { MultiSelectRadioComponent } from '@fg-shared/layout/multi-select-radio/multi-select-radio.component';
import { OrderListComponent } from '@fg-shared/layout/order-list/order-list.component';
import { OverlayFilterComponent } from '@fg-shared/layout/overlay-filter/overlay-filter.component';
import { RouterTabsContainerComponent } from '@fg-shared/layout/router-tabs-container/router-tabs-container.component';
import { RouterTabsComponent } from '@fg-shared/layout/router-tabs/router-tabs.component';
import { SaveLocalToGlobalComponent } from '@fg-shared/layout/save-local-to-global/save-local-to-global.component';
import { StripedLabelComponent } from '@fg-shared/layout/striped-label/striped-label.component';
import { TooltipButtonComponent } from '@fg-shared/layout/tooltip-button/tooltip-button.component';
import { WebWidgetComponent } from '@fg-shared/layout/web-widget/web-widget.component';
import { UploaderModule } from '@fg-shared/uploader/uploader.module';
import { GooglePlaceModule } from 'ngx-google-places-autocomplete';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ColorPickerModule } from 'primeng/colorpicker';
import { DataViewModule } from 'primeng/dataview';
import { DropdownModule } from 'primeng/dropdown';
import { FileUploadModule } from 'primeng/fileupload';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ToastModule } from 'primeng/toast';
import { ReplacePipe } from './pipes/replace.pipe';
import { GoogleAutocompleteComponent } from './google-autocomplete/google-autocomplete.component';
import { ViewHostDirective } from './directives/view-host.directive';
import { PrimeDragulaDirective } from './directives/prime-dragdrop.directive';
import { InfiniteScrollDirective } from './directives/infinite-scroll.directive';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CustomConfirmDialogModule } from './custom-confirm-dialog/custom-confirm-dialog.module';
import { FaqsComponent } from './faqs/faqs.component';
import { FgCardComponent } from './fg-card/fg-card.component';
import { FgDatePickerComponent } from './fg-date-picker/fg-date-picker.component';
import { FgSpinnerComponent } from './fg-spinner/fg-spinner.component';
import { FgCategoryFormComponent } from './layout/fg-category-form/fg-category-form.component';
import { FgOfficialAccountComponent } from './layout/fg-official-account/fg-official-account.component';
import { OrderListGridComponent } from './layout/order-list-grid/order-list-grid.component';
import { SearchModule } from './search/search.module';
import { TileModule } from './tile/tile.module';
import { FilterEventsComponent } from './layout/filter-events/filter-events.component';

@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    RouterModule,
    CustomConfirmDialogModule,
    FormsModule,
    ButtonModule,
    DataViewModule,
    FileUploadModule,
    InputTextModule,
    InputSwitchModule,
    DropdownModule,
    ColorPickerModule,
    CheckboxModule,
    CardModule,
    AutoCompleteModule,
    InputMaskModule,
    OverlayPanelModule,
    RadioButtonModule,
    TileModule,
    ToastModule,
    SearchModule,
    DragDropModule,
    MatTooltipModule,
    UploaderModule,
    PanelModule,
    CarouselModule,
    GooglePlaceModule
  ],
  declarations: [
    ReplacePipe,
    LoadingComponent,
    PrimeDragulaDirective,
    GoogleAutocompleteComponent,
    FilterEventsComponent,
    FileUploaderComponent,
    InfiniteScrollDirective,
    ConfirmationDialogComponent,
    FgSpinnerComponent,
    MultiSelectRadioComponent,
    FgDatePickerComponent,
    CharsLimitComponent,
    RouterTabsComponent,
    StripedLabelComponent,
    ListItemComponent,
    ViewHostDirective,
    FlatListComponent,
    OrderListComponent,
    OrderListGridComponent,
    ConnectedEventsComponent,
    ConnectedGuestsComponent,
    MobileDetailHeaderComponent,
    TooltipButtonComponent,
    FilterIconComponent,
    OverlayFilterComponent,
    CategoryListComponent,
    FgEditableListComponent,
    ContentFilterComponent,
    DeeplinkComponent,
    RouterTabsContainerComponent,
    FgCardComponent,
    SortTableComponent,
    LoadingListItemComponent,
    SaveLocalToGlobalComponent,
    CreateModalComponent,
    WebWidgetComponent,
    InfoBannerComponent,
    LoadingEventCardComponent,
    FaqsComponent,
    FgOfficialAccountComponent,
    FgCategoryFormComponent
  ],
  exports: [
    LoadingComponent,
    MultiSelectRadioComponent,
    PrimeDragulaDirective,
    GoogleAutocompleteComponent,
    FilterEventsComponent,
    FileUploaderComponent,
    ReplacePipe,
    InfiniteScrollDirective,
    ConfirmationDialogComponent,
    FgSpinnerComponent,
    FgDatePickerComponent,
    CharsLimitComponent,
    RouterTabsComponent,
    StripedLabelComponent,
    FlatListComponent,
    OrderListComponent,
    OrderListGridComponent,
    ConnectedEventsComponent,
    ConnectedGuestsComponent,
    MobileDetailHeaderComponent,
    TooltipButtonComponent,
    FilterIconComponent,
    OverlayFilterComponent,
    ViewHostDirective,
    DeeplinkComponent,
    RouterTabsContainerComponent,
    CategoryListComponent,
    FgEditableListComponent,
    ContentFilterComponent,
    ListItemComponent,
    SortTableComponent,
    TileModule,
    SearchModule,
    FgCardComponent,
    ToastModule,
    UploaderModule,
    LoadingListItemComponent,
    SaveLocalToGlobalComponent,
    CreateModalComponent,
    InfoBannerComponent,
    DragDropModule,
    LoadingEventCardComponent,
    FaqsComponent,
    FgOfficialAccountComponent,
    FgCategoryFormComponent
  ],
  providers: []
})
export class SharedModule {}

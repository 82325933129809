<p-overlayPanel #overlayPanel appendTo="body" [showCloseIcon]="true">
  <div class="filter-container">
    <div class="accent-header-style">{{ title }}</div>
    <div class="filter-sections">
      <div class="filter-section">
        <div class="p-col-12" *ngFor="let value of values">
          <p-radioButton
            name="filter"
            [value]="value.value"
            [label]="value.label"
            [(ngModel)]="selectedValue"
            (onClick)="emit()"
          ></p-radioButton>
        </div>
      </div>
    </div>
    <div class="actions">
      <button
        pButton
        class="p-button-secondary"
        type="button"
        label="RESET FILTERS"
        (click)="reset()"
      ></button>
      <span class="spacer"></span>
      <button
        pButton
        type="button"
        label="APPLY"
        class="p-button-primary"
        (click)="emit()"
      ></button>
    </div>
  </div>
</p-overlayPanel>

import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

@Component({
  selector: 'create-modal',
  templateUrl: './create-modal.component.html',
  styleUrls: ['./create-modal.component.scss']
})
export class CreateModalComponent {
  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;
  @Output() onCreate = new EventEmitter<string>();
  @Input() modelName: string;
  name: string;

  show(event: Event, name = '') {
    this.name = name;
    this.overlayPanel.show(event);
  }

  emit() {
    this.onCreate.emit(this.name);
    this.overlayPanel.hide();
  }
}

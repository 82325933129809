<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="name"
  [description]="data?.bio"
  [media]="imageUrl"
  [hoverActive]="hoverActive"
  categoryType="celebrityTypes"
  [types]="data?.types"
  [maxLines]="2"
>
  <ng-content></ng-content>
</tile>

<div
  class="tile"
  [ngClass]="tileType"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  [style.height]="height"
  (click)="onClick.emit()"
>
  <div *ngIf="image" class="tile-media" [style.backgroundImage]="image"></div>
  <div class="tile-right" [style.borderLeft]="stripeColor ? '5px ' + stripeColor + ' solid' : ''">
    <div class="body-container">
      <div class="tile-body">
        <div
          class="tile-title truncate-overflow"
          [ngClass]="'truncate-' + (5 - maxLines)"
          multilineDotdotdot
        >
          {{ title }}
        </div>
        <div *ngIf="showDescription(description)" class="tile-description">
          <div
            [ngClass]="{ 'truncate-overflow': truncate }"
            [class]="maxLines ? 'truncate-' + maxLines : ''"
            multilineDotdotdot
          >
            <div [innerHTML]="description"></div>
          </div>
        </div>
        <div
          class="tile-description"
          [style.display]="bodySpan?.childNodes?.length > 0 ? 'flex' : 'none'"
        >
          <ng-container>
            <span #bodySpan class="body-span">
              <ng-content select="[role=body]"></ng-content>
            </span>
          </ng-container>
        </div>
      </div>
      <div
        class="right-content"
        [style.display]="contentSpan?.childNodes?.length > 0 ? 'flex' : 'none'"
      >
        <ng-container
          ><span #contentSpan><ng-content></ng-content></span
        ></ng-container>
      </div>
    </div>
    <div *ngIf="categories?.length" class="category-container">
      <div
        *ngFor="let category of categories"
        class="striped-label"
        [style.borderLeftColor]="category.color"
      >
        {{ category.name }}
      </div>
    </div>
  </div>
  <div class="managed-icons">
    <img
      class="managed-icon"
      *ngIf="isSquareConnected"
      src="https://fan-guru-assets.s3.amazonaws.com/console/Icon-SellingBadge.png"
      matTooltip="This profile is managed by the Exhibitor."
    />
    <img
      class="managed-icon"
      *ngIf="isManaged"
      src="https://fan-guru-assets.s3.amazonaws.com/console/MANAGED_EXHIBITOR_ICON.png"
      matTooltip="This profile is managed by the Exhibitor."
    />
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

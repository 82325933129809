import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AdPartnerApi } from '@fg-services/api/models/adPartner.api';
import { AdvertisementsApi } from '@fg-services/api/models/advertisement.service';
import { ARAssetsApi } from '@fg-services/api/models/arAssets';
import { ARMarkersApi } from '@fg-services/api/models/arMarkers';
import { CategoryApi } from '@fg-services/api/models/category.api';
import { ConfigApi } from '@fg-services/api/models/config.api';
import { ConventionCelebrityApi } from '@fg-services/api/models/convention-celebrity.api';
import { ConventionContestsApi } from '@fg-services/api/models/convention-contests-api';
import { ConventionEventApi } from '@fg-services/api/models/convention-event.api';
import { ConventionExhibitorRequestApi } from '@fg-services/api/models/convention-exhibitor-request.api';
import { ConventionExhibitorSpecialApi } from '@fg-services/api/models/convention-exhibitor-special.api';
import { ConventionApi } from '@fg-services/api/models/convention.api';
import { ConventionHiddenModelsApi } from '@fg-services/api/models/conventionHiddenModels';
import { ExhibitorApi } from '@fg-services/api/models/exhibitor.api';
import { FandomApi } from '@fg-services/api/models/fandom.api';
import { FeedApi } from '@fg-services/api/models/feed.api';
import { GlobalGuestApi } from '@fg-services/api/models/guest.service';
import { ManagerApi } from '@fg-services/api/models/manager.api';
import { ModelTodayApi } from '@fg-services/api/models/model-today.api';
import { ModelUserApi } from '@fg-services/api/models/model-user.api';
import { ModulesApi } from '@fg-services/api/models/modules-api';
import { MomentApi } from '@fg-services/api/models/moment.api';
import { OrganizationApi } from '@fg-services/api/models/organization.api';
import { PaymentsApi } from '@fg-services/api/models/payments.api';
import { ReferralsApi } from '@fg-services/api/models/referrals.api';
import { RoleApi } from '@fg-services/api/models/role.service';
import { SearchApi } from '@fg-services/api/models/search.api';
import { ShippingMethodApi } from '@fg-services/api/models/shipping-method.api';
import { ShippingZoneApi } from '@fg-services/api/models/shipping-zone.api';
import { SquareAccountApi } from '@fg-services/api/models/square-account.api';
import { SquareCatalogApi } from '@fg-services/api/models/square-catalog.api';
import { SquareIntegrationApi } from '@fg-services/api/models/square-integration.api';
import { SurveyApi } from '@fg-services/api/models/survey.api';
import { TodayApi } from '@fg-services/api/models/today.api';
import { UserApi } from '@fg-services/api/models/user.api';
import { ColorPalette } from './models/color-palettes.api';
import { CodesApi } from './models/convention-event.api copy';
import { CuratedApi } from './models/curated.api';
import { InvitesApi } from './models/invites.api';
import { SubscriptionBaseApi } from './models/subscriptionBase.api';
import { CreatorApi } from './models/creator.api';
import { CreatorStripeApi } from './models/creator-stripe.api';
import { CreatorPromoApi } from './models/creator-promo.api';
import { StripePaymentsApi } from './models/stripe-payments.api';
import { StripeRefundsApi } from './models/stripe-refund.api';
import { CreatorContestApi } from './models/creator-contest.api';

@Injectable()
export class ApiService {
  config = new ConfigApi(this.http);
  conventionCelebrity = new ConventionCelebrityApi(this.http);
  conventionContests = new ConventionContestsApi(this.http);
  conventionEvent = new ConventionEventApi(this.http);
  conventionExhibitorRequest = new ConventionExhibitorRequestApi(this.http);
  conventionExhibitorSpecial = new ConventionExhibitorSpecialApi(this.http);
  conventions = new ConventionApi(this.http);
  exhibitors = new ExhibitorApi(this.http);
  fandoms = new FandomApi(this.http);
  feed = new FeedApi(this.http);
  globalGuest = new GlobalGuestApi(this.http);
  manager = new ManagerApi(this.http);
  modelToday = new ModelTodayApi(this.http);
  today = new TodayApi(this.http);
  modelUser = new ModelUserApi(this.http);
  modules = new ModulesApi(this.http);
  organizations = new OrganizationApi(this.http);
  payments = new PaymentsApi(this.http);
  referral = new ReferralsApi(this.http);
  search = new SearchApi(this.http);
  shippingMethod = new ShippingMethodApi(this.http);
  shippingZone = new ShippingZoneApi(this.http);
  squareAccount = new SquareAccountApi(this.http);
  squareIntegration = new SquareIntegrationApi(this.http);
  squareCatalog = new SquareCatalogApi(this.http);
  survey = new SurveyApi(this.http);
  users = new UserApi(this.http);
  roles = new RoleApi(this.http);
  ads = new AdvertisementsApi(this.http);
  adPartner = new AdPartnerApi(this.http);
  moment = new MomentApi(this.http);
  arAssets = new ARAssetsApi(this.http);
  arMarkers = new ARMarkersApi(this.http);
  categories = new CategoryApi(this.http);
  conventionHiddenModels = new ConventionHiddenModelsApi(this.http);
  curated = new CuratedApi(this.http);
  colorPalette = new ColorPalette(this.http);
  codes = new CodesApi(this.http);
  invites = new InvitesApi(this.http);
  subscriptionBase = new SubscriptionBaseApi(this.http);
  creator = new CreatorApi(this.http);
  creatorStripeProduct = new CreatorStripeApi(this.http);
  stripePaymentsApi = new StripePaymentsApi(this.http);
  creatorPromoApi = new CreatorPromoApi(this.http);
  stripeRefundsApi = new StripeRefundsApi(this.http);
  creatorContestApi = new CreatorContestApi(this.http);

  constructor(private http: HttpClient) {}
}

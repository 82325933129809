<p-panel header="Associated Guests">
  <div class="p-pt-2">
    <label>Search to add guests</label>
    <div class="p-grid p-pt-2">
      <div class="p-col-12">
        <search-guest
          [disabled]="disabled"
          [conventionId]="conventionId"
          (addGuest)="addGuest.emit($event)"
        ></search-guest>
      </div>
      <div class="p-col-12">
        <guest-tile *ngFor="let guest of guests; let i = index" [data]="guest">
          <i
            class="pi pi-times pointer"
            (click)="deleteGuest.emit({ guest: $event, index: i })"
            *ngIf="!disabled"
          ></i>
        </guest-tile>
      </div>
      <div class="clearfix"></div>
    </div>
  </div>
</p-panel>

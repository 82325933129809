import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { Observable, of } from 'rxjs';

export enum OzRole {
  Admin = 'admin'
}

export type OzSegment = NavigationContextSegment<NavigableModel.OZ, unknown, OzRole>;

export const routeToOzSegment = (): Observable<OzSegment> =>
  of({
    label: ' ',
    subLabel: 'OZ',
    segmentName: NavigableModel.OZ,
    model: {},
    role: OzRole.Admin
  });

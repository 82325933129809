import { ApiBase } from '@fg-services/api/models/api-base';
import { Category } from '@fg-types/category';
import { Community, FandomConvention } from '@fg-types/community';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { IConvention } from '@fg-types/convention.type';
import { DeleteResponse } from '@fg-types/delete-response';
import { GlobalGuest } from '@fg-types/global-guest';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IPost } from '@fg-types/post';
import { UserType } from '@fg-types/user.type';

export class FandomApi extends ApiBase {
  model = 'Fandoms';

  /** General */
  deleteFandom(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  deleteCommunityCommunities(id: string, associatedId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}/Fandoms/${associatedId}`);
  }

  patchCommunity(community: Partial<Community>) {
    return this.http.patch<Community>(`${this.modelBase}/${community.id}`, community);
  }

  communities(lastKey?: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/`, {
      params: this.setParams({ lastKey })
    });
  }

  communityCommunities(id: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/${id}/Fandoms`);
  }

  getFandomById(id: string) {
    return this.http.get<Community>(`${this.modelBase}/${id}`);
  }

  create(body: Partial<Community>) {
    return this.http.post<Community>(this.modelBase, body);
  }

  addCommunityCommunity(communityId: string, associatedCommunityId: string) {
    return this.http.put<GlobalGuest>(
      `${this.modelBase}/${communityId}/Fandoms/${associatedCommunityId}`,
      {}
    );
  }

  /** Conventions */
  conventions(id: string, lastKey?: string, isOwner?: boolean) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/${id}/Conventions`, {
      params: this.setParams({ lastKey, isOwner })
    });
  }

  putFandomConvention(id: string, conventionId: string, body: { isOwner?: boolean } = {}) {
    return this.http.put<any>(`${this.modelBase}/${id}/Conventions/${conventionId}`, body);
  }

  patchFandomConvention(id: string, body: Partial<FandomConvention>) {
    return this.http.patch<Community>(`${this.apiBase}/FandomConventions/${id}`, body);
  }

  deleteFandomConvention(id: string, conventionId: string) {
    return this.http.delete<void>(`${this.modelBase}/${id}/Conventions/${conventionId}`);
  }

  /** Categories */
  categories(id: string) {
    return this.http.get<PaginatedResponse<Category>>(`${this.modelBase}/${id}/Categories`);
  }

  deleteFandomCategories(id: string, exhibitorId: string) {
    return this.http.delete<void>(`${this.modelBase}/${id}/Categories/${exhibitorId}`);
  }

  addCategoryCommunity(communityId: string, categoryId: string) {
    return this.http.put<GlobalGuest>(
      `${this.modelBase}/${communityId}/Categories/${categoryId}`,
      {}
    );
  }

  /** Guests/Celebrities */
  addGuestToFandom(communityId: string, guestId: string) {
    return this.http.put<GlobalGuest>(
      `${this.modelBase}/${communityId}/Celebrities/${guestId}`,
      {}
    );
  }

  guests(id: string) {
    return this.http.get<PaginatedResponse<GlobalGuest>>(`${this.modelBase}/${id}/Celebrities`);
  }

  deleteFandomGuests(communityId: string, guestId: string) {
    return this.http.delete<DeleteResponse>(
      `${this.modelBase}/${communityId}/Celebrities/${guestId}`,
      {}
    );
  }

  /** Exhibitors */
  addExhibitorToFandom(communityId: string, exhibitorId: string) {
    return this.http.put<ConventionExhibitor>(
      `${this.modelBase}/${communityId}/Exhibitors/${exhibitorId}`,
      {}
    );
  }

  exhibitors(id: string) {
    return this.http.get<PaginatedResponse<ConventionExhibitor>>(
      `${this.modelBase}/${id}/Exhibitors`
    );
  }

  putFandomExhibitor(id: string, exhibitorId: string, body: { isOwner?: boolean } = {}) {
    return this.http.put<any>(`${this.modelBase}/${id}/Exhibitors/${exhibitorId}`, body);
  }

  deleteFandomExhibitor(id: string, exhibitorId: string) {
    return this.http.delete<void>(`${this.modelBase}/${id}/Exhibitors/${exhibitorId}`);
  }

  /**Creators */
  getCreatorFandoms(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/Creator/${id}`, {
      params: this.setParams({ lastKey })
    });
  }

  getCommunityMembers(fandomId: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<UserType>>(`${this.modelBase}/${fandomId}/users`,{
      params : this.setParams({lastKey})
    })
  }
  /** Feeds */
  posts(fandomId: string, lastKey?: string, postTypes?: string, order?: string) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/${fandomId}/Posts`, {
      params: this.setParams({
        postTypes,
        order,
        lastKey
      })
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FeaturedItem } from '@fg-types/convention-today.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'featured-item-tile',
  templateUrl: './featured-item-tile.component.html'
})
export class FeaturedItemTileComponent implements ListItem<FeaturedItem> {
  @Input() data: FeaturedItem;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConventionEvent } from '@fg-types/convention-event';

@Component({
  selector: 'connected-events',
  templateUrl: './connected-events.component.html',
  styleUrls: ['./connected-events.component.scss']
})
export class ConnectedEventsComponent implements OnInit {
  @Input() events: IConventionEvent[] = [];
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Output() addEvent = new EventEmitter<IConventionEvent>();
  @Output() deleteEvent = new EventEmitter<{ event: IConventionEvent; index: number }>();

  constructor() {}

  ngOnInit() {}
}

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';

@Injectable()
export class ConventionEventService {
  private conventionEventsURL = `${environment.API_URL + environment.API_VERSION}/ConventionEvents`;
  constructor(private http: HttpClient) {}

  createConventionEventsCelebs(eventId: string, guestId: any): Observable<any> {
    return this.http.put(this.conventionEventsURL + '/' + eventId + '/Celebrities/' + guestId, {});
  }

  deleteConventionEventsCelebs(eventId: string, guestId: string): Observable<any> {
    return this.http.delete(this.conventionEventsURL + '/' + eventId + '/Celebrities/' + guestId);
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { SquareAccount } from '@fg-types/square';

export class SquareAccountApi extends ApiBase {
  model = 'SquareAccounts';

  get() {
    return this.http.get<PaginatedResponse<SquareAccount>>(this.modelBase);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as script from 'scriptjs';
import { AppleAuthConfig, AppleSignInResult } from '@fg-types/user.type';

export declare const AppleID: {
  auth: {
    init ( config: AppleAuthConfig ): void;
    signIn (): Promise<AppleSignInResult | undefined>;
  };
};
@Injectable()
export class AppleLoginService {

  appleScriptUrl = 'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

  ready: Promise<boolean> = new Promise( resolve => {
    if ( typeof window !== 'undefined' ) {
      script( this.appleScriptUrl, () => resolve( true ) );
    } else {
      resolve( false );
    }
  });

  constructor( private http: HttpClient ) {
    this.ready.then( isReady => {
      if ( isReady ) {
        this.init();
      }
    } );
  }

  getUserLoginDetails( idToken: string ) {
    return this.http.post<any>( `/users/login/apple`, { data: { idToken } } );
  }

  init(): void {
    AppleID.auth.init( {
      clientId: 'guru.fan',
      scope: 'email',
      usePopup: true,
      redirectURI: `https://dev.fan.guru/auth/apple`
      // redirectURI: `https://${window.location.hostname}/auth/apple`
      // state: '', // ‘what ever string to be remembered’
    } );
  }

}

export interface SizeValue {
  label: string;
  min: number;
  max?: number;
}

export const sizeValues: SizeValue[] = [
  { label: '50,000+', min: 50001 },
  { label: '25,000 - 50,000', min: 25000, max: 50000 },
  { label: '10,000 - 24,999', min: 10000, max: 24999 },
  { label: '1,000 - 9,999', min: 1000, max: 9999 },
  { label: '0 - 999', min: 0, max: 999 }
];

export const goLiveValues = [
  'Coming Soon',
  'Live',
  'Admin Only',
  'Cancelled',
  'Postponed',
  'Rescheduled'
];

export const eventEnvironments = ['In Person', 'Virtual', 'Hybrid'];

export const partnerStatuses = [
  { label: '', value: '' },
  { label: 'Signature', value: 'Signature' },
  { label: 'Partner', value: 'Partner' },
  { label: 'Programmed', value: 'Programmed' },
  { label: 'Listed', value: 'Listed' }
];

export interface FilterEvent {
  type?: 'past' | 'upcoming' | 'all';
  categoryIds?: string[];
  size?: SizeValue;
  fromDate?: string;
  toDate?: string;
  ticketProvider?: string;
  goLive?: string;
}

import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ApiBase } from '@fg-services/api/models/api-base';
import {
  HostAccessCodeGetRequestBody,
  HostAccessCodePostRequestBody,
  HostAccessCode
} from '@fg-types/host-access';

export class CodesApi extends ApiBase {
  model = 'Codes';

  createAccessCode(body: HostAccessCodePostRequestBody) {
    return this.http.post<HostAccessCode>(`${this.modelBase}`, body);
  }

  getAll({ conventionId, conventionEventId, lastKey }: HostAccessCodeGetRequestBody) {
    const params = this.setParams({ conventionId, conventionEventId, lastKey });
    return this.http.get<PaginatedResponse<HostAccessCode>>(`${this.modelBase}`, { params });
  }

  deleteAccessCode(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '@fg-services/api/api.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';

@Component({
  selector: 'impersonate',
  templateUrl: './impersonate.component.html',
  styleUrls: ['./impersonate.component.scss']
})
export class ImpersonateComponent implements OnInit {
  authToken: string;
  constructor(
    private apiService: ApiService,
    private uiService: UiService,
    private router: Router,
    private userService: UserService
  ) {}

  ngOnInit(): void {}
  setAuthToken(accessToken: string) {
    this.apiService.users.authenticated(accessToken).subscribe(user => {
      this.userService.impersonate({ ...user, accessToken: { id: accessToken } });
      this.router.navigate(['apps']);
    }, this.uiService.errorHandler);
  }
}

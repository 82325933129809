<tile
  [stripeColor]="data?.color"
  (onClick)="onClick.emit()"
  [active]="active"
  [description]="data?.name"
  [hoverActive]="hoverActive"
  height="37px"
>
  <ng-content></ng-content>
</tile>

import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { BaseMenuItem } from '@fg-app/app-menu/base-menu-items';
import {
  ConventionRole,
  ConventionSegment
} from '@fg-services/navigation-context/segments/convention-segment';

export class ConventionMenuItems extends BaseMenuItem<ConventionSegment> {
  menuItems() {
    const { convention } = this.context.model;
    if (!convention) return [];
    const stats = convention.stats || {};
    const { eventsCount, celebritiesCount, exhibitorsCount } = stats;

    const items: MenuItem[] = [
      {
        label: 'Event Dashboard',
        icon: 'stars',
        order: 20,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [],
        visible: false
      },
      {
        label: 'Essentials',
        customIcon: 'essentials',
        order: 40,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Event Details',
            routerLink: [...this.routePath, 'details', 'con'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Artwork',
            routerLink: [...this.routePath, 'details', 'artwork'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Marketing',
            routerLink: [...this.routePath, 'details', 'marketing'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Web Widgets',
            routerLink: [...this.routePath, 'details', 'web-widget'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Settings',
            routerLink: [...this.routePath, 'details', 'settings'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Registration',
        customIcon: 'ticketing_registration',
        order: 50,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Registration',
            routerLink: [...this.routePath, 'registration', 'settings'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'TICKETS',
            routerLink: [...this.routePath, 'registration', 'tickets'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Program',
        icon: 'playlist_add_check',
        order: 70,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Schedule' + (eventsCount ? ` (${eventsCount})` : ''),
            routerLink: [...this.routePath, 'content', 'schedule'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Exhibitors' + (exhibitorsCount ? ` (${exhibitorsCount})` : ''),
            routerLink: [...this.routePath, 'content', 'exhibitors'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Guests' + (celebritiesCount ? ` (${celebritiesCount})` : ''),
            routerLink: [...this.routePath, 'content', 'guests'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Recordings',
            routerLink: [...this.routePath, 'content', 'recordings'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Chats',
            routerLink: [...this.routePath, 'content', 'chats'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Maps',
            routerLink: [...this.routePath, 'content', 'maps'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Communities',
            routerLink: [...this.routePath, 'content', 'communities'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'App',
        customIcon: 'app',
        order: 105,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'In-App Menu',
            routerLink: [...this.routePath, 'configure', 'in-app-menu'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Featured',
            routerLink: [...this.routePath, 'configure', 'featured'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Advertising',
        icon: 'person_add',
        order: 100,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'EVENT ADS',
            routerLink: [...this.routePath, 'advertising', 'event'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'FAN GURU ADS',
            routerLink: [...this.routePath, 'advertising', 'fg'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Notifications',
        icon: 'notifications_active',
        order: 120,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin, ConventionRole.ConMod],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'notifications']
      },
      {
        label: 'Gamification',
        icon: 'gamepad',
        order: 80,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'Challenges',
            routerLink: [...this.routePath, 'gamification', 'challenges'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Groups',
            routerLink: [...this.routePath, 'gamification', 'groups'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'Contests',
        icon: 'bookmark',
        order: 85,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin, ConventionRole.ConMod],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'contests']
      },
      {
        label: 'Moderation',
        icon: 'remove_red_eye',
        order: 150,
        roles: [ConventionRole.ConMod, ConventionRole.ConAdmin, ConventionRole.Admin],
        fatherActive: false,
        childActive: false,
        items: [
          {
            label: 'User Content',
            routerLink: [...this.routePath, 'ugc', 'user-content'],
            fatherActive: false,
            childActive: false
          },
          {
            label: 'Reported Content',
            routerLink: [...this.routePath, 'ugc', 'reported-content'],
            fatherActive: false,
            childActive: false
          }
        ]
      },
      {
        label: 'ATTENDEES',
        icon: 'people',
        order: 155,
        roles: [ConventionRole.ConMod, ConventionRole.ConAdmin, ConventionRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'attendees']
      },
      {
        label: 'ANALYTICS',
        icon: 'favorite',
        order: 160,
        roles: [ConventionRole.ConAdmin, ConventionRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'analytics']
      },
      {
        label: 'Team',
        icon: 'group_work',
        order: 50,
        roles: [ConventionRole.Admin, ConventionRole.ConAdmin],
        routerLink: [...this.routePath, 'manager', 'access'],
        fatherActive: false,
        childActive: false
      },
      {
        label: 'Settings',
        icon: 'settings',
        order: 170,
        roles: [ConventionRole.Admin],
        fatherActive: false,
        childActive: false,
        routerLink: [...this.routePath, 'settings']
      }
    ];

    return this.filterOptions(items);
  }

  filterOptions(menuItems: MenuItem[]) {
    const { model, role } = this.context;
    const { services } = model;
    const labelsToRemove: string[] = [];
    const subMenuItemsToRemove: SubMenuItemsToRemove[] = [];
    const labelsToLock: string[] = [];
    // Rules to remove side menu items
    if (role !== ConventionRole.Admin && role !== ConventionRole.ConAdmin) {
      subMenuItemsToRemove.push({
        childLabel: 'In-App Menu',
        parentLabel: 'App'
      });
    }
    if (!services.ConventionPartner && role !== ConventionRole.Admin) {
      labelsToLock.push('Advertising');
    }
    if (role !== ConventionRole.Admin) {
      subMenuItemsToRemove.push({
        childLabel: 'FAN GURU ADS',
        parentLabel: 'Advertising'
      });
    }
    if (!services.Gamification && role !== ConventionRole.Admin) {
      labelsToLock.push('Gamification');
    }
    if (!services.ConventionContest && role !== ConventionRole.Admin) {
      labelsToLock.push('Contests');
    }
    const filteredOptions = this.removeSideNavItems(menuItems, role, labelsToRemove);
    const subNavFilteredNavItems = this.removeSubNavItems(filteredOptions, subMenuItemsToRemove);
    return this.lockSideNavItems(labelsToLock, subNavFilteredNavItems);
  }

  lockSideNavItems(lockSideNavItems: string[], menuItems: MenuItem[]) {
    return menuItems.map(_menuItem =>
      lockSideNavItems.includes(_menuItem.label)
        ? {
            ..._menuItem,
            items: (_menuItem.items || []).map(c => ({
              ...c,
              icon: 'lock',
              badgeStyleClass: 'locked'
            }))
          }
        : _menuItem
    );
  }
}

import { Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import videojs from 'video.js';

@Component({
  selector: 'fg-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss']
})
export class VideoPlayerComponent implements OnChanges, OnDestroy {
  @ViewChild('target', { static: true }) target: ElementRef;
  @Input() options: {
    fluid: boolean;
    aspectRatio: string;
    autoplay: boolean;
    source: string;
    poster: string;
  };

  player: videojs.Player;
  showPlayButton = true;

  ngOnChanges() {
    this.initPlayer();
  }

  ngOnDestroy() {
    this.disposePlayer();
  }

  initPlayer() {
    /**
     * Delay videoJs initialization. This is purely for Jest tests
     * per https://github.com/videojs/video.js/issues/2822
     */
    setTimeout(() => {
      this.showPlayButton = true;
      if (!this.player) {
        this.player = videojs(
          this.target.nativeElement,
          {
            ...this.options,
            controls: true,
            sources: [{ src: this.options.source }],
            controlBar: {
              volumePanel: {
                inline: false,
                vertical: true
              }
            }
          },
          function onPlayerReady() {}
        );
      } else {
        this.player.src({ src: this.options.source });
      }
    }, 1);
  }

  disposePlayer() {
    if (this.player) {
      this.player.dispose();
    }
  }
}

import {
  animate,
  animation,
  AnimationEvent,
  style,
  transition,
  trigger,
  useAnimation
} from '@angular/animations';
import {
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
  ViewChild
} from '@angular/core';
import { Confirmation, ConfirmationService, Footer } from 'primeng/api';
import { DomHandler } from 'primeng/dom';
import { Subscription } from 'rxjs';

const showAnimation = animation([
  style({ transform: '{{transform}}', opacity: 1 }),
  animate('{{transition}}', style({ transform: 'none', opacity: 1 }))
]);

const hideAnimation = animation([
  animate('{{transition}}', style({ transform: '{{transform}}', opacity: 0 }))
]);

@Component({
  selector: 'custom-confirm-dialog',
  templateUrl: './custom-confirm-dialog.component.html',
  animations: [
    trigger('animation', [
      transition('void => visible', [useAnimation(showAnimation)]),
      transition('visible => void', [useAnimation(hideAnimation)])
    ])
  ]
})
export class CustomConfirmDialogComponent implements OnDestroy {
  @Input() header: string;
  @Input() icon: string;
  @Input() message: string;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() acceptIcon = '';
  @Input() acceptLabel = 'YES';
  @Input() acceptVisible = true;
  @Input() rejectIcon = '';
  @Input() rejectLabel = 'NO';
  @Input() rejectVisible = true;
  @Input() acceptButtonStyleClass: string;
  @Input() rejectButtonStyleClass: string;
  @Input() closeOnEscape = true;
  @Input() blockScroll = true;
  @Input() rtl: boolean;
  @Input() closable = true;
  @Input() appendTo: any;
  @Input() key: string;
  @Input() autoZIndex = true;
  @Input() baseZIndex = 0;
  @Input() transitionOptions = '150ms cubic-bezier(0, 0, 0.2, 1)';
  @Input() focusTrap = true;
  @Input() defaultFocus = 'accept';
  @Input() get visible(): any {
    return this._visible;
  }

  set visible(value: any) {
    this._visible = value;

    if (this._visible && !this.maskVisible) {
      this.maskVisible = true;
    }
  }

  @Input() get position(): string {
    return this._position;
  }

  set position(value: string) {
    this._position = value;
    switch (value) {
      case 'topleft':
      case 'bottomleft':
      case 'left':
        this.transformOptions = 'translate3d(-100%, 0px, 0px)';
        break;
      case 'topright':
      case 'bottomright':
      case 'right':
        this.transformOptions = 'translate3d(100%, 0px, 0px)';
        break;
      case 'bottom':
        this.transformOptions = 'translate3d(0px, 100%, 0px)';
        break;
      case 'top':
        this.transformOptions = 'translate3d(0px, -100%, 0px)';
        break;
      default:
        this.transformOptions = 'scale(0.7)';
        break;
    }
  }

  @ContentChild(Footer) footer;
  @ViewChild('content') contentViewChild: ElementRef;
  confirmation: Partial<Confirmation & { acceptIcon?: string; rejectIcon?: string }>;
  _visible: boolean;
  maskVisible: boolean;
  documentEscapeListener: any;
  container: HTMLDivElement;
  wrapper: HTMLElement;
  contentContainer: HTMLDivElement;
  subscription: Subscription;
  preWidth: number;
  _position = 'center';
  transformOptions: any = 'scale(0.7)';
  confirmationOptions: Partial<Confirmation & { acceptIcon?: string; rejectIcon?: string }>;

  constructor(
    public el: ElementRef,
    public renderer: Renderer2,
    private confirmationService: ConfirmationService,
    public zone: NgZone,
    private cd: ChangeDetectorRef
  ) {
    this.subscription = this.confirmationService.requireConfirmation$.subscribe(confirmation => {
      if (!confirmation) {
        this.hide();
        return;
      }

      if (confirmation.key === this.key) {
        this.confirmation = confirmation;
        this.confirmationOptions = {
          message: this.confirmation.message || this.message,
          icon: this.confirmation.icon || this.icon,
          header: this.confirmation.header || this.header,
          rejectVisible:
            this.confirmation.rejectVisible == null
              ? this.rejectVisible
              : this.confirmation.rejectVisible,
          acceptVisible:
            this.confirmation.acceptVisible == null
              ? this.acceptVisible
              : this.confirmation.acceptVisible,
          acceptLabel: this.confirmation.acceptLabel || this.acceptLabel,
          rejectLabel: this.confirmation.rejectLabel || this.rejectLabel,
          acceptIcon: this.confirmation.acceptIcon || this.acceptIcon,
          rejectIcon: this.confirmation.rejectIcon || this.rejectIcon,
          blockScroll:
            this.confirmation.blockScroll === false || this.confirmation.blockScroll === true
              ? this.confirmation.blockScroll
              : this.blockScroll
        };

        if (this.confirmation.accept) {
          this.confirmation.acceptEvent = new EventEmitter();
          this.confirmation.acceptEvent.subscribe(this.confirmation.accept);
        }

        if (this.confirmation.reject) {
          this.confirmation.rejectEvent = new EventEmitter<string>();
          this.confirmation.rejectEvent.subscribe(this.confirmation.reject);
        }

        this.visible = true;
      }
    });
  }

  option(name: string) {
    const source = this.confirmationOptions || this;
    if (source.hasOwnProperty(name)) {
      return source[name];
    }
    return undefined;
  }

  onAnimationStart(event: AnimationEvent) {
    if (event.toState === 'visible') {
      this.container = event.element;
      this.wrapper = this.container.parentElement;
      this.contentContainer = DomHandler.findSingle(this.container, '.p-dialog-content');

      const element = this.getElementToFocus();
      if (element) {
        element.focus();
      }

      this.appendContainer();
      this.moveOnTop();
      this.bindGlobalListeners();
      this.enableModality();
    }
  }

  onAnimationEnd(event: AnimationEvent) {
    if (event.toState === 'void') {
      this.onOverlayHide();
    }
  }

  getElementToFocus() {
    switch (this.option('defaultFocus')) {
      case 'accept':
        return DomHandler.findSingle(this.container, 'button.p-confirmdialog-acceptbutton');

      case 'reject':
        return DomHandler.findSingle(this.container, 'button.p-confirmdialog-rejectbutton');

      case 'close':
        return DomHandler.findSingle(this.container, 'a.p-dialog-titlebar-close');

      case 'none':
        return null;

      // backward compatibility
      default:
        return DomHandler.findSingle(this.container, 'button.p-confirmdialog-acceptbutton');
    }
  }

  appendContainer() {
    if (this.appendTo) {
      if (this.appendTo === 'body') document.body.appendChild(this.wrapper);
      else DomHandler.appendChild(this.wrapper, this.appendTo);
    }
  }

  restoreAppend() {
    if (this.wrapper && this.appendTo) {
      this.el.nativeElement.appendChild(this.wrapper);
    }
  }

  enableModality() {
    if (this.option('blockScroll')) {
      DomHandler.addClass(document.body, 'p-overflow-hidden');
    }
  }

  disableModality() {
    this.maskVisible = false;

    if (this.option('blockScroll')) {
      DomHandler.removeClass(document.body, 'p-overflow-hidden');
    }

    if (this.container) {
      this.cd.detectChanges();
    }
  }

  close(event: Event) {
    if (this.confirmation.rejectEvent) {
      this.confirmation.rejectEvent.emit('close');
    }

    this.hide();
    event.preventDefault();
  }

  hide() {
    this.visible = false;
    this.confirmation = null;
    this.confirmationOptions = null;
  }

  moveOnTop() {
    if (this.autoZIndex) {
      this.container.style.zIndex = String(this.baseZIndex + ++DomHandler.zindex);
      this.wrapper.style.zIndex = String(this.baseZIndex + (DomHandler.zindex - 1));
    }
  }

  getMaskClass() {
    const maskClass = {
      'p-component-overlay p-dialog-mask': true,
      'p-dialog-visible': this.maskVisible,
      'p-dialog-mask-scrollblocker': this.blockScroll
    };
    maskClass[this.getPositionClass().toString()] = true;
    return maskClass;
  }

  getPositionClass() {
    const positions = [
      'left',
      'right',
      'top',
      'topleft',
      'topright',
      'bottom',
      'bottomleft',
      'bottomright'
    ];
    const pos = positions.find(item => item === this.position);

    return pos ? `p-dialog-${pos}` : '';
  }

  bindGlobalListeners() {
    if ((this.closeOnEscape && this.closable) || (this.focusTrap && !this.documentEscapeListener)) {
      this.documentEscapeListener = this.renderer.listen('document', 'keydown', event => {
        if (
          event.which === 27 &&
          this.closeOnEscape &&
          this.closable &&
          parseInt(this.container.style.zIndex, 10) === DomHandler.zindex + this.baseZIndex &&
          this.visible
        ) {
          this.close(event);
        }

        if (event.which === 9 && this.focusTrap) {
          event.preventDefault();

          const focusableElements = DomHandler.getFocusableElements(this.container);

          if (focusableElements && focusableElements.length > 0) {
            if (!document.activeElement) {
              focusableElements[0].focus();
            } else {
              const focusedIndex = focusableElements.indexOf(document.activeElement);

              if (event.shiftKey) {
                if (focusedIndex === -1 || focusedIndex === 0)
                  focusableElements[focusableElements.length - 1].focus();
                else focusableElements[focusedIndex - 1].focus();
              } else {
                if (focusedIndex === -1 || focusedIndex === focusableElements.length - 1)
                  focusableElements[0].focus();
                else focusableElements[focusedIndex + 1].focus();
              }
            }
          }
        }
      });
    }
  }

  unbindGlobalListeners() {
    if (this.documentEscapeListener) {
      this.documentEscapeListener();
      this.documentEscapeListener = null;
    }
  }

  onOverlayHide() {
    this.disableModality();
    this.unbindGlobalListeners();
    this.container = null;
  }

  ngOnDestroy() {
    this.restoreAppend();
    this.onOverlayHide();
    this.subscription.unsubscribe();
  }

  accept() {
    if (this.confirmation.acceptEvent) {
      this.confirmation.acceptEvent.emit();
    }

    this.hide();
  }

  reject() {
    if (this.confirmation.rejectEvent) {
      this.confirmation.rejectEvent.emit('reject');
    }

    this.hide();
  }
}

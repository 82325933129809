import { ApiBase } from '@fg-services/api/models/api-base';
import { OzVersionConfig } from '@fg-types/oz-version-config';

export interface TicketProviders {
  ticketProviders: string[];
}
export interface ExhibitHallConfig {
  exhibitHall: {
    mobile?: boolean;
    mobileText?: string;
    web?: boolean;
    webText?: string;
  };
}

export class ConfigApi extends ApiBase {
  model = 'Configs';

  getTicketProviders() {
    return this.http.get<TicketProviders>(this.modelUri('ticketProviders'));
  }

  updateTicketProviders(ticketProviders: string[]) {
    return this.http.patch<TicketProviders>(this.modelUri('ticketProviders'), { ticketProviders });
  }

  getExhibitHallConfig() {
    return this.http.get<ExhibitHallConfig>(this.modelUri('exhibitHall'));
  }

  saveExhibitHallConfig(config: ExhibitHallConfig) {
    return this.http.patch<ExhibitHallConfig>(this.modelUri('exhibitHall'), config);
  }

  saveOzConfig(config: OzVersionConfig) {
    return this.http.patch<OzVersionConfig>(this.modelUri('version'), config);
  }

  getOzConfig() {
    return this.http.get<OzVersionConfig>(this.modelUri('version'));
  }
}

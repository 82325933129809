import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ModelToday, ModelTodaySuportedModels, ModelTodayType } from '@fg-types/today.type';

export class ModelTodayApi extends ApiBase {
  model = 'ModelToday';

  getDates(params: {
    type: ModelTodayType;
    modelName: ModelTodaySuportedModels;
    modelId: string;
    lastKey?: string;
  }) {
    return this.http.get<PaginatedResponse<ModelToday>>(`${this.modelBase}/dates`, {
      params: this.setParams(params)
    });
  }

  load(params: {
    type: ModelTodayType;
    modelName: ModelTodaySuportedModels;
    modelId: string;
    platform?: string;
  }) {
    return this.http.get<ModelToday>(this.modelBase, {
      params: this.setParams(params)
    });
  }

  add(data: Partial<ModelToday>) {
    return this.http.post<ModelToday>(this.modelBase, data);
  }

  patch(id: string, data: Partial<ModelToday>) {
    return this.http.patch<ModelToday>(`${this.modelBase}/${id}`, data);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

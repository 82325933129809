<div *ngIf="resetUploader" class="pdf-uploader-container">
  <div class="select-pdf-header" *ngIf="header">{{ header }}</div>
  <div class="drop-box needsclick modal-file-drop">
    <div class="pdf-body" [class.not-allowed]="disabled" (click)="inputFile.click()">
      <pdf-viewer
        *ngIf="pdf?.url"
        [src]="pdf.url"
        [render-text]="false"
        [original-size]="false"
        [autoresize]="true"
      ></pdf-viewer>
      <input
        [disabled]="disabled"
        type="file"
        accept="application/pdf"
        (change)="selectFile($event)"
        #inputFile
      />
    </div>
    <a *ngIf="pdf" href class="delete-icon" (click)="onDelete.emit(); (false)">
      <i class="pi pi-times"></i>
    </a>
    <br />
  </div>
  <div (click)="inputFile.click()" class="select-pdf-footer" *ngIf="footer">{{ footer }}</div>
</div>

<div class="input-container">
  <div class="input-cell">
    <label *ngIf="label" [ngClass]="{ required: required }">
      {{ label }} <span *ngIf="_timeZone === 'UTC'">(Displayed in UTC)</span>
      <tooltip-button *ngIf="tooltip" [tooltip]="tooltip"></tooltip-button>
    </label>
    <input
      [required]="required"
      #datePicker
      class="p-inputtext p-component"
      [ngModel]="_date"
      [ngClass]="{
        'p-filled': !!_date,
        'p-inputwrapper-filled': !!_date,
        error: errorClass
      }"
      [ngStyle]="inputStyle"
      [disabled]="disabled"
      (ngModelChange)="delayuserInputChange$.next($event)"
      (keydown.Tab)="fP.close()"
    />
    <i class="{{ iconClass }} icon" (click)="onIconClick.emit()"></i>
  </div>
</div>

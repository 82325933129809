import { NavigationContextHelpers } from '@fg-services/navigation-context/navigation-context.helpers';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import {
  OrganizationRole,
  organizationRoleResolver
} from '@fg-services/navigation-context/segments/organization-segment';
import { Community } from '@fg-types/community';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum FandomRole {
  FandomAdmin = 'fandom-admin',
  FandomMod = 'fandom-mod',
  Admin = 'admin'
}

export interface FandomSegmentModel {
  fandom: Community;
}

export type FandomSegment = NavigationContextSegment<
  NavigableModel.Fandom,
  FandomSegmentModel,
  FandomRole
>;

export const fandomRoleResolver = (
  modelId: string,
  navigationContextService: NavigationContextService,
  nestedModelIds: Map<NavigableModel, string>
): FandomRole => {
  if (!navigationContextService.userRoles) return null;
  if (navigationContextService.userRoles.isAdmin) return FandomRole.Admin;
  const orgId = nestedModelIds.get(NavigableModel.Organization);
  if (orgId) {
    const orgRole = organizationRoleResolver(orgId, navigationContextService);
    const orgCommunityRoleEquivalent = {
      [OrganizationRole.OrgAdmin]: FandomRole.FandomAdmin,
      [OrganizationRole.OrgMod]: FandomRole.FandomMod
    };
    return orgCommunityRoleEquivalent[orgRole];
  }
  if (navigationContextService.userRoles.fandomsAdminingIds.includes(modelId))
    return FandomRole.FandomAdmin;
  if (navigationContextService.userRoles.fandomsModeratingIds.includes(modelId))
    return FandomRole.FandomMod;
};

export const routeToFandomSegment = (
  navigationContextService: NavigationContextService,
  modelId: string,
  nestedModelIds: Map<NavigableModel, string>
): Observable<FandomSegment> =>
  NavigationContextHelpers.loader<Community>(
    modelId,
    navigationContextService.apiService.fandoms.getFandomById(modelId),
    navigationContextService.communities
  ).pipe(
    map(fandom => ({
      label: 'Community',
      subLabel: fandom?.name ?? '',
      segmentName: NavigableModel.Fandom,
      modelId,
      model: { fandom },
      role: fandomRoleResolver(modelId, navigationContextService, nestedModelIds)
    }))
  );

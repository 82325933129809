import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { FeaturedModule, FeaturedModuleStyle, TodayType } from '@fg-types/today.type';

export class ModulesApi extends ApiBase {
  model = 'Modules';

  get(params: {
    style?: FeaturedModuleStyle;
    query?: string;
    limit?: number;
    order?: string;
    type?: TodayType;
    displayOnMobile?: boolean;
    displayOnWeb?: boolean;
    fandomId?: string;
    lastKey?: string;
  }) {
    return this.http.get<PaginatedResponse<FeaturedModule>>(this.modelBase, {
      params: this.setParams(params)
    });
  }

  setType(e){
    switch ( e ) {
      case "Convention":
        return "featured_events"
      case "Exhibitor":
        return "Exhibitor"
      case "Fandom":
        return "Fandom"
      case "Post":
        return "Post"
      case "user":
        return "user"
      case "SquareCatalog":
      return "SquareCatalog"
   }
  }

  getById(id: string) {
    return this.http.get<FeaturedModule>(`${this.modelBase}/${id}`);
  }

  add(data: Partial<FeaturedModule>) {
    return this.http.post<FeaturedModule>(this.modelBase, {...data, type: this.setType(data.staticModels[0].modelName)});
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  patch(id: string, data: Partial<FeaturedModule>) {
    return this.http.patch<FeaturedModule>(`${this.modelBase}/${id}`, {...data, type: this.setType(data.staticModels[0].modelName)});
  }
}

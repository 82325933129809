import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import { of } from 'rxjs';

export type BackstageSegment = NavigationContextSegment<NavigableModel.Backstage, unknown, ''>;

export const routeToBackstageSegment = () =>
  of<BackstageSegment>({
    label: 'Home',
    subLabel: 'My Backstage',
    segmentName: NavigableModel.Backstage,
    model: {},
    role: ''
  });

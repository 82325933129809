import { CdkDrag, CdkDropList, CdkDropListGroup, moveItemInArray } from '@angular/cdk/drag-drop';
import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';

@Component({
  selector: 'order-list-grid',
  templateUrl: './order-list-grid.component.html',
  styleUrls: ['./order-list-grid.component.scss']
})
export class OrderListGridComponent<T, A> extends BaseComponent implements AfterViewInit {
  @ViewChild(CdkDropListGroup) listGroup: CdkDropListGroup<CdkDropList>;
  @ViewChild(CdkDropList) placeholder: CdkDropList;
  @Input() useMaxTileWidth = true;
  @Output() onScroll = new EventEmitter();
  @Input() scrollFinished = false;
  @Input() padding = '8px 14px';
  @Input() maxContainerHeight: string;
  @Input() itemComponent: T;
  @Input() selectedItem: A;
  @Input() loading: boolean;
  @Input() items: A[];
  @Input() containerScroll: boolean;
  @Input() showDeleteIcon: boolean;
  @Input() disabled = false;
  @Output() onClickItem = new EventEmitter<A>();
  @Output() onDeleteItem = new EventEmitter<{ item: A; index: number }>();
  @Output() onOrderChange = new EventEmitter<A[]>();
  target: CdkDropList;
  targetIndex: number;
  source: any;
  sourceIndex: number;

  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
    this.target = null;
    this.source = null;
  }

  ngAfterViewInit() {
    const phElement = this.placeholder?.element?.nativeElement;
    if (phElement) {
      phElement.style.display = 'none';
      phElement.parentNode.removeChild(phElement);
    }
  }

  drop() {
    if (!this.target) return;
    const phElement = this.placeholder.element.nativeElement;
    const parent = phElement.parentNode;
    phElement.style.display = 'none';
    parent.removeChild(phElement);
    parent.appendChild(phElement);
    parent.insertBefore(this.source.element.nativeElement, parent.children[this.sourceIndex]);
    this.target = null;
    this.source = null;
    if (this.sourceIndex !== this.targetIndex) {
      moveItemInArray(this.items, this.sourceIndex, this.targetIndex);
      this.onOrderChange.emit(this.items);
    }
  }

  enter = (drag: CdkDrag, drop: CdkDropList) => {
    if (drop === this.placeholder) return true;
    const phElement = this.placeholder.element.nativeElement;
    const dropElement = drop.element.nativeElement;
    const dragIndex = Array.from(dropElement.parentNode.children).indexOf(
      drag.dropContainer.element.nativeElement
    );
    const dropIndex = Array.from(dropElement.parentNode.children).indexOf(dropElement);
    if (!this.source) {
      this.sourceIndex = dragIndex;
      this.source = drag.dropContainer;
      const sourceElement = this.source.element.nativeElement;
      phElement.style.width = sourceElement.clientWidth + 'px';
      phElement.style.height = sourceElement.clientHeight + 'px';
      sourceElement.parentNode.removeChild(sourceElement);
    }
    this.targetIndex = dropIndex;
    this.target = drop;
    phElement.style.display = '';
    dropElement.parentNode.insertBefore(
      phElement,
      dragIndex < dropIndex ? dropElement.nextSibling : dropElement
    );
    this.source.start();
    this.placeholder.enterPredicate(drag, drop);
    return false;
  };
}

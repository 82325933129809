import { Injectable } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { NavigationStart, Router } from '@angular/router';
import { environment } from '@fg-environments/environment';
import { UserService } from '@fg-services/user.service';
import { UserPreferences } from '@fg-types/user-preferences.d';

@Injectable()
export class InfoBannerService {
  visible: boolean;
  info: string;
  mediaEmbed: SafeHtml;
  userPreferences: UserPreferences;
  constructor(
    private domSanitizer: DomSanitizer,
    private userService: UserService,
    private router: Router
  ) {
    this.userService.getUserPreferencesAsObservable().subscribe((preferences: UserPreferences) => {
      const tutorialModeEnabled = preferences?.backstageTutorialMode?.enabled ?? false;
      this.userPreferences = preferences;
      this.visible = !!(tutorialModeEnabled && this.info);
    });
    this.router.events.subscribe(navigation => {
      if (navigation instanceof NavigationStart) {
        delete this.info;
        delete this.mediaEmbed;
        this.visible = false;
      }
    });
  }

  setVisibility(visible: boolean) {
    this.visible = visible;
  }

  configureBannerContent(context) {
    if (environment.infoLabelMap[context]) {
      const { info, mediaEmbed } = environment.infoLabelMap[context];
      this.info = info;
      this.mediaEmbed = mediaEmbed ? this.domSanitizer.bypassSecurityTrustHtml(mediaEmbed) : null;
      const tutorialModeEnabled = this.userPreferences?.backstageTutorialMode?.enabled ?? false;
      this.visible = !!(tutorialModeEnabled && this.info);
    } else {
      this.visible = false;
    }
  }
}

<div class="impersonate-container">
  <img
    class="logo"
    src="https://fan-guru-assets.s3.amazonaws.com/console/Logo-ConsoleWhite%403x.svg"
    aria-hidden
  />
  <div class="container">
    <div class="impersonate-card">
      <form (ngSubmit)="setAuthToken(authToken)">
        <div class="p-grid">
          <div class="input-container p-col-12">
            <div class="input-cell">
              <label>Auth Token</label>
              <input
                class="impersonate-input"
                name="authToken"
                type="text"
                pInputText
                [(ngModel)]="authToken"
              />
            </div>
          </div>
          <div class="p-col-12 button-container">
            <button pButton type="submit" label="Set Auth Token" class="fg-button"></button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="fg-view-container fg-view-container-bg ">
  <div class="layout-padding fg-col-body">
    <div class="p-grid p-mt-3">
      <div class="p-col-12 input-container">
        <div class="input-cell">
          <label>
            Essentials
          </label>
        </div>
        <div>
          <button pButton label="Preview Profile" class="p-button-hollow"></button>
        </div>
      </div>
      <div class="p-col-12 input-container">
        <div class="input-cell">
          <label>Creator Name</label>
          <input type="text" name="firstName" [(ngModel)]="newCreator.firstName" (ngModelChange)="isDirty = true" maxlength="30" />
        </div>
      </div>
      <div class="p-col-12 input-container">
        <div class="input-cell height">
          <label>Description</label>
          <textarea type="text" name="bio" [(ngModel)]="newCreator.bio" (ngModelChange)="isDirty = true" maxlength="1000"></textarea>
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <label>Profile Banner</label>
          <!-- <div class="select-image-subheader">JPG or PNG image,</div> -->
          <cropper [img]="newCreator?.headerMedia?.url" (setImage)="setImage($event,'headerMedia')" width="519px"
            height="142px" [finalWidth]="1600" [finalHeight]="400" [marginAuto]="false" footer="Update Image"
            buttoncolor="true"></cropper>
        </div>
      </div>
      <div class="clearfix"></div>

      <div class="input-container p-col-12">
        <div class="input-cell">
          <label>Profile Image</label>
          <!-- <div calss="select-image-subheader">JPG or PNG image,</div> -->
          <cropper [img]="newCreator?.profileMedia?.url" (setImage)="setImage($event,'profileMedia')" width="170px"
            height="170px" [finalWidth]="720" [finalHeight]="720" [marginAuto]="false" footer="Update Image"></cropper>
        </div>
      </div>
      <div class="clearfix"></div>
      <!-- </div> -->
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Website</label>
          <input type="text" name="website" [(ngModel)]="newCreator.website" placeholder="https://fan.guru" />
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Instagram handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
            <input type="text" name="instagram" [(ngModel)]="newCreator.instagram" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Facebook handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
          <input type="text" name="facebook" [(ngModel)]="newCreator.facebook" placeholder="https://fan.guru" />
        </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>X handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
            <input type="text" name="twitter" [(ngModel)]="newCreator.twitter" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Twitch handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
            <input type="text" name="twitch" [(ngModel)]="newCreator.twitch" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Youtube handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
          <input type="text" name="youtube" [(ngModel)]="newCreator.youtube" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Tiktok handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
          <input type="text" name="tiktok" [(ngModel)]="newCreator.tiktok" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Spotify URL</label>
          <input type="text" name="spotify" [(ngModel)]="newCreator.spotify" placeholder="https://fan.guru" />
        </div>
      </div>      
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Discord URL</label>
          <input type="text" name="discord" [(ngModel)]="newCreator.discord" placeholder="https://fan.guru" />
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>Threads handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
          <input type="text" name="threads" [(ngModel)]="newCreator.threads" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-6">
        <div class="input-cell">
          <label>IMDB handle</label>
          <span class="input-flex flex-left flex-right">
            <span class="input-mask">@</span>
          <input type="text" name="imdb" [(ngModel)]="newCreator.imdb" placeholder="nm0000000" />
          </span>
        </div>
      </div>
      <div class="input-container p-col-12" *ngFor="let template of colorsTemplate">
        <div class="input-cell">
          <label>{{ template.label }}</label>
          <span class="input-flex">
            <input pInputText [name]="template.prop" type="text" [(ngModel)]="newCreator[template.prop]"
              (ngModelChange)="isDirty = true" />
            <p-colorPicker styleClass="rounded-color-picker" [name]="template.prop" width="100px!important"
              [(ngModel)]="newCreator[template.prop]"></p-colorPicker>
          </span>
        </div>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>

  <div class="fg-footer">
    <div class="btn-container ">
      <button pButton label="Save and Continue" class="fg-button" (click)="onSave()"></button>
    </div>
  </div>
</div>

<div
  *ngIf="data?.id"
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  (click)="onClick.emit()"
>
  <div class="tile-media" [style.backgroundImage]="image"></div>
  <div class="tile-body">
    <div class="title-and-indicator">
      <div class="tile-title" class="tile-title">
        {{ data.name }}
      </div>
      <div class="square-indicator">
        <event-indicator
          [shapeClass]="statusIndicatorClass"
          [label]="data.goLive"
        ></event-indicator>
      </div>
    </div>
    <div class="tile-description">
      {{ conventionDate }}
    </div>
    <div class="tile-description">
      <pre>{{ conventionData }}</pre>
    </div>
  </div>
  <div *ngIf="active" class="active-indicator"></div>
</div>

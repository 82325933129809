import { IConventionEvent } from '@fg-types/convention-event';
import { IConvention } from '@fg-types/convention.type';
import { utcToZonedTime } from 'date-fns-tz';

export class ConventionEventHelpers {
  static getImage(item: IConventionEvent, convention?: IConvention) {
    if (!item) return undefined;
    if (item.isGrowtix && item.growtixImage && item.growtixImage.url) return item.growtixImage;
    if (item.image) return item.image;
    if (item.alternateImage) return item.alternateImage;
    if (convention && convention.eventImagePlaceholder) return convention.eventImagePlaceholder;
    return undefined;
  }

  static getDetails(event: IConventionEvent, convention: IConvention) {
    if (!event) return { date: '', location: '' };
    const { locationPrimary, locationSecondary, startTime, endTime } = event;
    const { timeZoneId } = convention;

    const locationSeparator = locationSecondary && locationPrimary ? ' / ' : '';
    const location = `${locationPrimary || ''}${locationSeparator}${locationSecondary || ''}`;

    if (!startTime) return { location };

    const [_, monthStart, dayStart, yearStart, timeStart] = utcToZonedTime(startTime, timeZoneId)
      .toString()
      .split(' ');
    const [__, monthEnd, dayEnd, yearEnd, timeEnd] = utcToZonedTime(
      endTime || startTime,
      timeZoneId
    )
      .toString()
      .split(' ');
    const date = `${monthStart} ${dayStart} ${yearStart} ${
      timeEnd ? '- ' + `${monthEnd} ${dayEnd} ${yearEnd}` : ''
    }`;
    const time = `${formatTime(timeStart)} ${timeEnd ? '- ' + formatTime(timeEnd) : ''}`;
    return { date, location, time };
  }
}

function formatTime(time) {
  let amPm;
  let [hr, min] = time.split(':');
  if (+hr < 10) {
    amPm = 'AM';
    hr = hr[1];
  } else if (+hr >= 12) {
    amPm = 'PM';
    hr = hr - 12;
  } else {
    amPm = 'AM';
  }
  if (+hr === 0) hr = 12;
  return `${hr}:${min} ${amPm}`;
}

import { Injectable } from '@angular/core';
import { UserService } from '@fg-services/user.service';
import { UserRoles } from '@fg-types/roles.type';
import { BehaviorSubject } from 'rxjs';

export interface App {
  id: string;
  name: string;
  subtitle: string;
  route: string;
  asset: string;
  logo: string;
}

@Injectable({
  providedIn: 'root'
})
export class AppsService {
  userRoles: UserRoles;
  private _apps: BehaviorSubject<App[]> = new BehaviorSubject([]);

  get apps() {
    return this._apps.asObservable();
  }
  userType: boolean = true;

  constructor(userService: UserService) {
    userService.getRolesAsObservable().subscribe(roles => {
      this.updateApps(roles);
    });
    userService.getUserAsObservable().subscribe(user=>{
      if ( user?.userType !== 'subscriber'){
        this.userType = true
      }
      if(user?.userType === 'subscriber'){
        this.userType = false
      }
    });
  }

  updateApps(userRoles: UserRoles) {
    const apps: App[] = [
      {
        id: 'bs',
        name: 'Backstage',
        subtitle: 'Event Management',
        route: '/bs',
        asset: 'https://fan-guru-assets.s3.amazonaws.com/console/landing-BACKSTAGE.svg',
        logo: 'https://fan-guru-assets.s3.amazonaws.com/console/BACKSTAGE+LOGO1x.png'
      },
      {
        id: 'eh',
        name: 'Exhibit Hall',
        subtitle: 'Ecommerce Management',
        route: '/eh',
        asset: 'https://fan-guru-assets.s3.amazonaws.com/console/landing-EXHIBITHALL.svg',
        logo: 'https://fan-guru-assets.s3.amazonaws.com/console/exhibitor-hall-logo.png'
      },
      // {
      //   id: 'cp',
      //   name: 'Creator Platform',
      //   subtitle: 'Content Management',
      //   route: '/cp',
      //   asset: 'https://fan-guru-assets.s3.amazonaws.com/console/landing-OZ.svg',
      //   logo: 'https://fan-guru-assets.s3.amazonaws.com/console/exhibitor-hall-logo.png'
      // }
    ];
    if (userRoles?.isAdmin) {
      apps.push({
        id: 'oz',
        name: 'OZ',
        subtitle: 'Platform Management',
        route: '/oz',
        asset: 'https://fan-guru-assets.s3.amazonaws.com/console/landing-OZ.svg',
        logo: 'https://fan-guru-assets.s3.amazonaws.com/console/oz-header-logo.png'
      });
    }
    if(this.userType){
      apps.push({
          id: 'cp',
          name: 'Creator Platform',
          subtitle: 'Content Management',
          route: '/cp',
          asset: 'https://fan-guru-assets.s3.amazonaws.com/console/landing-OZ.svg',
          logo: 'https://fan-guru-assets.s3.amazonaws.com/console/exhibitor-hall-logo.png'
      });
    }
    this._apps.next(apps);
  }
}

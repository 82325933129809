import { Component } from '@angular/core';
import { AppsService } from '@fg-app/console/apps.service';

@Component({
  selector: 'console',
  templateUrl: './console.component.html',
  styleUrls: ['./console.component.scss']
})
export class ConsoleComponent {
  constructor(public appsService: AppsService) {}
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NewConventionModule } from '@fg-app/backstage/fg-new-convention-form/fg-new-convention-form.module';
import { FgNewOrgFormComponent } from '@fg-app/backstage/home/organizations/fg-new-org-form/fg-new-org-form.component';
import { SharedModule } from '@fg-shared/shared.module';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputSwitchModule } from 'primeng/inputswitch';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelModule } from 'primeng/panel';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CommunitiesComponent } from './communities/communities.component';
import { EventsComponent } from './events/events.component';
import { HomeComponent } from './home.component';
import { OrganizationsComponent } from './organizations/organizations.component';

@NgModule({
  declarations: [
    HomeComponent,
    OrganizationsComponent,
    CommunitiesComponent,
    EventsComponent,
    FgNewOrgFormComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ButtonModule,
    OverlayPanelModule,
    RadioButtonModule,
    FormsModule,
    CheckboxModule,
    PanelModule,
    InputSwitchModule,
    RadioButtonModule,
    NewConventionModule
  ]
})
export class HomeModule {}

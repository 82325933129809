import { Injectable } from "@angular/core";
import { ApiBase } from "./api-base";

@Injectable({
    providedIn: 'root'
  })
export class StripePaymentsApi extends ApiBase {
    model = 'StripePayments'

    getChargesHistory(userId:string, params?:{creatorId:string}){
        return this.http.get<any>(`${this.modelBase}/User/${userId}`,{
           params: this.setParams(params)
        })
    }

    getChargeDetails(id:string){
        return this.http.get<any>(`${this.modelBase}/charges/${id}`)
    }

    getInvoice(id:string){
        return this.http.get<any>(`${this.modelBase}/invoice/${id}`)
    }

    getRefunds(
        id: string,
        body:{
            chargeId: string,
            creatorId: string, 
            userId: string, 
            customerId: string, 
            amount?: number, 
            reason?: string
        }
    ){
    //reason can be any of these three duplicate,fraudulent or requested_by_customer.
        return this.http.patch<any>(`${this.modelBase}/${id}/refund`,body)
    }
}

<div class="fg-view-container">
  <div class="fg-view-body single-pane">
    <div class="fg-single-col wide">
      <div class="fg-col-body p-grid p-justify-center">
        <div class="container">
          <div class="content p-grid p-dir-col p-justify-even">
            <div class="p-grid p-align-center p-dir-col p-justify-even welcome-content">
              <h1>Welcome Back.</h1>
              <p class="available-apps">These are your available apps:</p>
            </div>
            <div class="p-grid p-justify-even card-container">
              <p-card
                class="p-col-fixed"
                [style.width]="'250px'"
                *ngFor="let app of appsService.apps | async"
              >
                <div class="p-grid p-justify-even p-dir-col p-align-center card-content">
                  <img [title]="app.name" [src]="app.asset" />
                  <h4>{{ app.name }}</h4>
                  <p>{{ app.subtitle }}</p>
                  <a class="p-button-primary button" label="OPEN" [routerLink]="app.route">OPEN</a>
                </div>
              </p-card>
            </div>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

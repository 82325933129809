<div
  data-ignore-global-click="true"
  *ngIf="showPlayButton"
  class="play-button"
  (click)="player.play(); showPlayButton = false"
></div>
<video
  #target
  class="video-js"
  controls
  muted
  playsinline
  preload="none"
  [style]="{ backgroundImage: options?.poster }"
></video>

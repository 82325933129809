import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LocalStorageService } from './localstorage.service';
@Injectable()
export class JWTInterceptorService implements HttpInterceptor {
  constructor(private localStorageService: LocalStorageService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let avoid = false;
    avoid = avoid || req.url.startsWith('https://maps.googleapis.com');
    if (!avoid) {
      const JWT = this.localStorageService.getItem('token') || '';
      const headers = {};
      /** Allows authorization to be manually over-ridden via the http request */
      if (JWT && !req.headers.get('authorization')) {
        headers['Authorization'] = JWT;
      }
      req = req.clone({
        setHeaders: headers
      });
    }
    return next.handle(req);
  }
}

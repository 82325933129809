import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

@Injectable()
export class BsGuard implements CanActivate {
  constructor(
    private userServ: UserService,
    private router: Router,
    private uiService: UiService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ) {
    const allowedStates = [
      '/bs/organizations',
      '/bs/events',
      '/bs/communities',
      '/bs'
    ];
    return this.userServ.isBsAllowed().pipe(
      mergeMap(isBsAllowed => {
        if (!this.userServ.user) this.router.navigate(['/login']);
        if ( isBsAllowed || allowedStates.includes(state.url.split('?')[0]) ) return of(true);
        return new Observable<boolean>(observer => {
          this.uiService.toast({severity: 'warn', detail: 'You aren\'t an administrator of any events.'});
          observer.next(false);
          observer.complete();
          }
        );
      })
    );
  }
}

import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { CreatorCommunity } from "@fg-types/creator-community";


@Component({
    selector: 'creator-community-card',
    templateUrl: './community-card.component.html',
    styleUrls: ['./community-card.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })
  
export class CreatorCommunityComponent{
    @Output() onSelect = new EventEmitter<any>();
    @Input() community: any;
  
    
    constructor(private domSanitizer: DomSanitizer) {}
  
    get image() {
      return this.community?.coverPhoto?.id
        ? this.domSanitizer.bypassSecurityTrustStyle(`url("${(this.community?.coverPhoto?.sizes)[0]?.url}")`)
        : '';
    }
  }
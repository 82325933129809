<p-overlayPanel #overlayPanel appendTo="body">
  <div class="filter-container">
    <i class="pi pi-times close" (click)="overlayPanel.hide()"></i>
    <div class="accent-header-style">FILTER TO INCLUDE:</div>
    <div class="filter-sections">
      <div class="filter-section">
        <a
          class="p-d-block p-mb-3"
          href
          (click)="expandedStatus.dates = !expandedStatus.dates; (false)"
        >
          DATES
          <i class="pi" [ngClass]="expandedStatus.dates ? 'pi-angle-down' : 'pi-angle-right'"></i>
        </a>
        <ng-template [ngIf]="expandedStatus.dates">
          <div class="p-grid">
            <div class="input-container p-col-12">
              <div class="input-cell">
                <p-radioButton
                  name="status"
                  value="createdDate"
                  label="Sort by created date"
                  [(ngModel)]="filter.type"
                  (onClick)="handleRadioClick('type', filter.type)"
                ></p-radioButton>
                <br />
                <p-radioButton
                  name="status"
                  value="past"
                  label="Past"
                  [(ngModel)]="filter.type"
                  (onClick)="handleRadioClick('type', filter.type)"
                ></p-radioButton>
                <br />
                <p-radioButton
                  name="status"
                  value="upcoming"
                  label="Upcoming"
                  [(ngModel)]="filter.type"
                  (onClick)="handleRadioClick('type', filter.type)"
                ></p-radioButton>
                <br />
                <p-radioButton
                  name="status"
                  value="all"
                  label="All"
                  [(ngModel)]="filter.type"
                  (onClick)="handleRadioClick('type', filter.type)"
                ></p-radioButton>
              </div>
            </div>
            <div class="p-col-12" *ngIf="filter.type === 'all'">
              <fg-date-picker
                [date]="filter.fromDate"
                [inputStyle]="{
                  'margin-top': '12px'
                }"
                label="SEARCH FROM"
                (onChange)="filter.fromDate = $event"
                dateFormat="m/d/Y"
                [enableTime]="false"
              ></fg-date-picker>
            </div>
            <div class="p-col-12" *ngIf="filter.type === 'all'">
              <fg-date-picker
                [date]="filter.toDate"
                [inputStyle]="{
                  'margin-top': '12px'
                }"
                label="SEARCH THROUGH"
                name="endTime"
                (onChange)="filter.toDate = $event"
                dateFormat="m/d/Y"
                [enableTime]="false"
              ></fg-date-picker>
            </div>
          </div>
        </ng-template>
        <div class="filter-section">
          <a
            class="p-d-block p-mb-3"
            href
            (click)="expandedStatus.status = !expandedStatus.status; (false)"
          >
            STATUS
            <i
              class="pi"
              [ngClass]="expandedStatus.status ? 'pi-angle-down' : 'pi-angle-right'"
            ></i>
          </a>
          <ng-template [ngIf]="expandedStatus.status">
            <div class="p-grid">
              <div class="input-container p-col-12">
                <div class="input-cell">
                  <ng-template ngFor let-goLiveValue [ngForOf]="goLiveValues">
                    <p-radioButton
                      name="adminStatus"
                      [value]="goLiveValue"
                      [label]="goLiveValue"
                      [(ngModel)]="filter.goLive"
                      (onClick)="handleRadioClick('goLive', filter.goLive)"
                    ></p-radioButton>
                    <br />
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="filter-section">
          <a
            class="p-d-block p-mb-3"
            href
            (click)="expandedStatus.category = !expandedStatus.category; (false)"
          >
            CATEGORY
            <i
              class="pi"
              [ngClass]="expandedStatus.category ? 'pi-angle-down' : 'pi-angle-right'"
            ></i>
          </a>
          <ng-template [ngIf]="expandedStatus.category">
            <div class="p-grid">
              <div class="input-container p-col-12">
                <div class="input-cell">
                  <ng-template ngFor let-category [ngForOf]="allCategories">
                    <p-checkbox
                      name="categories"
                      [value]="category.value"
                      [label]="category.label"
                      [(ngModel)]="filter.categoryIds"
                    ></p-checkbox>
                    <br />
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="filter-section">
          <a
            class="p-d-block p-mb-3"
            href
            (click)="expandedStatus.size = !expandedStatus.size; (false)"
          >
            SIZE
            <i class="pi" [ngClass]="expandedStatus.size ? 'pi-angle-down' : 'pi-angle-right'"></i>
          </a>
          <ng-template [ngIf]="expandedStatus.size">
            <div class="p-grid">
              <div class="input-container p-col-12">
                <div class="input-cell">
                  <ng-template ngFor let-sizeValues [ngForOf]="sizeValues">
                    <p-radioButton
                      name="size"
                      [value]="sizeValues"
                      [label]="sizeValues.label"
                      [(ngModel)]="filter.size"
                      (onClick)="handleRadioClick('size', filter.size)"
                    ></p-radioButton>
                    <br />
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="filter-section">
          <a
            class="p-d-block p-mb-3"
            href
            (click)="expandedStatus.ticketProvider = !expandedStatus.ticketProvider; (false)"
          >
            TICKET PROVIDER
            <i
              class="pi"
              [ngClass]="expandedStatus.ticketProvider ? 'pi-angle-down' : 'pi-angle-right'"
            ></i>
          </a>
          <ng-template [ngIf]="expandedStatus.ticketProvider">
            <div class="p-grid">
              <div class="input-container p-col-12">
                <div class="input-cell">
                  <ng-template ngFor let-ticketProvider [ngForOf]="ticketProviders">
                    <p-radioButton
                      name="ticketProvider"
                      [value]="ticketProvider.value"
                      [label]="ticketProvider.label"
                      [(ngModel)]="filter.ticketProvider"
                      (onClick)="handleRadioClick('ticketProvider', filter.ticketProvider)"
                    ></p-radioButton>
                    <br />
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="actions">
      <button
        pButton
        class="p-button-secondary"
        type="button"
        label="RESET"
        (click)="reset()"
      ></button>
      <span class="spacer"></span>
      <button
        pButton
        type="button"
        label="APPLY"
        class="p-button-primary"
        (click)="emit()"
      ></button>
    </div>
  </div>
</p-overlayPanel>

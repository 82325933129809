import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ShippingMethod, ShippingMethodFlatRate, ShippingZone } from '@fg-types/shipping';
export class ShippingZoneApi extends ApiBase {
  model = 'ShippingZones';
  create(data: Partial<ShippingZone>) {
    return this.http.post<ShippingZone>(this.modelBase, data);
  }

  update(id: string, data: Partial<ShippingZone>) {
    return this.http.patch<ShippingZone>(`${this.modelBase}/${id}`, data);
  }

  getById(id: string) {
    return this.http.get<ShippingZone>(`${this.modelBase}/${id}`);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  shippingMethods(id: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<ShippingMethodFlatRate>>(
      `${this.modelBase}/${id}/ShippingMethods`,
      {
        params: this.setParams({ lastKey })
      }
    );
  }
}

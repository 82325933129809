import { Route } from '@angular/router';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { AdBlockGuard } from '@fg-shared/guards/ad-block-guard';
import { AuthGuard } from '@fg-shared/guards/auth.guard';

export const cRoute: Route = {
  path: 'c/:id',
  data: { segmentName: NavigableModel.Convention },
  children: [
    {
      path: '',
      redirectTo: 'details',
      pathMatch: 'full'
    },
    {
      path: 'details',
      loadChildren: () => import('./details/details.module').then(m => m.DetailsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'content',
      loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'manager',
      loadChildren: () => import('./manager/manager.module').then(m => m.ManagerModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'advertising',
      loadChildren: () => import('./advertising/advertising.module').then(m => m.AdvertisingModule),
      canActivate: [AuthGuard],
      canLoad: [AdBlockGuard]
    },
    {
      path: 'ugc',
      loadChildren: () => import('./ugc/ugc.module').then(m => m.UGCModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'notifications',
      loadChildren: () =>
        import('@fg-shared/notifications/notifications.module').then(m => m.NotificationsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'settings',
      loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'registration',
      loadChildren: () =>
        import('./registration/registration.module').then(m => m.RegistrationModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'analytics',
      loadChildren: () => import('./analytics/analytics.module').then(m => m.AnalyticsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'contests',
      loadChildren: () => import('./contests/contests.module').then(m => m.ContestsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'configure',
      loadChildren: () => import('./configure/configure.module').then(m => m.ConfigureModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'attendees',
      loadChildren: () => import('./attendees/attendees.module').then(m => m.AttendeesModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'gamification',
      loadChildren: () =>
        import('./gamification/gamification.module').then(m => m.GamificationModule),
      canActivate: [AuthGuard]
    }
  ]
};

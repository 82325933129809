import { Component, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';

import { environment } from '@fg-environments/environment';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { MediaService } from '@fg-services/media.service';
import { UiService } from '@fg-services/ui.service';

@Component({
  selector: 'fg-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss']
})
export class FileUploaderComponent {
  @ViewChild('fileUploader', { static: true }) public fileUploader;
  @Input() title: string;
  @Input() tooltip: string;
  @Input() disabled: boolean;
  @Input() width: number;
  @Input() height: number;
  @Input() factor: number;
  @Input() accept: string;
  @Input() validateRatio = true;
  @Input() defaultImage: string;
  @Input() defaultValue: string;
  @Input() previewImage: string;
  @Input() externalLink = true;
  @Input() maxSize: number;
  @Input() chooseLabel = 'UPLOAD';
  @Output() onSuccess = new EventEmitter<any>();
  @Output() onError = new EventEmitter<any>();
  mediaEndpoint = `${environment.API_URL + environment.API_VERSION}/Media`;
  loading = true;
  input: any;
  showVideo = false;
  constructor(
    public uiService: UiService,
    public apiCallsInProgress: ApiCallsInProgress,
    private mediaService: MediaService
  ) {}
  // eslint-disable-next-line
  ngOnChanges(changes: SimpleChanges) {
    if (changes.defaultImage && /mp4/.test(changes.defaultImage.currentValue)) {
      this.showVideo = false;
      setTimeout(st => (this.showVideo = true));
    }
  }

  acceptPDF() {
    return /pdf/.test(this.accept) || this.accept === '*';
  }

  getSizeStyle() {
    return {
      width: this.width / (this.factor || 3) + 'px',
      height: this.height / (this.factor || 3) + 'px',
      'max-width': '100%'
    };
  }

  getBgStyle() {
    return {
      'background-image': this.previewImage
        ? 'url(' + this.previewImage + ')'
        : this.defaultImage
        ? 'url(' + this.defaultImage + ')'
        : null,
      'background-color': !this.acceptPDF() ? 'white' : 'transparent',
      ...this.getSizeStyle()
    };
  }

  getButtonStyle(option) {
    if (!this.acceptPDF()) {
      return {
        opacity: this.defaultImage ? 0 : 1
      };
    }
    return {};
  }

  openLink(url) {
    window.open(url, '_blank');
  }

  onSelect(event: any) {
    const file = event.files[0];
    if (file) {
      if (this.maxSize && file.size > this.maxSize) return this.onError.emit();
      if (/(mp4|pdf)/.test(this.accept) || this.accept === '*') {
        this.loading = true;
        this.fileUploader.upload();
        return;
      }
      this.input = event.originalEvent.srcElement;
      const image = new Image();
      image.src = file.objectURL.changingThisBreaksApplicationSecurity;
      // disabling auto to prevent the file to upload
      image.addEventListener('load', () => {
        const ratio = Number(this.width / this.height).toFixed(4);
        const imgRatio = Number(image.width / image.height).toFixed(4);
        // On the next method I validate size and ratio
        if (this.validateRatio && ratio !== imgRatio) {
          this.onUploadError(`The image you selected must be ${this.width}px x ${this.height}`);
        } else {
          this.loading = true;
          this.fileUploader.upload();
        }
      });
    }
  }

  cleanFileUploader() {
    this.loading = false;
    this.fileUploader.clear();
    if (this.input) {
      this.input.value = '';
    }
  }

  onUploadError(error: any) {
    this.uiService.toast({
      severity: 'error',
      summary: 'Error:',
      detail: typeof error === 'string' ? error : (error && error.message) || 'Error saving file'
    });
    this.cleanFileUploader();
  }

  customUploader({ files }) {
    this.apiCallsInProgress.addCall();
    this.loading = true;
    const formData = new FormData();
    files.forEach(file => formData.append('media', file, file.name));
    this.mediaService.upLoadMedia(formData).subscribe(
      res => {
        this.apiCallsInProgress.reset();
        this.loading = false;
        this.onSuccess.emit(res);
        this.cleanFileUploader();
      },
      error => {
        this.apiCallsInProgress.reset();
        this.loading = false;
        this.onUploadError(error.error.error.msg);
      }
    );
  }
}

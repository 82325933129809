import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IComment } from '@fg-types/post';
import { IReport } from '@fg-types/report';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'comment-tile',
  template: `
    <tile
      (onClick)="onClick.emit()"
      [active]="active"
      [title]="data.item?.owner?.username"
      [media]="data.item?.owner?.profilePhoto"
      [description]="data.item?.text"
      [hoverActive]="hoverActive"
    >
      <ng-content></ng-content>
    </tile>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommentTileComponent implements ListItem<IReport<IComment>> {
  @Input() data: IReport<IComment>;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

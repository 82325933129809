<div class="p-card">
  <div class="details">
    <div class="header">
      <div class="header1">
        {{active ? 'Scheduled for' : 'Sent on' }} {{time}} to {{data?.sentCount}} subscribers
      </div>
      <div class="header3" *ngIf="active">
        <i
          [matMenuTriggerFor]="menu"
          data-ignore-global-click="true"
          class="menu pi pi-ellipsis-h"
        ></i>
        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <div data-ignore-global-click="true" (click)="onEdit.emit()">Edit</div>
          </button>
          <button mat-menu-item>
            <div data-ignore-global-click="true" (click)="onSendNow.emit()">
              Send Now
            </div>
          </button>
          <button mat-menu-item>
            <div data-ignore-global-click="true" (click)="onDelete.emit(data.id)">
              Delete
            </div>
          </button>
        </mat-menu>
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Title</label>
        <input type="text" readonly  [value]="data?.title || defaultString" />
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Description</label>
        <textarea type="text" readonly [value]="data?.text || defaultString"></textarea>
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Who can see this post?</label>
        <input type="text" readonly [value]="this.whoCanSee ? 'EveryOne' :'AllSubscribers' " />
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Deep Link (Internal)</label>
        <input type="text" readonly [value]="data?.customDeeplink || defaultString" />
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Web Link (External)</label>
        <input type="text" readonly [value]="data?.link || defaultString" />
      </div>
    </div>
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { CmMedia } from '@fg-types/cm-media';

interface CategoryLabel {
  name: string;
  color: string;
}

@Component({
  selector: 'tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent extends BaseComponent {
  @Input() media?: string | CmMedia;
  @Input() stripeColor?: string;
  @Input() title?: string;
  @Input() name?: string;
  @Input() UserName?: string;
  @Input() isManaged?: string;
  @Input() isSquareConnected?: string;
  @Input() active?: boolean;
  @Input() truncate = true;
  @Input() maxLines = 3;
  @Input() tileType?: 'wide-image-tile' | 'tile';
  @Input() height?: string;
  @Input() hoverActive: boolean;
  @Output() onClick = new EventEmitter<void>();
  _description: string;
  @Input() set description(description: string) {
    this._description =
      description && description.length > 130 ? description.substring(0, 130) : description;
  }

  get description() {
    return this._description;
  }

  get image() {
    if (!this.media) return undefined;
    if (typeof this.media === 'string') return `url(${this.media})`;
    if (this.media.url) return `url(${this.media.url})`;
    if (this.media.sizes && this.media.sizes.length) return `url(${this.media.sizes[0].url})`;
  }

  _categoryType: string;
  @Input() set categoryType(categoryType: string) {
    this._categoryType = categoryType;
    this.loadCategories();
  }

  get categoryType() {
    return this._categoryType;
  }

  _types: string[];
  @Input() set types(types: any) {
    this._types = types;
    this.loadCategories();
  }

  get types() {
    return this._types;
  }

  categories: CategoryLabel[] = [];

  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }

  onContextChange = () => {
    this.loadCategories();
  };

  loadCategories() {
    if (this.types?.length && this.convention && this.categoryType) {
      const categories = this.convention[this.categoryType];
      this.categories = this.types.map(type => categories.find(category => category.name === type));
    }
  }

  showDescription(description?: string) {
    if (!description) return;
    /** If category labels are present, hide descriptions longer than 23
     * to allow full title and categories to fit */
    return !(this.categories?.length && this.name?.length > 23);
  }
}

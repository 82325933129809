import { Component, Input } from '@angular/core';

@Component({
  selector: 'event-indicator',
  templateUrl: './event-indicator.component.html',
  styleUrls: ['./event-indicator.component.scss']
})
export class EventIndicatorComponent {
  @Input() label: string;
  @Input() shapeClass = 'circle accent';
}

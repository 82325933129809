<div class="topbar creator-top-bar clearfix" [class.hidden]="topBarIsHidden" *ngIf="!isSubscriber">
  <div class="topbar-right">
    <div class="top-bar-title creator-top-bar-title">
      <div class="left-app-logo" *ngIf="sideMenuIsHidden; else menuButton">
        <img class="cplogo" [src]="srclogo" [routerLink]="['/']" routerLinkActive="router-link-active"/>
      </div>
      <ng-template #menuButton>
        <div class="leftpanel-menu-button" (click)="app.onMenuButtonClick()">
          <i class="fas pi pi-bars"></i>
        </div>
      </ng-template>
       <label class=" p-align-center" style="color: black;">{{topBarData}}</label>
    </div>
    <div id="rightpanel-menu-button" *ngIf="showButton">
      <button pButton class="p-button-hollow username" label={{buttonLabel}} (click)="ButtonOnClick()"></button> 
    </div>

    <div id="rightpanel-menu-button" *ngIf="user?.id && topBarData==='Console'">
      <span class="cpusername">Hello, {{ user?.username }}!</span>
      <img
        #overlayTarget
        (click)="creatorop.show($event, overlayTarget)"
        class="profile-pic"
        [src]="(user?.profilePhoto.sizes)[0]?.url"
      />
      <p-overlayPanel #creatorop>
        <ul>
          
          <li *ngIf="prevUser" (click)="userService.endImpersonation()" class="link">
            End Impersonation
          </li>
          <li class="link">
              <p>{{user.name}}</p>
              <p>@{{user.username}}</p>
          </li>
          <div class="separator"></div>
          <li [routerLink]="['/setting']"class="link" (click)="creatorop.hide()">Account Settings</li>
          <li [routerLink]="['/editprofile']" class="link" (click)="creatorop.hide()">Edit Profile</li>
          <div class="separator"></div>
          <li [routerLink]="['/faqs']" class="link" (click)="creatorop.hide()">Exhibit Hall FAQs</li>
          <li
            class="input-container p-col-12"
            *ngIf="userService?.userPreferences?.backstageTutorialMode"
          >
            <div class="link input-cell ">
              <label>TUTORIAL MODE</label>
              <p-inputSwitch
                [(ngModel)]="userService.userPreferences.backstageTutorialMode.enabled"
                (ngModelChange)="enableTutorialMode($event)"
                name="tutorial-mode"
              ></p-inputSwitch>
            </div>
          </li>
          <div class="separator"></div>
          <li *ngIf="!prevUser" (click)="logout()" class="link red">Logout</li>
          <div class="clearfix"></div>
        </ul>
      </p-overlayPanel>
    </div>
    <div *ngIf="user?.id && topBarData !=='Console'" id="rightpanel-menu-button">
      <button pButton class="p-button-hollow" label="Home" [routerLink]="['/']"></button>
     </div>
  </div>
</div>

<div class="topbar default-top-bar clearfix" [class.hidden]="topBarIsHidden" *ngIf="isSubscriber">
  <div class="topbar-right">
    <div class="top-bar-title default-top-bar-title">
      <div class="left-app-logo" *ngIf="sideMenuIsHidden; else menuButton">
        <div class="logo" [routerLink]="['/']" routerLinkActive="router-link-active"></div>
      </div>
      <ng-template #menuButton>
        <div class="leftpanel-menu-button" (click)="app.onMenuButtonClick()">
          <i class="fas pi pi-bars"></i>
        </div>
      </ng-template>
      <div
        class="context-breadcrumbs"
        *ngFor="let context of topBarNavigationContext; let i = index"
      >
        <label class="context-label" for="context-name">{{ context.label }} </label>
        <a
          [routerLink]="context.link"
          routerLinkActive="router-link-active"
          name="context-name"
          class="context-name"
        >
          <span>{{ context.name }}</span>
          <i *ngIf="topBarNavigationContext.length - 1 !== i" class="pi pi-chevron-right"></i>
        </a>
      </div>
    </div>
    <div id="rightpanel-menu-button" *ngIf="user?.id">
      <span class="username">Hello, {{ user?.username }}!</span>
      <img
        #overlayTarget
        (click)="op.show($event, overlayTarget)"
        class="profile-pic"
        [src]="(user?.profilePhoto.sizes)[0]?.url"
      />
      <p-overlayPanel #op>
        <ul>
          
          <li *ngIf="prevUser" (click)="userService.endImpersonation()" class="link">
            End Impersonation
          </li>
          <li class="link">
              <p>{{user.name}}</p>
              <p>@{{user.username}}</p>
          </li>
          <div class="separator"></div>
          <li [routerLink]="['/setting']"class="link" (click)="op.hide()">Account Settings</li>
          <li [routerLink]="['/editprofile']" class="link" (click)="op.hide()">Edit Profile</li>
          <div class="separator"></div>
          <li [routerLink]="['/faqs']" class="link" (click)="op.hide()">Exhibit Hall FAQs</li>
          <li
            class="input-container p-col-12"
            *ngIf="userService?.userPreferences?.backstageTutorialMode"
          >
            <div class="link input-cell ">
              <label>TUTORIAL MODE</label>
              <p-inputSwitch
                [(ngModel)]="userService.userPreferences.backstageTutorialMode.enabled"
                (ngModelChange)="enableTutorialMode($event)"
                name="tutorial-mode"
              ></p-inputSwitch>
            </div>
          </li>
          <div class="separator"></div>
          <li *ngIf="!prevUser" (click)="logout()" class="link red">Logout</li>
          <div class="clearfix"></div>
        </ul>
      </p-overlayPanel>
    </div>
  </div>
</div>

<ng-template ngFor let-child let-i="index" [ngForOf]="root ? item : item.items">
  <li
    [ngClass]="{ 'active-menuitem': child.fatherActive || child.childActive }"
    [class]="child.badgeStyleClass"
    *ngIf="child.visible === false ? false : true"
  >
    <a
      [href]="child.url || '#'"
      (click)="itemClick($event, child, i)"
      (mouseenter)="onMouseEnter(i)"
      class="ripplelink"
      *ngIf="!child.routerLink"
      [routerLinkActive]="['active-menuitem-routerlink']"
      [routerLinkActiveOptions]="{ exact: false }"
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
    >
      <mat-icon *ngIf="child.customIcon" [svgIcon]="child.customIcon"></mat-icon>
      <i *ngIf="child.icon" class="material-icons second">{{ child.icon }}</i>
      <span>{{ child.label }}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items">keyboard_arrow_down</i>
    </a>

    <a
      (click)="itemClick($event, child, i)"
      (mouseenter)="onMouseEnter(i)"
      class="ripplelink"
      *ngIf="child.routerLink"
      [routerLink]="child.routerLink"
      [routerLinkActive]="['active-menuitem-routerlink']"
      [routerLinkActiveOptions]="{ exact: false }"
      [attr.tabindex]="!visible ? '-1' : null"
      [attr.target]="child.target"
    >
      <mat-icon *ngIf="child.customIcon" [svgIcon]="child.customIcon"></mat-icon>
      <i *ngIf="child.icon" class="material-icons second">{{ child.icon }}</i>
      <div *ngIf="child.image" class="nav-img-container">
      <img  class="profile-nav " [src]="child.image"/>
      <span >{{ child.label }}</span>
      </div>
      <span *ngIf="!child.image">{{ child.label }}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
      <span class="menuitem-badge" *ngIf="child.badge">{{ child.badge }}</span>
      <i class="material-icons submenu-icon" *ngIf="child.items?.length">keyboard_arrow_down</i>
    </a>
    <div class="layout-menu-tooltip">
      <div class="layout-menu-tooltip-arrow"></div>
      <div class="layout-menu-tooltip-text">{{ child.label }}</div>
    </div>
    <ul
      app-submenu
      class="sub-menu"
      [item]="child"
      *ngIf="child.items?.length"
      [visible]="isActive(i)"
      [reset]="reset"
      [@children]="
        (app.isSlim() || app.isHorizontal()) && root
          ? isActive(i)
            ? 'visible'
            : 'hidden'
          : isActive(i, child.childActive)
          ? 'visibleAnimated'
          : 'hiddenAnimated'
      "
    ></ul>
  </li>
</ng-template>

import { Component, OnInit } from '@angular/core';
import { CanDeactivateComponent } from '@fg-shared/helpers/can-deactivate-component';
import { Observable } from 'rxjs';
import { Chart } from 'chart.js';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { CreatorService, DummyDataService } from '@fg-services/dummy.service';
import { Creator } from '@fg-types/creator';
import { ApiService } from '@fg-services/api/api.service';
import * as moment from 'moment-mini';

@Component({
  selector: 'cp-overview',
  templateUrl: './cp-overview.html',
  styleUrls: ['./cp-overview.scss']
})
export class OverviewComponent extends CanDeactivateComponent implements OnInit {
  filteredItems: any;
  label: any;
  isCreator: Creator;
  getCreator: string;
  count: number =0;
  constructor(
    private creatorService : CreatorService,
    private api : ApiService,
    private dummyservice:DummyDataService,
    private nav: NavigationContextService,
  ){
    super(nav);
  }
  
  ngOnInit(): void {    
    const ctx = document.getElementById('myChart') as HTMLCanvasElement;
    const myChart = new Chart(ctx, {
      type: 'line', // Change to the chart type you want (e.g., 'bar', 'line', 'pie')
      data: {
        labels: ['Jan','Feb', 'Mar', 'Apr', 'May', 'Apr', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov','Dec'],
        datasets: [
          {
            label: 'Dataset 1',
            data: [12, 23, 45, 23, 35, 37, 23, 67], // Your data values here
            fill: false,
            borderColor: 'rgb(159,185,226)',
            backgroundColor: 'rgb(159,185,226)', // Colors for the bars/points
            tension: 0.1
          },
        ]
      },
      options: {
        // Chart options go here
      }
    });
    this.loadItems();
  }
  
  isDirty: boolean;
  canDeactivate(): boolean | Observable<boolean> {
    throw new Error('Method not implemented.');
  }

  loadItems(){
    this.creatorService.currentCreator$.subscribe(creator=>{
      this.api.subscriptionBase.getCreatorSubscribers(creator?.id,{active :true}).subscribe(
        res=>{
          this.count = res.length
          this.filteredItems = res
          this.filteredItems.sort((a,b)=> new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          this.filteredItems= this.filteredItems.slice(0,3);
        }
      )
    })
  }

  timeAgo(item : any) {
    return moment( item?.createdAt ).fromNow();
  }
  
}

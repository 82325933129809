<div class="fg-view-container fg-view-container-bg">
  <div class="layout-padding fg-col-body">
    <div class="p-grid p-mt-3 p-3">
      <div class="input-container p-col-12 p-mb-4 p-mt-1">
        <div class="input-cell">
          <h4 class="color unsetFontStyles">
            Your Events
          </h4>
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <h5 class="unsetCase color p-mb-3">Search Calender
            <div class="link-color right-text">
              <i>filter</i>
              <!-- <i (click)="filterOverlay.show($event, filter)">filter</i> -->
            </div>
          </h5>
          <!-- <filter-events (onFilter)="filter = $event" #filterOverlay></filter-events> -->
          <span class="input-flex flex-left inline-input white alignment">
            <i class="pi pi-search weight"></i>
            <input name="search" type="text" pInputText autocomplete="off" placeholder="Search Events"
              [(ngModel)]="searchText" (input)="searchEvents($event)" />
          </span>
        </div>
      </div>
      <div class="input-container width">
        <div *ngFor="let con of items; let i = index" class="p-col-6">
          <fg-event-feed-tile 
            [showRemove]="false" 
            (onClick)="selectItem(con)" 
            [hoverActive]="true"
            [convention]="con">
          </fg-event-feed-tile>
        </div>
      </div>
      <div *ngIf="!items?.length && searchText" class="p-col-12">
        <label>The term you entered didn't bring up any results.</label>
      </div>
      <div *ngIf="noEvents" class=" input-container p-col-12 p-align-center">
        <label>No Events Found.</label>
      </div>
      <div class="clearfix"></div>
    </div>

  </div>
</div>

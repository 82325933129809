import { Injectable } from "@angular/core"
import { ApiBase } from "./api-base"

@Injectable({
    providedIn: 'root'
  })
export class StripeRefundsApi extends ApiBase {
    model = 'StripeRefunds'

    getRefunds(
        params:{chargeid: string},
        body:{creatorId: string, userId: string, amount?: number, reason?: string}
    ){
    //reason can be any of these three duplicate,fraudulent or requested_by_customer.
        return this.http.post<any>(`${this.modelBase}`,body,{
            params: this.setParams(params)
        })
    }
}
import { NavigationContextHelpers } from '@fg-services/navigation-context/navigation-context.helpers';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import {
  FandomRole,
  fandomRoleResolver
} from '@fg-services/navigation-context/segments/fandom-segment';
import {
  NavigableModel,
  NavigationContextSegment
} from '@fg-services/navigation-context/segments/navigation-context-segment';
import {
  OrganizationRole,
  organizationRoleResolver
} from '@fg-services/navigation-context/segments/organization-segment';
import { GroupedAllowedService } from '@fg-types/convention-service';
import { IConvention } from '@fg-types/convention.type';
import { forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export enum ConventionRole {
  ConAdmin = 'con-admin',
  ConMod = 'con-mod',
  Admin = 'admin'
}

export interface ConventionSegmentModel {
  convention: IConvention;
  services: GroupedAllowedService;
}

export type ConventionSegment = NavigationContextSegment<
  NavigableModel.Convention,
  ConventionSegmentModel,
  ConventionRole
>;

export const conventionRoleResolver = (
  modelId: string,
  navigationContextService: NavigationContextService,
  nestedModelIds: Map<NavigableModel, string>
): ConventionRole => {
  if (!navigationContextService.userRoles) return null;
  if (navigationContextService.userRoles.isAdmin) return ConventionRole.Admin;
  const orgId = nestedModelIds.get(NavigableModel.Organization);
  const communityId = nestedModelIds.get(NavigableModel.Fandom);
  if (orgId) {
    const orgRole = organizationRoleResolver(orgId, navigationContextService);
    const orgConventionRoleEquivalent = {
      [OrganizationRole.OrgAdmin]: ConventionRole.ConAdmin,
      [OrganizationRole.OrgMod]: ConventionRole.ConMod
    };
    return orgConventionRoleEquivalent[orgRole];
  } else if (communityId) {
    const communityRole = fandomRoleResolver(communityId, navigationContextService, nestedModelIds);
    const communityEventRoleEquivalent = {
      [FandomRole.FandomAdmin]: ConventionRole.ConAdmin,
      [FandomRole.FandomMod]: ConventionRole.ConMod
    };
    return communityEventRoleEquivalent[communityRole];
  }
  if (navigationContextService.userRoles.consAdminingIds.includes(modelId))
    return ConventionRole.ConAdmin;
  if (navigationContextService.userRoles.consModeratingIds.includes(modelId))
    return ConventionRole.ConMod;
};

export const routeToConventionSegment = (
  navigationContextService: NavigationContextService,
  modelId: string,
  nestedModelIds: Map<NavigableModel, string>
): Observable<ConventionSegment> =>
  forkJoin([
    NavigationContextHelpers.loader<IConvention>(
      modelId,
      navigationContextService.apiService.conventions.getConventionById(modelId),
      navigationContextService.conventions
    ),
    NavigationContextHelpers.loader<GroupedAllowedService>(
      modelId,
      navigationContextService.apiService.conventions.getConventionAllowedServices(modelId),
      navigationContextService.services
    )
  ]).pipe(
    map(([convention, services]) => ({
      label: 'Event',
      subLabel: convention?.name ?? '',
      segmentName: NavigableModel.Convention,
      modelId,
      model: { convention, services },
      role: conventionRoleResolver(modelId, navigationContextService, nestedModelIds)
    }))
  );

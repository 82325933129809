import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { UiService } from '@fg-services/ui.service';

@Component({
  selector: 'deeplink',
  templateUrl: './deeplink.component.html',
  styleUrls: ['./deeplink.component.scss']
})
export class DeeplinkComponent implements OnInit {
  @ViewChild('deeplinkInput', { static: true }) deeplinkInput: ElementRef;
  @Input() deeplink: string;
  @Input() title = 'DEEPLINK';
  @Input() tooltip: string;

  constructor(private uiService: UiService) {}

  ngOnInit() {}

  copyLink() {
    this.deeplinkInput.nativeElement.select();
    document.execCommand('copy');
    this.uiService.toast({
      severity: 'success',
      summary: 'Copied',
      detail: 'The link was copied to the clipboard!'
    });
  }
}

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';

@Component({
  selector: 'fg-multi-select-radio',
  templateUrl: './multi-select-radio.component.html',
  styleUrls: ['./multi-select-radio.component.scss']
})
export class MultiSelectRadioComponent implements OnChanges {
  @Input() items: any[] = [];
  @Input() selectedItems: any[] = [];
  @Input() name: string;
  @Input() value: any;
  @Input() label: string;
  @Input() disabled: boolean;
  @Input() binary: boolean;
  @Input() tabindex: number;
  @Input() inputId: string;
  @Input() style: any;
  @Input() styleClass: string;
  @Input() containerClass: string;
  @Input() labelStyleClass: string;
  @Input() checkboxIcon: string;
  @Output() onSelect = new EventEmitter<string>();
  /**
   * PrimeNg doesn't currently have a multi-select radio option. To get this functionality we are
   * using a multi-select checkbox and styling it like a radio button.
   */
  ngOnChanges() {
    this.selectedItems = this.selectedItems || [];
  }
}

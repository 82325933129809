import { Injectable } from '@angular/core';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { ICreatorSubscriptionBase, ISubscriptionBase } from '@fg-types/subscriptionBase';
import { ApiBase } from './api-base';

@Injectable({
  providedIn: 'root'
})
export class SubscriptionBaseApi extends ApiBase {
  model = 'SubscriptionBases';

  getOrgSubscriptionBases(params?: {
    organizationIds?: string[];
    celebrityIds?: string[];
    lastKey?: string;
  }) {
    return this.http.get<PaginatedResponse<ISubscriptionBase>>(`${this.modelBase}`, {
      params: this.setParams(params)
    });
  }

  //get all creator subscriptions
  getCreatorSubscriptionBases(params?: {
    organizationId?: string[];
    creatorId?: string[];
    isactive: boolean;
  }) {
    return this.http.get<PaginatedResponse<ICreatorSubscriptionBase>>(`${this.modelBase}/BasesForCreator`,{
      params: this.setParams(params)
    })
  }

  deActivateSubscriptionBase(id: string){
    return this.http.patch<ICreatorSubscriptionBase>(`${this.modelBase}/${id}/deactivate?id=${id}`, {})
  }

  activateSubscriptionBase(id: string){
    return this.http.patch<ICreatorSubscriptionBase>(`${this.modelBase}/${id}/activate`, {})
  }

  patchSubscriptionBase(id: string, body: ISubscriptionBase) {
    return this.http.patch<ISubscriptionBase>(`${this.modelBase}/${id}`, body);
  }

  patchCreatorSubscriptionBase(id: string, body: ICreatorSubscriptionBase) {
    return this.http.patch<ICreatorSubscriptionBase>(`${this.modelBase}/${id}/update`, body);
  }

  createSubscriptionBase(body: ISubscriptionBase) {
    return this.http.post<ISubscriptionBase>(`${this.modelBase}`, body);
  }

  //get all creator subscribers 
  getCreatorSubscribers(id : string,params:{active: boolean}){
    return this.http.get<any>(`${this.modelBase}/Creator/${id}/Subscriptions`,{
      params: this.setParams(params)
    })
  }

  //get only subscribers related to subscription
  getSubscriptionSubscribers(id : string, creatorid: string){
    return this.http.get<any>(`${this.modelBase}/${id}/Creator/${creatorid}/Subscriptions`,{
      params: this.setParams({active: true})
    })
  }

  //get subscription by Id
  getSubscriptionBase(id: string){
    return this.http.get<any>(`${this.modelBase}/${id}`)
  }
}

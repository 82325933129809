import { ApiBase } from '@fg-services/api/models/api-base';
import { ConventionHiddenModels } from '@fg-types/conventionHiddenModels';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';

interface ConventionHiddenModelsBody {
  conventionId: string;
  modelId: string;
  modelName: string;
}

export class ConventionHiddenModelsApi extends ApiBase {
  model = 'ConventionHiddenModels';
  get({ conventionId, lastKey }: { conventionId: string; lastKey?: string }) {
    return this.http.get<PaginatedResponse<ConventionHiddenModels>>(this.modelBase, {
      params: this.setParams({ lastKey, conventionId })
    });
  }

  create(category: ConventionHiddenModelsBody) {
    return this.http.post<ConventionHiddenModels>(this.modelBase, category);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

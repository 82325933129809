import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { Today, TodayType } from '@fg-types/today.type';

export class TodayApi extends ApiBase {
  model = 'Today';

  add(data: Partial<Today>) {
    return this.http.post<Today>(this.modelBase, data);
  }

  getCurrentToday(params?: { type?: TodayType; platform?: 'web' | 'mobile' }) {
    return this.http.get<Today>(this.modelBase, {
      params: this.setParams(params)
    });
  }

  getTodayById(id: string) {
    return this.http.get<Today>(this.modelUri(id));
  }

  patch(id: string, data: Partial<Today>) {
    return this.http.patch<Today>(this.modelUri(id), data);
  }

  getDates(params?: { type?: TodayType; lastKey?: string }) {
    return this.http.get<PaginatedResponse<Today>>(`${this.modelBase}/dates`, {
      params: this.setParams(params)
    });
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(this.modelUri(id));
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';

@Component({
  selector: 'search-exhibitor',
  templateUrl: './search-exhibitor.component.html',
  styleUrls: ['./search-exhibitor.component.scss']
})
export class SearchExhibitorComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() createNewText: string;
  @Output() addExhibitor = new EventEmitter<ConventionExhibitor>();
  @Input() title = 'Add Exhibitors';
  @Output() queryChange = new EventEmitter<string>();
  filteredExhibitors: (ConventionExhibitor | string)[] = [];
  exhibitor = '';

  constructor(private apiService: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.apiService.search
        .searchExhibitor(params.query, undefined, this.conventionId)
        .subscribe(res => {
          this.filteredExhibitors = [
            ...res.items,
            ...(this.createNewText ? [this.createNewText] : [])
          ];
        });
    }
  }
}

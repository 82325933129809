import { Component, Input } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { NotificationAnalyticsItem } from '@fg-types/analytics';

@Component({
  selector: 'notification-analytics-tile',
  templateUrl: './notification-analytics-tile.component.html',
  styleUrls: ['./notification-analytics-tile.component.scss']
})
export class NotificationAnalyticsTileComponent implements ListItem<any> {
  @Input() data: NotificationAnalyticsItem;
  constructor() {}
}

export class TextTransform {
  static camelCaseToTitleCase(value: string) {
    const result = value.replace(/([A-Z])/g, ' $1');
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  static formatNameForSearch(name: string) {
    const removeCamelCase = name
      ?.split(/(?=[A-Z])/)
      .filter(c => c)
      .join(' ');
    const removeNonLetters = removeCamelCase
      .match(/[a-z]*/gi)
      .filter(c => c)
      .join(' ');
    return removeNonLetters.toLowerCase();
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { FeaturedModule } from '@fg-types/today.type';

@Component({
  selector: 'module-tile',
  templateUrl: './module-tile.component.html'
})
export class ModuleTileComponent implements ListItem<FeaturedModule> {
  @Input() data: FeaturedModule;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

<circle-progress
  [percent]="percentage"
  [maxPercent]="100"
  [radius]="30"
  toFixed="0"
  [outerStrokeWidth]="16"
  outerStrokeColor="#00e5da"
  outerStrokeLinecap="butt"
  innerStrokeColor="#dddddd"
  [innerStrokeWidth]="16"
  [animation]="true"
  [space]="-16"
  [showSubtitle]="false"
  [responsive]="true"
  unitsFontSize="15"
  titleFontSize="15"
  [showTitle]="showTitle"
  [showUnits]="showUnits"
></circle-progress>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Community } from '@fg-types/community';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'community-card',
  templateUrl: './community-card.component.html',
  styleUrls: ['./community-card.component.scss']
})
export class CommunityCardComponent implements ListItem<Community> {
  get image() {
    if (!this.data.coverPhoto) return undefined;
    if (typeof this.data.coverPhoto === 'string') return `url(${this.data.coverPhoto})`;
    if (this.data.coverPhoto.url) return `url(${this.data.coverPhoto.url})`;
    if (this.data.coverPhoto.sizes && this.data.coverPhoto.sizes.length) {
      const sortedImages = this.data.coverPhoto.sizes.sort((a: any, z: any) => z.height - a.height);
      return `url(${sortedImages[0].url})`;
    }
  }

  @Input() data: Community;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ISubscriptionBase } from '@fg-types/subscriptionBase';

@Component({
  selector: 'subscription-base-card',
  templateUrl: './subscription-base-card.component.html',
  styleUrls: ['./subscription-base-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SubscriptionBaseComponent {
  @Output() onSelect = new EventEmitter<ISubscriptionBase>();
  @Input() subscriptionBase: ISubscriptionBase;

  constructor(private domSanitizer: DomSanitizer) {}

  get image() {
    return this.subscriptionBase?.media?.url
      ? this.domSanitizer.bypassSecurityTrustStyle(`url("${this.subscriptionBase?.media?.url}")`)
      : '';
  }

  get price() {
    const amount = this.subscriptionBase.stripeProduct?.prices?.[0]?.unit_amount;
    return amount ? `$${(amount / 100).toFixed(2)}/month` : '';
  }
}

import { Injectable } from "@angular/core";
import { ApiBase } from "./api-base";
import { CreatorContest } from "@fg-types/creator-contest";

@Injectable({
    providedIn: 'root'
})
export class CreatorContestApi extends ApiBase{
    model= 'CreatorContests';


    create(body: CreatorContest){
        return this.http.post<any>(`${this.modelBase}`,body);
    }

    getContestById(id: string){
        return this.http.get<any>(`${this.modelBase}/${id}`)
    }

    updateContest(id:string, body: CreatorContest){
        return this.http.patch<any>(`${this.modelBase}/${id}`,body)
    }

    deleteContest(id: string){
        return this.http.delete<any>(`${this.modelBase}/${id}`)
    }

    getAllContestApplications(id: string){
        return this.http.get<any>(`${this.modelBase}/${id}/CreatorContestApplications`);
    }

    getPosts(id:string){
        return this.http.get<any>(`${this.modelBase}/${id}/Posts`)
    }

    getAllContests(id: string){
        return this.http.get<any>(`${this.modelBase}/Creator/${id}`)
    }

}
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConventionEventType } from '@fg-types/convention.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'schedule-category-tile',
  templateUrl: './schedule-category-tile.component.html',
  styleUrls: ['./schedule-category-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScheduleCategoryTileComponent implements ListItem<ConventionEventType> {
  @Input() data: ConventionEventType;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

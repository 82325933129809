import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { Validators } from '@fg-shared/helpers/validators';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IUser } from '@fg-types/user.type';

@Component({
  selector: 'search-user',
  templateUrl: './search-user.component.html',
  styleUrls: ['./search-user.component.scss']
})
export class SearchUserComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Input() label: string;
  @Input() placeholder = 'Type to search a user';
  @Input() inputDescription: string;
  @Input() createNewText: string;
  @Input() clearOnSelect = true;
  @Output() addUser = new EventEmitter<IUser>();
  @Output() onEnter = new EventEmitter<string>();
  @Output() queryChange = new EventEmitter<string>();
  filteredUsers: (IUser | string)[] = [];
  userInput = '';
  get hostUserInputIsEmail() {
    return Validators.isValidEmail(this.userInput);
  }

  constructor(private api: ApiService) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.api.search.searchUsers(params.query, undefined, this.conventionId).subscribe(res => {
        this.filteredUsers = [...res.items, ...(this.createNewText ? [this.createNewText] : [])];
      });
    }
  }

  onSelect(user: IUser) {
    this.addUser.emit(user);
    if (this.clearOnSelect) this.userInput = '';
  }

  onKeyUp({ key }) {
    if (key === 'Enter') {
      this.onEnter.emit(this.userInput);
      if (this.clearOnSelect) {
        this.userInput = '';
        this.filteredUsers = [];
      }
    }
  }
}

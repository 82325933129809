import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@fg-environments/environment';
import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';
import { TService } from '@fg-types/service';
import { Observable } from 'rxjs';

@Injectable()
export class FgService {
  private servicesURL = `${environment.API_URL + environment.API_VERSION}/Services`;
  constructor(private http: HttpClient) {}

  getServices(modelName = 'Convention'): Observable<any> {
    return this.http.get<TService>(this.servicesURL, {
      params: NetworkHelpers.setParams({ modelName })
    });
  }
}

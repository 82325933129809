import { ApiBase } from '@fg-services/api/models/api-base';
import { Advertisement } from '@fg-types/advertisement';
import { Category } from '@fg-types/category';
import { AugmentedRealityAsset, AugmentedRealityMarker, Moment } from '@fg-types/challenge';
import { Community } from '@fg-types/community';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';
import { IConventionEvent } from '@fg-types/convention-event';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { IConvention } from '@fg-types/convention.type';
import { Organization } from '@fg-types/organization.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IPartner } from '@fg-types/partner';
import { IPost } from '@fg-types/post';
import { SquareItem } from '@fg-types/square';
import { IUser } from '@fg-types/user.type';
import { Observable } from 'rxjs';

export class SearchApi extends ApiBase {
  model = 'search';

  organizations(query?: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Organization>>(`${this.modelBase}/organizations`, {
      params: this.setParams({ query, lastKey })
    });
  }

  conventions(query?: string, lastKey?: string, organizationId?:string) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/OrganizationConventions`, {
      params: this.setParams({ query, lastKey, organizationId })
    });
  }
  Conventions(query?: string, lastKey?: string, allConventions = false) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/Conventions`, {
      params: this.setParams({ query, lastKey, allConventions })
    });
  }

  searchFandoms(query?: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/Fandoms`, {
      params: this.setParams({ query, lastKey})
    });
  }

  searchCelebrities(query: string, lastKey?: string, conventionId?: string) {
    return this.http.get<PaginatedResponse<IConventionCelebrity>>(`${this.modelBase}/Celebrities`, {
      params: this.setParams({ lastKey, query, conventionId })
    });
  }

  searchMoments(query: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Moment>>(`${this.modelBase}/Moments`, {
      params: this.setParams({ lastKey, query })
    });
  }

  searchARAsset(query: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<AugmentedRealityAsset>>(
      `${this.apiBase}/AugmentedRealityAssets`,
      {
        params: this.setParams({ lastKey, query })
      }
    );
  }

  searchARMarker(query: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<AugmentedRealityMarker>>(
      `${this.apiBase}/AugmentedRealityMarkers`,
      {
        params: this.setParams({ lastKey, query })
      }
    );
  }

  searchExhibitor(query: string, lastKey?: string, conventionId?: string) {
    return this.http.get<PaginatedResponse<ConventionExhibitor>>(`${this.modelBase}/Exhibitors`, {
      params: this.setParams({ query, conventionId, lastKey })
    });
  }

  searchItem(params: {
    query: string;
    lastKey?: string;
    conventionId?: string;
    exhibitorId?: string;
  }) {
    return this.http.get<PaginatedResponse<SquareItem>>(`${this.modelBase}/SquareCatalog`, {
      params: this.setParams(params)
    });
  }

  searchConventionEvent(query: string, conventionId: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IConventionEvent>>(`${this.modelBase}/ConventionEvent`, {
      params: this.setParams({ query, conventionId, lastKey })
    });
  }

  searchPost(params: {
    query: string;
    lastKey?: string;
    conventionIds?: string;
    postTypes?: string;
  }) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/Posts`, {
      params: this.setParams(params)
    });
  }

  searchCustomUrl(urlName: string): Observable<any> {
    return this.http.get(`${this.apiBase}/Conventions/url/${urlName}`);
  }

  searchCategories(query: string, modelName?: string, modelId?: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Category>>(`${this.modelBase}/Categories`, {
      params: this.setParams({
        query,
        modelName,
        modelId,
        lastKey
      })
    });
  }

  searchUsers(query: string, lastKey?: string, conventionId?: string) {
    return this.http.get<PaginatedResponse<IUser>>(`${this.modelBase}/users`, {
      params: this.setParams({
        query,
        lastKey,
        conventionId
      })
    });
  }

  searchAdvertisements(query?: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<Advertisement>>(`${this.modelBase}/Advertisements`, {
      params: this.setParams({
        query,
        lastKey
      })
    });
  }

  searchAdPartners(query?: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<IPartner>>(`${this.modelBase}/AdPartners`, {
      params: this.setParams({
        query,
        lastKey
      })
    });
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IPrize } from '@fg-types/prize';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'prize-tile',
  templateUrl: './prize-tile.component.html'
})
export class PrizeTileComponent extends BaseComponent implements ListItem<IPrize> {
  @Input() data: IPrize;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor(navigationContextService: NavigationContextService) {
    super(navigationContextService);
  }
}

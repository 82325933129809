export class Validators {
  static isValidHexidecimalValue(color: string) {
    const colorMatcher = /^#[0-9a-f]{6}$/i;
    return colorMatcher.test(color);
  }

  static isValidEmail(email: string) {
    const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return reg.test(String(email).toLowerCase());
  }

  static convertStringBoolean(str) {
    if (!str) return undefined;
    if (typeof str === 'boolean') return str;
    if (str === 'true') return true;
    if (str === 'false') return false;
    return undefined;
  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { CmMedia } from '@fg-types/cm-media';

import { IUser } from '@fg-types/user.type';

@Component({
  selector: 'edit-profile',
  templateUrl: './edit-profile.component.html',
  styleUrls: ['./edit-profile.component.scss']
})
export class EditProfileComponent implements OnInit {
  _fan: IUser;

  @Input() set fan(fan: IUser) {
    this._fan = fan;
  }

  get fan() {
    return this.userService.user;
  }

  submitForm() {
    this.apiCallsInProgress.addCall();
    this.apiService.users.patchUser(this.fan.id, this.fan).subscribe((fan: IUser) => {
      this.fan = { ...this.fan, ...fan };
      this.userService.user = this.fan;
      this.router.navigate(['/apps']);
      this.uiService.toast({
        severity: 'success',
        summary: 'Success:',
        detail: 'Changes successfully saved.'
      });
      this.apiCallsInProgress.reset();
    },
    ()=>{
      this.uiService.toast({
        severity:'error',
        summary:'Error',
        detail:"Looks like there's a problem with the URL fields. Please Enter valid URLs."
      })
      this.apiCallsInProgress.reset();
    }
  );
  }

  updateImage(image: CmMedia) {
    this.fan.profilePhoto = image;
    this.fan.profilePhoto.id = image ? image.id : null;
    this.apiService.users.updateProfile({mediaId: image.id}).subscribe();
  }

  constructor(
    private userService: UserService,
    private apiService: ApiService,
    private router: Router,
    private uiService: UiService,
    private apiCallsInProgress : ApiCallsInProgress,
  ) {}
  ngOnInit(): void {
   
  }
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { utcToZonedTime } from 'date-fns-tz';

@Component({
  selector: 'notification-tile',
  templateUrl: './notification-tile.component.html',
  styleUrls: ['./notification-tile.component.scss']
})
export class NotificationTileComponent extends BaseComponent implements ListItem<any> {
  @Input() data: any;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  get description() {
    const { scheduledDate, text } = this.data;
    const [_, monthStart, monthDayStart, yearStart] = utcToZonedTime(
      scheduledDate,
      this.convention?.timeZoneId || this.organization?.timeZoneId
    )
      .toString()
      .split(' ');
    const date = `${monthStart} ${monthDayStart} ${yearStart}`;
    return [date, text].join('<br />');
  }
}

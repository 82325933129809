import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { ConventionButton } from '@fg-types/convention-button';

@Injectable()
export class ConventionButtonService {
  private buttonUrl = `${environment.API_URL + environment.API_VERSION}/ConventionButtons`;

  constructor(private http: HttpClient) {}

  create(body: Partial<ConventionButton>) {
    return this.http.post<ConventionButton>(this.buttonUrl, body);
  }

  patchById(id: string, body: Partial<ConventionButton>) {
    return this.http.patch<ConventionButton>(this.buttonUrl + '/' + id, body);
  }

  getById(id: string, filter: any) {
    const options: any = {};
    options.params = new HttpParams();
    if (filter) {
      options.params = options.params.set('filter', JSON.stringify(filter));
    }
    return this.http.get<any>(this.buttonUrl + '/' + id, options);
  }

  putById(id: string, body: any) {
    return this.http.put<any>(this.buttonUrl + '/' + id, body);
  }

  deleteById(id: string) {
    return this.http.delete<any>(this.buttonUrl + '/' + id);
  }
}

/* eslint-disable prefer-arrow/prefer-arrow-functions */

import { joinNonEmptyStrings } from '@fg-shared/helpers/join-non-empty-strings';
import { IConvention } from '@fg-types/convention.type';
import { utcToZonedTime } from 'date-fns-tz';

export function formatEventDate(convention: IConvention) {
  const { startDate, endDate, timeZoneId } = convention;
  if (!startDate) return '';
  const [_, monthStart, monthDayStart, yearStart] = utcToZonedTime(startDate, timeZoneId)
    .toString()
    .split(' ');
  const [__, monthEnd, monthDayEnd, yearEnd] = endDate
    ? utcToZonedTime(endDate, timeZoneId)
        .toString()
        .split(' ')
    : [_, monthStart, monthDayStart, yearStart];
    if(monthStart+monthDayStart+yearStart === monthEnd+monthDayEnd+yearEnd)
      return `${monthStart} ${monthDayStart} ${yearStart}`
  return `${monthStart} ${monthDayStart} ${yearStart} - ${monthEnd} ${monthDayEnd} ${yearEnd}`;
}

export function conventionData(convention: IConvention) {
  const { parsedAddress, facility, facilityExtension } = convention;
  const city = parsedAddress?.fullAddress?.city;
  const state = parsedAddress?.fullAddress?.administrativeLevels?.level1short;
  const cityAndState = joinNonEmptyStrings([city, state]);
  const fullFacility = joinNonEmptyStrings([facility, facilityExtension]);
  return [fullFacility, cityAndState];
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ConventionHelpers } from '@fg-shared/helpers/convention-helpers';
import { formatEventDate } from '@fg-shared/tile/event-card/helpers';
import { IConvention } from '@fg-types/convention.type';
@Component({
  selector: 'fg-event-feed-tile',
  templateUrl: './event-feed-tile.component.html',
  styleUrls: ['./event-feed-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventFeedTileComponent {
  @Input() convention: IConvention;
  @Input() hoverActive: boolean;
  @Input() tileWidth: string;
  @Input() showRemove = false;
  @Input() small = false;
  @Output() onClick = new EventEmitter<IConvention>();
  @Output() onRemove = new EventEmitter<void>();
  indicatorColorMap = {
    'Admin Only': '#f26b6b',
    Cancelled: '#f26b6b',
    Postponed: '#f26b6b',
    'Coming Soon': '#f0ad4e',
    Rescheduled: '#f0ad4e'
  };

  get conventionDate() {
    return this.convention.isStartDateExempt ? '' : formatEventDate(this.convention);
  }

  get imageUrl() {
    return ConventionHelpers.getImageUrl(this.convention);
  }
}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@fg-environments/environment';
import { AllowedService } from '@fg-types/convention-service';
import { Observable } from 'rxjs';

@Injectable()
export class ConventionServiceService {
  private servicesURL = `${environment.API_URL + environment.API_VERSION}/ConventionServices`;
  private conServicesURL = `${environment.API_URL +
    environment.API_VERSION}/Conventions/:id/Services`;

  constructor(private http: HttpClient) {}

  getConventionServices(conventionId: string): Observable<any> {
    const options: any = {};
    return this.http.get<AllowedService>(this.conServicesURL.replace(':id', conventionId), options);
  }

  deleteConventionServices(serviceId: string): Observable<any> {
    const options: any = {};
    return this.http.delete<any>(`${this.servicesURL}/${serviceId}`, options);
  }

  allowConventionService(conventionService: Partial<AllowedService>): Observable<any> {
    return this.http.post<any>(`${this.servicesURL}/allow`, conventionService);
  }

  patchConventionService(id: string, conventionService: Partial<AllowedService>): Observable<any> {
    return this.http.patch<any>(`${this.servicesURL}/${id}`, conventionService);
  }
}

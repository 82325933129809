import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from '@fg-services/localstorage.service';

@Component({
  selector: 'exhibit-hall-referral-link',
  templateUrl: './exhibit-hall-referral-link.component.html',
  styleUrls: ['./exhibit-hall-referral-link.component.scss']
})
export class ExhibitHallReferralLinkComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storage: LocalStorageService
  ) {}

  ngOnInit() {
    const referrerModelId = this.route.snapshot.queryParams?.referrerModelId;
    const referrerModelName = this.route.snapshot.queryParams?.referrerModelName;
    if (referrerModelId && referrerModelName) {
      this.storage.setItem('referrer-model', {
        referrerModelId,
        referrerModelName
      });
    }
    this.router.navigate(['/'], { queryParamsHandling: 'preserve' });
  }
}

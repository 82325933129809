import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@fg-environments/environment';
import { IReport } from '@fg-types/report';

@Injectable()
export class ReportService {
  private reportUrl = `${environment.API_URL + environment.API_VERSION}/Reports/`;

  constructor(private http: HttpClient) {}

  dismissReport(reportId: string, notes?: string) {
    return this.http.put<IReport<any>>(this.reportUrl + reportId + '/dismiss', { notes });
  }

  deleteItem(reportId: string, notes?: string) {
    return this.http.put<IReport<any>>(this.reportUrl + reportId + '/delete', { notes });
  }

  removeContest(reportId, body) {
    return this.http.put<any>(this.reportUrl + reportId + '/removeContest', body);
  }

  removeConvention(reportId: string, notes?: string) {
    return this.http.put<IReport<any>>(this.reportUrl + reportId + '/removeConvention', { notes });
  }

  removeFandom(reportId: string, notes?: string) {
    return this.http.put<IReport<any>>(this.reportUrl + reportId + '/removeFandom', { notes });
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from '@fg-environments/environment';
import { Community } from '@fg-types/community';
import { ConventionUserExperience } from '@fg-types/convention-user-experience';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { Observable } from 'rxjs';

@Injectable()
export class FansService {
  conventionId: string;
  private conventionURL = `${environment.API_URL}${environment.API_VERSION}/Conventions`;
  private conventionUsersURL = `${environment.API_URL}${environment.API_VERSION}/users`;
  private conventionUsersExperiences = `${environment.API_URL}${environment.API_VERSION}/ConventionUserExperiences`;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.conventionId = this.route.snapshot.params.id;
  }

  getConventionFans(conventionId: string, lastKey?: string, query?: string): Observable<any> {
    const params = this.getParams({ lastKey, query });
    return this.http.get(`${this.conventionURL}/${conventionId}/attendees`, { params });
  }

  getConventionUserExperience<T>(queryParams: {
    conventionId?: string;
    ownerIds?: string;
    modelIds?: string;
    modelNames?: string;
    lastKey?: string;
  }) {
    const params = this.getParams(queryParams);
    return this.http.get<PaginatedResponse<ConventionUserExperience<T>>>(
      `${this.conventionUsersExperiences}`,
      { params }
    );
  }

  getAttendeeFandom(id: string, limit?: number) {
    return this.http.get<PaginatedResponse<Community>>(
      `${this.conventionUsersURL}/${id}/subscribed`,
      {
        params: this.getParams({ limit })
      }
    );
  }

  getParams(paramsTemplate) {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (paramsTemplate[key]) {
        params = params.set(key, paramsTemplate[key]);
      }
    }
    return params;
  }
}

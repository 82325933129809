<!-- <tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.title"
  [name]="data?.name"
  [UserName]="UserName"
  [description]="data?.bio"
  [media]="data?.profilePhoto"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile> -->
<div
  class="tile"
 
  [class.active]="active"
  [class.hover-active]="hoverActive"
  
  (click)="onClick.emit()"
>
  <div *ngIf="image" class="tile-media" [style.backgroundImage]="image"></div>
  <div class="tile-right" >
    <div class="body-container">
      <div class="tile-body">
        <div class="title-head" *ngIf="data.title">
          <img class="title-icon" src="assets/layout/images/title_icon.svg" />
          <div class="tile-title truncate-overflow" [ngClass]="'truncate-' + (5 - maxLines)">
            {{ data.title }}
          </div>
        </div>
        <div
          class="tile-name truncate-overflow"
          [ngClass]="'truncate-' + (5 - maxLines)"
          multilineDotdotdot
        >
          {{ data.name }}
        </div>
        <div class="tile-Username truncate-overflow" [ngClass]="'truncate-' + (5 - maxLines)">
          {{ UserName }}
        </div>
        <div  class="tile-description">
          <div
            [ngClass]="{ 'truncate-overflow': truncate }"
            [class]="maxLines ? 'truncate-' + maxLines : ''"
            multilineDotdotdot
          >
            <div >{{data?.bio | slice:0:23}}{{ data?.bio?.length > 23 ? '...' : '' }}</div>
          </div>
        </div>
        <div
          class="tile-description"
          [style.display]="bodySpan?.childNodes?.length > 0 ? 'flex' : 'none'"
        >
          <ng-container>
            <span #bodySpan class="body-span">
              <ng-content select="[role=body]"></ng-content>
            </span>
          </ng-container>
        </div>
      </div>
      <div
        class="right-content"
        [style.display]="contentSpan?.childNodes?.length > 0 ? 'flex' : 'none'"
      >
        <ng-container
          ><span #contentSpan><ng-content></ng-content></span
        ></ng-container>
      </div>
    </div>
    
  </div>
  
</div>
<div *ngIf="active" class="active-indicator"></div>

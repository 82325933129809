<p-overlayPanel #overlayPanel appendTo="body">
  <div class="filter-container">
    <i class="pi pi-times close" (click)="overlayPanel.hide()"></i>
    <div class="accent-header-style">
      FILTER TO INCLUDE:
    </div>
    <div class="filter-sections">
      <div *ngFor="let section of sections" class="filter-section">
        <a href (click)="section.expanded = !section.expanded; (false)">
          {{ section.title }}
          {{ section.selected.length ? ' (' + section.selected.length + ')' : '' }}
          <i class="pi" [ngClass]="section.expanded ? 'pi-angle-down' : 'pi-angle-right'"></i>
        </a>
        <category-list
          *ngIf="section.expanded"
          [title]="section.title"
          [source]="section.source"
          [allowNewCategories]="false"
          [cardWrapper]="false"
          [selectedCategories]="section.selected"
          (onSelect)="section.selected = $event"
          [showCount]="true"
          containerClass="p-col-12 p-mx-2"
        ></category-list>
        <div class="clearfix"></div>
      </div>
    </div>
    <div class="actions">
      <button
        pButton
        class="p-button-secondary"
        type="button"
        label="RESET FILTERS"
        (click)="reset()"
      ></button>
      <span class="spacer"></span>
      <button
        pButton
        type="button"
        label="APPLY"
        class="p-button-primary"
        (click)="emit()"
      ></button>
    </div>
  </div>
</p-overlayPanel>

import { Injectable } from '@angular/core';
import { CanLoad } from '@angular/router';
import { UiService } from '@fg-services/ui.service';
import { Observable } from 'rxjs';

@Injectable()
export class AdBlockGuard implements CanLoad {
  constructor(private uiService: UiService) {}
  canLoad()  {
    return new Observable<boolean>(observer => {
      const onComplete = (can: boolean) => {
        observer.next(can);
        observer.complete();
      };
      try {
        if (!window['FuckAdBlock']) {
          onComplete(true);
          return;
        }
        const fuckAdBlock = new window['FuckAdBlock']({
          checkOnLoad: false,
          resetOnEnd: false
        });
        fuckAdBlock.onDetected(() => {
          this.uiService.toast({
            severity: 'error',
            summary: 'Ad-blocker Detected',
            detail: 'Please disable ad-blocker and reload this page to continue.'
          });
          onComplete(false);
        });
        fuckAdBlock.onNotDetected(() => {
          onComplete(true);
        });
        fuckAdBlock.check(true);
      } catch (e) {
        onComplete(true);
      }
    });
  }
}

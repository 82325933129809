import { Component, OnInit } from '@angular/core';
import { InfoBannerService } from '@fg-services/info-banner.service';
@Component({
  selector: 'info-banner',
  templateUrl: './info-banner.component.html',
  styleUrls: ['./info-banner.component.scss']
})
export class InfoBannerComponent implements OnInit {
  constructor(public infoBannerService: InfoBannerService) {}
  ngOnInit() {}
}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SortEvent } from 'primeng/api/sortevent';

type SortableColumn = SortEvent & {
  title: string;
};

@Component({
  selector: 'sort-table',
  templateUrl: './sort-table.component.html',
  styleUrls: ['./sort-table.component.scss']
})
export class SortTableComponent {
  @Input() field: string;
  @Input() label: string;
  @Input() status?: SortableColumn;
  @Input() length?: number;
  @Output() onSort = new EventEmitter<SortEvent>();

  get order() {
    if (this.status && this.status.field === this.field) return -this.status.order;
    return 1;
  }

  get class() {
    if (!this.status || this.status.field !== this.field) return 'pi-chevron-down p-disabled';
    return this.status.order === 1 ? 'pi-chevron-up' : 'pi-chevron-down';
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';
import { IConventionEvent } from '@fg-types/convention-event';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ConventionEventApi extends ApiBase {
  model = 'ConventionEvents';

  getConventionEvent(id: string) {
    return this.http.get<IConventionEvent>(`${this.modelBase}/${id}`);
  }

  getConventionEventGuests(id: string, limit?: number) {
    return this.http.get<PaginatedResponse<IConventionCelebrity>>(
      `${this.modelBase}/${id}/Celebrities`,
      {
        params: this.setParams({ limit })
      }
    );
  }

  updateConventionEventGuestOrder(id: string, celebId: string, order: number) {
    return this.http.patch<IConventionCelebrity>(`${this.modelBase}/${id}/Celebrities/${celebId}`, {
      order
    });
  }
}

import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import Cropper from 'cropperjs';

@Component({
  selector: 'crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent implements OnInit {
  @ViewChild('image', { static: true }) image;
  @Input() finalWidth: number;
  @Input() finalHeight: number;
  @Input() selectedImage: string;
  @Input() isGif: boolean;
  @Output() selectImage = new EventEmitter<string>();
  @Output() cancel = new EventEmitter<void>();
  private cropper: Cropper;

  constructor() {}

  ngOnInit() {
    if (this.finalWidth && this.finalHeight && !this.isGif) {
      setTimeout(() => {
        this.cropper = new Cropper(this.image.nativeElement, {
          zoomable: false,
          scalable: false,
          aspectRatio: this.finalWidth / this.finalHeight,
          viewMode: 2,
          autoCropArea: 1
        });
      });
    }
  }

  onConfirm() {
    const croppedImage =
      this.isGif || (!this.finalWidth && !this.finalHeight)
        ? this.selectedImage
        : this.cropper.getCroppedCanvas().toDataURL('image/png');
    this.selectImage.emit(croppedImage);
  }
}

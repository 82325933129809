<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.name"
  [description]="data?.description"
  [media]="imageUrl"
  [hoverActive]="hoverActive"
  [isManaged]="data?.isManaged"
  [isSquareConnected]="data?.isSquareConnected"
  categoryType="exhibitorTypes"
  [types]="data?.types"
  [maxLines]="2"
>
  <ng-content></ng-content>
</tile>

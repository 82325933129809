import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '@fg-environments/environment';
import isNonNil from '@fg-shared/helpers/isNonNil';

export abstract class ApiBase {
  protected readonly apiBase = `${environment.API_URL}${environment.API_VERSION}`;
  abstract model: string;
  get modelBase() {
    return `${this.apiBase}/${this.model}`;
  }

  constructor(protected http: HttpClient) {}

  modelUri(id: string) {
    return `${this.modelBase}/${id}`;
  }

  setParams(paramsTemplate: any) {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (isNonNil(paramsTemplate[key])) {
        const element = paramsTemplate[key];
        params =
          typeof element === 'string'
            ? params.set(key, element)
            : params.set(key, element.toString());
      }
    }
    return params;
  }
}

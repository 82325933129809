import { ActivityMapItem } from '@fg-types/analytics';

export const activityMap: ActivityMapItem[] = [
  {
    count: 'attendeeCount',
    percentage: () => 100,
    title: 'Attended Your Event'
  },
  {
    count: 'eventRatingCount',
    percentage: 'eventRatingCountPercentage',
    title: 'Rated an Event'
  },
  {
    count: 'voteCount',
    percentage: 'voteCountPercentage',
    title: 'Voted in Contests'
  },
  {
    count: 'celebrityRatingCount',
    percentage: 'celebrityRatingCountPercentage',
    title: 'Rated a Celebrity'
  },
  {
    count: 'contestEntryCount',
    percentage: 'contestEntryCountPercentage',
    title: 'Entered in Contests'
  },
  {
    count: 'exhibitorRatingCount',
    percentage: 'exhibitorRatingCountPercentage',
    title: 'Rated an Exhibitor'
  },
  {
    count: 'postCount',
    percentage: 'postCountPercentage',
    title: 'Created Post'
  },
  {
    count: 'surveyResponseCount',
    percentage: 'surveyResponseCountPercentage',
    title: 'Completed a Review'
  },
  {
    count: 'eventAttendeeCount',
    percentage: 'eventAttendeeCountPercentage',
    title: 'Followed Schedule Events'
  }
];

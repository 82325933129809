import { GlobalGuest } from '@fg-types/global-guest';
import { ApiBase } from '@fg-services/api/models/api-base';
import { Category } from '@fg-types/category';
import { Community } from '@fg-types/community';
import { AllowedService, GroupedAllowedService } from '@fg-types/convention-service';
import { IConvention } from '@fg-types/convention.type';
import { DeleteResponse } from '@fg-types/delete-response';
import {
  Organization,
  OrganizationModels,
  OrganizationUserRoles
} from '@fg-types/organization.type';
import { PaginatedResponse } from '@fg-types/paginated-response';
import {
  StripeCreditCard,
  StripeProduct,
  StripeTransaction,
  StripeTransactionBody,
  StripeTransactionSummary
} from '@fg-types/stripe';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CreatorData } from '@fg-types/creator';

export class OrganizationApi extends ApiBase {
  model = 'organizations';

  find() {
    return this.http.get<PaginatedResponse<Organization>>(this.modelBase);
  }

  patch(config: { id: string; body: Partial<Organization> }) {
    return this.http.patch<Organization>(`${this.modelBase}/${config.id}`, config.body);
  }

  delete(id: string) {
    return this.http.delete(`${this.modelBase}/${id}`);
  }

  putModel(id: string, model: OrganizationModels, modelId: string) {
    return this.http.put(`${this.modelBase}/${id}/${model}/${modelId}`, {});
  }

  deleteModel(id: string, model: OrganizationModels, modelId: string) {
    return this.http.delete(`${this.modelBase}/${id}/${model}/${modelId}`);
  }

  addUser(id: string, userId: string, role: OrganizationUserRoles) {
    return this.http.post(`${this.modelBase}/${id}/users`, { userId, role });
  }

  deleteUser(id: string, groupUserId: string) {
    return this.http.delete(`${this.modelBase}/${id}/users/${groupUserId}`);
  }

  create(body: Partial<Organization>) {
    return this.http.post<Organization>(this.modelBase, body);
  }

  getOrganizationById(id: string) {
    return this.http.get<Organization>(`${this.modelBase}/${id}`);
  }

  getCommunitiesByOrg(id: string) {
    return this.http.get<PaginatedResponse<Community>>(`${this.modelBase}/${id}/Fandoms`);
  }

  addModel(id: string, modelName: 'Fandoms', modelId: string) {
    return this.http.put(`${this.modelBase}/${id}/${modelName}/${modelId}`, {});
  }

  getModels<T>(id: string, model: OrganizationModels) {
    return this.http.get<PaginatedResponse<T>>(`${this.modelBase}/${id}/${model}`);
  }

  conventions(id: string, lastKey?: string, isOwner?: boolean) {
    return this.http.get<PaginatedResponse<IConvention>>(`${this.modelBase}/${id}/Conventions`, {
      params: this.setParams({ lastKey, isOwner })
    });
  }

  putOrgConvention(id: string, conventionId: string, body: { isOwner?: boolean } = {}) {
    return this.http.put<any>(`${this.modelBase}/${id}/Conventions/${conventionId}`, body);
  }

  getOrgGuests(id: string) {
    return this.http.get<PaginatedResponse<GlobalGuest>>(`${this.modelBase}/${id}/Celebrities`);
  }

  putOrgGuest(id: string, guestId: string) {
    return this.http.put<GlobalGuest>(`${this.modelBase}/${id}/Celebrities/${guestId}`, {});
  }

  getOrgCreators(id: string) {
    return this.http.get<PaginatedResponse<CreatorData>>(`${this.modelBase}/${id}/Creators`);
  }

  putOrgCreators(id: string, CreatorId: string) {
    return this.http.put<CreatorData>(`${this.modelBase}/${id}/Creators/${CreatorId}`, {});
  }

  createOrgCategory(orgId: string, category: any) {
    return this.http.post<Category>(`${this.modelBase}/${orgId}/Category`, category);
  }

  patchOrgCategory(orgId: string, categoryId: string, category: any) {
    return this.http.patch<Category>(`${this.modelBase}/${orgId}/Category/${categoryId}`, category);
  }

  deleteCategory(orgId: string, categoryId: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${orgId}/Category/${categoryId}`);
  }

  getCards(orgId: string) {
    return this.http.get<{ cards: StripeCreditCard[] }>(`${this.modelBase}/${orgId}/cards`);
  }

  addCard(orgId: string, body) {
    return this.http.post<{ card: StripeCreditCard }>(`${this.modelBase}/${orgId}/card`, body);
  }

  deleteCard(orgId: string, cardId: string) {
    return this.http.delete(`${this.modelBase}/${orgId}/card/${cardId}`);
  }

  getOrganizationServices(orgId: string) {
    return this.http.get<PaginatedResponse<AllowedService>>(`${this.modelBase}/${orgId}/Services`);
  }

  /** STRIPE */
  loadStripeProducts(id: string) {
    return this.http.get<{ products: StripeProduct[] }>(`${this.modelBase}/${id}/stripeProducts`);
  }

  loadStripeStripeTransactionSummary(id: string) {
    return this.http.get<StripeTransactionSummary>(`${this.modelBase}/${id}/Transaction/summary`);
  }

  updateStripeStripeTransactions(id: string, body: StripeTransactionBody) {
    return this.http.post<StripeTransaction>(`${this.modelBase}/${id}/Transaction`, body);
  }

  createStripeCharge(orgId: string, body) {
    return this.http.post(`${this.modelBase}/${orgId}/stripePurchase`, body);
  }

  getOrganizationAllowedServices(id: string): Observable<GroupedAllowedService> {
    return this.http
      .get<PaginatedResponse<AllowedService>>(`${this.modelBase}/${id}/Services`)
      .pipe(
        map(res => {
          const groupedServices: GroupedAllowedService = {};
          res.items.forEach(svc => {
            if (svc?.service && !svc.service.mobile) {
              groupedServices[svc.service.referenceModelName] = svc.access;
            }
          });
          return groupedServices;
        })
      );
  }
}

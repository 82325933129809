import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CategorySource } from '@fg-shared/layout/category-list/category-list.component';
import { OverlayPanel } from 'primeng/overlaypanel';

export interface FilterSection {
  source: CategorySource;
  selected: string[];
  title: string;
  expanded?: boolean;
}

@Component({
  selector: 'content-filter',
  templateUrl: './content-filter.component.html',
  styleUrls: ['./content-filter.component.scss']
})
export class ContentFilterComponent {
  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;
  @Input() sections: FilterSection[];
  @Output() onChange = new EventEmitter<FilterSection[]>();

  show(event: Event) {
    this.sections.forEach(section => {
      section.expanded = section.selected.length > 0;
    });
    this.overlayPanel.show(event);
  }

  emit() {
    this.onChange.emit(this.sections);
    this.overlayPanel.hide();
  }

  reset() {
    this.sections.forEach(section => (section.selected = []));
    this.emit();
  }
}

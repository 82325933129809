import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';

interface GetScheduledNotificationsConfig {
  id?: string;
  fandomId?: string;
  organizationId?: string;
  creatorId?: string;
  action?: string;
  lastKey?: string;
  limit?: number;
  sent?: string;
  query?: string;
}

@Injectable()
export class ScheduledNotificationService {
  private readonly basePath: string = `${environment.API_URL +
    environment.API_VERSION}/ScheduledNotifications`;

  private readonly actionQuery: string = '?action=convention_scheduled';

  constructor(private http: HttpClient) {}

  patch(id: string, body: any): Observable<any> {
    return this.http.patch<any>(`${this.basePath}/${id}`, body);
  }

  delete(id: string): Observable<any> {
    return this.http.delete<any>(`${this.basePath}/${id}`);
  }

  get(id: string): Observable<any> {
    return this.http.get<any>(`${this.basePath}/${id}${this.actionQuery}`);
  }

  post(body: Partial<Notification>, query?: string) {
    const actions = query ? `?action=${query}` : this.actionQuery;
    return this.http.post<Notification>(`${this.basePath}${actions}`, body);
  }

  getScheduledNotifications(config: GetScheduledNotificationsConfig): Observable<any> {
    const params = NetworkHelpers.setParams({
      ...config,
      order: 'scheduledDate ASC'
    });
    return this.http.get(this.basePath, {
      params
    });
  }

  getNotificationVisibilityCount(config) {
    const params = NetworkHelpers.setParams(config);
    return this.http.get<{ count: number }>(`${this.basePath}/visibility`, {
      params
    });
  }
}

import { AfterViewInit, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UiService } from '@fg-services/ui.service';
import { cleanObject } from '@fg-shared/helpers/clean-object';
import flatpickr from 'flatpickr';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

@Component({
  selector: 'fg-date-picker',
  templateUrl: './fg-date-picker.component.html',
  styleUrls: ['./fg-date-picker.component.scss']
})
export class FgDatePickerComponent implements AfterViewInit {
  /**
   * This component is being implemented due to many issues with PrimeNg's default calender. We have
   * elected to use flatPicker in it's place. More details can be found
   * @ https://flatpickr.js.org/examples/.
   */
  @ViewChild('datePicker', { static: true }) datePicker;
  @Input() altFormat: string; // "F j, Y"	Exactly the same as date format, but for the altInput field
  @Input() altInput: boolean; // false	Show the user a readable date (as per altFormat), but return something totally different to the server.
  @Input() altInputClass: string; // 	""	This class will be added to the input element created by the altInput option.  Note that altInput already inherits classes from the original input.
  @Input() allowInput = true; // true	Allows the user to enter a date directly input the input field. By default, direct entry is enabled.
  @Input() appendTo: HTMLElement; // 	null	Instead of body, appends the calendar to the specified node instead*.
  @Input() ariaDateFormat: string; // 	"F j, Y"	Defines how the date will be formatted in the aria-label for calendar days, using the same tokens as dateFormat. If you change this, you should choose a value that will make sense if a screen reader reads it out loud.
  @Input() clickOpens = true; // 	true	Whether clicking on the input should open the picker. You could disable this if you wish to open the calendar manually with.open()
  @Input() dateFormat = 'm/d/Y h:i K'; // 	"Y-m-d"	A string of characters which are used to define how the date will be displayed in the input box. The supported characters are defined in the table below.
  @Input() defaultDate: string | Date; // 	Sets the initial selected date(s).
  @Input() defaultHour: number; // 	12	Initial value of the hour element.
  @Input() defaultMinute: number; // 	0	 Initial value of the minute element
  @Input() disable: any[]; // 	[]	See Disabling dates
  @Input() disableMobile: boolean; // 	false	Set disableMobile to true to always use the non-native picker.
  @Input() enable: any[]; // 	[]	See Enabling dates
  @Input() enableTime = true; // 	true	Enables time picker
  @Input() enableSeconds: boolean; // 	false	Enables seconds in the time picker.
  @Input() formatDate: any; // 	null	Allows using a custom date formatting function instead of the built-in handling for date formats using dateFormat, altFormat, etc.
  @Input() hourIncrement: number; // 	1	Adjusts the step for the hour input (incl. scrolling)
  @Input() inline: boolean; // 	false	Displays the calendar inline
  @Input() maxDate: string; // Date	null	The maximum date that a user can pick to (inclusive).
  @Input() minDate: string; // Date	null	The minimum date that a user can start picking from (inclusive).
  @Input() minuteIncrement = 1; // 	5	Adjusts the step for the minute input (incl. scrolling)
  @Input() mode: 'single' | 'multiple' | 'range' | 'time'; // 	"single"	"single", "multiple", or "range"
  @Input() nextArrow: string; // 	>	HTML for the arrow icon, used to switch months.
  @Input() noCalendar: boolean; // 	false	Hides the day selection in calendar. Use it along with enableTime to create a time picker.
  @Output() onChange = new EventEmitter<any>(); // , [functions]	null	Function(s) to trigger on every date selection. See Events API
  @Output() onClose = new EventEmitter<any>(); // , [functions]	null	Function(s) to trigger on every time the calendar is closed. See Events API
  @Output() onOpen = new EventEmitter<any>(); // , [functions]	null	Function(s) to trigger on every time the calendar is opened. See Events API
  @Output() onReady = new EventEmitter<any>(); // , [functions]	null	Function to trigger when the calendar is ready. See Events API
  @Output() onIconClick = new EventEmitter<any>();
  @Input() iconClass: 'string';
  @Input() position: 'auto' | 'above' | 'below' = 'below'; // 	"auto"	Where the calendar is rendered relative to the input.
  @Input() prevArrow: string; // 	<	HTML for the left arrow icon.
  @Input() shorthandCurrentMonth: boolean; // 	false	Show the month using the shorthand version (ie, Sep instead of September).
  @Input() static: boolean; // 	false	Position the calendar inside the wrapper and next to the input element*.
  @Input() time_24hr: boolean; // 	false	Displays time picker in 24 hour mode without AM/PM selection when enabled.
  @Input() weekNumbers: boolean; // 	false	Enables display of week numbers in calendar.
  @Input() wrap: boolean; // 	false	Custom elements and input groups
  @Input() label: string;
  @Input() inputStyle: any = {};
  @Input() disabled: boolean;
  @Input() required: boolean;
  @Input() tooltip: string;
  _timeZone = 'UTC';
  @Input() set timeZone(timeZone: string) {
    this._timeZone = timeZone || this._timeZone;
    if (this.dateInput) {
      /**
       * If `date` is added before `timeZone` when rendering component:
       *
       * <fg-date-picker
       *   [date]="editingConvention.startDate"
       *   [timeZone]="editingConvention.timeZoneId"
       * />
       *
       * The set Date will be in UTC because the default value has not yet be overridden.
       * By storing "this.dateInput" we can update the this._date with the corect timezone offset.
       */
      this._date = flatpickr.formatDate(utcToZonedTime(this.dateInput, timeZone), this.dateFormat);
    }
  }

  delayuserInputChange$ = new Subject<any>();
  fP: any;
  errorClass: boolean;
  dateInput: string;
  _date: string;
  @Input() set date(_date: string) {
    // necessary dependant on timeZone placement, see `@Input() set timeZone` for more detail
    if (_date) {
      this.dateInput = _date;
      const zonedTime = utcToZonedTime(_date, this._timeZone);
      this._date = flatpickr.formatDate(zonedTime, this.dateFormat);

      // If input has changed without re initializing element, reset the date on fP element.
      // Otherwise initial date will remain.
      if (this.fP) this.fP.setDate(zonedTime, false);
    } else if (this.fP) this.fP.clear();
  }

  constructor(private uiService: UiService) {
    this.delayuserInputChange$.pipe(debounceTime(1000)).subscribe(date => {
      this.errorClass = false;
      if (!date || this.dateValidator(date)) {
        this.fP.setDate(date, false);
        this._onChange(date);
      } else this.errorClass = true;
    });
  }

  ngAfterViewInit() {
    const config = cleanObject({
      altFormat: this.altFormat,
      altInput: this.altInput,
      altInputClass: this.altInputClass,
      allowInput: this.allowInput,
      appendTo: this.appendTo ?? this.datePicker?.nativeElement?.parentElement,
      ariaDateFormat: this.ariaDateFormat,
      clickOpens: this.clickOpens,
      dateFormat: this.dateFormat,
      defaultDate: this.defaultDate,
      defaultHour: this.defaultHour,
      defaultMinute: this.defaultMinute,
      disable: this.disable,
      disableMobile: this.disableMobile,
      enable: this.enable,
      enableTime: this.enableTime,
      enableSeconds: this.enableSeconds,
      formatDate: this.formatDate,
      hourIncrement: this.hourIncrement,
      inline: this.inline,
      maxDate: this.maxDate,
      minDate: this.minDate,
      minuteIncrement: this.minuteIncrement,
      mode: this.mode,
      nextArrow: this.nextArrow,
      noCalendar: this.noCalendar,
      onClose: this._onClose,
      onOpen: this._onOpen,
      onReady: this._onReady,
      position: this.position,
      prevArrow: this.prevArrow,
      shorthandCurrentMonth: this.shorthandCurrentMonth,
      static: this.static,
      time_24hr: this.time_24hr,
      weekNumbers: this.weekNumbers,
      wrap: this.wrap
    });
    this.fP = flatpickr(this.datePicker.nativeElement, config);
    this.fP.setDate(this._date);
  }

  _onChange = (date: Date) => {
    const _date = new Date(date);
    if (date && _date.toString() === 'Invalid Date') {
      return this.uiService.toast({
        severity: 'error',
        detail: 'Invalid Date(s) provided.'
      });
    }
    return this.onChange.emit(date ? zonedTimeToUtc(_date, this._timeZone).toISOString() : null);
  };

  _onClose = () => {
    const currentDate = this.getCurrentDateTime();
    if (currentDate === null) return this._onChange(null);
    if (!this.dateValidator(currentDate))
      return this.uiService.toast({
        severity: 'error',
        detail: 'Invalid Date(s) provided.'
      });
  };

  _onOpen = () => {
    if (this.onOpen) {
      this.onOpen.emit();
    }
  };

  _onReady = () => {
    if (this.onReady) {
      this.onReady.emit();
    }
  };

  dateValidator(date) {
    const pattern = this.enableTime
      ? /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4} (([1-9]|1[0-2])|(0[1-9])):[0-5][0-9] (AM|PM)$/gi
      : /^(0[1-9]|1[0-2])\/(0[1-9]|[1-2][0-9]|3[0-1])\/\d{4}$/gi;
    return pattern.test(date);
  }

  getCurrentDateTime() {
    if (!this.datePicker?.nativeElement?.value || !this.fP) return null;
    const min = `0${this.fP.minuteElement?.value ?? '0'}`.slice(-2);
    const hr = `0${this.fP.hourElement?.value ?? '0'}`.slice(-2);
    const day = `0${this.fP.selectedDateElem?.textContent ?? '0'}`.slice(-2);
    const month = `0${1 + Number(this.fP.monthElements[0]?.value ?? 0)}`.slice(-2);
    const year = `20${this.fP.currentYearElement?.value}`.slice(-4);
    const amPM = (this.fP.amPM?.textContent ?? '').toUpperCase();
    return this.enableTime
      ? `${month}/${day}/${year} ${hr}:${min} ${amPM}`
      : `${month}/${day}/${year}`;
  }
}

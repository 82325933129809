import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IConventionCelebrity } from '@fg-types/convention-celebrity';

@Component({
  selector: 'connected-guests',
  templateUrl: './connected-guests.component.html',
  styleUrls: ['./connected-guests.component.scss']
})
export class ConnectedGuestsComponent implements OnInit {
  @Input() guests: IConventionCelebrity[] = [];
  @Input() disabled: boolean;
  @Input() conventionId: string;
  @Output() addGuest = new EventEmitter<IConventionCelebrity>();
  @Output() deleteGuest = new EventEmitter<{ guest: IConventionCelebrity; index: number }>();

  constructor() {}

  ngOnInit() {}
}

import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { OverlayPanel } from 'primeng/overlaypanel';

export interface FilterValue {
  label: string;
  value: string;
}

@Component({
  selector: 'overlay-filter',
  templateUrl: './overlay-filter.component.html',
  styleUrls: ['./overlay-filter.component.scss']
})
export class OverlayFilterComponent implements OnChanges {
  @ViewChild('overlayPanel', { static: true }) overlayPanel: OverlayPanel;
  @Input() title: string;
  @Input() values: FilterValue[];
  @Input() selectedValue: FilterValue;
  @Output() onChange = new EventEmitter<FilterValue>();

  constructor() {}

  ngOnChanges() {}

  show(event: Event) {
    this.overlayPanel.show(event);
  }

  emit() {
    this.onChange.emit(this.selectedValue);
    this.overlayPanel.hide();
  }

  reset() {
    this.selectedValue = undefined;
    this.emit();
  }
}

<div class="input-container p-col-12">
  <div class="input-cell">
    <label *ngIf="label">{{ label }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="adPartner"
        [suggestions]="filteredAdPartners"
        placeholder="Type to search an Ad Partner"
        field="adPartnername"
        (completeMethod)="filter($event)"
        (onSelect)="onSelect($event)"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <generic-tile
            *ngIf="item.id; else createNew"
            [data]="item"
            hoverActive="1"
          ></generic-tile>
          <ng-template #createNew>{{ item }}</ng-template>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

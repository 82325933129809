import { HttpHeaders, HttpParams } from '@angular/common/http';
import { ApiBase } from '@fg-services/api/models/api-base';
import { ApiModelBase } from '@fg-types/api-base-model';
import { IConvention } from '@fg-types/convention.type';
import { CreatorData } from '@fg-types/creator';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { UserRoles, UserRolesReponse } from '@fg-types/roles.type';
import { NotificationPreferernces, UserPreferences } from '@fg-types/user-preferences';
import { IUser, IUserLoginCredentials } from '@fg-types/user.type';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '@fg-environments/environment';

interface SignUpInfo {
  name: string;
  title?: string;
  username: string;
  email: string;
  password: string;
}

export class UserApi extends ApiBase {
  model = 'users';
  private userPreferencesUrl = `${this.apiBase}/Preferences/users`;
  private notificationPreferencesUrl = `${this.apiBase}/Preferences/Notifications`;
  private API_URL = environment.API_URL + environment.API_VERSION;

  login(credentials: IUserLoginCredentials) {
    return this.http.post<IUser>(`${this.modelBase}/login`, credentials);
  }

  fbLogin(token: string) {
    return this.http.post<IUser>(`${this.modelBase}/login/facebook`, { token });
  }

  appleLogin(idToken: string) {
    return this.http.post<IUser>(`${this.modelBase}/login/apple`, { idToken });
  }

  logout() {
    return this.http.post<void>(`${this.modelBase}/logout`, {});
  }

  userCreators(userId: string,lastKey:string){
    const params = new HttpParams();
        if (lastKey) params.set('lastKey', lastKey ? JSON.stringify(lastKey) : null);
        return this.http.get<PaginatedResponse<CreatorData>>(`${this.modelBase}/${userId}/Creators`, { params });

  }
  
  getConventionConAdmin(id: string) {
    return this.http.get<PaginatedResponse<IConvention>>(this.conventionConAdminUrl(id));
  }

  getModelId<T extends ApiModelBase>(model: T) {
    return model.id;
  }

  getRoles(id: string): Observable<UserRoles> {
    return this.http.get<UserRolesReponse>(this.userRolesUrl(id)).pipe(
      map(roles => ({
        ...roles,
        consAdminingIds: roles.consAdmining.map(c => c.convention && c.convention.id),
        consModeratingIds: roles.consModerating.map(c => c.convention && c.convention.id),
        fandomsAdminingIds: roles.fandomsAdmining.map(this.getModelId),
        fandomsModeratingIds: roles.fandomsModerating.map(this.getModelId),
        organizationsAdminingIds: roles.organizationsAdmining.map(this.getModelId),
        organizationsModeratingIds: roles.organizationsModerating.map(this.getModelId),
        creatorAdminingIds : roles.creatorsAdmining.map(this.getModelId),
        creatorModeratingIds: roles.creatorsModerating.map(this.getModelId) 
      }))
    );
  }

  authenticated(accessToken?: string) {
    const options = accessToken ? { headers: new HttpHeaders({ Authorization: accessToken }) } : {};
    return this.http.get<IUser>(`${this.modelBase}/authenticated`, options);
  }

  isSquareIntegrated(id: string) {
    return this.http.get<{
      isLinked: boolean;
      needsRefresh: boolean;
    }>(`${this.modelBase}/${id}/SquareIntegrations`);
  }
  updatePassword(currentPassword: string, newPassword: string) {
    const body = { currentPassword : currentPassword, newPassword: newPassword };
    return this.http.put<IUser>(`${this.modelBase}/password`, body);    
  }

  resetPassword(password: string, accessToken: string) {
    const headers = new HttpHeaders({
      Authorization: accessToken
    });
    return this.http.put(`${this.modelBase}/password/reset`, { password }, { headers });
  }

  requestPasswordReset(email: string) {
    return this.http.post(`${this.modelBase}/reset`, { email, source: 'backstage' });
  }

  getUserPreferences() {
    return this.http.get<UserPreferences>(this.userPreferencesUrl);
  }

  updateUserPreferences(changes) {
    return this.http.put<UserPreferences>(this.userPreferencesUrl, changes);
  }

  getNotificationPreferences() {
    return this.http.get<NotificationPreferernces>(this.notificationPreferencesUrl);
  }

  updateNotificationPreferences(changes) {
    return this.http.put<NotificationPreferernces>(this.notificationPreferencesUrl, changes);
  }

  userNameExists(username: string) {
    return this.http.get<IUser>(`${this.modelBase}/username/${username}`);
  }
  
  getUser(userId: string) {
    return this.http.get<IUser>(`${this.modelBase}/${userId}`);
  }

  signup(user: SignUpInfo) {
    return this.http.post<IUser>(this.modelBase, user);
  }

  patchUser(id: string, user: Partial<IUser>) {
    return this.http.patch<IUser>(`${this.modelBase}/${id}`, user);
  }

  addUserToRole(userId, role) {
    return this.http.put<IUser>(`${this.userRolesUrl(userId)}/${role}`, undefined);
  }

  removeUserFromRole(userId: string, role: string) {
    return this.http.delete<DeleteResponse>(`${this.userRolesUrl(userId)}/${role}`);
  }

  deleteUser(userId: string) {
    return this.http.delete<DeleteResponse>(this.modelUri(userId));
  }

  getUserEmail(id: string) {
    return this.http.get<{ email: string }>(`${this.modelUri(id)}/email`);
  }

  updateUserEmail(body: any) {
    return this.http.put<IUser>(`${this.modelBase}/email`, body);
  }

  updateProfile(mediaId: any) {
    return this.http.put<IUser>(`${this.modelBase}/photo`, mediaId);
  }

  getcountryList() {
    return this.http.get<{ countries: { code: string; name: string }[] }>(
      `${this.modelBase}/countryList`
    );
  }

  deleteChatMessage(eventId: string, message_id?: string){
    const requestBody ={
      message_id : message_id
    };
    return this.http.request<any>('delete',`${this.API_URL}/ConventionOpenChannels/${eventId}/deleteMessage`, {
      body: requestBody
    })
  }

  private conventionConAdminUrl = (id: string) => `${this.modelBase}/${id}/conventions`;
  private userRolesUrl = (id: string) => `${this.modelBase}/${id}/roles`;

  getActiveCreatorSubscription(id: string,params:{
    creatorid ?: string
  }){
    return this.http.get<any>(`${this.modelBase}/${id}/SubscriptionsActive`,{
      params: this.setParams(params)
    })
  }

  checkIsOrgAdmin(id: string, conventionId: string) {
    return this.http.get<any>(`${this.modelUri(id)}/convention/${conventionId}/isadmin`);
  }
}

import isNonNil from '@fg-shared/helpers/isNonNil';
import { Observable } from 'rxjs';

export class NavigationContextHelpers {
  static loader<T>(id: string, loader: Observable<T>, cache: Map<string, T>, useCache = true) {
    return new Observable<T>(subscriber => {
      const cachedValue = cache.get(id);
      // Refresh once in a while
      const refresh = Math.round(Math.random() * 5) === 1;

      if (isNonNil(cachedValue) && !refresh && useCache) {
        subscriber.next(cachedValue);
        subscriber.complete();
        return;
      }
      loader.subscribe(
        c => {
          cache.set(id, c);
          subscriber.next(c);
          subscriber.complete();
        },
        e => {
          subscriber.error(e);
          subscriber.complete();
        }
      );
    });
  }
}

<div class="input-container p-col-12">
  <div class="input-cell">
    <label *ngIf="label">{{ label }}</label>
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [(ngModel)]="post"
        [suggestions]="filteredPosts"
        [placeholder]="placeholder"
        (completeMethod)="filter($event)"
        (onSelect)="onSelect($event)"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <contest-entry-tile [data]="item" hoverActive="1"></contest-entry-tile>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

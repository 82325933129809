import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IConvention } from '@fg-types/convention.type';
import { Organization } from '@fg-types/organization.type';

@Component({
  selector: 'fg-official-account',
  templateUrl: './fg-official-account.component.html',
  styleUrls: ['./fg-official-account.component.scss']
})
export class FgOfficialAccountComponent {
  @Input() label: string;
  @Input() editingContext: IConvention | Organization;
  @Output() addUser = new EventEmitter<void>();
  @Output() removeUser = new EventEmitter<void>();
}

/* eslint-disable @typescript-eslint/member-ordering */
import { Directive, HostListener } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseComponent } from './base.component';

@Directive()
export abstract class CanDeactivateComponent extends BaseComponent {
  @HostListener('window:beforeunload')
  beforeUnload(): boolean {
    return !this.isDirty;
  }

  abstract isDirty: boolean;
  abstract canDeactivate(): boolean | Observable<boolean>;
}

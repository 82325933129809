import { ApiBase } from '@fg-services/api/models/api-base';
import { IConventionContest } from '@fg-types/convention-contest';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { IPost } from '@fg-types/post';

export class ConventionContestsApi extends ApiBase {
  model = 'ConventionContests';

  updateConventionContests(id: string, contest: Partial<IConventionContest>) {
    return this.http.patch<IConventionContest>(`${this.modelBase}/${id}`, contest);
  }

  createConventionContests(conventionContest: Partial<IConventionContest>) {
    return this.http.post<IConventionContest>(this.modelBase, conventionContest);
  }

  deleteConventionContests(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  getConventionContestsPosts(
    id: string,
    lastKey?: string,
    order?: string,
    status?: string,
    limit?: number
  ) {
    return this.http.get<PaginatedResponse<IPost>>(`${this.modelBase}/${id}/Posts`, {
      params: this.setParams({ lastKey, order, status, limit })
    });
  }
}

<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.name"
  [description]="data?.description"
  [media]="data?.image || convention?.eventImagePlaceholder"
  [hoverActive]="hoverActive"
  tileType="wide-image-tile"
  [maxLines]="1"
>
  <ng-content></ng-content>
</tile>

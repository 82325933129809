/**
 * NOTE: Answer is parsed as html
 */
export const faqs = [
  {
    question: `WHAT IS EXHIBIT HALL?`,
    answer: `<p>Exhibit hall is a marketplace created by FAN GURU that allows exhibitors to showcase and sell their items year-round. Additionally, event managers can add exhibitors to their events to create curated, event-specific Exhibit Halls.</p>`
  },
  {
    question: `CAN ANYONE JOIN EXHIBIT HALL?`,
    answer: `<p>No.  To join Exhibit Hall, you must either: (i) have participated as an official exhibitor at any event that is listed on the FAN GURU platform; or (ii) provide some basic info <a target="_blank" href="https://fanguru.typeform.com/to/kSsOQt">here</a> and be approved by FAN GURU.</p>`
  },
  {
    question: `CAN ANYONE SELL WITHIN EXHIBIT HALL?`,
    answer: `<p>In order to facilitate transactions on FAN GURU, you must operate a USA registered online store through Square and authenticate your Square account with your FAN GURU Exhibitor profile.<br><br>If you're not currently using Square, but you're interested in creating a free account so you can join Exhibit Hall, <a target="_blank" href="https://square.sjv.io/fanguru">click here to get started.</a>
    </p>`
  },
  {
    question: `WHAT IF I USE A DIFFERENT ECOMMERCE PROVIDER BESIDES SQUARE?`,
    answer: `While we’d love to support other ecommerce integrations directly on FAN GURU, we currently only integrate with Square. However, many ecommerce platforms have integrations that enable you to sync your existing inventory with a Square account, which can help you quickly tie the gap. For example, <a target="_blank" href="https://help.etsy.com/hc/en-us/articles/360000905467-Etsy-and-Square?segment=selling">Etsy</a>, <a target="_blank" href="https://apps.shopify.com/square-stock-sync">Shopify</a>, and <a target="_blank" href="https://docs.woocommerce.com/document/woocommerce-square">Woocommerce</a> are just a few examples of ecommerce platforms with Square integrations.<br><br>If you're interested in creating a free Square account, <a target="_blank" href="https://square.sjv.io/fanguru">click here to get started.</a>`
  },
  {
    question: `WHAT ARE THE FEES TO SELL WITHIN EXHIBIT HALL?`,
    answer: `<p>There are no upfront fees, listing fees or membership fees to join Exhibit Hall.  There is a 5% marketplace transaction fee, separate from Square’s standard card processing fees.</p>`
  },
  {
    question: `CAN BUYERS LEAVE AN EXTRA TIP ON THEIR ORDER?`,
    answer: `<p>Absolutely! Buyers can offer any tip value they like on their Exhibit Hall orders, and the exhibitor will retain 100% of the tip value, after Square’s processing fees.</p>`
  },
  {
    question: `HOW CAN I MAKE MONEY WITH EXHIBIT HALL?`,
    answer: `<p>Besides selling your items as an exhibitor, if you are an exhibitor or event who refers other exhibitors to the platform with your referral link, you can earn up to 1.25% of the order total (less any tips & Square processing fees) from transactions with each exhibitor you refer for 2 years from the day the referred exhibitor signed up using your referral link.<br><br>As an event manager, you’ll also earn 1.25% of the order total (less any tips & Square processing fees) from exhibitor transactions that take place within your event-specific Exhibit Hall.</p>`
  },
  {
    question: `HOW DO I CLAIM MY REFERRAL REWARDS?`,
    answer: `<p>Your referral rewards will become available for cash out 60 days after the transaction completes directly on FAN GURU. Once you have earned a minimum of $15 in referral rewards, you will be able to request a secure ACH Bank Transfer directly to your bank account.</p>`
  },
  {
    question: `AM I ALLOWED TO SELL ANYTHING WITHIN EXHIBIT HALL?`,
    answer: `<p>Items that are allowed for sale at live fandom conventions and trade shows are accepted within Exhibit Hall. “Adult content” is allowed so long as any nudity or adult content is blurred in the uploaded item images, similar to how this is handled at live conventions and trade shows.</p>`
  },
  {
    question: `HOW DOES THE SQUARE INTEGRATION WORK?`,
    answer: `<p>If you’re an existing Square vendor, simply sign into our <a target="_blank" href="https://console.fan.guru">Console</a>, navigate to <a target="_blank" href="https://console.fan.guru/eh/settings/square">Exhibit Hall > Account > Square Account</a>, and then click to Authenticate your Square account.  You will log in on Square’s site, and  complete authentication, after which your Square Categories and Items will import to FAN GURU. From there, you can choose to enable or disable any of your Categories to expose or hide all the items within them, and you can individually enable or disable items within any Category.<br><br>If an Item does not belong to a Category within your Square dashboard, the item will import to FAN GURU in a newly created "Uncategorized" category.<br><br>Due to so many events shifting online at the moment, and to serve users worldwide, your items must support Shipping as a fulfilment option within Square to be eligible for sale on FAN GURU.</p>`
  },
  {
    question: `HOW DOES SHIPPING WORK?`,
    answer: `<p>When you set up your Exhibitor Profile on FAN GURU, you will choose either Free Shipping or Flat Rate Shipping. If you choose Flat Rate, you will be able to set a base Flat Rate which can be charged Per Order, or Per Item. Additionally, you can add additional flat rate shipping charges when specific items are purchased. We do not offer support for dynamic, weight based shipping at this time.</p>`
  },
  {
    question: `HOW DO I FULFILL AN ORDER PROCESSED ON FAN GURU?`,
    answer: `<p>All of the item, customer & shipping information associated with your order will be available within your Square Dashboard for you to fulfill your order.</p>`
  },
  {
    question: `CAN I STILL PARTICIPATE IN EXHIBIT HALL IF I DON’T USE SQUARE?`,
    answer: `<p>You can still participate in Exhibit Hall and enjoy the benefits of curating your own Exhibitor Profile which can be attached to any events that you belong to! While you won’t be able to upload your inventory and process transactions on FAN GURU, you can still provide your store links in your profile to help fans discover your business.</p>`
  }
];

<div
  class="tile"
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  (click)="onClick.emit()"
>
  <div class="square-indicator">{{ data.responseCount }} <br />FANS</div>
  <div class="tile-body">
    <div class="tile-name">
      {{ data.name }}
    </div>
    <span><br />{{ data.averageAnswer | percent: '1.0-2' }}</span>
  </div>
  <div class="square-indicator percentage-bar-container">
    <percentage-bar
      [positiveCount]="data.upvoteCount"
      [negativeCount]="data.downvoteCount"
      [percentage]="data.averageAnswer * 100"
    ></percentage-bar>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

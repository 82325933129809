<div class="fg-view-container fg-with-header">
  <div class="fg-view-header">
    <router-tabs></router-tabs>
  </div>
  <div class="fg-view-body single-pane">
    <div
      class="fg-single-col wide"
      fgInfiniteScroll
      (scrolled)="loadItems()"
      [scrollFinished]="scrollFinished"
      [containerScroll]="true"
    >
      <div class="fg-col-body">
        <div class="card">
          <div class="input-container fg-header-single-col">
            <search-input
              placeholder="Search Communities"
              (onSearch)="search($event)"
            ></search-input>
            <filter-icon></filter-icon>
            <button
              pButton
              class="p-button-primary"
              type="button"
              label="CREATE NEW"
              (click)="createOverlay.show($event, query)"
            ></button>
            <create-modal
              #createOverlay
              (onCreate)="createNew($event)"
              modelName="Community"
            ></create-modal>
          </div>
          <div class="items p-grid">
            <ng-container *ngFor="let item of items; let i = index">
              <div class="p-col-12 p-lg-4 p-md-6">
                <community-card
                  (onClick)="selectItem(item)"
                  [hoverActive]="true"
                  [data]="item"
                ></community-card>
              </div>
            </ng-container>
            <div class="clearfix"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="input-container">
  <div class="input-cell">
    <span class="input-flex flex-right">
      <p-autoComplete
        [disabled]="disabled"
        [ngModelOptions]="{ standalone: true }"
        [(ngModel)]="conventionEvent"
        [suggestions]="filteredEvents"
        placeholder="Type to search a Schedule Event"
        field="name"
        (completeMethod)="filter($event)"
        (onSelect)="addEvent.emit($event); conventionEvent = ''"
        styleClass="autocomplete-tile complete-input"
        inputStyleClass="complete-input"
      >
        <ng-template let-item pTemplate="item">
          <schedule-event-tile [data]="item" hoverActive="1"></schedule-event-tile>
        </ng-template>
      </p-autoComplete>
    </span>
  </div>
</div>

import { Injectable } from '@angular/core';
import * as moment from 'moment-mini';

@Injectable()
export class TimeService {
  localToConvDate(_date: any) {
    if (_date) {
      let date: any = moment(_date);
      date = date.add(date.utcOffset(), 'm');
      if (date.isValid()) {
        return date.toISOString();
      }
    }
    return null;
  }

  convDateToLocal(_date: Date | string) {
    if (!_date) {
      return false;
    }
    let date: any = moment(_date);
    date = date.subtract(date.utcOffset(), 'm');
    if (date.isValid()) {
      return date.toDate();
    } else {
      return false;
    }
  }

  parseDBDate(date: string | Date) {
    if (!date) return undefined;
    if (typeof date === 'string') {
      const time: any = moment(date.replace(/Z/, ''));
      if (time.isValid()) {
        return time.toDate();
      }
    } else if (typeof date.getTime === 'function') {
      return date;
    }
  }
}

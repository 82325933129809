import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { IPost } from '@fg-types/post';
import { LocalStorageService } from './localstorage.service';

@Injectable()
export class PostService {
  private postUrl = `${ environment.API_URL + environment.API_VERSION }/Posts`;
  constructor( private http: HttpClient, private localStorageService: LocalStorageService ) { }

  patchPost( postId: string, post: IPost ): Observable<IPost> {
    return this.http.patch<IPost>( this.postUrl + '/' + postId, post );
  }

  createPost( post: IPost ) {
    return this.http.post<IPost>( this.postUrl, post );
  }

  reportPost( postId: string, body: any ): Observable<any> {
    return this.http.post( this.postUrl + '/' + postId + '/report', body );
  }

  getPostById( postId: string ) {
    return this.http.get<IPost>( `${ this.postUrl }/${ postId }` );
  }

  deletePostById( postId: string ) {
    // let headers = new HttpHeaders( { Authorization: '' } );
    // const options: any = {};
    return this.http.delete<any>( `${ this.postUrl }/${ postId }` );
  }

  deletePostMedia(postId: string, mediaId: string) {
    const url = `${ this.postUrl }/${postId}/media`;
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: { mediaId: mediaId }
    };
  
    return this.http.delete(url, options);
  }

}

<div class="fg-view-container">
  <div class="fg-view-body single-pane">
    <div class="fg-single-col wide fg-col-with-header">
      <div class="fg-col-header fg-col-list-header" *ngIf="!addNew">
        <div class="input-container">
          <div class="search-filter" *ngIf="userRoles?.isAdmin">
            <search-input placeholder="Search" (onSearch)="search($event)"></search-input>
            <filter-icon (onClick)="filterOverlay.show($event, filter)"></filter-icon>
            <label *ngIf="filterText" class="filter-label">{{ filterText }}</label>
          </div>
          <button
            pButton
            class="p-button-primary"
            type="button"
            label="CREATE NEW"
            (click)="onCreateNew()"
          ></button>
          <filter-events (onFilter)="filter = $event" #filterOverlay></filter-events>
        </div>
      </div>
      <!-- CREATE EVENT VIEW -->
      <div *ngIf="addNew" class="fg-col-body single-pane">
        <ng-template [ngTemplateOutlet]="newEventForm"></ng-template>
      </div>

      <!-- Events Cards -->
      <ng-template [ngIf]="!addNew">
        <!-- Admin View -->
        <div
          class="fg-col-body"
          fgInfiniteScroll
          (scrolled)="loadItems()"
          [scrollFinished]="scrollFinished"
          [containerScroll]="true"
          *ngIf="userRoles?.isAdmin; else conAdminView"
        >
          <div class="card">
            <div class="items p-grid">
              <div *ngFor="let item of items" class="p-col-12 p-md-6 p-lg-4">
                <fg-event-feed-tile
                  (onClick)="selectItem(item)"
                  [hoverActive]="true"
                  [convention]="item"
                ></fg-event-feed-tile>
              </div>

              <div class="clearfix"></div>
            </div>
          </div>
          <div
            *ngIf="!items?.length && !upcomingEvents?.length && !pastEvents?.length && loading"
            class="fg-col-body"
          >
            <div class="card">
              <div class="items p-grid">
                <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let x of [1, 2, 3, 4, 5, 6]">
                  <loading-event-card></loading-event-card>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>

<!--
  ********************************************************
  ********        View for con admins/mods        ********
  ********************************************************
 -->
<ng-template #conAdminView>
  <div>
    <p-panel
      header="Upcoming & Ongoing Events"
      [toggleable]="true"
      expandIcon="pi pi-angle-down"
      collapseIcon="pi pi-angle-up"
      toggler="header"
    >
      <div class="fg-col-body">
        <div class="items ">
          <div class="p-grid p-pl-0">
            <div *ngFor="let item of upcomingEvents" class="p-col-12 p-md-6 p-lg-4">
              <fg-event-feed-tile
                (onClick)="selectItem(item)"
                [hoverActive]="true"
                [convention]="item"
              ></fg-event-feed-tile>
            </div>
          </div>
          <div *ngIf="!upcomingEvents?.length && loadingUpcomingEvents" class="p-grid p-pl-0">
            <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let x of [1, 2, 3]">
              <loading-event-card></loading-event-card>
            </div>
          </div>
          <div *ngIf="!upcomingEvents?.length && !loadingUpcomingEvents" class="fg-col-body">
            No Events Found.
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <button
        label="Load More"
        pButton
        type="button"
        class="p-button-primary load-more"
        (click)="displayContainer = 'upcoming'; loadItems('upcoming')"
        [disabled]="upcomingScrollFinished"
      ></button>
    </p-panel>
    <p-panel
      header="Past Events"
      [toggleable]="true"
      expandIcon="pi pi-angle-down"
      collapseIcon="pi pi-angle-up"
      toggler="header"
    >
      <div class="fg-col-body">
        <div class="items">
          <div class="p-grid p-pl-0">
            <div *ngFor="let item of pastEvents" class="p-col-12 p-md-6 p-lg-4">
              <fg-event-feed-tile
                (onClick)="selectItem(item)"
                [hoverActive]="true"
                [convention]="item"
              ></fg-event-feed-tile>
            </div>
          </div>
          <div *ngIf="!pastEvents?.length && loadingPastEvents" class="p-grid p-pl-0">
            <div class="p-col-12 p-md-6 p-lg-4" *ngFor="let x of [1, 2, 3]">
              <loading-event-card></loading-event-card>
            </div>
          </div>
          <div *ngIf="!pastEvents?.length && !loadingPastEvents" class="fg-col-body">
            No Events Found.
          </div>
          <div class="clearfix"></div>
        </div>
      </div>
      <button
        label="Load More"
        pButton
        type="button"
        class="p-button-primary load-more"
        (click)="displayContainer = 'past'; loadItems('past')"
        [disabled]="pastScrollFinished"
      ></button>
    </p-panel>
  </div>
</ng-template>

<!--
  ********************************************************
  ********            CREATE EVENT VIEW           ********
  ********************************************************
 -->
<ng-template #newEventForm>
  <fg-new-convention-form
    (cancel)="addNew = false"
    (save)="createNew($event)"
  ></fg-new-convention-form>
</ng-template>

import { Route } from '@angular/router';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { AuthGuard } from '@fg-shared/guards/auth.guard';
import { EhOnBoardGuard } from '@fg-shared/guards/eh-onboard.guard';

export const ehRoute: Route = {
  path: 'eh',
  data: { segmentName: NavigableModel.Exhibitor, app: 'eh' },
  canActivate: [AuthGuard],
  children: [
    {
      path: '',
      redirectTo: 'details',
      pathMatch: 'full'
    },
    {
      path: 'details',
      canActivate: [EhOnBoardGuard],
      data:{
        Label:'Exhibitor Details',
        showButton: true,
      },
      loadChildren: () => import('./eh-details/eh-details.module').then(m => m.EhDetailsModule)
    },
    {
      path: 'inventory',
      canActivate: [EhOnBoardGuard],
      data:{
        Label:'Creator Profile',
        showButton: true,
      },
      loadChildren: () =>
        import('./eh-inventory/eh-inventory.module').then(m => m.EhInventoryModule)
    },
    {
      path: 'events',
      canActivate: [EhOnBoardGuard],
      data:{
        Label:'Events Dashboard',
        showButton: true,
      },
      loadChildren: () => import('./eh-events/eh-events.module').then(m => m.EhEventsModule)
    },
    {
      path: 'settings',
      canActivate: [EhOnBoardGuard],
      data:{
        Label:'Account',
        showButton: true,
      },
      loadChildren: () => import('./eh-settings/eh-settings.module').then(m => m.EhSettingsModule)
    },
    {
      path: 'taxes',
      canActivate: [EhOnBoardGuard],
      data:{
        Label:'Taxes',
        showButton: true,
      },
      loadChildren: () => import('./eh-taxes/eh-taxes.module').then(m => m.EhTaxesModule)
    },
    {
      path: 'on-boarding',
      data:{
        Label:'Exhibitor',
        showButton: true,
      },
      loadChildren: () =>
        import('./eh-on-boarding/eh-on-boarding.module').then(m => m.EhOnBoardingModule)
    },
    {
      path: 'tips',
      data:{
        Label:'Tips',
        showButton: true,
      },
      loadChildren: () => import('./eh-tips/eh-tips.module').then(m => m.EhTipsModule)
    }
  ]
};

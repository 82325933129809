import { Component, Input } from '@angular/core';
import { SquareItem } from '@fg-types/square';

@Component({
  selector: 'item-tile',
  templateUrl: './item-tile.component.html',
  styleUrls: ['./item-tile.component.scss']
})
export class ItemTileComponent {
  @Input() item: SquareItem;
  @Input() width = '130px';

  get image() {
    const image = this.item.media?.[0]?.url ?? this.item?.squareData?.item_data?.image_url;
    return image ? `url(${image})` : undefined;
  }
}

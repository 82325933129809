<div *ngIf="resetUploader" class="select-image-container" [ngClass]="classContainer">
  <div class="select-image-header" *ngIf="header">{{ header }}</div>
  <ng-content></ng-content>
  <div *ngIf="finalWidth && finalHeight" class="select-image-subheader">
   JPG or PNG image, {{ finalWidth }}px x {{ finalHeight }}px
  </div>
  <div
    class="drop-box needsclick auadd-modal-file-drop"
    [style.margin]="marginAuto ? 'auto' : '0'"
    [style.width]="width"
    [style.height]="height"
    [style.border-radius]="borderRadius"
    [style.background-image]="sanitizedImageUrl"
    [style.background-size]="backgroundSizeOverride"
  >
    <div
      class="imageContainer"
      [ngClass]="{
        'image-placeholder': !sanitizedImageUrl,
        'not-allowed': disabled
      }"
      [style.background-size]="containBackgroundImage ? 'contain' : '50%'"
      [style.padding-bottom]="paddingBottom"
      (click)="inputFile.click()"
    >
      <div>
        {{ sanitizedImageUrl ? '&nbsp;' : '' }}
      </div>
      <input
        [disabled]="disabled"
        type="file"
        accept="image/*"
        (change)="selectFile($event)"
        #inputFile
        [multiple]="multiple"
      />
    </div>
    <a *ngIf="showDeleteIcon" href class="delete-icon" (click)="onDelete.emit(); (false)">
      <i class="pi pi-times"></i>
    </a>
    <br />
  </div>
  <div (click)="inputFile.click()" class="select-image-footer" [ngClass]="{'select-image-footer1': 'buttonColor'}" *ngIf="footer">{{ footer }}</div>
  <p-progressBar #progressBar class="p-col-12 no-padding" [class.hide]="!loading"></p-progressBar>
</div>
<crop-image
  *ngIf="selectedImage"
  [finalWidth]="finalWidth"
  [finalHeight]="finalHeight"
  [style.border-radius]="borderRadius"
  [selectedImage]="selectedImage"
  [isGif]="isGif"
  (cancel)="selectedImage = undefined"
  (selectImage)="selectImage($event)"
></crop-image>

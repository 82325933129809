import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { SquareItem } from '@fg-types/square';

@Component({
  selector: 'search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.scss']
})
export class SearchItemComponent {
  @Input() disabled: boolean;
  @Input() conventionId?: string;
  @Input() exhibitorId?: string;
  @Input() createNewText: string;
  @Output() addItem = new EventEmitter<SquareItem>();
  @Input() title = 'Add Item';
  @Output() queryChange = new EventEmitter<string>();
  filteredItems: (SquareItem | string)[] = [];
  item = '';

  constructor(private api: ApiService) {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.queryChange.emit(params.query);
      this.api.search
        .searchItem({
          query: params.query,
          conventionId: this.conventionId,
          exhibitorId: this.exhibitorId
        })
        .subscribe(res => {
          this.filteredItems = [...res.items, ...(this.createNewText ? [this.createNewText] : [])];
        });
    }
  }
}

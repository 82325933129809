/* eslint-disable prefer-arrow/prefer-arrow-functions */
import { IConvention } from '@fg-types/convention.type';
import { Theme } from '@fg-types/theme.type';

export class ConventionHelpers {
  static getImageUrl(convention: IConvention) {
    if (!convention) return 'https://static.cosmunity.com/images/default-event-background.jpg';
    if (convention.logoHorizontal) return convention.logoHorizontal.url;
    return 'https://static.cosmunity.com/images/default-event-background.jpg';
  }
}

export function conventionToTheme(convention: Partial<IConvention> = {}): Theme {
  return {
    primaryColor: convention.primaryColor,
    secondaryColor: convention.secondaryColor,
    labelColor: convention.labelColor,
    headerColor: convention.secondaryColor,
    headerLabelColor: convention.labelColor,
    accentColor: convention.primaryColor,
    iconColor: convention.primaryColor,
    searchTextColor: convention.searchTextColor
  };
}

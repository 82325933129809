import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'filter-icon',
  templateUrl: './filter-icon.component.html',
  styleUrls: ['./filter-icon.component.scss']
})
export class FilterIconComponent implements OnInit {
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit() {}
}

<div
  class="tile"
  [class.active]="active"
  [class.hover-active]="hoverActive"
  (click)="onClick.emit()"
>
  <div class="tile-body">
    <div class="tile-title">
      <div class="title">{{ data?.title }}</div>
      <div class="send-to" *ngIf="data.sentCount || data.calculatedSendingToCount">
        <div *ngIf="data.sentCount; else sendingTo">
          SENT TO: <span class="send-count">{{ data.sentCount }} FANS</span>
        </div>
        <ng-template #sendingTo>
          <div>
            SENDING TO: <span class="send-count">{{ data.calculatedSendingToCount }} FANS</span>
          </div>
        </ng-template>
      </div>
    </div>
    <div *ngIf="description" class="tile-description">
      <div [innerHTML]="description"></div>
    </div>
  </div>
</div>
<div *ngIf="active" class="active-indicator"></div>

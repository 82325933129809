import { Theme } from '@fg-types/theme.type';
import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ColorPalette extends ApiBase {
  model = 'ColorPalettes';

  getAll({ modelName, lastKey, limit }: { modelName?: string; lastKey?: string; limit?: number }) {
    const params = this.setParams({ modelName, lastKey, limit });
    return this.http.get<PaginatedResponse<Theme>>(this.modelBase, { params });
  }

  patchColorPalette(id: string, palette: Theme) {
    return this.http.patch<Theme>(`${this.modelBase}/${id}`, palette);
  }

  deleteColorPalette(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  createColorPalette(palette: Theme) {
    return this.http.post<Theme>(this.modelBase, palette);
  }
}

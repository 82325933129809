<div class="percentage-bar-container">
  <div class="count-text">
    {{ positiveCount }}
  </div>
  <div class="bar">
    <div [style.width]="percentage + '%'" class="green"></div>
  </div>
  <div class="count-text">
    {{ negativeCount }}
  </div>
</div>

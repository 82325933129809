import { Directive, OnDestroy } from '@angular/core';
import { NavigationContext } from '@fg-services/navigation-context/navigation-context';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { Subscription } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
  subscriptions: Subscription[] = [];
  get organization() {
    const orgData = this.navigationContext.getOrganizationData();
    if (!orgData) return;
    return orgData.organization;
  }

  get convention() {
    return this.navigationContext.getConvention();
  }

  navigationContext: NavigationContext;
  constructor(protected navigationContextService: NavigationContextService) {
    const subs = this.navigationContextService.getContextAsObservable().subscribe(c => {
      this.navigationContext = c;
      this.onContextChange();
    });
    this.subscriptions.push(subs);
  }

  onContextChange = () => {};
  ngOnDestroy() {
    this.subscriptions.filter(s => s && s.unsubscribe).forEach(s => s.unsubscribe());
  }
}

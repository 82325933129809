<div
  class="items-list"
  fgInfiniteScroll
  (scrolled)="onScroll.emit()"
  [scrollFinished]="scrollFinished"
  [containerScroll]="containerScroll"
>
  <ng-template #loadingPlaceholder>
    <loading-list-item></loading-list-item>
  </ng-template>
  <div *ngIf="items?.length || !loading; else loadingPlaceholder">
    <p-dataView emptyMessage="No Records Found" [value]="items">
      <ng-template let-item let-i="index" pTemplate="listItem">
        <list-item
          [data]="item"
          [active]="selectedItem?.id === item.id"
          [itemComponent]="itemComponent"
          (onClick)="onClickItem.emit(item)"
        ></list-item>
      </ng-template>
    </p-dataView>
  </div>
</div>

export const devChatTemplate = {
  mediaId: '5c8805595ae3ba00161b583a',
  media: {
    ownerId: '5be070a1b65dea00179f3820',
    mimetype: 'image/png',
    s3Bucket: 'cm-sandbox-media',
    s3Key: '5be070a1b65dea00179f3820/9a93b2a9-cbfe-4411-895d-a88ebb0067eb.png',
    url:
      'https://cm-sandbox-media.s3.amazonaws.com/5be070a1b65dea00179f3820/9a93b2a9-cbfe-4411-895d-a88ebb0067eb.png',
    sizes: [
      {
        url:
          'https://cm-sandbox-media.s3.amazonaws.com/5be070a1b65dea00179f3820/9a93b2a9-cbfe-4411-895d-a88ebb0067eb.png',
        status: 'processed',
        mimetype: 'image/png',
        width: 360,
        height: 360
      }
    ],
    id: '5c8805595ae3ba00161b583a',
    createdAt: '2019-03-12T19:15:37.219Z',
    updatedAt: '2019-03-12T19:15:37.542Z'
  }
};
export const prodChatTemplate = {
  mediaId: '5c88070eee488300169964ac',
  media: {
    ownerId: '5ba0471f71ea960028a55956',
    mimetype: 'image/png',
    s3Bucket: 'cm-live-media',
    s3Key: '5ba0471f71ea960028a55956/23ec667c-f9e4-4f1a-a7c6-53e9e6e91437.png',
    url:
      'https://cm-live-media.s3.amazonaws.com/5ba0471f71ea960028a55956/23ec667c-f9e4-4f1a-a7c6-53e9e6e91437.png',
    sizes: [
      {
        url:
          'https://cm-live-media.s3.amazonaws.com/5ba0471f71ea960028a55956/23ec667c-f9e4-4f1a-a7c6-53e9e6e91437.png',
        status: 'processed',
        mimetype: 'image/png',
        width: 360,
        height: 360
      }
    ],
    id: '5c88070eee488300169964ac',
    createdAt: '2019-03-12T19:22:53.934Z',
    updatedAt: '2019-03-12T19:22:54.128Z'
  }
};

<div class="fg-col-body">
  <div class="card">
    <p-card>
      <div class="p-grid p-mt-3">
        <div class="name-container p-col-12 p-md-6 p-pl-0 p-ai-start">
          <div class="input-container p-col-12">
            <div class="input-cell">
              <label>NAME</label>
              <input
                pInputText
                name="name"
                type="text"
                [(ngModel)]="category.name"
                (ngModelChange)="onChange.emit()"
              />
            </div>
          </div>
        </div>
        <div class="input-container p-col-12 p-md-6 p-pl-0">
          <div class="input-cell">
            <cropper
              [img]="category?.media?.url"
              (setImage)="updateImage($event, 'media')"
              width="100%"
              ratio="9:16"
              [showDeleteIcon]="!!category?.media?.url"
              (onDelete)="deleteImage()"
            ></cropper>
          </div>
        </div>
        <div class="input-container p-col-12 p-md-6 p-pl-0">
          <div class="input-cell">
            <p-fileUpload
              name="demo[]"
              accept="image/svg+xml"
              (uploadHandler)="selectImage($event)"
              chooseLabel="ICON MEDIA (SVG)"
              [showUploadButton]="false"
              [showCancelButton]="false"
              (onSelect)="selectImage($event)"
              ><ng-template pTemplate="content"></ng-template
            ></p-fileUpload>
            <p-progressBar #progressBar class="no-padding" [hidden]="!svg_loading"></p-progressBar>
          </div>
        </div>
        <div class="input-container p-col-12 p-md-6 p-pl-0" *ngIf="category?.iconMedia?.url">
          <div class="input-cell">
            <label>ICON MEDIA</label>
            <div
              class="logo"
              [style.width]="'100%'"
              style.padding-bottom="100px"
              [style.-webkit-mask]="svgPreview"
              [style.mask]="svgPreview"
            ></div>
          </div>
        </div>
      </div>
    </p-card>
  </div>
</div>

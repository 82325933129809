<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="data?.name"
  [media]="(data?.image?.sizes)[0]?.previewUrl || data?.image"
  tileType="wide-image-tile"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

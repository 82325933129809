import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component( {
    selector: 'fg-custom-req-card',
    templateUrl: './custom-req-card.component.html',
    styleUrls: [ './custom-req-card.component.scss' ]
  } )
export class CustomRequestCardComponent implements OnInit{
  ngOnInit(): void {

  }
    data: any;
    active?: boolean;
    hoverActive?: boolean;
    postMenuVisible?: boolean;

    @Input() _data: { id: number; name: string; email: string; description: string; message: string; private: boolean; date: string; isCompleted: boolean; };
    @Output() onMark = new EventEmitter<any>();

    

        
}
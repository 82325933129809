import { ApiBase } from '@fg-services/api/models/api-base';
import { PaginatedResponse } from '@fg-types/paginated-response';
import {
  Survey,
  SurveyQuestion,
  SurveyResponse,
  SurveyResponseData,
  SurveyType
} from '@fg-types/survey';

export class SurveyApi extends ApiBase {
  model = 'Surveys';

  getSurveys(modelNames: string, modelIds: string, type: SurveyType, lastkey?: string) {
    return this.http.get<PaginatedResponse<Survey>>(this.modelBase, {
      params: this.setParams({ modelNames, modelIds, type, lastkey })
    });
  }

  getSurveyResponses(id: string) {
    return this.http.get<PaginatedResponse<SurveyResponse>>(`${this.modelBase}/${id}/Responses`);
  }

  getSurveyResponseData(id: string) {
    return this.http.get<SurveyResponseData>(`${this.modelBase}/${id}/ResponseData`);
  }

  exportSurveyResponses(id: string) {
    return this.http.get<any[]>(`${this.modelBase}/${id}/export`);
  }

  addQuestion(id: string, question: SurveyQuestion) {
    return this.http.post<SurveyQuestion>(`${this.modelBase}/${id}/question`, question);
  }

  deleteQuestionById(surveyId: string, questionId: string) {
    return this.http.delete(`${this.modelBase}/${surveyId}/question/${questionId}`);
  }

  toggleSurveyVisibility(id: string, survey: Survey) {
    return this.http.patch<Survey>(`${this.modelBase}/${id}`, survey);
  }

  editQuestion(surveyId: string, id, question: Partial<SurveyQuestion>) {
    return this.http.patch<Survey>(`${this.modelBase}/${surveyId}/question/${id}`, question);
  }

  create(survey: Partial<Survey>) {
    return this.http.post<Survey>(this.modelBase, survey);
  }

  editResponseToSurvey(surveyId: string, surveyResponseId: string, surveyOwnerResponse: string) {
    return this.http.patch(`${this.modelBase}/${surveyId}/Responses/${surveyResponseId}`, {
      surveyOwnerResponse
    });
  }
}

import { ApiBase } from '@fg-services/api/models/api-base';
import { AugmentedRealityAsset, AugmentedRealityMarker } from '@fg-types/challenge';
import { DeleteResponse } from '@fg-types/delete-response';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ARAssetsApi extends ApiBase {
  model = 'AugmentedRealityAssets';

  getArAssets(query?: string, lastKey?: string) {
    const params = this.setParams({ lastKey, query });
    return this.http.get<PaginatedResponse<AugmentedRealityAsset>>(this.modelBase, { params });
  }

  createArAsset(body: Partial<AugmentedRealityAsset>) {
    return this.http.post<AugmentedRealityAsset>(this.modelBase, body);
  }

  updateArAsset(id: string, body: Partial<AugmentedRealityAsset>) {
    return this.http.patch<AugmentedRealityAsset>(`${this.modelBase}/${id}`, body);
  }

  deleteArAsset(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }

  getArAssetById(id: string) {
    return this.http.get<AugmentedRealityAsset>(`${this.modelBase}/${id}`);
  }
}

<div (click)="onClick.emit(convention)" class="event-feed-tile" [class.hover-active]="hoverActive">
  <div
    class="header-image"
    [ngStyle]="{
      'background-image': imageUrl ? 'url(\'' + imageUrl + '\')' : null,
      'max-width': tileWidth
    }"
  >
    <i *ngIf="showRemove" (click)="onRemove.emit()" class="pull-right pi pi-check pointer"></i>
    <div class="go-live-status p-grid p-align-center">
      <div
        class="indicator"
        [style.background-color]="indicatorColorMap[convention?.goLive] || '#5cb85c'"
      ></div>
      <div>{{ convention?.goLive }}</div>
    </div>
  </div>
  <div class="convention-details" [class.small]="small">
    <div class="title">{{ convention.name }}</div>
    <div>
      {{ conventionDate }}
    </div>
    <!--  NOTE :: There is been issue with parsedAddress to fetch the appropreate data, need to investigate further. Till then manually entering the address -->
    <!-- <div>
      {{
        convention?.parsedAddress?.fullAddress?.city
          ? convention?.parsedAddress?.fullAddress?.city + ','
          : ''
      }}
      {{ convention?.parsedAddress?.fullAddress?.administrativeLevels?.level1short }} <br />
      {{ convention?.facility
      }}{{
        !!convention?.facilityExtension
          ? ', ' + convention?.facilityExtension
          : convention?.facilityExtension
      }}
    </div> -->
    <div>
      {{ convention?.facility ? convention?.facility : "" }} {{ convention?.facilityExtension ?  ', ' + convention?.facilityExtension : "" }} <br />
      {{ convention?.fullAddress }}
    </div>
  </div>
  <!-- <ng-content select="[footer]"></ng-content> -->
</div>

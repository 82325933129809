import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { IPost } from '@fg-types/post';
import * as moment from 'moment-mini';

@Component( {
  selector: 'fg-post-card',
  templateUrl: './post-card.component.html',
  styleUrls: [ './post-card.component.scss' ]
} )
export class PostCardComponent implements ListItem<IPost> {
  @Input() data: IPost;
  @Input() showMenu = false;
  @Output() onClick = new EventEmitter<void>();
  @Output() onCopyClick = new EventEmitter<any>();
  @Output() onEditClick = new EventEmitter<any>();
  @Output() onDeleteClick = new EventEmitter<any>();
  @Input() status: { color: string; status: string };
  @Input() active?: boolean;
  postMenuVisible: boolean;
  square: boolean;
  currentMedia = 0;
  videoReset: boolean;
  get timeAgo() {
    return moment( this.data.createdAt ).fromNow();
  }

  formattedCount( count = 0 ) {
    return count < 1000 ? count : `${ Math.floor( count / 1000 ) }k`;
  }

  updateCurrentMedia( increment: number ) {
    this.videoReset = true;
    setTimeout( () => {
      this.currentMedia = this.currentMedia + increment;
      this.videoReset = false;
    }, 1 );
  }
}

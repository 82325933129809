import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { IConvention } from '@fg-types/convention.type';

export type CategorySource =
  | 'celebrityTypes'
  | 'exhibitorTypes'
  | 'eventType'
  | 'eventTags'
  | 'locationPrimary';

type CategoryItem = { label: string; value: string; color?: string } | string;
interface EventTags {
  label: string;
  value: string;
  color?: string;
}
@Component({
  selector: 'category-list',
  templateUrl: './category-list.component.html',
  styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent extends BaseComponent implements OnInit {
  @Input() otherConvention: IConvention;
  @Input() title: string;
  @Input() allowNewCategories = true;
  @Input() cardWrapper = true;
  @Input() placeholder: string;
  @Input() tooltip: string;
  @Input() modelName: string;
  @Input() disabled: boolean;
  @Input() showCount: boolean;
  @Input() containerClass: string;
  @Input() selectedCategories: string[];
  @Input() source: CategorySource;
  @Output() onSelect = new EventEmitter<string[]>();
  allCategories: CategoryItem[];

  newCategory: string;

  constructor(
    private api: ApiService,
    private uiService: UiService,
    private apiCallsInProgress: ApiCallsInProgress,
    navigationContextService: NavigationContextService
  ) {
    super(navigationContextService);
  }

  ngOnInit() {
    this.parseCategories();
  }

  onContextChange = () => {
    this.parseCategories();
  };

  parseCategories() {
    const convention = this.otherConvention || this.convention;
    if (!convention) return;
    const types = convention[this.source] || [];
    this.allCategories = types.map(type => {
      const label = typeof type === 'string' ? type : type.name;
      return {
        label: this.showCount && type.count ? `${label} (${type.count})` : label,
        value: label,
        color: type.color
      };
    });
    if (this.source === 'eventTags') {
      this.allCategories.sort((a: EventTags, z: EventTags) => a.label.localeCompare(z.label));
    }
  }

  addEvent(name: string) {
    // Don't add it if already exists
    const existing = this.allCategories.find(item =>
      typeof item === 'string'
        ? item.toString() === name.toLowerCase()
        : item.label.toLowerCase() === name.toLowerCase()
    );
    if (existing) {
      this.newCategory = '';
      this.uiService.toast({
        severity: 'error',
        summary: 'Error:',
        detail: 'Category already exists'
      });
      return;
    }
    const convention = this.otherConvention || this.convention;
    this.apiCallsInProgress.addCall();
    const source = convention[this.source]?.slice() || [];
    this.api.conventions
      .updateConvention(convention.id, {
        [this.source]: [{ name }, ...source]
      })
      .subscribe(
        savedCon => {
          this.navigationContextService.updateConvention(convention.id, {
            [this.source]: savedCon[this.source]
          });
          this.navigationContextService.updateNavigationContext();
          this.apiCallsInProgress.reset();
          this.newCategory = '';
          this.onSelect.emit([...(this.selectedCategories || []), name]);
        },
        err => {
          this.apiCallsInProgress.reset();
          this.uiService.toast({
            severity: 'error',
            summary: 'Error:',
            detail: err.statusText
          });
        }
      );
  }
}

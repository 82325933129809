import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { RouterTab } from '../router-tabs/router-tabs.component';

@Component({
  selector: 'router-tabs-container',
  templateUrl: './router-tabs-container.component.html',
  styleUrls: ['./router-tabs-container.component.scss']
})
export class RouterTabsContainerComponent {
  routes: RouterTab[] = this.route.snapshot.data.routes;
  constructor(private route: ActivatedRoute) {}
}

<div class="p-datalist p-component">
  <div class="p-datalist-content p-component-content">
    <div class="p-datalist-data">
      <div class="tile">
        <div class="tile-media fading"></div>
        <div class="tile-body">
          <div class="title fading"></div>
          <div class="desc fading"></div>
        </div>
      </div>
    </div>
  </div>
</div>

import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { CreatorService } from '@fg-services/dummy.service';
import { CreatorRole } from '@fg-services/navigation-context/segments/creator-segment';
import { UiService } from '@fg-services/ui.service';
import { CreatorData } from '@fg-types/creator';
import { ModelUser, ModelUserSuportedModels, ModelUserSuportedRoles } from '@fg-types/model-user';
import { IUser } from '@fg-types/user.type';

@Component({
  selector: 'team-members',
  templateUrl: './team-members.component.html',
  styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit {
  creator: CreatorData;
  adminUsers: IUser[]=[];
  modUsers:IUser[] = [];
  constructor(
    private apiCallsInProgress: ApiCallsInProgress,
    private apiService: ApiService,
    private uiService: UiService,
    private creatorService :CreatorService,
  ) { }

  ngOnInit(): void {
    this.creatorService.currentCreator$.subscribe(res=>{
      this.creator=res;
      this.config = {
        roles: [CreatorRole.CreatorAdmin, CreatorRole.CreatorMod],
        modelId: res?.id,
        modelName: 'Creator'
      };
      this.loadItems()
    })
  }
  
  config: {
    roles: ModelUserSuportedRoles[];
    modelName: ModelUserSuportedModels;
    modelId: string;
  };
  modelUsers = new Map<ModelUserSuportedRoles, ModelUser[]>();
  roleLabel = {
    [CreatorRole.CreatorAdmin]: 'Administrators',
    [CreatorRole.CreatorMod]: 'Moderators',
  };
  loadItems() {
    this.apiCallsInProgress.addCall();
    this.modelUsers.clear();
    this.config.roles.forEach(role => this.modelUsers.set(role, []));
    this.creator?.id && this.apiService.modelUser.get(this.config.modelName, this.config.modelId).subscribe(
      ({ items }) => {
        items.forEach(item => {
          const list = this.modelUsers.get(item.role);
          if (!list) console.warn(`Role ${item.role} does not exist in supported roles`);
          list.push(item);
        });
        this.apiCallsInProgress.reset();
      },
      () => {
        this.apiCallsInProgress.reset();
        this.uiService.toast({
          severity: 'error',
          summary: 'Error:',
          detail: 'There was an error processing your request'
        });
      }
    );
  }

  addUserConvention(user: IUser, role: ModelUserSuportedRoles) {
    if (!user || !user.username) return;
    const exists = this.config.roles.some(_role => {
      const list = this.modelUsers.get(_role) || [];
      return list.some(_user => _user.user && _user.user.id === user.id);
    });
    if (exists) {
      this.uiService.toast({
        severity: 'error',
        summary: 'Error:',
        detail: 'The user already exists'
      });
      return;
    }
    this.apiService.modelUser
      .add({
        modelId: this.config.modelId,
        modelName: this.config.modelName,
        role,
        userId: user.id
      })
      .subscribe(
        added => {
          const list = this.modelUsers.get(role);
          if (list) {
            list.push({ ...added, user });
          }
          this.uiService.toast({
            severity: 'success',
            summary: 'Success',
            detail: `The user has been successfully added`
          });
        },
        _ => {
          this.uiService.toast({
            severity: 'error',
            summary: 'Error:',
            detail: 'Username does not exists'
          });
        }
      );
  }
  deleteUserConvention(modelUser: ModelUser, role: ModelUserSuportedRoles) {
    this.apiCallsInProgress.addCall();
    this.apiService.modelUser.delete(modelUser.id).subscribe(
      _ => {
        const list = this.modelUsers.get(role).filter(model => model.id !== modelUser.id);
        this.modelUsers.set(role, list);
        this.uiService.toast({
          severity: 'success',
          summary: 'Success:',
          detail: 'The user has been successfully deleted from the Event'
        });
        this.apiCallsInProgress.subtractCall();
      },
      err => {
        this.uiService.toast({
          severity: 'error',
          summary: 'Error:',
          detail: 'Something is wrong x-x'
        });
        this.apiCallsInProgress.subtractCall();
      }
    );
  }
}

// Forked and customized verion of https://www.primefaces.org/primeng/#/confirmdialog
// to detect if the the rejection was made through the reject button or a close action
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CustomConfirmDialogComponent } from './custom-confirm-dialog.component';

@NgModule({
  imports: [CommonModule, ButtonModule],
  exports: [CustomConfirmDialogComponent, SharedModule, ButtonModule],
  declarations: [CustomConfirmDialogComponent]
})
export class CustomConfirmDialogModule {}

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { ConventionExhibitoSpecial } from '@fg-types/convention-exhibitor';

@Component({
  selector: 'special-tile',
  templateUrl: './special-tile.component.html',
  styleUrls: ['./special-tile.component.scss']
})
export class SpecialTileComponent implements ListItem<ConventionExhibitoSpecial> {
  @Input() data: ConventionExhibitoSpecial;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  constructor() {}
}

<div class="p-grid p-dir-col card">
  <p-panel header="NEW ORGANIZATION">
    <div class="p-grid">
      <div class="p-col-12 input-container">
        <div class="input-cell">
          <label>ORGANIZATION NAME</label>
          <input pInputText name="name" type="text" [(ngModel)]="org.name" />
        </div>
      </div>
      <div class="p-col-6 input-container">
        <div class="input-cell">
          <label>ORGANIZATION EMAIL</label>
          <input pInputText name="email" type="email" [(ngModel)]="org.billingEmail" />
        </div>
      </div>
      <div class="p-col-6 input-container">
        <div class="input-cell">
          <label
            >OFFICIAL ACCOUNT<tooltip-button
              tooltip="Push notifications will come from this account. You'll be able to change this once your Organization is created."
            ></tooltip-button
          ></label>
          <input
            readonly
            pInputText
            name="account"
            type="text"
            [value]="userService?.user?.username"
          />
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <cropper
            [img]="org?.headerMedia?.url"
            (setImage)="setImage($event)"
            width="256px"
            height="144px"
            [finalWidth]="1280"
            [finalHeight]="720"
            [marginAuto]="false"
            footer="UPLOAD"
            header="JPG or PNG image"
            [showDeleteIcon]="org?.headerMedia?.url"
            (onDelete)="deleteImage()"
          ></cropper>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </p-panel>
  <br />
  <button
    [disabled]="!org.name || !org.billingEmail"
    pButton
    class="p-button-primary full-width large"
    type="button"
    label="CREATE ORGANIZATION"
    (click)="saveConvention()"
  ></button>
  <br />
  <button
    pButton
    class="p-button-secondary full-width large"
    type="button"
    label="CANCEL"
    (click)="cancel.emit()"
  ></button>
  <br />
</div>

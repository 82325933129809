import { Component, Input } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { CelebrityService } from '@fg-services/celebrity.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'save-local-to-global',
  templateUrl: './save-local-to-global.component.html',
  styleUrls: ['./save-local-to-global.component.scss']
})
export class SaveLocalToGlobalComponent {
  @Input() selectedItem: ConventionExhibitor;
  @Input() context: 'exhibitor' | 'celebrity';

  isAdmin: boolean;
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private confirmationService: ConfirmationService,
    private celebrityService: CelebrityService,
    private apiCallsInProgress: ApiCallsInProgress
  ) {
    this.apiService.users
      .getRoles(this.localStorageService.getItem('userId'))
      .subscribe(({ isAdmin }) => (this.isAdmin = isAdmin));
  }

  saveLocalObjectAsGlobal() {
    this.apiCallsInProgress.addCall();
    return (this.context === 'exhibitor'
      ? this.apiService.exhibitors.patch(this.selectedItem.id, this.selectedItem)
      : this.celebrityService.patch(this.selectedItem)
    ).subscribe(
      () => this.apiCallsInProgress.reset(),
      () => this.apiCallsInProgress.reset()
    );
  }

  confirmSaveAsGlobal() {
    this.confirmationService.confirm({
      acceptLabel: 'SAVE GLOBALLY',
      message:
        'Are you sure you want to save this data to the global object for this exhibitor or guest?',
      header: 'Warning',
      accept: () => {
        this.saveLocalObjectAsGlobal();
      }
    });
  }
}

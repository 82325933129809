<div class="input-container">
  <div class="input-cell">
    <label>
      {{ label }}
      <tooltip-button *ngIf="tooltip" [tooltip]="tooltip"></tooltip-button>
    </label>
    <input
      id="float-input"
      [(ngModel)]="autocompleteInput"
      (ngModelChange)="handleChange(autocompleteInput)"
      ngx-google-places-autocomplete
      class="input"
      type="text"
      #placesRef="ngx-places"
      (onAddressChange)="setAddress.emit($event)"
      [class]="inputClass"
      [disabled]="disabled"
      [placeholder]="placeholder"
      [name]="name"
      [autofocus]="autofocus"
      [required]="required"
    />
  </div>
</div>

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';
import { ChannelMessage } from '@fg-types/convention-open-channel';
import { AdminMessage, FileMessage, UserMessage } from 'sendbird';

@Component({
  selector: 'channel-message-tile',
  templateUrl: './channel-message-tile.component.html',
  styleUrls: ['./channel-message-tile.component.scss']
})
export class ChannelMessageTileComponent implements ListItem<ChannelMessage> {
  @Input() data: ChannelMessage;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Output() onClickImage = new EventEmitter<string>();
  @Input() hoverActive: boolean;

  constructor() {}

  get title() {
    if (this.data.isAdminMessage()) return;
    const data = this.data as UserMessage | FileMessage;
    if (data.sender && data.sender.nickname) return data.sender.nickname;
  }

  get senderImage() {
    if (this.data.isAdminMessage()) return;
    const data = this.data as UserMessage | FileMessage;
    if (data.sender && data.sender.profileUrl) return `url(${data.sender.profileUrl})`;
  }

  get image() {
    if (!this.data.isFileMessage()) return;
    const data = this.data as FileMessage;
    if (data.url) return data.url;
  }

  get message() {
    if (this.data.isFileMessage()) return;
    const data = this.data as AdminMessage | UserMessage;
    return data.message;
  }
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { ConventionExhibitor } from '@fg-types/convention-exhibitor';
import { PaginatedResponse } from '@fg-types/paginated-response';

@Injectable()
export class ConventionExhibitorService {
  private readonly conventionExhibitorUrl: string = `${environment.API_URL +
    environment.API_VERSION}/ConventionExhibitors`;

  private readonly conventionUrl: string = `${environment.API_URL +
    environment.API_VERSION}/Conventions`;

  constructor(private http: HttpClient) {}

  mapConventionExhibitor(conventionExhibitor) {
    const growtixData = conventionExhibitor.growtixData || {};
    const imageUrl =
      growtixData.image && growtixData.image[0] && growtixData.image[0].big
        ? growtixData.image[0].big
        : null;
    return {
      ...conventionExhibitor,
      logoMedia: imageUrl ? { url: imageUrl } : null
    };
  }

  get(
    isVisible: boolean,
    lastKey?: string,
    conventionIds?: string,
    exhibitorIds?: string
  ): Observable<any> {
    const options = {
      params: new HttpParams().set('isVisible', isVisible.toString())
    };
    if (lastKey) {
      options.params = options.params.set('lastKey', lastKey);
    }
    if (conventionIds) {
      options.params = options.params.set('conventionIds', conventionIds);
    }
    if (exhibitorIds) {
      options.params = options.params.set('exhibitorIds', exhibitorIds);
    }
    return this.http
      .get<PaginatedResponse<ConventionExhibitor>>(this.conventionExhibitorUrl, options)
      .pipe(
        map(response => ({
          ...response,
          items: response.items.map(this.mapConventionExhibitor)
        }))
      );
  }

  attach(conventionExhibitorId: string, exhibitorId: string): Observable<any> {
    const options: any = {};
    return this.http.put<any>(`${this.conventionExhibitorUrl}/${conventionExhibitorId}`, {
      exhibitorId
    });
  }

  patch(id: string, exhibitorId: string, body: any) {
    return this.http.patch(`${this.conventionUrl}/${id}/ConventionExhibitors/${exhibitorId}`, body);
  }

  delete(id: string) {
    return this.http.delete(`${this.conventionExhibitorUrl}/${id}`);
  }

  setParams(paramsTemplate: any): HttpParams {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      // eslint-disable-next-line
      if (paramsTemplate.hasOwnProperty(key) && paramsTemplate[key] != undefined) {
        params = params.set(key, paramsTemplate[key]);
      }
    }
    return params;
  }
}

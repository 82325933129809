import {
  ConventionRole,
  ConventionSegment
} from '@fg-services/navigation-context/segments/convention-segment';
import { ExhibitorSegment } from '@fg-services/navigation-context/segments/exhibitor-segment';
import { FandomSegment } from '@fg-services/navigation-context/segments/fandom-segment';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import {
  OrganizationRole,
  OrganizationSegment
} from '@fg-services/navigation-context/segments/organization-segment';
import { conventionToTheme } from '@fg-shared/helpers/convention-helpers';
import { NavigationContextSegments } from '@fg-shared/services/navigation-context/types';
import { Theme } from '@fg-types/theme.type';

export class NavigationContext {
  constructor(public segments: NavigationContextSegments = []) {}

  get isConventionAdmin() {
    const segment = this.getLastSegment();
    if (!segment) return false;
    if (segment.segmentName === NavigableModel.Convention) {
      return segment.role === ConventionRole.Admin || segment.role===ConventionRole.ConAdmin;
    }
    return false;
  }

  get isOrganizationAdmin() {
    const segment = this.getLastSegment();
    if (!segment) return false;
    if (segment.segmentName === NavigableModel.Organization) {
      return segment.role === OrganizationRole.Admin || segment.role === OrganizationRole.OrgAdmin;
    }
    return false;
  }

  isConventionServiceAllowed(referenceModelName: string) {
    const segment = this.getLastSegment();
    if (!segment) return false;
    if (segment.segmentName === NavigableModel.Convention) {
      const access = segment?.model?.services?.[referenceModelName];
      return access === 'everyone';
    }
    return false;
  }

  isConventionServiceAllowedForAdmin(referenceModelName: string) {
    const segment = this.getLastSegment();
    if (!segment) return false;
    if (segment.segmentName === NavigableModel.Convention) {
      const access = segment?.model?.services?.[referenceModelName];
      return access === 'everyone' || access=='admin';
    }
    return false;
  }

  getConventionData() {
    const segment = this.segments.find(
      s => s.segmentName === NavigableModel.Convention
    ) as ConventionSegment;
    if (!segment) return;
    return segment.model;
  }

  getConvention() {
    const segment = this.segments.find(
      s => s.segmentName === NavigableModel.Convention
    ) as ConventionSegment;
    if (!segment) return;
    return segment.model.convention;
  }

  getExhibitor() {
    const segment = this.getExhibitorSegment();
    if (!segment) return;
    return segment.model?.managing?.exhibitor;
  }

  getExhibitorSegment() {
    return this.segments.find(s => s.segmentName === NavigableModel.Exhibitor) as ExhibitorSegment;
  }

  getCommunityData() {
    const segment = this.segments.find(
      s => s.segmentName === NavigableModel.Fandom
    ) as FandomSegment;
    if (!segment) return;
    return segment.model;
  }

  getOrganizationData() {
    const segment = this.segments.find(
      s => s.segmentName === NavigableModel.Organization
    ) as OrganizationSegment;
    if (!segment) return;
    return segment.model;
  }

  getLastSegment() {
    return this.segments[this.segments.length - 1];
  }

  getRoutePath(segments = this.segments) {
    const route: string[] = [];
    segments.forEach(s => {
      switch (s.segmentName) {
        case NavigableModel.Exhibitor:
          route.push('eh');
          break;
        case NavigableModel.Backstage:
          route.push('bs');
          break;
        case NavigableModel.Organization:
          route.push('o', s.modelId);
          break;
        case NavigableModel.Fandom:
          route.push('cm', s.modelId);
          break;
        case NavigableModel.Convention:
          route.push('c', s.modelId);
          break;
        case NavigableModel.OZ:
          route.push('oz');
          break;
        case NavigableModel.Creator:
          route.push('cp');
          break;
      }
    });
    return route;
  }

  getTheme(): Theme {
    const segment = this.getLastSegment();
    if (!segment) return;
    switch (segment.segmentName) {
      case NavigableModel.Convention:
        return conventionToTheme(segment.model.convention);
      case NavigableModel.Organization:
        return segment.model.organization.theme;
    }
  }
}

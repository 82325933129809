import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, NgForm } from '@angular/forms';
import { TextTransform } from '@fg-shared/helpers/text-transform';
import { MessageService } from 'primeng/api';
interface Config {
  key: string;
  fields: string[];
  form: NgForm;
}

@Component({
  selector: 'fg-card',
  templateUrl: './fg-card.component.html',
  styleUrls: ['./fg-card.component.scss']
})
export class FgCardComponent implements OnInit {
  @Input() header: string;
  @Input() styleClass: string;
  @Input() style: string;
  @Input() subheader: string;
  @Input() content: boolean;
  @Input() validationNotification: Config;
  userForm: FormGroup;

  constructor(private messageService: MessageService) {}

  ngOnInit() {
    if (this.validationNotification.form) {
      this.validationNotification.form.valueChanges.subscribe(() => this.updateFormError());
    }
  }

  updateFormError() {
    let errors: string[] = [];
    const { form, fields, key } = this.validationNotification;
    /** No changes have been made, do not error yet. */
    if (form.pristine) return;
    if (form && fields) {
      fields.forEach(field => {
        errors = [...errors, ...this.handleValidationErrors(form, field)];
      });
    }
    this.messageService.clear(key);
    this.messageService.addAll(
      errors.map((detail, index) => ({
        severity: 'error',
        summary: `ERROR (${index + 1} of ${errors.length})`,
        sticky: true,
        detail,
        key
      }))
    );
  }

  handleValidationErrors(form: NgForm, field: string) {
    const errors: string[] = [];
    if (form.controls[field] && form.controls[field].errors) {
      for (const error in form.controls[field].errors) {
        if (!error) continue;
        const titleCasedField = TextTransform.camelCaseToTitleCase(field);
        if (error === 'pattern') {
          const { actualValue, requiredPattern } = form.controls[field].errors.pattern;
          const test = new RegExp(requiredPattern.replace(/\^|\$/g, ''));
          const invalidCharacters = actualValue.replace(test, '');
          const gt1 = invalidCharacters.length > 1;
          errors.push(
            `Invalid character${gt1 ? 's' : ''} ${gt1 ? 'are' : 'is'} used in ${titleCasedField}.`
          );
        }
        if (error === 'required') {
          errors.push(`${titleCasedField} is required.`);
        }
        if (error === 'minlength') {
          errors.push(
            `${titleCasedField} has a minimum length of ${form.controls[field].errors.minlength.requiredLength}.`
          );
        }
        if (error === 'maxlength') {
          errors.push(
            `${titleCasedField} has a minimum length of ${form.controls[field].errors.maxlength.requiredLength}.`
          );
        }
      }
    }
    return errors;
  }
}

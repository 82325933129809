import { Component, Input } from '@angular/core';

@Component({
  selector: 'percentage-circle',
  templateUrl: './percentage-circle.component.html',
  styleUrls: ['./percentage-circle.component.scss']
})
export class PercentageCircleComponent {
  @Input() showTitle = true;
  @Input() showUnits = true;
  // 0 - 100
  private _percentage: number;
  @Input() set percentage(percentage: number) {
    this._percentage = percentage;
  }

  get percentage() {
    if (this._percentage < 0) return 0;
    if (this._percentage > 100) return 100;
    return this._percentage;
  }
}

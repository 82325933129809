<p-card>
  <div class="p-card-title ng-star-inserted">
    SUPER ADMIN ONLY
    <tooltip-button
      tooltip="This feature is only available to Fan Guru staff. If you manage several events and spend considerable time updating your guest or exhibitor description each time, please send us an email with a request to save your version of this exhibitor or guest globally so the information will appear by default when added to other events."
    ></tooltip-button>
    <div class="actions">
      <div class="button-container">
        <button
          pButton
          type="button"
          label="Apply Data to Global Object"
          class="p-button-primary"
          [disabled]="!isAdmin"
          (click)="confirmSaveAsGlobal()"
        ></button>
      </div>
    </div>
  </div>
</p-card>

import { Injectable } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { Confirmation, ConfirmationService, MessageService } from 'primeng/api';
import { Message } from 'primeng/api/message';
import { Observable } from 'rxjs';

export enum ConfirmationResponse {
  Accept = 'accept',
  Reject = 'reject',
  Close = 'close'
}

@Injectable()
export class UiService {
  public toasts: Message[] = [];
  public styleClass = '';
  view: string;
  constructor(
    private messageService: MessageService,
    private apiCallsInProgress: ApiCallsInProgress,
    private confirmationService: ConfirmationService
  ) {}

  setStyleClass(styleClass: string) {
    this.styleClass = styleClass;
  }

  errorHandler = (error: any) => {
    const errorObject = error?.error?.error;
    this.toast({
      severity: 'error',
      detail:
        errorObject?.userMessage ??
        errorObject?.message ??
        errorObject?.developerMsg ??
        'An unknown error occurred'
    });
    this.apiCallsInProgress.reset();
  };

  toast(config: Message): void {
    this.messageService.clear('global');
    this.messageService.add({ ...config, key: 'global' });
  }

  getNumeratedName(tag, items, field = 'name') {
    let name = tag;
    for (let i = 0, length = items.length, numerator = 1; i < length; i++) {
      name = tag + ' ' + numerator;
      if (items[i][field] === name) {
        numerator++;
        i = -1;
      }
    }
    return name;
  }

  setView(nav) {
    /** Look for active nav item */
    const currentNavItem = nav.reduce((acc: string | null, cur) => {
      // already found.
      if (acc) {
        return acc;
      }
      // cur.items means it's a drop down, search children nodes.
      if (cur.items) {
        const child = cur.items.find(c2 => c2.fatherActive);
        return child ? `${cur.label} > ${child.label}` : null;
      } else {
        return cur.fatherActive ? cur.label : null;
      }
    }, null);
    if (currentNavItem) {
      // Some nav items include count ex: Events (8). This selects everything up to the `(`
      const itemIncludesCount = /^(.*?)\(/.exec(currentNavItem);
      this.view = itemIncludesCount ? itemIncludesCount[1].trim() : currentNavItem;
    }
  }

  confirm(confirmation: Confirmation) {
    return new Observable<ConfirmationResponse>(observer => {
      this.confirmationService.confirm({
        ...confirmation,
        accept: () => {
          if (confirmation.accept) {
            confirmation.accept();
          }
          observer.next(ConfirmationResponse.Accept);
          observer.complete();
        },
        reject: (reason: string) => {
          if (confirmation.reject) {
            confirmation.reject(reason);
          }
          observer.next(
            reason === 'close' ? ConfirmationResponse.Close : ConfirmationResponse.Reject
          );
          observer.complete();
        }
      });
    });
  }
}

import { PaginatedResponse } from '@fg-types/paginated-response';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { GlobalGuest } from '@fg-types/global-guest';

import { Observable } from 'rxjs';

import { environment } from '@fg-environments/environment';
import { IPost } from '@fg-types/post';

@Injectable()
export class CelebrityService {
  private celebsURL = `${environment.API_URL + environment.API_VERSION}/Celebrities`;
  constructor(private http: HttpClient) {}

  getCelebConventionEvents(id: string, conventionIds?: [string]): Observable<any> {
    const params = new HttpParams().set(
      'conventionIds',
      conventionIds ? JSON.stringify(conventionIds) : null
    );
    return this.http.get(this.celebsURL + '/' + id + '/ConventionEvents', { params });
  }

  create(celeb: GlobalGuest) {
    return this.http.post<GlobalGuest>(this.celebsURL, celeb);
  }

  patch(celeb: any): Observable<any> {
    return this.http.patch<any>(`${this.celebsURL}/${celeb.id}`, celeb);
  }

  getCelebrityPosts(id: string, lastKey: string) {
    const params = new HttpParams();
    if (lastKey) params.set('lastKey', lastKey ? JSON.stringify(lastKey) : null);
    return this.http.get<PaginatedResponse<IPost>>(`${this.celebsURL}/${id}/Posts`, { params });
  }
}

import { AnalyticsService } from '@fg-services/analytics.service';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { AppleLoginService } from '@fg-services/apple-login.service';
import { CelebrityService } from '@fg-services/celebrity.service';
import { ConfigService } from '@fg-services/config-service.service';
import { ConventionButtonService } from '@fg-services/convention-button.service';
import { ConventionEventService } from '@fg-services/convention-event.service';
import { ConventionExhibitorService } from '@fg-services/convention-exhibitor.service';
import { ConventionGroupedChallengeService } from '@fg-services/convention-grouped-challenge.service';
import { ConventionOpenChannelService } from '@fg-services/convention-open-channel.service';
import { ConventionServiceService } from '@fg-services/convention-service.service';
import { ConventionUserService } from '@fg-services/convention-user.service';
import { ContestDataService, ContestService } from '@fg-services/creator-contest.service';
import { CommunityData, CreatorService, CreatorSubsription, DummyDataService, PollService } from '@fg-services/dummy.service';
import { FansService } from '@fg-services/fans.service';
import { FgService } from '@fg-services/fg-service.service';
import { InfoBannerService } from '@fg-services/info-banner.service';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { ManagerService } from '@fg-services/manager.service';
import { MediaService } from '@fg-services/media.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { PostService } from '@fg-services/post.service';
import { RefreshPreviewProgress } from '@fg-services/refresh-preview.service';
import { ReportService } from '@fg-services/report.service';
import { ScheduledNotificationService } from '@fg-services/scheduled-notification.service';
import { TagService } from '@fg-services/tag.service';
import { TimeService } from '@fg-services/time.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { AdBlockGuard } from '@fg-shared/guards/ad-block-guard';
import { AuthGuard } from '@fg-shared/guards/auth.guard';
import { BsGuard } from '@fg-shared/guards/bs.guard';
import { EhOnBoardGuard } from '@fg-shared/guards/eh-onboard.guard';
import { FandomAdminGuard } from '@fg-shared/guards/fandom-admin.guard';
import { NotAuthGuard } from '@fg-shared/guards/not-auth.guard';
import { PendingChangesGuard } from '@fg-shared/guards/pending-changes-guard';
import { ConfirmationService, MessageService } from 'primeng/api';

export const GlobalServices = [
  ApiCallsInProgress,
  RefreshPreviewProgress,
  UiService,
  ConventionEventService,
  CelebrityService,
  UserService,
  FansService,
  LocalStorageService,
  AuthGuard,
  FandomAdminGuard,
  BsGuard,
  NotAuthGuard,
  EhOnBoardGuard,
  PendingChangesGuard,
  ManagerService,
  MediaService,
  PostService,
  TagService,
  ReportService,
  AnalyticsService,
  TimeService,
  MessageService,
  ConventionServiceService,
  FgService,
  ConfirmationService,
  ConventionButtonService,
  ConventionGroupedChallengeService,
  ScheduledNotificationService,
  ConventionUserService,
  ConventionOpenChannelService,
  ConventionExhibitorService,
  NavigationContextService,
  ApiService,
  AdBlockGuard,
  InfoBannerService,
  ConfigService,
  AppleLoginService,
  DummyDataService,
  CreatorSubsription,
  CreatorService,
  PollService,
  CommunityData,
  ContestService,
  ContestDataService,
];

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs/';

@Injectable()
export class RefreshPreviewProgress {
  private subject: Subject<any> = new Subject<any>();
  getObservable(): Observable<any> {
    return this.subject.asObservable();
  }

  refresh() {
    this.subject.next();
  }
}

import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CreatorNotification } from "@fg-types/creator-notification";
import { zonedTimeToUtc } from "date-fns-tz";

@Component({
    selector: 'creator-notification-tile',
    templateUrl: './creator-notification-tile.component.html',
    styleUrls: ['./creator-notification-tile.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CreatorNotificationTileComponent implements OnInit {
    @Input() data: CreatorNotification;
    @Input() active?: boolean;
    @Output() onEdit = new EventEmitter<void>();
    @Output() onSendNow = new EventEmitter<void>();
    @Output() onDelete = new EventEmitter<any>();
    @Input() hoverActive: boolean;
    defaultString=""
    whoCanSee : boolean;
  
    ngOnInit(): void {
        if(this.data?.subscriptionBaseIds?.length>0){
            this.whoCanSee = true
        }else{
            this.whoCanSee = false
        }
    }

    get time(){
        return new Date(this.data?.scheduledDate).toLocaleString();
    }
}

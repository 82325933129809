<div class="p-grid p-dir-col card">
  <p-panel header="NEW EVENT">
    <div class="p-grid">
      <div class="p-col-12 input-container" *ngIf="roles.isAdmin; else notAdmin">
        <div class="input-cell">
          <label>ORGANIZATION</label>
          <search-organization
            (addItem)="orgId = $event.id"
            [clearOnSelect]="false"
          ></search-organization>
        </div>
      </div>
      <ng-template #notAdmin>
        <div
          class="input-container p-col-12"
          *ngIf="organizations?.length && roles.isOrganizationAdmin"
        >
          <div class="input-cell">
            <label>ORGANIZATION</label>
            <p-dropdown [options]="organizations" [(ngModel)]="orgId" name="type"></p-dropdown>
          </div>
        </div>
      </ng-template>
      <div class="p-col-12 input-container">
        <div class="input-cell">
          <label>EVENT NAME *</label>
          <input pInputText name="name" type="text" [(ngModel)]="convention.name" />
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <label for="test-time-zones">Time Zone</label>
          <p-dropdown
            required
            inputId="test-time-zones"
            [options]="timezones"
            [(ngModel)]="convention.timeZoneId"
            placeholder="Select Timezone"
            [filter]="true"
            filterBy="label"
            optionValue="value"
            optionLabel="label"
          >
          </p-dropdown>
        </div>
      </div>
      <div *ngIf="!convention.isStartDateExempt" class="p-col-12 p-md-6">
        <fg-date-picker
          [tooltip]="!convention.timeZoneId ? 'Please set a timezone above to select dates.' : ''"
          [disabled]="!convention.timeZoneId"
          [timeZone]="convention.timeZoneId"
          [date]="convention.startDate"
          name="startDate"
          [inputStyle]="{
            'background-color': 'transparent'
          }"
          label="START DATE *"
          (onChange)="convention.startDate = $event"
        ></fg-date-picker>
      </div>
      <div *ngIf="!convention.isStartDateExempt" class="p-col-12 p-md-6">
        <fg-date-picker
          [tooltip]="!convention.timeZoneId ? 'Please set a timezone above to select dates.' : ''"
          [disabled]="!convention.timeZoneId"
          [timeZone]="convention.timeZoneId"
          [date]="convention.endDate"
          name="endDate"
          [inputStyle]="{
            'background-color': 'transparent'
          }"
          label="END DATE *"
          (onChange)="convention.endDate = $event"
        ></fg-date-picker>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell inline-toggle">
          <span>
            <label>This event does not have a specific date</label>
            <tooltip-button
              tooltip="Only turn this on if you wish to configure long-term ticketing for a venue."
            ></tooltip-button>
          </span>
          <p-inputSwitch
            [disabled]="!convention.timeZoneId"
            name="isStartDateExempt"
            [(ngModel)]="convention.isStartDateExempt"
          ></p-inputSwitch>
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell event-types">
          <label>Event Environment</label>
          <div class="radio-container p-grid">
            <p-radioButton
              [label]="env"
              class="p-col-12 p-pl-0"
              [value]="env"
              [(ngModel)]="convention.environment"
              checkboxIcon="pi pi-check"
              *ngFor="let env of eventEnvironments"
            ></p-radioButton>
          </div>
        </div>
      </div>
      <div class="p-col-12">
        <fg-google-autocomplete
          name="fullAddress"
          (setAddress)="convention.fullAddress = $event.formatted_address"
          adressType="geocode"
          [label]="(eventType === 'virtual' ? 'Timezone ' : '') + 'Location'"
          [autocompleteInput]="convention.fullAddress"
          (delete)="convention.fullAddress = ''"
          [tooltip]="eventType === 'virtual' ? 'Enter an address within your timezone' : ''"
        ></fg-google-autocomplete>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <label>DESCRIPTION</label>
          <textarea
            pInputText
            name="description"
            rows="15"
            maxlength="3000"
            [(ngModel)]="convention.description"
          ></textarea>
        </div>
      </div>
      <div class="input-container p-col-12">
        <div class="input-cell">
          <cropper
            [img]="convention?.logoHorizontal?.url"
            (setImage)="setImage($event)"
            width="256px"
            height="144px"
            [finalWidth]="1280"
            [finalHeight]="720"
            [marginAuto]="false"
            footer="UPLOAD"
            header="JPG or PNG image"
            [showDeleteIcon]="convention?.logoHorizontal?.url"
            (onDelete)="deleteImage()"
          ></cropper>
        </div>
      </div>
      <div class="clearfix"></div>
    </div>
  </p-panel>
  <br />
  <button
    [disabled]="
      !convention.name ||
      (!convention.startDate && !convention.isStartDateExempt) ||
      (!convention.endDate && !convention.isStartDateExempt)
    "
    pButton
    class="p-button-primary full-width large"
    type="button"
    label="CREATE EVENT"
    (click)="saveConvention()"
  ></button>
  <br />
  <button
    pButton
    class="p-button-secondary full-width large"
    type="button"
    label="CANCEL"
    (click)="cancel.emit()"
  ></button>
  <br />
</div>

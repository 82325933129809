import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';

@Component({
  selector: 'fg-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit, OnDestroy {
  subscription: any;
  visible: boolean;
  constructor(
    private apiCallsInProgress: ApiCallsInProgress,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.subscription = this.apiCallsInProgress.getObservable().subscribe(calls => {
      this.visible = calls > 0;
      this.changeDetectorRef.detectChanges();
    });
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}

<div class="multi-select-radio p-col-12 p-px-0">
  <p-panel styleClass="with-header" *ngIf="cardWrapper">
    <ng-template pTemplate="header">
      {{ title }} <tooltip-button *ngIf="tooltip" [tooltip]="tooltip"></tooltip-button>
    </ng-template>
    <br />
    <label *ngIf="modelName">Select all that apply below or add a new {{ modelName }}</label>
    <ng-template [ngTemplateOutlet]="list"></ng-template>
  </p-panel>
  <ng-template *ngIf="!cardWrapper" [ngTemplateOutlet]="list"></ng-template>
</div>

<ng-template #list>
  <div class="p-grid">
    <div class="p-col-12 p-mt-3" *ngIf="allowNewCategories">
      <div class="input-container">
        <div class="input-cell">
          <label *ngIf="modelName">ADD {{ modelName }}</label>
          <span class="inline-flex">
            <input
              [disabled]="disabled"
              pInputText
              class="input"
              name="newCategory"
              type="text"
              [placeholder]="placeholder"
              [(ngModel)]="newCategory"
              [ngModelOptions]="{ standalone: true }"
              (keydown.enter)="addEvent($event.target.value)"
            />
            <div class="spacer"></div>
            <button
              [disabled]="!newCategory"
              pButton
              class="p-button-primary large"
              type="button"
              label="CREATE"
              (click)="addEvent(newCategory)"
            ></button>
          </span>
        </div>
      </div>
    </div>
    <div class="input-container p-col-12">
      <div class="input-cell">
        <fg-multi-select-radio
          class="p-grid"
          [disabled]="disabled"
          [items]="allCategories"
          [selectedItems]="selectedCategories"
          (onSelect)="onSelect.next($event)"
          [containerClass]="containerClass"
        >
        </fg-multi-select-radio>
      </div>
    </div>
    <div class="clearfix"></div>
  </div>
</ng-template>

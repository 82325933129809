import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { IConventionEvent } from '@fg-types/convention-event';
import { IReport } from '@fg-types/report';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'reported-schedule-event-tile',
  template: `
    <schedule-event-tile
      (onClick)="onClick.emit()"
      [active]="active"
      [data]="data?.item"
      [hoverActive]="hoverActive"
    >
      <ng-content></ng-content>
    </schedule-event-tile>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportedScheduleEventTileComponent implements ListItem<IReport<IConventionEvent>> {
  @Input() data: IReport<IConventionEvent>;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

<tile
  (onClick)="onClick.emit()"
  [active]="active"
  [title]="title"
  [description]="data?.label"
  [media]="media"
  [hoverActive]="hoverActive"
>
  <ng-content></ng-content>
</tile>

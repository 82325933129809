import { ApiBase } from '@fg-services/api/models/api-base';
import { Category } from '@fg-types/category';
import { DeleteResponse } from '@fg-types/delete-response';

export class CategoryApi extends ApiBase {
  model = 'Categories';
  createCategory(category: any) {
    return this.http.post<Category>(this.modelBase, category);
  }

  patchCategory(id: string, category: any) {
    return this.http.patch<Category>(`${this.modelBase}/${id}`, category);
  }

  deleteCategory(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

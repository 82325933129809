<div class="input-container">
  <div class="input-cell">
    <div>
      <label>{{ title }}</label>
      <tooltip-button *ngIf="tooltip" [tooltip]="tooltip"></tooltip-button>
    </div>
    <span class="inline-flex">
      <input
        #deeplinkInput
        pInputText
        readonly
        class="input p-col p-mr-1"
        type="text"
        [value]="deeplink"
      />
      <button
        pButton
        class="p-button-secondary p-col-fixed"
        style="width: 104px"
        type="button"
        label="COPY"
        (click)="copyLink()"
      ></button>
    </span>
  </div>
</div>

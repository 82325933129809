import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ApiService } from '@fg-services/api/api.service';
import { UserService } from '@fg-services/user.service';
import { FeaturedService } from '@fg-shared/featured-content/featured.service';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConvention } from '@fg-types/convention.type';

@Component({
  selector: 'search-convention',
  templateUrl: './search-convention.component.html',
  styleUrls: ['./search-convention.component.scss']
})
export class SearchConventionComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() allConventions = false;
  @Input() organizationId  =  this.featuredService.modelId
  @Input() placeholder = 'Type to search an Event';
  @Output() addItem = new EventEmitter<IConvention>();
  filtered: IConvention[] = [];
  convention = '';

  constructor(private apiService: ApiService, private userService: UserService,public featuredService: FeaturedService,) {}

  ngOnInit() {}

  filter(params?: AutoCompleteEvent) {
    if (params) {
      this.apiService.search
        .conventions(params.query, null, this.organizationId)
        .subscribe(({ items }) => (this.filtered = items));
    }
  }
}

import { HttpParams } from '@angular/common/http';

export class NetworkHelpers {
  static setParams(paramsTemplate: any) {
    let params = new HttpParams();
    for (const key in paramsTemplate) {
      if (paramsTemplate[key]) {
        const element = paramsTemplate[key];
        params =
          typeof element === 'string'
            ? params.set(key, element)
            : params.set(key, element.toString());
      }
    }
    return params;
  }
}

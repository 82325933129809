import { MenuItem, SubMenuItemsToRemove } from '@fg-app/app-menu/app-submenu/types';
import { AllRoles } from '@fg-services/navigation-context/types';
import { UserRoles } from '@fg-types/roles.type';

export abstract class BaseMenuItem<T> {
  constructor(
    protected context: T,
    protected routePath: string[] = [],
    protected userRoles: UserRoles
  ) {}

  removeSideNavItems(menuItems: MenuItem[], role: AllRoles, labelsToRemove: string[]) {
    return menuItems.filter(item => {
      const roleAllowed = !item.roles?.length || (item.roles?.includes(role) ?? true);
      const removeItem = labelsToRemove.includes(item.label);
      return roleAllowed && !removeItem;
    });
  }

  removeSubNavItems(menuItems: MenuItem[], itemsToRemove: SubMenuItemsToRemove[]) {
    if (!itemsToRemove || !itemsToRemove.length) return menuItems;
    return menuItems.map(item => {
      if (item.items) {
        const config = itemsToRemove.find(label => item.label === label.parentLabel);
        return config
          ? {
              ...item,
              items: item.items.filter(subItem => !subItem.label.includes(config.childLabel))
            }
          : item;
      }
      return item;
    });
  }

  abstract menuItems(): MenuItem[];
}

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'imageuploader',
  templateUrl: './imageuploader.component.html',
  styleUrls: ['./imageuploader.component.scss']
})
export class ImageuploaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  // selectSingleFile(event): void {
  //   if(event.target.files){
  //       var reader = new FileReader();
  //       reader.readAsDataURL(event.target.files[0]);
  //       reader.onload=(e:any)=>{
  //           this.newSubscription.image=e.target.result;
  //       }
  //   }
  // }

}

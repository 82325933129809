import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SurveyResponse } from '@fg-types/survey';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'survey-response-tile',
  templateUrl: './survey-response-tile.component.html',
  styleUrls: ['./survey-response-tile.component.scss']
})
export class SurveyResponseTileComponent implements OnInit, ListItem<SurveyResponse> {
  @Input() data: SurveyResponse;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;

  constructor() {}
  ngOnInit() {}
}

<div class="light-box">
  <div class="light-box-body">
    <img #image [src]="selectedImage" [style.display]="isGif ? 'none' : 'inline'" />
    <div class="alert" *ngIf="isGif">
      Gif files can't be cropped.
    </div>
    <div class="footer">
      <button pButton class="p-button-secondary" (click)="cancel.emit()" label="CANCEL"></button>
      <div class="spacer"></div>
      <button pButton class="p-button-primary" (click)="onConfirm()" label="CONFIRM"></button>
    </div>
    <div class="clearfix"></div>
  </div>
</div>
<div class="light-box-background"></div>

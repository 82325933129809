import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiCallsInProgress } from '@fg-services/api-calls-in-progress.service';
import { ApiService } from '@fg-services/api/api.service';
import { CreatorService } from '@fg-services/dummy.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { FilterEvent } from '@fg-shared/layout/filter-events/filter-events.type';
import { AutoCompleteEvent } from '@fg-types/autoCompleteEvent';
import { IConvention } from '@fg-types/convention.type';
import { CreatorData } from '@fg-types/creator';
import { UserRoles } from '@fg-types/roles.type';

@Component({
  selector: 'calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss']
})
export class CalenderComponent extends BaseComponent implements OnInit {
  loading: boolean;
  items: IConvention[];
  lastKey: string;
  noEvents: boolean;
  scrollFinished: boolean;
  creator: CreatorData;
  organizationId: string;
  searchText="";
  userRoles: UserRoles;

  constructor(
    private apiCallsInProgress:ApiCallsInProgress,
    private api: ApiService,
    private creatorService: CreatorService,
    private router:Router,
    private navigatinContext: NavigationContextService,
    private userService : UserService,
  ) {
    super(navigatinContext);
  }

  ngOnInit(): void {
    this.creatorService.currentCreator$.subscribe(creator=>{
      this.creator = creator;
      this.loadItems();
    });  
  }
  loadRoles(){
    if (!this.userRoles) {
      this.userService.loadRoles().subscribe(roles => {
        this.userRoles = roles;
        this.loadItems();
      });
    } else {
      this.userRoles = this.userService.roles;
      this.loadItems();
    }
  }
  loadItems() {
    this.loading = true;
    this.apiCallsInProgress.addCall();
    this.creator?.id && this.api.creator.getCreatorConventions(this.creator).subscribe(
      ({items,lastKey})=>{
        this.items = this.lastKey ? [...this.items, ...items] : items;
        this.scrollFinished = !items.length || !lastKey;
        this.lastKey = lastKey;
        this.apiCallsInProgress.reset();
        if (this.items.length === 0) this.noEvents = true;
        else{
            this.noEvents= false;
            this.items= this.items    
        }
      }
    )
    this.creator && this.api.creator.getCreatorOrg(this.creator).subscribe(res=>{
      this.organizationId = res?.id
    })
    this.apiCallsInProgress.reset();
  }
  selectItem(item: IConvention) {
    this.router.navigate(['bs', 'c', item.id]);
  }
  searchEvents(params?: AutoCompleteEvent) {
    if (params) {
      this.api.search.conventions(this.searchText, "", this.organizationId)
        .subscribe(({ items }) => (this.items = items));
    }
  }
  applyfilter(event:any){
    console.log(event)
  }
}

import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { IUser } from '@fg-types/user.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'user-tile',
  templateUrl: './user-tile.component.html',
  styleUrls: ['./user-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTileComponent implements OnInit, ListItem<IUser> {
  @Input() data: IUser;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  @Input() altTitle: string;
  @Input() maxLines = 3;
  @Input() truncate = true;
  _description : string
  
  get UserName(): string {
    return this.data?.username ? `@${this.data.username}` : `${''}`;
  }
  get image() {
    if (!this.data?.profilePhoto) return undefined;
    if (typeof this.data?.profilePhoto === 'string') return `url(${this.data?.profilePhoto})`;
    if (this.data?.profilePhoto.url) return `url(${this.data?.profilePhoto.url})`;
    if (this.data?.profilePhoto.sizes && this.data?.profilePhoto.sizes.length) return `url(${this.data?.profilePhoto.sizes[0].url})`;
  }
  get description(): string {
    return this._description=this.data?.bio
    
  }
  // showDescription(_description: string) {
  //   console.log(this._description,"asdasdasd")
  //   if (!_description) return;
  //   /** If category labels are present, hide descriptions longer than 23
  //    * to allow full title and categories to fit */
  //   return !(this.data.name.length > 23);
  // }

  constructor() {
  }
  ngOnInit() {

  }
}

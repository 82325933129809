import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '@fg-services/localstorage.service';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit {
  constructor(
    navigationContextService: NavigationContextService,
    private router: Router,
    private userService: UserService,
    private localStorageService: LocalStorageService
  ) {
    super(navigationContextService);
  }

  get initialRoute() {
    const {
      isAdmin,
      isOrganizationMod,
      isOrganizationAdmin,
      isFandomMod,
      isFandomAdmin,
      isConMod,
      isConAdmin
    } = this.userService.roles;
    const orgAccess = isOrganizationAdmin || isOrganizationMod || isConAdmin;
    const fandomAccess = isFandomAdmin || isFandomMod || isConAdmin;
    const eventAccess = isConAdmin || isConMod || isAdmin;
    const lastHomeOption = this.localStorageService.getItem('last-home-option');
    // If the user has access to the last option
    if (lastHomeOption === 'organizations' && orgAccess) return 'organizations';
    if (lastHomeOption === 'communities' && fandomAccess) return 'communities';
    if (lastHomeOption === 'events' && eventAccess) return 'events';
    // Default option
    if (eventAccess) return 'events';
    if (fandomAccess) return 'communities';
    if (orgAccess) return 'organizations';
    return 'organizations';
  }

  ngOnInit() {
    this.router.navigate(['bs', this.initialRoute], { replaceUrl: true });
  }
}

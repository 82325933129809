import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { activityMap } from '@fg-app/backstage/c/analytics/general-analytics/percentages-report/activityMap';
import { environment } from '@fg-environments/environment';
import { NetworkHelpers } from '@fg-shared/helpers/network-helpers';
import {
  ActivityMapItem,
  AnalyticsCategoryResponse,
  AnalyticsFandomResponse,
  AnalyticsItem,
  AnalyticsPercentageResponse,
  NotificationAnalyticsItem,
  NotificationAnalyticsResponse
} from '@fg-types/analytics';
import { Category } from '@fg-types/category';
import { Community } from '@fg-types/community';
import {
  IConventionRatingSummaryCommon,
  IConventionSurveyResponseData
} from '@fg-types/convention-rating';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable()
export class AnalyticsService {
  cachedFandoms = new Map<string, Community[]>();
  cachedCategories = new Map<string, Category[]>();
  cachedEvent = new Map<string, IConventionRatingSummaryCommon[]>();
  cachedAnalytics = new Map<string, AnalyticsItem[]>();
  cachedNotifications = new Map<string, NotificationAnalyticsItem[]>();

  constructor(private http: HttpClient) {}

  getFanFandomsAnalytics(id: string) {
    return this.http
      .get<AnalyticsFandomResponse[]>(this.getConventionDataURL(id), {
        params: NetworkHelpers.setParams({ type: 'fandom' })
      })
      .pipe(
        map(this.mapCommunities),
        tap(res => this.cachedFandoms.set(id, res))
      );
  }

  getFanCategoriesAnalytics(id: string) {
    return this.http
      .get<AnalyticsCategoryResponse[]>(this.getConventionDataURL(id), {
        params: NetworkHelpers.setParams({ type: 'category' })
      })
      .pipe(
        map(this.mapCategorys),
        tap(res => this.cachedCategories.set(id, res))
      );
  }

  getEventAnalytics(id: string) {
    return this.http.get<IConventionSurveyResponseData>(this.getConventionSurveyDataURL(id)).pipe(
      map(survey => survey.questionResponses),
      tap(res => {
        this.cachedEvent.set(id, res);
        return res;
      })
    );
  }

  getFanPercentageAnalytics(id: string) {
    return this.http
      .get<AnalyticsPercentageResponse>(this.getConventionDataURL(id), {
        params: NetworkHelpers.setParams({ type: 'percentage' })
      })
      .pipe(
        map(res => activityMap.map(item => this.mapFanPercentageItem(item, res))),
        tap(res => this.cachedAnalytics.set(id, res))
      );
  }

  getNotificationAnalytics(id: string): Observable<NotificationAnalyticsItem[]> {
    return this.http
      .get<NotificationAnalyticsResponse>(this.getConventionDataURL(id), {
        params: NetworkHelpers.setParams({ type: 'notification' })
      })
      .pipe(
        map(res => [
          { label: 'Total Notifications Sent', value: res.total.allNotificationSentCount },
          { label: 'Custom Notifications Sent', value: res.total.campaignNotificationSentCount },
          {
            label: 'Schedule Event Reminders',
            value: res.conventionEventReminders.notificationSentCount
          },
          {
            label: 'Schedule Event Review Reminders',
            value: res.conventionEventRatingReminders.notificationSentCount
          }
        ]),
        tap(res => this.cachedNotifications.set(id, res))
      );
  }

  private getConventionDataURL = (id: string) =>
    `${environment.API_URL + environment.API_VERSION}/Conventions/${id}/data`;

  private getConventionSurveyDataURL = (id: string) =>
    `${environment.API_URL + environment.API_VERSION}/Surveys/${id}/ResponseData`;

  private mapCommunities(items: AnalyticsFandomResponse[]): Community[] {
    return items.map(item => ({
      ...item.fandom,
      subscriberCount: item.count,
      description: `${Math.round(item.fandomPercentage * 100).toString()}%`
    }));
  }

  private mapFanPercentageItem(
    item: ActivityMapItem,
    percentages: AnalyticsPercentageResponse
  ): AnalyticsItem {
    const percentage =
      typeof item.percentage === 'function'
        ? item.percentage()
        : percentages[item.percentage] * 100;
    return {
      count: percentages[item.count],
      percentage,
      title: item.title
    };
  }

  private mapCategorys(items: AnalyticsCategoryResponse[]): Category[] {
    return items.map(item => ({
      ...item.category,
      subscriberCount: item.count,
      description: `${Math.round(item.categoryPercentage * 100).toString()}%`
    }));
  }
}

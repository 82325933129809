import { Component, EventEmitter, Input, Output } from '@angular/core';
import { joinNonEmptyStrings } from '@fg-shared/helpers/join-non-empty-strings';
import { conventionData, formatEventDate } from '@fg-shared/tile/event-card/helpers';
import { IConvention } from '@fg-types/convention.type';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'event-card',
  templateUrl: './event-card.component.html',
  styleUrls: ['./event-card.component.scss']
})
export class EventCardComponent implements ListItem<IConvention> {
  get image() {
    if (!this.data.logoHorizontal) return undefined;
    if (typeof this.data.logoHorizontal === 'string') return `url(${this.data.logoHorizontal})`;
    if (this.data.logoHorizontal.url) return `url(${this.data.logoHorizontal.url})`;
    if (this.data.logoHorizontal.sizes && this.data.logoHorizontal.sizes.length)
      return `url(${this.data.logoHorizontal.sizes[0].url})`;
  }

  get conventionData() {
    const data = conventionData(this.data);
    return joinNonEmptyStrings(data, '\n');
  }

  get statusIndicatorClass() {
    if (this.data.goLive === 'Live') return 'accent circle';
    return 'red circle';
  }

  get conventionDate() {
    console.log(this.data,"ooooos")
    return formatEventDate(this.data);
  }

  @Input() data: IConvention;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
}

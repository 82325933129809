import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent {
  @Input() placeholder = '';
  @Input() query = '';
  @Input() disabled: boolean;
  @Output() onSearch = new EventEmitter<string>();
  delaySearch$ = new Subject<void>();

  constructor() {
    this.delaySearch$.pipe(debounceTime(800)).subscribe(() => this.onSearch.next(this.query));
  }
}

<div class="faq-header">Frequently Asked Questions</div>
<hr />
<div *ngFor="let faq of faqs; let i = index" class="panel-container">
  <p-panel [toggleable]="true" [collapsed]="i !== 0">
    <ng-template pTemplate="header">
      {{ faq.question }}
    </ng-template>
    <div [innerHtml]="faq.answer"></div>
  </p-panel>
  <hr />
</div>

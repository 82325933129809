<div class="fg-view-container fg-view-container-bg "  >
  <div class="layout-padding fg-col-body">
    <div class="input-container p-col-12">
      <div class="input-cell">
        <label>Account Activity</label>
      </div>
    </div>
    <p-card class="p-col-12">
      <div class="input-container p-col-12">
        <div class="input-cell">
          <label>{{count}} Subscribers</label>
        </div>
      </div>
      <canvas id="myChart"></canvas>
    </p-card>
    <div class="p-grid">
      <p-card class="p-col-6" header="Recent Subscribers">
        <div *ngFor="let item of filteredItems">
          <div class="p-grid list">
            <div>
              <img [src]="(item?.user?.profilePhoto.sizes)[0]?.url" class="profile-nav" />
            </div>
            <div class="input-container ">
              <div class="input-cell">
                <p><label>{{item?.user?.name}}</label> @{{item?.user?.username}}</p>
                <div>{{timeAgo(item)}}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-mt-2" *ngIf="filteredItems?.length === 0"><label>No Recent Subscribers.</label></div>
      </p-card>
      <p-card class="p-col-6" header="By Country">
        <div class="p-mt-2" *ngIf="filteredItems?.length === 0"><label>No Data Available.</label></div>
      </p-card>
    </div>
  </div>
</div>



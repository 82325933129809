import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { ModelUser, ModelUserSuportedModels, ModelUserSuportedRoles } from '@fg-types/model-user';
import { PaginatedResponse } from '@fg-types/paginated-response';

export class ModelUserApi extends ApiBase {
  model = 'ModelUsers';

  get(modelName: ModelUserSuportedModels, modelId: string, lastKey?: string) {
    return this.http.get<PaginatedResponse<ModelUser>>(this.modelBase, {
      params: this.setParams({ modelName, modelId, lastKey })
    });
  }

  add(data: {
    modelName: ModelUserSuportedModels;
    modelId: string;
    userId: string;
    role: ModelUserSuportedRoles;
  }) {
    return this.http.post<ModelUser>(this.modelBase, data);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

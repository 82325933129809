import { Injectable } from "@angular/core";
import { ApiBase } from "./api-base";
import { PromoData } from "@fg-types/promoType";

@Injectable({
    providedIn: 'root'
  })
export class CreatorPromoApi extends ApiBase{
    model = "StripePromos";

    createPromo(body: Partial<PromoData>){
      return this.http.post<PromoData>(`${this.modelBase}`,body)
    }

    updatePromo(item: PromoData){
      return this.http.patch<PromoData>(`${this.modelBase}/${item?.id}`,item)
    }

    getPromotionCode(stripePromoId : string){
      return this.http.get<PromoData>(`${this.modelBase}/Promo/${stripePromoId}`)
    }

    getPromoById(itemId: string){
      return this.http.get<any>(`${this.modelBase}/${itemId}`)
    }

    getAllPromos(creatorId: string, status: any){
      return this.http.get<any>(`${this.modelBase}/creator/${creatorId}/GetByStatus`,{
        params:{
          isActive: status
        }
      })
    }

    deactivatePromo(id: string,creatorId: string){
      return this.http.patch<any>(`${this.modelBase}/${id}/deactivate`,{
        creatorId: creatorId
      })
    }

    activatePromo(id: string){
      return this.http.patch<any>(`${this.modelBase}/${id}/activate`,{})
    }
}
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';
import { Community } from '@fg-types/community';
import { ListItem } from '@fg-shared/layout/flat-list/list-item.component';

@Component({
  selector: 'community-tile',
  templateUrl: './community-tile.component.html',
  styleUrls: ['./community-tile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CommunityTileComponent implements OnInit, ListItem<Community> {
  @Input() data: Community;
  @Input() commonTileSize = false;
  @Input() active?: boolean;
  @Output() onClick = new EventEmitter<void>();
  @Input() hoverActive: boolean;
  @Input() showFans = false;
  // @ts-ignore
  @Input() set mediaFlexBasis(val: string) {
    this._mediaFlexBasis = this.sanitizer.bypassSecurityTrustStyle(val);
  }

  // @ts-ignore
  get mediaFlexBasis(): SafeStyle {
    return this._mediaFlexBasis;
  }

  get image() {
    if (!this.data.coverPhoto) return undefined;
    if (typeof this.data.coverPhoto === 'string') return `url(${this.data.coverPhoto})`;
    if (this.data.coverPhoto.url) return `url(${this.data.coverPhoto.url})`;
    if (this.data.coverPhoto.sizes && this.data.coverPhoto.sizes.length)
      return `url(${this.data.coverPhoto.sizes[0].url})`;
  }

  _mediaFlexBasis: SafeStyle;

  constructor(private sanitizer: DomSanitizer) {}
  ngOnInit() {}
}

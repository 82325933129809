import { ApiBase } from '@fg-services/api/models/api-base';
import { DeleteResponse } from '@fg-types/delete-response';
import { ShippingMethodFlatRate } from '@fg-types/shipping';

export class ShippingMethodApi extends ApiBase {
  model = 'ShippingMethods';
  create(data: Partial<ShippingMethodFlatRate>) {
    return this.http.post<ShippingMethodFlatRate>(this.modelBase, data);
  }

  update(id: string, data: Partial<ShippingMethodFlatRate>) {
    return this.http.patch<ShippingMethodFlatRate>(`${this.modelBase}/${id}`, data);
  }

  getById(id: string) {
    return this.http.get<ShippingMethodFlatRate>(`${this.modelBase}/${id}`);
  }

  delete(id: string) {
    return this.http.delete<DeleteResponse>(`${this.modelBase}/${id}`);
  }
}

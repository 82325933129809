import { Component, Input } from '@angular/core';

@Component({
  selector: 'percentage-bar',
  templateUrl: './percentage-bar.component.html',
  styleUrls: ['./percentage-bar.component.scss']
})
export class PercentageBarComponent {
  @Input() positiveCount: number;
  @Input() negativeCount: number;
  @Input() set percentage(percentage: number) {
    this._percentage = percentage;
  }

  get percentage() {
    if (this._percentage < 0) return 0;
    if (this._percentage > 100) return 100;
    if (this._percentage > 0 && this._percentage < 1) {
      // convert to percent;
    }
    return this._percentage;
  }

  private _percentage: number;
}

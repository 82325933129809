import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@fg-services/user.service';
import { tap } from 'rxjs/operators';

@Injectable()
export class FandomAdminGuard implements CanActivate {
  constructor(private userServ: UserService, private router: Router) {}
  canActivate() {
    return this.userServ.isAuthenticated().pipe(
      tap(isAuth => {
        const { isFandomAdmin, isAdmin, isOrganizationAdmin } = this.userServ.roles;
        if (!isAuth || (!isFandomAdmin && !isAdmin && !isOrganizationAdmin) ) {
          this.router.navigate(['/bs']);
        }
      })
    );
  }
}

import { Component, Input, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AmplitudeService } from '@fg-services/amplitude.service';
import { InfoBannerService } from '@fg-services/info-banner.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

export interface RouterTab {
  label: string;
  route?: string;
  segment?: string;
  view?: string;
  icon?: string;
  action?: () => void;
  iconAction?: () => void;
}

@Component({
  selector: 'router-tabs',
  templateUrl: './router-tabs.component.html',
  styleUrls: ['./router-tabs.component.scss']
})
export class RouterTabsComponent implements OnDestroy {
  @Input() routes: RouterTab[] = [];
  @Input() exact = true;
  subscriptions: Subscription[] = [];
  constructor(
    private amplitudeService: AmplitudeService,
    public infoBannerService: InfoBannerService,
    private router: Router
  ) {
    this.subscriptions.push(
      this.router.events
        .pipe(
          filter((e): e is NavigationEnd => e instanceof NavigationEnd),
          map(() => {
            this.getRoute(3);
          })
        )
        .subscribe()
    );
  }

  getRoute(attemptsLeft) {
    if (this.routes.length) {
      const currentRoute = this.routes.find(({ route }) => {
        const regex = new RegExp(route + '$', 'gi');
        return regex.test(location.pathname);
      });
      if (currentRoute) {
        this.amplitudeService.logEvent('VIEW', {
          TITLE: currentRoute.view,
          SEGMENT: currentRoute.segment
        });
      }
    } else if (attemptsLeft) {
      setTimeout(() => {
        this.getRoute(--attemptsLeft);
      }, 500);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach(c => c.unsubscribe());
  }
}

import { Route } from '@angular/router';
import { cRoute } from '@fg-app/backstage/c/c-route';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { AuthGuard } from '@fg-shared/guards/auth.guard';

export const cmRoute: Route = {
  path: 'cm/:id',
  data: { segmentName: NavigableModel.Fandom },
  children: [
    {
      path: '',
      redirectTo: 'essentials',
      pathMatch: 'full'
    },
    {
      path: 'events',
      loadChildren: () => import('./cm-events/cm-events.module').then(m => m.CMEventsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'team',
      loadChildren: () => import('../../shared/team/team.module').then(m => m.TeamModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'featured',
      loadChildren: () =>
        import('@fg-shared/featured-content/featured-content.module').then(
          m => m.FeaturedContentModule
        ),
      canActivate: [AuthGuard]
    },
    {
      path: 'notifications',
      loadChildren: () =>
        import('@fg-shared/notifications/notifications.module').then(m => m.NotificationsModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'moderation',
      loadChildren: () =>
        import('./cm-moderation/cm-moderation.module').then(m => m.CmModerationModule),
      canActivate: [AuthGuard]
    },
    {
      path: 'essentials',
      loadChildren: () =>
        import('./cm-essentials/cm-essentials.module').then(m => m.CmEssentialsModule),
      canActivate: [AuthGuard]
    },
    cRoute
  ]
};

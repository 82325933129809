import { Component, ChangeDetectionStrategy, EventEmitter, Input, Output } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { ISubscriptionBase } from "@fg-types/subscriptionBase";

@Component({
    selector: 'creator-subscription-card',
    templateUrl: './creator-subscription-card.html',
    styleUrls: ['./creator-subscription-card.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
  })

export class CreatorSubscriptionBaseComponent{
    @Output() onSelect = new EventEmitter<ISubscriptionBase>();
    @Input() subscriptionBase: ISubscriptionBase;
  
    constructor(private domSanitizer: DomSanitizer) {}
  
    get image() {
      return this.subscriptionBase?.media?.url
        ? this.domSanitizer.bypassSecurityTrustStyle(`url("${this.subscriptionBase?.media?.url}")`)
        : '';
    }
  
    get price() {
      const amount = this.subscriptionBase.stripeProduct?.prices?.[0]?.unit_amount;
      return amount ? `$${(amount / 100).toFixed(2)}` : '';
    }
  }
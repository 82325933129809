import { ApiBase } from '@fg-services/api/models/api-base';
import { PaginatedResponse } from '@fg-types/paginated-response';
import { AddReferralParams, Referral } from '@fg-types/referral';

export class ReferralsApi extends ApiBase {
  model = 'Referrals';

  getReferrals(params: {
    referrerModelId?: string;
    referrerModelName?: string;
    referredModelId?: string;
    referredModelName?: string;
    lastKey?: string;
  }) {
    return this.http.get<PaginatedResponse<Referral>>(this.modelBase, { params });
  }

  addReferral(body: AddReferralParams) {
    return this.http.post<Referral>(this.modelBase, body);
  }
}

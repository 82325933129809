import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from '@fg-app/app-menu/app-submenu/types';
import { BackstageMenuItems } from '@fg-app/backstage/bs-menu-items';
import { ConventionMenuItems } from '@fg-app/backstage/c/c-menu-items';
import { CommunityMenuItems } from '@fg-app/backstage/cm/cm-menu-items';
import { OrganizationMenuItems } from '@fg-app/backstage/o/o-menu-items';
import { CreatorMenuItems } from '@fg-app/creator-platform/cp-menu-items';
import { ExhibitorHallMenuItems } from '@fg-app/exhibit-hall/eh-menu-items';
import { OzMenuItems } from '@fg-app/oz/oz-menu-items';
import { NavigationContextService } from '@fg-services/navigation-context/navigation-context.service';
import { NavigableModel } from '@fg-services/navigation-context/segments/navigation-context-segment';
import { UiService } from '@fg-services/ui.service';
import { UserService } from '@fg-services/user.service';
import { BaseComponent } from '@fg-shared/helpers/base.component';
import { UserRoles } from '@fg-types/roles.type';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent extends BaseComponent implements OnInit {
  @Input() reset: boolean;
  selectedNavItem: MenuItem;
  sidenavItems: MenuItem[];
  url: string;
  userRoles: UserRoles;
  constructor(
    private userService: UserService,
    private uiService: UiService,
    navigationContextService: NavigationContextService
  ) {
    super(navigationContextService);
  }

  ngOnInit() {
    const subs = this.userService.getRolesAsObservable().subscribe(roles => {
      this.userRoles = roles;
      this.updateMenu();
    });
    this.subscriptions.push(subs);
  }

  onContextChange = () => {
    this.updateMenu();
  };

  updateMenu() {
    this.sidenavItems = this.getMenuItems();
    this.updateOptions(this.navigationContextService.url);
  }

  getMenuItems() {
    if (!this.navigationContext) return [];
    const segment = this.navigationContext.getLastSegment();
    if (!segment || !this.userRoles) return [];
    switch (segment.segmentName) {
      case NavigableModel.Convention:
        return new ConventionMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.Fandom:
        return new CommunityMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.Organization:
        return new OrganizationMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.Backstage:
        return new BackstageMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.Exhibitor:
        return new ExhibitorHallMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.OZ:
        return new OzMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
      case NavigableModel.Creator:
        return new CreatorMenuItems(
          segment,
          this.navigationContext.getRoutePath(),
          this.userRoles
        ).menuItems();
    }
    return [];
  }

  parseMenu(sidenavItems: MenuItem[], url: string) {
    return sidenavItems.map((menuItem, menuIndex) => {
      const { routerLink } = menuItem;
      const routerLinkMenu = routerLink ? `/${routerLink.join('/')}` : null;
      if (routerLink && url.includes(routerLinkMenu)) {
        menuItem.fatherActive = true;
        menuItem.childActive = false;
      } else {
        menuItem.fatherActive = false;
        menuItem.childActive = false;
      }
      return menuItem;
    });
  }

  parseSubMenu(sidenavItems: MenuItem[], url: string) {
    return sidenavItems.map(menuItem => {
      if (menuItem.items) {
        menuItem.items.forEach(subMenuItem => {
          const { routerLink } = subMenuItem;
          const routerLinkSubmenu = routerLink ? `/${routerLink.join('/')}` : null;
          if (routerLink && url.includes(routerLinkSubmenu)) {
            menuItem.fatherActive = false;
            menuItem.childActive = true;
            const urlMap = url
              .split('/')
              .reduce((keyMap, path) => ({ ...keyMap, [path]: true }), {});

            /**
             * Ensure sub-nav items are exact path match.
             */
            if (routerLink.every(path => urlMap[path])) {
              subMenuItem.fatherActive = true;
              subMenuItem.childActive = false;
            }
          } else {
            subMenuItem.fatherActive = false;
            subMenuItem.childActive = false;
          }
          return subMenuItem;
        });
      }
      return menuItem;
    });
  }

  public updateOptions(url: string) {
    if (url) {
      this.sidenavItems = this.parseMenu(this.sidenavItems, url);
      this.sidenavItems = this.parseSubMenu(this.sidenavItems, url);
    }
    this.uiService.setView(this.sidenavItems);
  }
}
